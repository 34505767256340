import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import Movimientos from '../../Venta'
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [tipoacumulado, settipoacumulado] = useState("-");
    const [sucursal, setsucursal] = useState("-");
    const [editarorden, seteditarorden] = useState(false);
    const [movimientoenvio, setmovimientoenvio] = useState(false);

    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosIvaYDesc/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipoacumulado + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data.movimientos);
                // var conceptos = data.reduce((array, venta) => {
                //     if (!array[venta.concepto]) {
                //         array[venta.concepto] = 0;
                //     }
                //     array[venta.concepto] += venta.total;
                //     return array;
                // }, {});

                // const conceptosarray = Object.entries(conceptos).map(([concepto, total]) => ({
                //     concepto,
                //     total,
                // }));
                setventasconceptos(data.concepto)
                setventasterceros(data.terceros)
                setventasvendedor(data.vendedor)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const buscarpdfdiscriminado = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosIvaYDescpdfdiscriminadoivaydescuento/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipoacumulado + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'estado_de_cuenta.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const buscarexceldiscriminado = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosIvaYDescexeldiscriminadoivaydescuento/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipoacumulado + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                var wb = XLSX.utils.book_new();
                var ws = XLSX.utils.json_to_sheet(data);

                XLSX.utils.book_append_sheet(wb, ws, "Reporte");
                XLSX.writeFile(wb, "IvasyDescuentosDiscriminados.xlsx")
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const buscarpdf = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosIvaYDescpdf/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipoacumulado + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'estado_de_cuenta.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const CustomOrden = ({ row }) => (
        <div>
            { }
            <div>
                <div style={{ color: 'blue' }} onClick={() => {
                    try {

                        let dt = JSON.parse(user)
                        var url = props.servidor + '/Movimientoapi/Buscarbuscarunico/' + row.numero + '?llave=' + dt.usuario.llaveempresa + ''
                        const api = fetch(props.servidor + '/Movimientoapi/buscarunico/' + row.concepto + '/' + row.ndocumento + '?llave=' + dt.usuario.llaveempresa + '')
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then((dataJson) => {
                                setmovimientoenvio(dataJson)
                                seteditarorden(true)

                            }).catch((error) => {
                                Swal.fire(
                                    'Opps!',
                                    'No se encontro el movimiento',
                                    'error'
                                )
                            })
                    } catch (error) {
                        console.log(error)
                    }
                }}
                >
                    {row.ndocumento}
                </div>
            </div>
        </div>
    );

    const columns = [
        {
            name: 'Con',
            selector: row => row.concepto,
            sortable: true,
            grow: 1,
            width: '80px'
        },
        {
            name: 'Doc',
            selector: row => row.ndocumento,
            sortable: true,
            grow: 1,
            width: '80px',
            cell: row => <CustomOrden row={row} />,

        },
        {
            name: 'Doc 1',
            selector: row => row.documento1,
            sortable: true,
            grow: 1,
            width: '80px'
        },
        {
            name: 'Doc 2',
            selector: row => row.documento2,
            sortable: true,
            grow: 1,
            width: '80px'
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 4,
            width: '130px'
        },
        {
            name: 'Nombre Cliente',
            selector: row => row.nombrecli,
            sortable: true,
            width: '200px',
            grow: 4,
            cell: row => <div>
                { }
                <div>
                    <div
                    >
                        { }
                        {row.nombrecli}
                    </div>
                </div>
            </div>,
        },
        {
            name: 'Bruto',
            selector: row => fm3.from(Number(row.bruto + "")),
            sortable: true,
            grow: 5,
            width: '200px',
        },
        {
            name: 'Descuento',
            selector: row => fm3.from(Number(row.descuento + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'D Pie Fac',
            selector: row => fm3.from(Number(row.despiefac + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'Iva',
            selector: row => fm3.from(Number(row.iva + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'Rete Fuente',
            selector: row => fm3.from(Number(row.retefuente + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'Rete Iva',
            selector: row => fm3.from(Number(row.reteiva + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'Ica',
            selector: row => fm3.from(Number(row.ica + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        },
        {
            name: 'Total',
            selector: row => fm3.from(Number(row.total + "")),
            sortable: true,
            grow: 4,
            width: '200px',
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        // var wb = XLSX.utils.book_new();
        // var ws = XLSX.utils.json_to_sheet(ventas);

        // XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        // XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(ventas);

        // Encontrar la letra de la última columna
        const range = XLSX.utils.decode_range(ws['!ref']);
        // ws[`A${range.e.r+1}`] = { t: 'n', v: '' };
        const lastCol = 'K';
        const totalsRow = ["", "", "", "", ""];
        // Calcular los totales para cada columna
        for (let col = 'F'; col <= lastCol; col = XLSX.utils.encode_col(XLSX.utils.decode_col(col) + 1)) {
            let total = 0;

            for (let row = 2; row <= range.e.r + 1; row++) {
                const cell = ws[`${col}${row}`];
                if (cell && cell.t === 'n') {
                    total += parseFloat(cell.v);
                }
            }

            // Agregar una fila adicional para mostrar el total de la columna
            totalsRow.push(total);
        }
        XLSX.utils.sheet_add_aoa(ws, [totalsRow], { origin: -1 });

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx");

    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={buscarpdf}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={buscarpdfdiscriminado}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF Discriminado
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={buscarexceldiscriminado}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Excel Discriminado
                        </Button>
                    </FormGroup>
                </Col>
                
            </Row>
            <Row  >
                <Col xs={2} onClick={() => {
                    setconcepto(!concepto)
                    setvendedor(false)
                    settercero(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Concepto
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(!tercero)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Cliente
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={4}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Movimientos Totales
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Bruto</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Desc</p>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>D Pie</p>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Iva</p>
                            </Col>
                            <Col style={{ flex: '0 0 15.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>R Fuente</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row noGutters>
                            <Col xs={1}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black', fontSize: '9px' }}>{item.concepto}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }}  >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.bruto)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.descuento)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.despiefac)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.iva)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.retefuente)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {tercero &&
                ventasterceros.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Cliente</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Bruto</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Desc</p>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>D Pie</p>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Iva</p>
                            </Col>
                            <Col style={{ flex: '0 0 15.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>R Fuente</p>
                            </Col>
                            <Col style={{ flex: '0 0 12.66667%', maxWidth: '16.25%' }} >
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row noGutters >
                            <Col style={{ flex: '0 0 11.66667%', maxWidth: '16.25%' }} >
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black', fontSize: '9px' }}>{item.nombrecli}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }}  >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.bruto)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.descuento)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.despiefac)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.iva)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.retefuente)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col style={{ flex: '0 0 14.66667%', maxWidth: '16.25%' }} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black', fontSize: '11px' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black', fontSize: '11px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}


            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col md="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

                    <ModalBody >

                        <Movimientos caja={props.caja} permisosusuario={props.permisosusuario} movimiento={movimientoenvio} cerrar={() => {
                            seteditarorden(false)
                            buscar()
                        }} editarorden={editarorden} servidor={props.servidor} />

                    </ModalBody>
                    <ModalFooter>

                        <Button size="lg" color="primary" onClick={() => {
                            seteditarorden(false)
                            buscar()
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}
                    settipoacumulado={(e) => {
                        settipoacumulado(e)
                    }}
                    sucursal={sucursal}
                    setsucursal={(e) => { setsucursal(e) }}
                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    tipoacumulado={tipoacumulado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;