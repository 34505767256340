import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar';

const modeloDevengadoDeduccion = {
    id: 0,
    codigoempleado: "",
    codigoconcepto: "",
    centrodecosto: "",
    formaretencion: "",
    valorretencion: 0.0,
    saldointeres: 0.0,
    abonovoluntario: 0.0,
    saldoactual: 0.0,
    valorinicial: 0.0,
    fechainicio: "",
    fechafin: "",
    valoracumuladomes: "",
    valoracumuladoaño: "",
    periodicidad: "",
    activa: "",
    opcion2: "",
    opcion3: "",
    opcion4: "",
    opcion5: "",
};

const DevengadoDeduccion = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [DevengadoDeduccion, setDevengadoDeduccion] = useState(modeloDevengadoDeduccion);
    const [DevengadosDeducciones, setDevengadosDeducciones] = useState([]);
    const [usuario, setDataUser] = useState([]);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('DatosGenerales');
    const [pendiente, setPendiente] = useState(true);
    const [verModalDevengadoDeduccion, setVerModalDevengadoDeduccion] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        var _DevengadoDeduccion = { ...DevengadoDeduccion };
        _DevengadoDeduccion[e.target.name] = value;
        setDevengadoDeduccion(_DevengadoDeduccion);
    }

    const obtenerDevengadosDeducciones = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/DevengadoDeduccionAPI/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setDevengadosDeducciones(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let dt = JSON.parse(user);
        setDataUser(dt);
        obtenerDevengadosDeducciones('');
    }, []);

    const columns = [
        {
            name: 'Codigo Empleado',
            selector: row => row.codigoempleado,
            sortable: true,
        },
        {
            name: 'Codigo Concepto',
            selector: row => row.codigoconcepto,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" className="mr-2"
                    onClick={() => abrirEditarModalDevengadoDeduccion(row)}
                >
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalDevengadoDeduccion = (data) => {
        var enviodata = { ...data };
        var _DevengadoDeduccion = {
            id: enviodata.id,
            codigoempleado: enviodata.codigoempleado,
            codigoconcepto: enviodata.codigoconcepto,
            centrodecosto: enviodata.centrodecosto,
            formaretencion: enviodata.formaretencion,
            valorretencion: enviodata.valorretencion,
            saldointeres: enviodata.saldointeres,
            abonovoluntario: enviodata.abonovoluntario,
            saldoactual: enviodata.saldoactual,
            valorinicial: enviodata.valorinicial,
            fechainicio: enviodata.fechainicio,
            fechafin: enviodata.fechafin,
            valoracumuladomes: enviodata.valoracumuladomes,
            valoracumuladoaño: enviodata.valoracumuladoaño,
            periodicidad: enviodata.periodicidad,
            activa: enviodata.activa,
            opcion2: enviodata.opcion2,
            opcion3: enviodata.opcion3,
            opcion4: enviodata.opcion4,
            opcion5: enviodata.opcion5,
        };
        setDevengadoDeduccion(_DevengadoDeduccion);
        setVerModalDevengadoDeduccion(!verModalDevengadoDeduccion);
        seteditar(true);
    }

    const cerrarModalDevengadoDeduccion = () => {
        setDevengadoDeduccion(modeloDevengadoDeduccion);
        setVerModalDevengadoDeduccion(!verModalDevengadoDeduccion);
        seteditar(false);
        setKey('DatosGenerales');
    }

    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/DevengadoDeduccionAPI?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(DevengadoDeduccion)
            });
        } else {
            response = await fetch("" + props.servidor + "/DevengadoDeduccionAPI?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(DevengadoDeduccion)
            });
        }

        if (response.ok) {
            await obtenerDevengadosDeducciones('');
            setDevengadoDeduccion(modeloDevengadoDeduccion);
            setVerModalDevengadoDeduccion(!verModalDevengadoDeduccion);
            setKey('DatosGenerales');
            Swal.fire(
                'Guardado Exitosamente!',
                'El Devengado o Deducción Fija fue guardado exitosamente',
                'success'
            );
        } else {
            var textoerrores = await response.text();
            var jsonerrores = JSON.parse(textoerrores);
            var errores = jsonerrores.errors;
            let claves = Object.keys(errores);
            var stringerrores = "";
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n ";
                }
            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido');
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numéricos rellenados con letras';
            }
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            );
        }
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Devengados y Deducciones Fijas
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <input type="text" id="buscardataDevengadosDeducciones" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerDevengadosDeducciones(document.getElementById('buscardataDevengadosDeducciones').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalDevengadoDeduccion(!verModalDevengadoDeduccion);
                                seteditar(false);
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{                                 fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setCrearDevengadoDeduccion(false) }}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Col>
                        </div>
                    </div>
                    <hr />
                    <DataTable
                        columns={columns}
                        data={DevengadosDeducciones}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalDevengadoDeduccion}>
                <ModalHeader>
                    Devengado o Deducción Fija
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="pestañas"
                    >
                        <Tab eventKey="DatosGenerales" title="Datos Generales">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Código Empleado</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigoempleado" value={DevengadoDeduccion.codigoempleado} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Código Concepto</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigoconcepto" value={DevengadoDeduccion.codigoconcepto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Centro de Costo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="centrodecosto" value={DevengadoDeduccion.centrodecosto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Forma Retención</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="formaretencion" value={DevengadoDeduccion.formaretencion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Retención</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="valorretencion" value={DevengadoDeduccion.valorretencion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Saldo Interes</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="saldointeres" value={DevengadoDeduccion.saldointeres} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Abono Voluntario</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="abonovoluntario" value={DevengadoDeduccion.abonovoluntario} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Saldo Actual</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="saldoactual" value={DevengadoDeduccion.saldoactual} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Inicial</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="valorinicial" value={DevengadoDeduccion.valorinicial} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fecha Inicio</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechainicio" value={DevengadoDeduccion.fechainicio} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fecha Fin</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechafin" value={DevengadoDeduccion.fechafin} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Acumulados" title="Acumulados">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Acumulado Mes</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="valoracumuladomes" value={DevengadoDeduccion.valoracumuladomes} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Valor Acumulado Año</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="valoracumuladoaño" value={DevengadoDeduccion.valoracumuladoaño} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Periodicidad</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="periodicidad" value={DevengadoDeduccion.periodicidad} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Opciones" title="Opciones">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Activa</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="activa" value={DevengadoDeduccion.activa} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Opción 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion2" value={DevengadoDeduccion.opcion2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Opción 3</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion3" value={DevengadoDeduccion.opcion3} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Opción 4</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion4" value={DevengadoDeduccion.opcion4} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Opción 5</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion5" value={DevengadoDeduccion.opcion5} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalDevengadoDeduccion}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DevengadoDeduccion;
