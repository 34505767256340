import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar';

const modeloSucursal = {
    codigo: "",
    nombre: "",
};

const SucursalNomina = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Sucursal, setSucursal] = useState(modeloSucursal);
    const [Sucursales, setSucursales] = useState([]);
    const [usuario, setDataUser] = useState([]);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [pendiente, setPendiente] = useState(true);
    const [verModalSucursal, setVerModalSucursal] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        var _Sucursal = { ...Sucursal };
        _Sucursal[e.target.name] = value;
        setSucursal(_Sucursal);
    }

    const obtenerSucursales = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/SucursalNominaAPI/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setSucursales(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let dt = JSON.parse(user);
        setDataUser(dt);
        obtenerSucursales('');
    }, []);

    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" className="mr-2"
                    onClick={() => abrirEditarModalSucursal(row)}
                >
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalSucursal = (data) => {
        var enviodata = { ...data };
        var _Sucursal = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        };
        setSucursal(_Sucursal);
        setVerModalSucursal(!verModalSucursal);
        seteditar(true);
    }

    const cerrarModalSucursal = () => {
        setSucursal(modeloSucursal);
        setVerModalSucursal(!verModalSucursal);
        seteditar(false);
        setKey('Datos');
    }

    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/SucursalNominaAPI?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Sucursal)
            });
        } else {
            response = await fetch("" + props.servidor + "/SucursalNominaAPI?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Sucursal)
            });
        }

        if (response.ok) {
            await obtenerSucursales('');
            setSucursal(modeloSucursal);
            setVerModalSucursal(!verModalSucursal);
            setKey('Datos');
            Swal.fire(
                'Guardado Exitosamente!',
                'La Sucursal fue guardada exitosamente',
                'success'
            );
        } else {
            var textoerrores = await response.text();
            var jsonerrores = JSON.parse(textoerrores);
            var errores = jsonerrores.errors;
            let claves = Object.keys(errores);
            var stringerrores = "";
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n ";
                }
            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido');
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numéricos rellenados con letras';
            }
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            );
        }
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Sucursales Nómina
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <input type="text" id="buscardataSucursales" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerSucursales(document.getElementById('buscardataSucursales').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalSucursal(!verModalSucursal);
                                seteditar(false);
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setCrearSucursal(false) }}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Col>
                        </div>
                    </div>
                    <hr />
                    <DataTable
                        columns={columns}
                        data={Sucursales}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalSucursal}>
                <ModalHeader>
                    Sucursal
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Código</Label>
                                <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Sucursal.codigo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre</Label>
                                <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Sucursal.nombre} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalSucursal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default SucursalNomina;
