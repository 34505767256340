import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Button, Table, Col, Row } from "reactstrap";
import Container from "reactstrap/lib/Container";
// import { DataContext } from "../../Context/Data";
import { CardText, CardLink, CardTitle, CardSubtitle } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../../context/UserProvider';
import { FormatMoney } from 'format-money-js'

import '../css/Venta.css'

toast.configure();

const modelo = {
  usuario: {
    id: "",
    cedula: "",
    nitempresa: "",
    nombre: "",
    nombreempresa: "",
    contraseña: "",
    llaveempresa: "",
    llaveusuario: "",
    cargo: "",
    departamento: "",
    Eliminado: "",
  },
  empresa: {
    empresa: {
      id: "",
      nit: "",
      nombre: "",
      descripcion: "",
      telefono: "",
      direccion: "",
      email: "",
      celular: "",
      llave: "",
      servidor: "",
      basededatos: "",
      activo: "",
      fechaultimopago: "",
      maximafechadeuso: "",
      Eliminado: "",
    },
    config: {
      carpeta_documentos: "",
      carpeta_bd: "",
      contribuyente_nit: "",
      contribuyente_razon_social: "",
      contribuyente_tipo: "",
      contribuyente_responsabilidad_fiscal: "",
      contribuyente_regimen_fiscal: "",
      contribuyente_matricula_mercantil: "",
      direccion_contribuyente_linea: "",
      direccion_contribuyente_municipio: "",
      direccion_contribuyente_departamento: "",
      direccion_contribuyente_pais: "",
      contribuyente_email: "",
      contribuyente_telefono: "",
      software_en_pruebas: "",
      usar_set_pruebas: "",
      identificador_set_pruebas: "",
      identificador_software: "",
      rango_numero_resolucion: "",
      rango_fecha_resolucion: "",
      rango_vigencia_desde: "",
      rango_vigencia_hasta: "",
      rango_clave_tecnica: "",
      rango_prefijo: "",
      rango_desde: "",
      rango_hasta: "",
      ruta_certificado: "",
      clave_certificado: "",
      encabezado_pdf: "",
      ruta_logo: "",
      smtp_host: "",
      smtp_puerto: "",
      smtp_ssl: "",
      smtp_usuario: "",
      smtp_clave: "",
      sucursal: "",
      pin_software: "",
      especialidad: "",
      Eliminado: "",
    },
    modulos: {
      FACTURA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      INVENTARIO: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      CARTERA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      NOMINA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      FACTURAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      NOMINAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      CONTABILIDAD: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      TESORERIA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      COMPRA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      RADIAN: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      MAESTROS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      ADMINISTRACION: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      DATOSBASICOS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      }, ARTICULOS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      }



    }
  }
}
const modelomovimiento = {
  compañia: "",
  concepto: "",
  ndocumento: "",
  fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
  tercero: "",
  cliente: "",
  nombrecli: "",
  vendedor: "",
  direccion: "",
  nombreven: "",
  documento1: "",
  documento2: "",
  periodo: "",
  horafecha: "",
  estacion: "",
  nomusuario: "",
  observacion: "",
  anuladopor: "",
  borradopor: "",
  bruto: "",
  iva: "",
  descuento: "",
  despiefac: "",
  retefuente: "",
  reteiva: "",
  ica: "",
  retefte: "",
  impconsumo: "",
  subtotal: "",
  total: "",
  cuotai: "",
  ncuotasi: "",
  diferidasi: "",
  apartirdei: "",
  tipocre: "",
  ncuotasf: "",
  tasa: "",
  diferidas: "",
  apartirde: "",
  fechpost: "",
  banco: "",
  efectivo: "",
  cheque: "",
  debito: "",
  credito: "",
  descuentoc: "",
  beneficiario: "",
  impreso: "",
  anulado: "",
  estado: "",
  modificadopor: "",
  convertcon: "",
  convertdoc: "",
  devolcon: "",
  devoldoc: "",
  concprod: "",
  ndocprod: "",
  conctrasl: "",
  ndoctrasl: "",
  concodcped: "",
  ndocodcped: "",
  moneda: "",
  pendiente: "",
  cree: "",
  fletes: "",
  tcambio: "",
  dsctopp1: "",
  dsctopp2: "",
  dsctopp3: "",
  dsctovapp1: "",
  dsctovapp2: "",
  dsctovapp3: "",
  bancocr: "",
  convdescon: "",
  convdesdoc: "",
  convoricon: "",
  convoridoc: "",
  poretefte: "",
  poreteiva: "",
  porica: "",
  porimptoconsumo: "",
  vlr_dolar: "",
  autorizado: "",
  fpago: "contado",
  mpago: "",
  sucursal: "",
  conceptodevo: "",
  ndocumentodevo: 0,
  cufe: "",
  emitido: "no",
  qr: "",
  famovimiento: [
    // {
    //     item: "",
    //     referencia: "",
    //     descrip: "",
    //     bodega: "",
    //     cantidad: "",
    //     precio: "",
    //     pordescuento: "",
    //     poriva: "",
    //     porimptoconsumo: "",
    //     subtotal: "",
    //     compañia: "",
    //     concepto: "",
    //     nrodocumento: "",
    //     costo: "",
    //     desadicional: "",
    //     unimed: "",
    //     ccosto: "",
    //     fecha: "",
    //     preivainc: "",
    //     codprod: "",
    //     canprod: "",
    //     horafecha: "",
    //     tipoitem: "",
    //     tercero: "",
    //     cliente: "",
    //     vlr_desc: "",
    //     vlr_iva: "",
    //     vlr_base_iva: "",
    //     costodolar: "",
    //     tasa: "",
    //     vlrimptoconsumo: "",
    //     poretefte: "",
    //     vlretefte: "",
    //     porica: "",
    //     vlrica: "",
    //     poreteiva: "",
    //     vlreteiva: "",
    //     cantdig: "",
    // } 
  ]
}
const MenuCard = (props) => {
  // const { menuValue } = useContext(DataContext);
  // const [menus] = menuValue;
  // const { cartValue } = useContext(DataContext);
  // const [cart, setCart] = cartValue;
  // const { userValue } = useContext(DataContext);
  // const [userData] = userValue;
  const [bruto, setbruto] = useState(0)
  const [descuento, setdescuento] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [iva, setiva] = useState(0)
  const [consumo, setconsumo] = useState(0)
  const [reteiva, setreteiva] = useState(0)
  const [retefuente, setretefuente] = useState(0)
  const [reteica, setreteica] = useState(0)
  const [total, setTotal] = useState(0)
  const [searchTerm, setSearchTerm] = useState("");
  const [categoriaseleccionada, setcategoriaseleccionada] = useState("-");
  const [menus, setmenus] = useState([]);
  const [cart, setCart] = useState([]);
  const { user, cerrarSession } = useContext(UserContext);
  const [pendiente, setPendiente] = useState(true);
  const [usuario, setDataUser] = useState(modelo);
  const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
  const [Movimiento, setMovimiento] = useState(props.Movimiento);
  const [mostrarimagenes, setmostrarimagenes] = useState(props.mostrarimagenes);
  const [clasificaciones, setclasificaciones] = useState([]);
  const [articuloseleecionado, setarticuloseleecionado] = useState([]);
  const [buscarporplu, setbuscarporplu] = useState(false);

  const cardStyle = {
    marginRight: '1%',
    width: '10rem',
    cursor: 'pointer',
    ':hover': {
      background: '#188BAD',
    },
  };
  useEffect(() => {
    let dt = JSON.parse(user)
    setDataUser(dt)
    obtenerInarticuloss('');
    obtenerConclasiarticuloss('');
    setMovimiento(props.Movimiento)
  }, [])
  const obtenerConclasiarticuloss = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Clasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
      if (response.ok) {
        let data = await response.json()

        setclasificaciones(data.filter(p => p.conclasificacion == 'Clasificación1'))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const obtenerInarticuloss = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      var coin = ""
      if (coincidencia != "") {
        coin = "coin/"
      }
      let response = await fetch(props.servidor + '/Inarticulosapi/restaurante/' + coin + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        setmenus(data)
        setPendiente(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const calcularsubtotalitem = (item) => {
    var base = Number(item.cantidad + "") * Number(item.precio + "")
    var descuento = base * (Number(item.pordescuento + "") / 100)
    var basecondescuento = base - descuento
    var iva = basecondescuento * (Number(item.poriva + "") / 100)
    var impoconsumo = basecondescuento * (Number(item.porimptoconsumo + "") / 100)
    var retefuente = basecondescuento * (Number(item.poretefte + "") / 100)
    var total = basecondescuento + iva + impoconsumo - retefuente
    return total
  }

  const calculartotalesmovimiento = (_enviodatos2) => {
    var totales = {
      bruto: 0,
      descuento: 0,
      subtotal: 0,
      iva: 0,
      consumo: 0,
      reteiva: 0,
      retefuente: 0,
      reteica: 0,
      total: 0,
    }
    _enviodatos2.map((dato, index) => {
      var bruto = 0
      var descuento = 0
      var subtotal = 0
      var iva = 0
      var consumo = 0
      var reteiva = 0
      var retefuente = 0
      var reteica = 0
      var total = 0
      var cantidad = Number(dato.cantidad)
      var precio = Number(dato.precio)
      var pordescuento = Number(dato.pordescuento)
      var poriva = Number(dato.poriva)
      var porimptoconsumo = Number(dato.porimptoconsumo)
      var poretefte = Number(dato.poretefte)
      var porica = Number(dato.porica)
      var poreteiva = Number(dato.poreteiva)
      bruto = (cantidad * precio)
      descuento = bruto * (pordescuento / 100)
      subtotal = bruto - descuento
      iva = subtotal * (poriva / 100)
      consumo = subtotal * (porimptoconsumo / 100)
      reteiva = iva * (poreteiva / 100)
      retefuente = subtotal * (poretefte / 100)
      reteica = subtotal * (porica / 100)
      total = subtotal + iva + consumo - reteiva - retefuente - reteica
      totales.bruto = totales.bruto + bruto
      totales.descuento = totales.descuento + descuento
      totales.subtotal = totales.subtotal + subtotal
      totales.iva = totales.iva + iva
      totales.consumo = totales.consumo + consumo
      totales.reteiva = totales.reteiva + reteiva
      totales.retefuente = totales.retefuente + retefuente
      totales.reteica = totales.reteica + reteica
      totales.total = totales.total + total
    })
    setbruto(totales.bruto)
    setdescuento(totales.descuento)
    setSubTotal(totales.subtotal)
    setiva(totales.iva)
    setconsumo(totales.consumo)
    setreteiva(totales.reteiva)
    setretefuente(totales.retefuente)
    setreteica(totales.reteica)
    setTotal(totales.total)
    return totales
  }
  const buscarpreciodomicilio = (dat) => {
    var precioooo = 0
    if (dat.inarticulo.produccion == "domicilio") {
      if (dat.inarticuloslistaprecio.length > 0) {
        var liprecioooo = dat.inarticuloslistaprecio.filter(p => p.listadeprecio == props.barrio)

        if (liprecioooo.length > 0) {
          precioooo = liprecioooo[0].precio
        }
      }

    }
    return precioooo
  }
  const crearmovimiento = (mov, dat) => {
    var _Movimiento = { ...mov }
    var _datos = { ...mov }['famovimiento']
    var bodega = ""
    var ccosto = ""
    var presentacion = "3"
    var retefuente = 0
    if (props.tipodocumento.tbodegasn == "si") {
      bodega = props.tipodocumento.tbodega
    }
    if (props.tipodocumento.ccosto == "si") {
      ccosto = props.tipodocumento.centrocosto
    }
    if (props.tipodocumento.tipodoc == "compra") {
      presentacion = "1"
    }

    var porcentajeimpconsuincluido = 0
    if (props.tipodocumento.cambioiva == "si" && props.tipodocumento.imptoporc != 0) {
      porcentajeimpconsuincluido = Number(props.tipodocumento.imptoporc)
    } else {
      if (props.tipodocumento.cambioiva == "si") {
        porcentajeimpconsuincluido = Number(dat.inarticulo.imptocons)

      } else {
        porcentajeimpconsuincluido = 0
      }
    }
    var precioooo = 0
    if (dat.inarticuloslistaprecio.length > 0) {
      precioooo = dat.inarticuloslistaprecio[0].precio

    }
    if (dat.inarticulo.produccion == "domicilio") {
      if (dat.inarticuloslistaprecio.length > 0) {
        var liprecioooo = dat.inarticuloslistaprecio.filter(p => p.listadeprecio == props.barrio)

        if (liprecioooo.length > 0) {
          precioooo = liprecioooo[0].precio
        }
      }

    }
    var nuevodato = {
      item: "",
      referencia: dat.inarticulo.codigo,
      descrip: dat.inarticulo.nombre,
      bodega: bodega,
      cantidad: 1,
      precio: (Number(precioooo + "") / ((100 + porcentajeimpconsuincluido) / 100)),
      pordescuento: 0,
      poriva: 0,
      porimptoconsumo: porcentajeimpconsuincluido,
      subtotal: "",
      compañia: "",
      concepto: "",
      nrodocumento: "",
      costo: "",
      desadicional: "",
      unimed: "",
      ccosto: ccosto,
      fecha: "",
      preivainc: "",
      codprod: "",
      canprod: "",
      horafecha: "",
      tipoitem: "",
      tercero: "",
      cliente: "",
      vlr_desc: "",
      vlr_iva: "",
      vlr_base_iva: "",
      costodolar: "",
      tasa: "",
      vlrimptoconsumo: "",
      poretefte: retefuente,
      vlretefte: "",
      porica: "",
      vlrica: "",
      poreteiva: "",
      vlreteiva: "",
      cantdig: "",
      presentacion: "3",
      cuentadebito: "",
      cuentacredito: ""


    }
    var total = calcularsubtotalitem(nuevodato)
    nuevodato.subtotal = total
    var _nuevosdatos = _datos.concat(nuevodato)
    // ES AQUI
    if (_Movimiento.porimptoconsumo != 0) {
      _Movimiento.convoridoc = Math.round((props.total2 + total) * (_Movimiento.porimptoconsumo / 100))
      if (_Movimiento.convoridoc != 0) {
        props.calculartotalesmovimiento(_nuevosdatos, _Movimiento.convoridoc)

      } else {
        props.calculartotalesmovimiento(_nuevosdatos, props.Movimiento.convoridoc)

      }
    } else {
      props.calculartotalesmovimiento(_nuevosdatos, props.Movimiento.convoridoc)

    }

    _Movimiento['famovimiento'] = _nuevosdatos
    return _Movimiento
  }

  const crearmovimientoexistente = (mov, dat) => {
    var _Movimiento = { ...mov }
    var _datos = { ...mov }['famovimiento']

    var total33 = 0

    _datos = _datos.map((dato, index) => {
      if (dato.referencia == dat.inarticulo.codigo) {
        var dat2 = {
          ...dato,
          ['cantidad']: Number(dato.cantidad + "") + 1
        }
        var total = calcularsubtotalitem(dat2)
        dat2.subtotal = total
        var dat23 = {
          ...dato,
          ['cantidad']: 1
        }
        total33 = calcularsubtotalitem(dat23)
        return dat2
      } else {
        return dato
      }
    })
    if (_Movimiento.porimptoconsumo != 0) {
      _Movimiento.convoridoc = Math.round((props.total2 + total33) * (_Movimiento.porimptoconsumo / 100))
      if (_Movimiento.convoridoc != 0) {
        props.calculartotalesmovimiento(_datos, _Movimiento.convoridoc)

      } else {
        props.calculartotalesmovimiento(_datos, props.Movimiento.convoridoc)

      }
    } else {
      props.calculartotalesmovimiento(_datos, props.Movimiento.convoridoc)

    }


    _Movimiento['famovimiento'] = _datos
    return _Movimiento
  }

  const addToCart = async (codigo) => {
    if (props.Movimiento.concepto != "") {
      const check = props.Movimiento.famovimiento.every((item) => {
        return item.referencia !== codigo;
      });
      if (check) {
        // const data = menus.filter((menu) => {
        //   return menu.inarticulo.codigo === codigo;
        // });
        props.setfila(props.Movimiento.famovimiento.length)
        setarticuloseleecionado(codigo)
        const data = await props.buscararticulounico(codigo)
        // var _Movimiento = crearmovimiento(props.Movimiento, data[0])
        // props.setMovimiento(_Movimiento)
        props.seleccionararticulo(data, true)
        toast.success("Añadido al Carrito", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        const data = menus.filter((menu) => {
          return menu.inarticulo.codigo === codigo;
        });
        var _Movimiento = crearmovimientoexistente(props.Movimiento, data[0])
        props.setMovimiento(_Movimiento)
        var menss = "Añadido al Carrito"
        if (buscarporplu) {
          menss = menss + " " + data[0].inarticulo.plu
        }
        toast.success(menss, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
        });
      }
    }
  };
  const actualizarfila = async (codigo) => {
    const index = props.Movimiento.famovimiento.findIndex((menu) => menu.referencia === codigo);
    if (index != -1) {
      props.setfila(index)
      props.agregaritemfuncion()

    } else {
      if (props.Movimiento.famovimiento[props.Movimiento.famovimiento.length - 1]) {
        props.setfila(props.Movimiento.famovimiento.length - 1)
        props.agregaritemfuncion()
      } else {
        props.setfila(0)
        props.agregaritemfuncion()
      }
    }

  };
  return (
    <Fragment>
      <Card className="menucard mb-3 shadow bg-white rounded">

        <CardBody>
          <div className="mb-3">
            <FormGroup>

              <Input
                type='checkbox'
                checked={buscarporplu}
                value={buscarporplu}
                onClick={(e) => {
                  setbuscarporplu(e.target.checked)
                }}
              />
              <Label check>Por PLU</Label>

            </FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText>
              </InputGroupAddon>

              <Input
                type="text"
                placeholder="Buscar"
                value={searchTerm}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    if (buscarporplu) {
                      if (e.target.value != "") {
                        var articuloplu = menus.find(p => p.inarticulo.plu == e.target.value)
                        if (articuloplu) {
                          addToCart(articuloplu.inarticulo.codigo)
                          setSearchTerm("");
                        }

                      } else {
                        setSearchTerm(e.target.value);
                      }
                    }
                  }
                }}
                onChange={(e) => {
                  // if (buscarporplu) {
                  if (false) {
                    console.log(e.target.value)

                    if (e.target.value != "") {
                      var articuloplu = menus.find(p => p.inarticulo.plu == e.target.value)
                      if (articuloplu) {
                        addToCart(articuloplu.inarticulo.codigo)
                        if (props.tipodocumento.busplu != "si") {
                          setSearchTerm("");
                        }
                      }

                    } else {
                      setSearchTerm(e.target.value);
                    }
                  } else {
                    setSearchTerm(e.target.value);

                  }
                }}
              />
            </InputGroup>
          </div>
          <Row >
            <Col sm={2}>
              <Button style={{ fontSize: '14px', fontSize: '11px', backgroundColor: '#188BAD', color: 'white', boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.1)' }} block onClick={() => {
                setcategoriaseleccionada("")
              }} >
                <i >TODOS</i> </Button>
            </Col>
            {clasificaciones && clasificaciones.length > 0 &&
              clasificaciones.map((dato) => {
                var tamletra = '11px'
                // if (dato.nombre.length>12) {
                //   tamletra='9px'
                // }
                return <Col sm={2}>
                  <Button style={{ fontSize: '14px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: tamletra, backgroundColor: '#188BAD', color: 'white', boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.1)' }} block onClick={() => {
                    setcategoriaseleccionada(dato.codigo)
                  }} >
                    <i >{dato.nombre}</i> </Button>
                </Col>
              })
            }
          </Row>
          <div style={{ marginTop: '11px' }}>
            {menus.length > 0 ? (
              <div className="flexevenly" >
                {menus
                  .filter((menu) => {
                    if (searchTerm === "" && categoriaseleccionada == "") {
                      return menu;
                    } else {
                      if (categoriaseleccionada != "") {
                        if (
                          (menu.inarticulo.nombre
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                            menu.inarticulo.codigo
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())  ||
                              menu.inarticulo.codigo2
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                            menu.inarticulo.plu
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())) && menu.inarticulo.clas1 == categoriaseleccionada
                        ) {
                          return menu;
                        }
                      } else {
                        if (
                          menu.inarticulo.nombre
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          menu.inarticulo.codigo
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                            menu.inarticulo.codigo2
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                          menu.inarticulo.plu
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return menu;
                        }
                      }
                    }
                  })
                  .map((menu) => (
                    <div className="d-flex flex-wrap align-items-start">
                      <Card
                        onMouseOver={() => { actualizarfila(menu.inarticulo.codigo) }}
                        onClick={() => addToCart(menu.inarticulo.codigo)}
                        key={menu.inarticulo.codigo}
                        className="cardsinsidecurrent mb-3 shadow-lg rounded-lg border-0 d-flex flex-column"
                        style={{ backgroundColor: '#f8f9fa', transition: 'transform 0.3s', cursor: 'pointer', height: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: (menu.inarticulo.nombre+" "+menu.inarticulo.codigo2).length < 20 ? 'nowrap' : 'normal' }}
                        onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                      >
                        {
                          menu.inarticulo.clas10 && menu.inarticulo.clas10.includes("BuscarImagenesApi") &&
                          <div className="menuimgdiv" style={{ textAlign: 'center', padding: '15px 0' }}>
                            {props.mostrarimagenes &&
                              <img
                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover', borderRadius: '8px' }}
                                width="90%"
                                src={`${props.servidor + menu.inarticulo.clas10 + '?llave=' + usuario.usuario.llaveempresa}`}
                                alt="factin"
                              />
                            }
                          </div>
                        }

                        <CardBody className="text-center d-flex flex-column" style={{ padding: '20px', flexGrow: 1 }}>
                          <CardText style={{ fontSize: '14px', color: '#495057'}}>
                            {menu.inarticulo.nombre+" "+(menu.inarticulo.codigo2?menu.inarticulo.codigo2:"")}
                            <br />
                            <span style={{ fontSize: '12px', color: '#6c757d' }}>
                              Uni: {menu.inarticuloslistaprecio && menu.inarticuloslistaprecio.length > 0
                                ? menu.inarticulo.produccion === "restaurante"
                                  ? fm2.from(props.tipodocumento.precsug ? menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "3") ? menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "3").precio : false : menu.inarticuloslistaprecio[0].precio)
                                  : fm2.from(buscarpreciodomicilio(menu))
                                : 0}
                            </span> <br />
                            <span style={{ fontSize: '12px', color: '#6c757d' }}>
                              {menu.inarticuloslistaprecio && menu.inarticuloslistaprecio.length > 0
                                ? menu.inarticulo.produccion === "restaurante"
                                  ? props.tipodocumento.precsug ? menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "2") ? "Inter: " + fm2.from(menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "2").precio) : false : false
                                  : fm2.from(buscarpreciodomicilio(menu))
                                : 0}
                            </span> <br />
                            <span style={{ fontSize: '12px', color: '#6c757d' }}>
                              {menu.inarticuloslistaprecio && menu.inarticuloslistaprecio.length > 0
                                ? menu.inarticulo.produccion === "restaurante"
                                  ? props.tipodocumento.precsug ? menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "1") ? "Mayor: " + fm2.from(menu.inarticuloslistaprecio.find(p => p.listadeprecio == props.tipodocumento.precsug && p.presentacion == "1").precio) : false : false
                                  : fm2.from(buscarpreciodomicilio(menu))
                                : 0}
                            </span>
                          </CardText>
                        </CardBody>
                      </Card>

                    </div>


                  ))}
              </div>
            ) : (
              <div>Cargando...</div>
            )}
          </div>

        </CardBody>
      </Card>

    </Fragment>
  );
};

export default MenuCard;
