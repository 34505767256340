import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../context/UserProvider';
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ReportesInventario from './MovimientosPorConcepto';
import MovimientosPorConcepto from './MovimientosPorConcepto';
import MovimientosElectronicos from './MovimientosElectronicos';
import MovimientosDetalleconCartera from './MovimientosDetalleconCartera';
import MovimientosMercancia from './MovimientosMercancia';
import CorreosEnviados from './CorreosEnviados';

import ConceptosAfeCaja from './ConceptosAfeCaja';
import Consecutivos from './Consecutivos';
import IvasyDescuentos from './IvasyDescuentos';
import IvasyDescuentoscruzados from './IvasyDescuentoscruzado';
import ResumenVentas from './ResumenVentas';
import UltimoFaencmovi from './UltimoFaencmovi'
import ReportesInicio from './inicioBOTONES';
import MovimientosVendedorComision from './MovimientosVendedorComision';
import MovimientosAnulados from './MovimientosAnulados';
import AuditoriaUsuario from './AuditoriaUsuario';

import ReportesCartera from '../../Reportes/Cartera/inicio';
import ReportesInventarioDrogueria from '../../Reportes/Inventario/Drogueria/inicio';
import HistorialVenta from '../../HistorialVenta';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import '../../css/Venta.css'
import Icono from '../../images/informes/informeventas.png'
import Icono2 from '../../images/informes/informeventas_auto_x2.jpg'
import IconoCartera from '../../images/informes/cartera.jpg'
import IconoInventario from '../../images/informes/inventario.jpg'
import IconoTesoreria from '../../images/informes/tesoreria.jpg'
import IconoContabilidad from '../../images/informes/contabilidad.jpg'
import IconoNomina from '../../images/informes/nomina.jpg'
import IconoSalir from '../../images/informes/salir.jpg'
import IconoOtros from '../../images/informes/otros.jpg'

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}


const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [key, setKey] = React.useState('Inicio');
    const [movimientoporconcepto, setmovimientoporconcepto] = React.useState(false);
    const [movimientodetalleconcartera, setmovimientodetalleconcartera] = React.useState(false);
    const [movimientomercancia, setmovimientomercancia] = React.useState(false);
    const [conceptosafectancaja, setconceptosafectancaja] = React.useState(false);
    const [resumendiariodeventas, setresumendiariodeventas] = React.useState(false);
    const [listdoivaydescuentos, setlistdoivaydescuentos] = React.useState(false);
    const [listdoivaydescuentoscruzados, setlistdoivaydescuentoscruzados] = React.useState(false);
    const [controlconsecutivos, setcontrolconsecutivos] = React.useState(false);
    const [liquidacionvendedores, setliquidacionvendedores] = React.useState(false);
    const [ultimomovimiento, setultimomovimiento] = React.useState(false);
    const [movimientoselectronicos, setmovimientoselectronicos] = React.useState(false);
    const [correosenviados, setcorreosenviados] = React.useState(false);
    const [movimientosvendedorcomision, setmovimientosvendedorcomision] = React.useState(false);

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])
    const handleClick = (reportName) => {
        // Lógica para generar el informe
        console.log(`Generando informe: ${reportName}`);
    };

    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            <Tab eventKey="Inicio" title="Inicio">
                                <ReportesInicio servidor={props.servidor}
                                    setmovimientoporconcepto={(reporteInventario) => {
                                        setmovimientoporconcepto(reporteInventario)
                                        setKey("Mov Por Conceptos")
                                    }}
                                    setmovimientodetalleconcartera={(reporteInventario) => {
                                        setmovimientodetalleconcartera(reporteInventario)
                                        setKey("Mov Det Car")
                                    }}
                                    setmovimientomercancia={(reporteInventario) => {
                                        setmovimientomercancia(reporteInventario)
                                        setKey("mov Mercancia")
                                    }}
                                    setliquidacionvendedores={(reporteInventario) => {
                                        setliquidacionvendedores(reporteInventario)
                                        setKey("Liquidacion Vendedores")
                                    }}
                                    setconceptosafectancaja={(reporteInventario) => {
                                        setconceptosafectancaja(reporteInventario)
                                        setKey("Conceptos Afectan Caja")
                                    }}
                                    setresumendiariodeventas={(reporteInventario) => {
                                        setresumendiariodeventas(reporteInventario)
                                        setKey("Resumen Ventas")
                                    }}
                                    setlistdoivaydescuentos={(reporteInventario) => {
                                        setlistdoivaydescuentos(reporteInventario)
                                        setKey("Ivas y Descuentos")
                                    }}
                                    setlistdoivaydescuentoscruzados={(reporteInventario) => {
                                        setlistdoivaydescuentoscruzados(reporteInventario)
                                        setKey("Ivas y Descuentos cruzados")
                                    }}

                                    setcontrolconsecutivos={(reporteInventario) => {
                                        setcontrolconsecutivos(reporteInventario)
                                        setKey("Consecutivos")
                                    }}
                                    setmovimientosvendedorcomision={(movimientosvendedorcomision) => {
                                        setmovimientosvendedorcomision(movimientosvendedorcomision)
                                        setKey("Movimientos Vendedor Comision")
                                    }}

                                    setreporteVentasalir={(reporteInventario) => {
                                        props.setreporteVenta(reporteInventario)
                                    }}
                                    setultimomovimiento={(reporteInventario) => {
                                        setultimomovimiento(reporteInventario)
                                        setKey("Ultimo Movimiento")
                                    }}
                                    setmovimientoselectronicos={(reporteInventario) => {
                                        setmovimientoselectronicos(reporteInventario)
                                        setKey("Movimientos Electronicos")
                                    }}
                                    setcorreosenviados={(reporteInventario) => {
                                        setcorreosenviados(reporteInventario)
                                        setKey("Correos Enviados")
                                    }}

                                />
                            </Tab>
                            {correosenviados && <Tab eventKey="Correos Enviados" title="Correos Enviados">
                                <CorreosEnviados caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcorreosenviados={(reporteInventario) => {
                                    setcorreosenviados(reporteInventario)
                                }} />
                            </Tab>}
                            {movimientoselectronicos && <Tab eventKey="Movimientos Electronicos" title="Movimientos Electronicos">
                                <MovimientosElectronicos caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientoselectronicos={(reporteInventario) => {
                                    setmovimientoselectronicos(reporteInventario)
                                }} />
                            </Tab>}
                            {movimientoporconcepto && <Tab eventKey="Mov Por Conceptos" title="Mov Por Conceptos">
                                <MovimientosPorConcepto caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientoporconcepto={(reporteInventario) => {
                                    setmovimientoporconcepto(reporteInventario)
                                }} />
                            </Tab>}
                            {movimientodetalleconcartera && <Tab eventKey="Mov Det Car" title="Mov Det Car">
                                <MovimientosDetalleconCartera caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientodetalleconcartera={(reporteInventario) => {
                                    setmovimientodetalleconcartera(reporteInventario)
                                }} />
                            </Tab>}
                            {movimientosvendedorcomision && <Tab eventKey="Movimientos Vendedor Comision" title="Movimientos Vendedor Comision">
                                <MovimientosVendedorComision caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientodetalleconcartera={(reporteInventario) => {
                                    setmovimientodetalleconcartera(reporteInventario)
                                }} />
                            </Tab>}

                            {movimientomercancia && <Tab eventKey="mov Mercancia" title="mov Mercancia">
                                <MovimientosMercancia caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientomercancia={(reporteInventario) => {
                                    setmovimientomercancia(reporteInventario)
                                }} />
                            </Tab>}

                            {conceptosafectancaja && <Tab eventKey="Conceptos Afectan Caja" title="Conceptos Afectan Caja">
                                <ConceptosAfeCaja caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setconceptosafectancaja={(reporteInventario) => {
                                    setconceptosafectancaja(reporteInventario)
                                }} />
                            </Tab>}

                            {resumendiariodeventas && <Tab eventKey="Resumen Ventas" title="Resumen Ventas">
                                <ResumenVentas caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setresumendiariodeventas={(reporteInventario) => {
                                    setresumendiariodeventas(reporteInventario)
                                }} />
                            </Tab>

                            }
                            {ultimomovimiento && <Tab eventKey="Ultimo Movimiento" title="Ultimo Movimiento">
                                <UltimoFaencmovi caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setultimomovimiento={(reporteInventario) => {
                                    setultimomovimiento(reporteInventario)
                                }} />
                            </Tab>

                            }

                            {controlconsecutivos && <Tab eventKey="Consecutivos" title="Consecutivos">
                                <Consecutivos caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcontrolconsecutivos={(reporteInventario) => {
                                    setcontrolconsecutivos(reporteInventario)
                                }} />
                            </Tab>}
                            {listdoivaydescuentos && <Tab eventKey="Ivas y Descuentos" title="Ivas y Descuentos">
                                <IvasyDescuentos caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcontrolconsecutivos={(reporteInventario) => {
                                    setcontrolconsecutivos(reporteInventario)
                                }} />
                            </Tab>}
                            {listdoivaydescuentoscruzados && <Tab eventKey="Ivas y Descuentos cruzados" title="Ivas y Descuentos cruzados">
                                <IvasyDescuentoscruzados caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcontrolconsecutivos={(reporteInventario) => {
                                    setlistdoivaydescuentoscruzados(reporteInventario)
                                }} />
                            </Tab>}
                            <Tab eventKey="Movimientos Anulados" title="Movimientos Anulados">
                                <MovimientosAnulados caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcontrolconsecutivos={(reporteInventario) => {
                                    // setlistdoivaydescuentoscruzados(reporteInventario)
                                }} />
                            </Tab>
                            <Tab eventKey="Auditoria" title="Auditoria">
                                <AuditoriaUsuario caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setcontrolconsecutivos={(reporteInventario) => {
                                    // setlistdoivaydescuentoscruzados(reporteInventario)
                                }} />
                            </Tab>
                            

                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>

    );
}

export default Reportes