import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Categoria from './views/Categoria';
import DashBoard from './views/DashBoard';
import HistorialVenta from './views/HistorialVenta';
import Inicio from './views/Inicio';
import NotFound from './views/NotFound';
import Producto from './views/Producto';
import ReporteVenta from './views/ReporteVenta';
import Usuario from './views/Usuario';
import Venta from './views/Venta';
import Login from './views/Login';
import Pestañas from './views/Pestañas';
import UserProvider from "./context/UserProvider";
import MensajesProvider from "./context/MensajesProvider";
import PermisosProvider from "./context/PermisosProvider";
import VerificarUsuario from './componentes/VerificarUsuario';
import { servidor, servidorempresa } from './config';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

const root = ReactDOM.createRoot(document.getElementById('wrapper'));

// Conexión de SignalR
const connection = new HubConnectionBuilder()
    .withUrl(servidor + "/socket", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
    })
    .build();
    const correrSocket = async () => {
        // try {
        //     await connection.start();
        //     console.log("Conexión establecida", connection.state);
        // } catch (error) {
        //     console.error("Error al conectar con SignalR: ", error);
        // }
    };
const AppRouter = () => {

    useEffect(() => {
        // Función para iniciar la conexión de SignalR al montar el componente
    


        // Optional: Limpiar la conexión al desmontar el componente
        return () => {
            if (connection.state === "Connected") {
                connection.stop();
                console.log("Conexión cerrada");
            }
        };
    }, []); // El array vacío [] asegura que el efecto se ejecute solo una vez al montar el componente

    return (
        <BrowserRouter>
            <UserProvider>
                <MensajesProvider>
                    <PermisosProvider>
                        <Routes>
                            {/* Rutas de tu aplicación */}
                            <Route index path='/Login' element={<Login correrSocket={correrSocket} connection={connection} servidor={servidor} servidorempresa={servidorempresa} />} />
                            <Route path='/' element={<App correrSocket2={correrSocket} servidor={servidor} servidorempresa={servidorempresa} />}>
                                <Route index element={<Inicio />} />
                                <Route path='dashboard' element={<VerificarUsuario><Pestañas /></VerificarUsuario>} />
                                <Route path='usuario' element={<VerificarUsuario><Usuario /></VerificarUsuario>} />
                                <Route path='producto' element={<VerificarUsuario><Producto /></VerificarUsuario>} />
                                <Route path='categoria' element={<VerificarUsuario><Categoria /></VerificarUsuario>} />
                                <Route path='venta' element={<VerificarUsuario><Venta /></VerificarUsuario>} />
                                <Route path='historialventa' element={<VerificarUsuario><HistorialVenta /></VerificarUsuario>} />
                                <Route path='reporteventa' element={<VerificarUsuario><ReporteVenta /></VerificarUsuario>} />
                            </Route>
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </PermisosProvider>
                </MensajesProvider>
            </UserProvider>
        </BrowserRouter>
    );
};

root.render(<AppRouter />);
