import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modeloPais = {
    codigo: "",
    nombre: "",
}
const Pais = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Pais, setPais] = useState(modeloPais);
    const [fila, setfila] = useState(0);
    const [pendiente, setPendiente] = useState(true);
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2));
    const [verModalPais, setVerModalPais] = useState(false);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [novedades, setnovedades] = useState([{
        id: 1,
        empleado: "Miguel",
        centrocost: "",
        concepto: "",
        horas: 0,
        valor: 0,
        dia: 0,
        finicial: fechahoy,
        ffinal: fechahoy
    }]);
    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])



    const grillanovedades = (
        <Row>
            <Col sm={12}>
                <Card style={{ borderColor: '#188BAD' }}>

                    <CardBody >

                        <div className="table-responsive" style={{ height: '300px', overflow: 'auto' }}>
                            <Table striped  >
                                <thead>
                                    <tr >
                                        <th>Empleado</th>
                                        <th >C. Costo</th>
                                        <th >Concepto</th>
                                        <th >O. Campo</th>
                                        <th>Fecha Inicial</th>
                                        <th>Fecha Final</th>
                                        <th >Dia</th>
                                        <th >Horas</th>
                                        <th >Valor</th>
                                        <th >Previsualizar</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (novedades.length < 1) ? (
                                            <tr>
                                                <td colSpan="12">Sin Novedades</td>
                                            </tr>
                                        ) :
                                            (
                                                novedades.map((item, index) => (
                                                    <tr key={index} style={{ zoom: '80%' }}>

                                                        {/* <td>
                                                            <a href="#" onFocus={() => { setfila(index) }} style={{ fontSize: '11px' }} className="fas fa-pen-alt" onClick={() => {

                                                                // eliminarProducto(index)
                                                            }}>{item.id}</ a >
                                                        </td> */}

                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='empleado' value={item.empleado}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='centrocost' value={item.centrocost}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='concepto'
                                                                value={item.concepto}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='referencia'
                                                                value=""

                                                            />
                                                        </td>
                                                        <td>
                                                            <Input type="date" tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='finicial}'
                                                                value={item.finicial}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input type="date" tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='ffinal'
                                                                value={item.ffinal}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='dia'
                                                                value={item.dia}

                                                            />
                                                        </td>
                                                        <td >
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='horas'
                                                                value={item.horas}

                                                            />
                                                        </td>
                                                        <td>
                                                            <Input tabIndex={0} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='valor'
                                                                value={item.valor}

                                                            />
                                                        </td>
                                                        <td>
                                                            <Button style={{ backgroundColor: '#188BAD' }} className="ml-2" size="sm" onClick={() => {
                                                                setVerModalPais(!verModalPais)
                                                            }}>Previsualizar</Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )


                                    }
                                </tbody>
                            </Table>

                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Novedades
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >

                        <div className="input-group input-group-sm mb-3" >
                            <Input bsSize="sm" type="month" className="form-control form-control-sm"
                                selected={fechaInicio}
                                value={fechaInicio}
                                onChange={(e) => setFechaInicio(e.target.value)}></Input>
                            <Input type="select" style={{ fontSize: '13px' }} >
                                <option value="1">Primera Quincena</option>
                                <option value="2">Segunda Quincena</option>
                            </Input>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalPais(!verModalPais)
                            }}>Editar</Button>
                            <Button style={{ backgroundColor: '#188BAD' }} className="ml-2" size="sm" onClick={() => {
                                setVerModalPais(!verModalPais)
                            }}>Guardar</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black', alignItems: 'center' }} block onClick={() => { props.setcrearpais(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    {true && grillanovedades}
                </CardBody>
            </Card>


        </>
    )
}
export default Pais;
