import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../componentes/filtro";
import Ayuda from "../../ConfiguracionReportes/Ayuda";
import { Doughnut, Bar } from 'react-chartjs-2';
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]


const data_inicio_bar = {
    labels: ['Sin resultados'],
    datasets: [
        {
            label: 'Cantidad',
            data: [0],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};
const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [dataBar, setDataBar] = useState(data_inicio_bar)

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [conmeta, setconmeta] = useState("si");


    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [referenciafinseleccionado, setreferenciafinseleccionado] = useState("-");
    const [referenciainicioseleccionado, setreferenciainicioseleccionado] = useState("-");
    const [editarorden, seteditarorden] = useState(false);

    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/CtabonoApi/ProductosporVendedor/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + '/-?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                if (conmeta == "si") {
                    data = data.filter(p=>p.cumplimientodeventa != 0)
                }
                setVentas(data);
                let d = data.sort((a, b) => b.total - a.total);
                let lblsBar = d.map((item) => { return item.nombrevendedor })
                lblsBar = [...new Set(lblsBar)];
                let vendedores = d.map((item) => { return item.vendedor })
                vendedores = [...new Set(vendedores)];
                let dtaBartotal = d.map((item) => { return item.total })
                var producto1 = []
                var productoarray1 = []
                for (let i = 0; i < vendedores.length; i++) {
                    const element = vendedores[i];
                    var proven = d.filter(p => p.vendedor == element)
                    if (proven.length > 0) {
                        var artt = proven[0].total
                        producto1.push(artt)
                        productoarray1.push(proven[0].referencia)
                    } else {
                        producto1.push(0)
                        productoarray1.push("")
                    }
                }
                var producto2 = []
                var productoarray2 = []

                for (let i = 0; i < vendedores.length; i++) {
                    const element = vendedores[i];
                    var proven = d.filter(p => p.vendedor == element)
                    if (proven.length > 1) {
                        var artt = proven[1].total
                        producto2.push(artt)
                        productoarray2.push(proven[1].referencia)

                    } else {
                        producto2.push(0)
                        productoarray2.push("")
                    }
                }
                var producto3 = []
                var productoarray3 = []

                for (let i = 0; i < vendedores.length; i++) {
                    const element = vendedores[i];
                    var proven = d.filter(p => p.vendedor == element)
                    if (proven.length > 2) {
                        var artt = proven[2].total
                        producto3.push(artt)
                        productoarray3.push(proven[2].referencia)

                    } else {
                        producto3.push(0)
                        productoarray3.push("")
                    }
                }
                for (let i = 0; i < lblsBar.length; i++) {
                    const element = lblsBar[i];
                    var proven = d.filter(p => p.nombrevendedor == element)
                    // lblsBar[i] = lblsBar[i] + " ( " + productoarray1[i] + " )" + " ( " + productoarray2[i] + " )" + " ( " + productoarray3[i] + " )"


                }
                let modeloBar = {
                    labels: lblsBar,
                    datasets: [{
                        label: 'P1',
                        data: producto1,
                        backgroundColor: '#298BFA',
                    }, {
                        label: 'P2',
                        data: producto2,
                        backgroundColor: '#298BFA',
                    }, {
                        label: 'P3',
                        data: producto3,
                        backgroundColor: '#298BFA',
                    }
                    ]
                };


                if (d.length < 1)
                    setDataBar(data_inicio_bar)
                else
                    setDataBar(modeloBar)


                // let productos = d.map((item) => { return item.referencia })
                // let productoAData = lblsBar.map((data) => {
                //     if (d.find(p => p.nombrevendedor == data)) {
                //         return d.find(p => p.nombrevendedor == data).total
                //     }
                // });
                // let productoBData = lblsBar.map(vendedor => ventasPorVendedor[vendedor]['Producto B']);
                // let productoCData = lblsBar.map(vendedor => ventasPorVendedor[vendedor]['Producto C']);
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const CustomDescripcion = ({ row }) => (
        <div>
            { }
            <div>
                <div
                >
                    { }
                    {row.descripcion}
                </div>
            </div>
        </div>
    );
    const CustomTipo = ({ row }) => (
        <div>
            { }
            <div>
                <div
                >
                    { }
                    {row.tipo}
                </div>
            </div>
        </div>
    );
    const columns = [

        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
            grow: 2,
        },
        {
            name: 'vendedor',
            selector: row => row.nombrevendedor,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Cantidad',
            selector: row => row.cantidad,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Total',
            selector: row => fm.from(Number(row.total + "")),
            sortable: true,
            grow: 2,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            if (element.item == 1) {
                tot = tot + element.total
            }

        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalcomisionvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.comision
        })
        return total
    }

    const totaltotalvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.total
        })
        return total
    }


    const optionsBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }, scales: {
            x: {
                ticks: {
                    font: {
                        size: 10, // Cambia este valor al tamaño deseado (en píxeles)
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 10, // Cambia este valor si también quieres ajustar el eje Y
                    },
                },
            },
        },
    };

    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Con Meta:</Label>
                        <Input type="select" onChange={(e) => { setconmeta(e.target.value) }} style={{ fontSize: '13px' }} name="filtroart" value={conmeta}  >
                            <option value="si">Si</option>
                            <option value="no">No</option>
                        </Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Ayuda mensaje="Para que salgan los valores correctos verifique que en los tipos de documento de venta tenga la opcion
                Act. Acumulados en Ventas" />
                    </FormGroup>
                </Col>

            </Row>
            <Row  >
                <Col xs={2} onClick={() => {
                    setconcepto(!concepto)
                    setvendedor(false)
                    settercero(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Concepto
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(!tercero)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Cliente
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(!vendedor)
                    settercero(false)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Vendedor
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={4}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Movimientos Totales
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.tipo}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {tercero &&
                ventasterceros.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >

                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nomcliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {vendedor &&
                ventasvendedor.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Vendedor</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Porcentaje</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Comision</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.vendedor == "" ? "Sin Vendedor" : item.vendedor}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.porcentaje)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.comision)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            {vendedor &&
                <Row >
                    <Col xs={4}>
                        <div className="card border-left-info shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{"Totales Vendedor"}</div>
                                            </div>
                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>

                    <Col xs={3}>

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totaltotalvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}></div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totalcomisionvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            }
            <Row>
                <Col sm={12}>
                    <div className=" mt-5">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between " style={{ background: '#188BAD' }} >
                                    <h6 className="m-0 font-weight-bold text-white">Ventas Por Mes</h6>

                                </div>
                                <div className="card-body">
                                    <div style={{ height: 350 }}>
                                        <Bar options={optionsBar} data={dataBar} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-lg-5">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between " style={{ background: '#188BAD' }}>
                                <h6 className="m-0 font-weight-bold text-white">Productos más vendidos</h6>

                            </div>
                            <div className="card-body">
                                <div style={{ height: 350 }}>
                                    <Doughnut options={optionsBar} data={dataDonut} />
                                </div>

                            </div>
                        </div>
                    </div> */}


                    </div>
                </Col>


                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}
                    // setsucursal={(e) => { setsucursal(e) }}
                    // sucursal={sucursal}
                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>

        </>
    )
}

export default ReporteVenta;