
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import { FormatMoney } from 'format-money-js'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect, useRef } from "react";
import "./css/Venta.css"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import "./css/Facturas.css"
import React, { UserContext } from "../context/UserProvider";
import { PermisoContext } from "../context/PermisosProvider";
import Buscar from '../componentes/Buscar'
import Esperando from '../componentes/Esperando'
import BuscarMovimiento from '../componentes/Buscarmovimiento'
import BuscarBono from '../componentes/Buscar2/Bono'
import Buscarmovimientomultiple from '../componentes/Buscarmovimientomultiple'
import Venn from './Venta'
import CrearTercero from './CrearTercero'
import { Event, event } from "jquery";
import { jsPDF } from "jspdf";
import Icono from './images/blanco.png'
import { QRCode } from 'react-qrcode-logo';
import { renderToStaticMarkup } from "react-dom/server"
import ReactDOMServer from 'react-dom/server';
import { Autocomplete, TextField } from '@mui/material';
import { Try } from "@mui/icons-material";
import Caclientes from "./CrearTercero";
import MenuCard from "./Restaurante/ProductosMenuposnormal";
import Tipopdf from "./Tipopdf";
import TipopdfCarta from "./TipopdfCarta";

const tabespacio = '\u00A0';

const municipios = [
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: ""
    },
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: "08001"
    },
    {
        municipio: "Oiba",
        departamento: "Santander",
        codigo: "68500"
    },
    {
        municipio: "Santa Marta",
        departamento: "Magdalena",
        codigo: "47001"
    },
    {
        municipio: "Funza",
        departamento: "Cundinamarca",
        codigo: "25286"
    },
    {
        municipio: "Bogota",
        departamento: "Bogota",
        codigo: "11001"
    },
    {
        municipio: "Popayan",
        departamento: "Cauca",
        codigo: "19001"
    },
    {
        municipio: "FACATATIVÁ",
        departamento: "",
        codigo: "25269"
    },
    {
        municipio: "MADRID",
        departamento: "",
        codigo: "25430"
    },
    {
        municipio: "QUIBDÓ",
        departamento: "CHOCÓ",
        codigo: "27001"
    },
    {
        municipio: "JAMUNDÍ",
        departamento: "VALLE DEL CAUCA",
        codigo: "76364"
    },
    {
        municipio: "ENVIGADO",
        departamento: "ANTIOQUIA",
        codigo: "05226"
    },
    {
        municipio: "PUERTO LIBERTADOR",
        departamento: "CORDOBA",
        codigo: "25580"
    },
    {
        municipio: "TUNJA",
        departamento: "BOYACA",
        codigo: "15001"
    },
    {
        municipio: "SAN GIL",
        departamento: "SANTANDER",
        codigo: "68679"
    },
    {
        municipio: "BUCARAMANGA",
        departamento: "SANTANDER",
        codigo: "68001"
    },
    {
        municipio: "ENVIGADO",
        departamento: "ANTIOQUIA",
        codigo: "05266"
    },
    {
        municipio: "BARRANCAS",
        departamento: "La Guajira",
        codigo: "44078"
    },
    {
        municipio: "DIBULLA",
        departamento: "La Guajira",
        codigo: "44090"
    },
    {
        municipio: "PEREIRA",
        departamento: "Risaralda",
        codigo: "66001"
    },
    {
        municipio: "ATLANTICO",
        departamento: "Galapa",
        codigo: "08296"
    },
    {
        municipio: "EL PRADO",
        departamento: "Tolima",
        codigo: "73563"
    }

]

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modelotipodocumento = {
    codigo: "",
    descripcion: "",
    tipodoc: "",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "nada",
    formapago: "si",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "si",
    descporc: "",
    ivasn: "si",
    ivaporc: "",
    rete: "si",
    ica: "si",
    reteiva: "si",
    imptosn: "si",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "",
    mvendedor: "si",
    captionvend: "",
    docu1: "si",
    captiondoc1: "",
    val_doc1: "",
    docu2: "si",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",

}
const modeloVendedor = {
    codigo: "",
    nombre: ""
}
const modeloInbodegas = {
    codigo: "",
    nombre: ""
}
const modelomediopago = {
    codigo: "",
    nombre: ""
}
const modeloTerceros = {
    caclientes: {
        tercero: "",
        clase: "",
        codigo: "",
        cedula: "",
        digito: "",
        razsoc: "",
        nom1: "",
        nom2: "",
        ape1: "",
        ape2: "",
        codigomun: "",
        coddepar: "",
        codpais: "",
        email: "",
        contacto: "",
        beneficiario: "",
        repleg: "",
        reg_merc: "",
        direccion: "",
        tel1: "",
        tel2: "",
        tel3: "",
        barrio: "",
        fechnac: "",
        interes: "",
        cupocre: "",
        plazo: "",
        vendedor: "",
        ccosto: "",
        precsug: "",
        actecono: "",
        feching: "",
        fechulne: "",
        regtrib: "",
        resfis: "",
        desccom: "",
        desccom2: "",
        desccom3: "",
        desccom4: "",
        autoreteft: "",
        estado: "",
        salant1: "",
        saldeb1: "",
        salcre1: "",
        venretefuente: "",
        venbasefte: "",
        venporcfte: "",
        veniva: "",
        venbaseiva: "",
        venporciva: "",
        venreteiva: "",
        venbasereteiva: "",
        venporcreteiva: "",
        venreteica: "",
        venbaseica: "",
        venporica: "",
        salact1: "",
        ciudad: "",
        pais: "",
        clas1: "",
        clas2: "",
        clas3: "",
        clas4: "",
        clas5: "",
        reteica: "",
        personat: "",
        auto: "",
        salant2: "",
        saldeb2: "",
        salcre2: "",
        salact2: "",
        brutop1: "",
        brutop2: "",
        ivap: "",
        desctop1: "",
        desctop2: "",
        reteftep: "",
        reteivap: "",
        reteica2p: "",
        imptoconsumop: "",
        brutoa1: "",
        brutoa2: "",
        ivaa: "",
        desctoa1: "",
        desctoa2: "",
        retftea: "",
        reteivaa: "",
        reteicaa: "",
        imptoconsa: "",
        creadopor: "",
        borradopor: "",
        modificpor: "",
        municipio: "",
        cambioimp: "",
        flete: "",
        cumplemes: "",
        cumpledia: "",
        cumpleano: "",
        horafecha: "",
        sexo: "",
        zona: "",
        clas6: "",
        clas7: "",
        clas8: "",
        clas9: "",
        clas10: "",
        autorexpro: "",
        cod_postal: "",
        nompais: "",
        campo: "",
        comretefte: "",
        combasefte: "",
        comporcfte: "",
        comreteiva: "",
        combasereteiva: "",
        comporcreteiva: "",
        comiva: "",
        combaseiva: "",
        comporciva: "",
        comreteica: "",
        combaseica: "",
        comporica: "",
        vencree: "",
        venbacree: "",
        venporcree: "",
        comcree: "",
        combacree: "",
        comporcree: "",
        Eliminado: "",
    },
    direcciones: []
}
const modelomovimiento = {
    compañia: "",
    concepto: "",
    ndocumento: "",
    fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
    tercero: "",
    cliente: "",
    nombrecli: "",
    vendedor: "",
    direccion: "",
    nombreven: "",
    documento1: "",
    documento2: "",
    periodo: "",
    horafecha: "",
    estacion: "",
    nomusuario: "",
    observacion: "",
    anuladopor: "",
    borradopor: "",
    bruto: "",
    iva: "",
    descuento: "",
    despiefac: "",
    retefuente: "",
    reteiva: "",
    ica: "",
    retefte: "",
    impconsumo: "",
    subtotal: "",
    total: "",
    cuotai: "",
    ncuotasi: "",
    diferidasi: "",
    apartirdei: "",
    tipocre: "",
    ncuotasf: "",
    tasa: "",
    diferidas: "",
    apartirde: "",
    fechpost: "",
    banco: "",
    efectivo: "",
    cheque: "",
    debito: "",
    credito: "",
    descuentoc: "",
    beneficiario: "",
    impreso: "",
    anulado: "",
    estado: "",
    modificadopor: "",
    convertcon: "",
    convertdoc: "",
    devolcon: "",
    devoldoc: "",
    concprod: "",
    ndocprod: "",
    conctrasl: "",
    ndoctrasl: "",
    concodcped: "",//esto es el telefono al momento de hacer factura de restaurante
    ndocodcped: "",
    moneda: "",
    pendiente: "",
    cree: "",
    fletes: "",
    tcambio: "",
    dsctopp1: "",
    dsctopp2: "",
    dsctopp3: "",
    dsctovapp1: "",
    dsctovapp2: "",
    dsctovapp3: "",
    bancocr: "",
    convdescon: "",
    convdesdoc: "",
    convoricon: "",
    convoridoc: "",
    poretefte: "",
    poreteiva: "",
    porica: "",
    porimptoconsumo: "",
    vlr_dolar: "",
    autorizado: "",
    fpago: "contado",
    mpago: "",
    sucursal: "",
    conceptodevo: "",
    ndocumentodevo: 0,
    cufe: "",
    emitido: "no",
    qr: "",
    famovimiento: [
        // {
        //     item: "",
        //     referencia: "",
        //     descrip: "",
        //     bodega: "",
        //     cantidad: "",
        //     precio: "",
        //     pordescuento: "",
        //     poriva: "",
        //     porimptoconsumo: "",
        //     subtotal: "",
        //     compañia: "",
        //     concepto: "",
        //     nrodocumento: "",
        //     costo: "",
        //     desadicional: "",
        //     unimed: "",
        //     ccosto: "",
        //     fecha: "",
        //     preivainc: "",
        //     codprod: "",
        //     canprod: "",
        //     horafecha: "",
        //     tipoitem: "",
        //     tercero: "",
        //     cliente: "",
        //     vlr_desc: "",
        //     vlr_iva: "",
        //     vlr_base_iva: "",
        //     costodolar: "",
        //     tasa: "",
        //     vlrimptoconsumo: "",
        //     poretefte: "",
        //     vlretefte: "",
        //     porica: "",
        //     vlrica: "",
        //     poreteiva: "",
        //     vlreteiva: "",
        //     cantdig: "",
        // }
    ]
}
const modeloproductos = [{
    idProducto: "1001",
    descripcion: "articulo 1",
    bodega: "5",
    cantidad: 25.3654,
    descuento: 5,
    iva: 19,
    consumo: 8,
    precio: 10000000,
    total: 500000 * 25.3654
}, {
    idProducto: "1002",
    descripcion: "articulo 2",
    bodega: "4",
    cantidad: 3,
    descuento: 2,
    iva: 19,
    consumo: 8,
    precio: 2000,
    total: 2000 * 3
}, {
    idProducto: "1003",
    descripcion: "articulo 3",
    bodega: "3",
    cantidad: 24,
    descuento: 7,
    iva: 0,
    consumo: 8,
    precio: 8000,
    total: 8000 * 24
}, {
    idProducto: "1004",
    descripcion: "articulo 4",
    bodega: "7",
    cantidad: 1,
    descuento: 19,
    iva: 19,
    consumo: 0,
    precio: 500000,
    total: 500000 * 1
}, {
    idProducto: "1005",
    descripcion: "articulo 5",
    bodega: "1",
    cantidad: 100,
    descuento: 20,
    iva: 19,
    consumo: 8,
    precio: 24.256,
    total: 24.256 * 100
}, {
    idProducto: "1006",
    descripcion: "articulo 6",
    bodega: "9",
    cantidad: 1000,
    descuento: 0,
    iva: 0,
    consumo: 8,
    precio: 200,
    total: 200 * 10000
}]
const modeloInarticulos = {
    codigo: "",
    nombre: "",
    descripcion: "",
    descadicional: "",
    referencia: "",
    plu: "",
    unimed: "",
    unxmed: "",
    preccost: "",
    preccost2: "",
    cantmin: "",
    cantmax: "",
    ultmvto: "",
    ultcosto: "",
    dscto: "",
    artserv: "",
    tipoitem: "",
    invfisico1: "",
    ulinvfisico1: "",
    saldact: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    facturable: "",
    produccion: "",
    fchaprom: "",
    fchainiprom: "",
    fchafinprom: "",
    serial: "",
    mfvence: "",
    fvence: "",
    vnr: "",
    costoniif: "",
    pos: "",
    lote: "",
    desc1: "",
    comision: "",
    horafecha: "",
    tasa_ultco: "",
    ult_fa_com: "",
    fe_ult_com: "",
    stockmin1: "",
    stockmax1: "",
    canesca1: "",
    canesca2: "",
    pvtesca1: "",
    pvtesca2: "",
    convunid: "",
    digitado: "",
    iva: "",
    imptocons: "",
    rete: "",
    ica: "",
    reteiva: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    inarticulosbodega: [
    ],
    inarticuloslistaprecio: [
    ],
}
const modeloInccostos = {
    codigo: "",
    nombre: "",
    subcodigo: "",
    subnombre: "",
    codigoa: "",
    horafecha: "",
}

const modeloSucursal = {
    nit: "",
    nombre: "",
    descripcion: "",
    telefono: "",
    direccion: "",
    email: "",
    celular: "",
    activo: "si",
    fechaultimopago: "",
    maximafechadeuso: "",
    electronica: "no",
    clavecertificado: "",
    tipopersona: "",
    responsabilidadfiscal: "",
    regimenfiscal: "",
    matriculamercantil: ""
}

const Venta = (props) => {
    const { user } = useContext(UserContext)
    const { permisos } = useContext(PermisoContext)

    const [dataUser, setDataUser] = useState(modelo)
    const [dataUserpermi, setDataUserpermi] = useState(modelo)
    const [valoruvt, setvaloruvt] = useState(47065)
    const [cantidadvaloruvt, setcantidadvaloruvt] = useState(5)
    const [a_Productos, setA_Productos] = useState([])
    const [a_Busqueda, setA_Busqueda] = useState("")
    const [documentoCliente, setDocumentoCliente] = useState("")
    const [nombreCliente, setNombreCliente] = useState("")
    const [valorabuscarproducto, setvalorabuscarproducto] = useState("")

    const [tipoDocumento, setTipoDocumento] = useState("Boleta")
    const [productos, setProductos] = useState(modeloproductos)
    const [bruto, setbruto] = useState(0)
    const [descuento, setdescuento] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [iva, setiva] = useState(0)
    const [consumo, setconsumo] = useState(0)
    const [reteiva, setreteiva] = useState(0)
    const [retefuente, setretefuente] = useState(0)
    const [reteica, setreteica] = useState(0)
    const [total, setTotal] = useState(0)
    const [total233, setTotal233] = useState(0)
    const [mostrarimagenes, setmostrarimagenes] = useState(true);
    const [previsualizartirillaplantilla, setprevisualizartirillaplantilla] = useState(false);
    const [previsualizartirillaplantillacarta, setprevisualizartirillaplantillacarta] = useState(false);
    const [igv, setIgv] = useState(0)
    const [tipodocumento, settipodocumento] = useState(modelotipodocumento)
    const [sucursal, setsucursal] = useState(modeloSucursal)
    const [valorescaja, setvalorescaja] = useState([])
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [plantillaspdf, setplantillaspdf] = useState([]);
    const [plantillapdf, setplantillapdf] = useState([]);
    const [busquedatipodocumentos, setbusquedatipodocumentos] = useState([]);
    const [verModaltipodocumentos, setVerModaltipodocumentos] = useState(false);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [Icono2, seticono2] = useState('./images/logo.png');
    const [Movimiento, setMovimiento] = useState(modelomovimiento);
    const [Movimientos, setMovimientos] = useState([]);
    const [busquedaMovimientos, setbusquedaMovimientos] = useState([]);
    const [busquedaMovimientosDevoluciones, setbusquedaMovimientosDevoluciones] = useState([]);
    const [verModalMovimientos, setVerModalMovimientos] = useState(false);
    const [verModalMovimientosremision, setVerModalMovimientosremision] = useState(false);
    const [verModalMovimientosDevoluciones, setVerModalMovimientosDevoluciones] = useState(false);
    const [cajaabierta, setcajaabierta] = useState(true);
    const [Roles, setRoles] = useState([]);
    const [fila, setfila] = useState([]);
    const [filaenmenu, setfilaenmenu] = useState([]);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [key, setKey] = useState('Datos');

    const [tercero, settercero] = useState(modeloTerceros)
    const [notascredito, setnotascredito] = useState([])
    const [notascredito2, setnotascredito2] = useState([])

    const [busquedaterceros, setbusquedaterceros] = useState([]);
    const [verModalterceros, setVerModalterceros] = useState(false);
    const [verModaltercerosdocumento2, setVerModaltercerosdocumento2] = useState(false);
    const [esperando, setesperando] = useState(false);
    const [terceros, setterceros] = useState([]);

    const [vendedor, setvendedor] = useState(modeloVendedor)
    const [busquedavendedors, setbusquedavendedors] = useState([]);
    const [verModalvendedors, setVerModalvendedors] = useState(false);
    const [vendedors, setvendedors] = useState([]);

    const [mediopago, setmediopago] = useState(modelomediopago)
    const [busquedamediopagos, setbusquedamediopagos] = useState([]);
    const [verModalmediopagos, setVerModalmediopagos] = useState(false);
    const [mediopagos, setmediopagos] = useState([]);

    const [articulo, setarticulo] = useState(modeloInarticulos)
    const [listaarticulo, setlistaarticulo] = useState([])
    const [remisionesfactura, setremisionesfactura] = useState([]);
    const [facturaremisiones, setfacturaremisiones] = useState([]);

    const [listaarticulocostoprecio, setlistaarticulocostoprecio] = useState([])
    const [listaexistenciaarticulo, setlistaexistenciaarticulo] = useState([])
    const [busquedaarticulos, setbusquedaarticulos] = useState([]);
    const [verModalarticulos, setVerModalarticulos] = useState(false);
    const [articulos, setarticulos] = useState([]);
    const [estoybuscando, setestoybuscando] = useState(false);

    const [bodega, setbodega] = useState(modeloInbodegas)
    const [busquedabodegas, setbusquedabodegas] = useState([]);
    const [busquedalistaprecioarticulo, setbusquedalistaprecioarticulo] = useState([]);
    const [precioescogido, setprecioescogido] = useState([]);
    const [verModalbodegas, setVerModalbodegas] = useState(false);
    const [verModalbodegas2, setVerModalbodegas2] = useState(false);
    const [bodegatranslado, setbodegatranslado] = useState("");
    const [verModallistaprecioarticulo, setVerModallistaprecioarticulo] = useState(false);
    const [abrilistadeprecio, setabrilistadeprecio] = useState(false);
    const [bodegas, setbodegas] = useState([]);
    const [abrirdescripcionadicional, setabrirdescripcionadicional] = useState(false);
    const [abrirotrosimpuestos, setabrirotrosimpuestos] = useState(false);
    const [abrirmediosdepago, setabrirabrirmediosdepago] = useState(false);
    const [abrirlotes, setabrirlotes] = useState(false);
    const [lotesporarticulo, setlotesporarticulo] = useState([]);
    const [lotesescritos, setlotesescritos] = useState([]);

    const [abriropcionespuntoventa, setabriropcionespuntoventa] = useState(false);
    const [porcentajereteiva, setporcentajereteiva] = useState(0);
    const [porcentajeica, setporcentajeica] = useState(0);
    const [acabodeseleccionararticulo, setacabodeseleccionararticulo] = useState(false);
    const [abrirbonos, setabrirbonos] = useState(false);
    const [valorbono, setvalorbono] = useState(0);
    const [codigobono, setcodigobono] = useState("");
    const [vercambiodecostoprecio, setvercambiodecostoprecio] = useState(false);
    const [articulocostoprecio, setarticulocostoprecio] = useState(modeloInarticulos);

    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));

    const [ccosto, setccosto] = useState(modeloInccostos)
    const [busquedaccostos, setbusquedaccostos] = useState([]);
    const [verModalccostos, setVerModalccostos] = useState(false);
    const [ccostos, setccostos] = useState([]);

    const [puntodeventacambiarcantidad, setpuntodeventacambiarcantidad] = useState(false);
    const [puntodeventacambiarprecio, setpuntodeventacambiarprecio] = useState(false);

    const [nuevo, setnuevo] = useState(false);
    const [editar, seteditar] = useState(false);
    const [existenciaarticulo, setexistenciaarticulo] = useState(0);
    const [existenciaarticulocaja, setexistenciaarticulocaja] = useState(0);
    const [existenciaarticuloblister, setexistenciaarticuloblister] = useState(0);
    const [existenciaarticulounidad, setexistenciaarticulounidad] = useState(0);
    const [Mediodepagos, setMediodepagos] = useState([]);
    const [previsualizar, setprevisualizar] = useState(false);
    const [previsualizar2, setprevisualizar2] = useState(false);
    const [previsualizar3, setprevisualizar3] = useState(false);
    const [mostrarexistencia, setmostrarexistencia] = useState(true);
    const [pararencantidad, setpararencantidad] = useState(false);

    const [previsualizarelectronica, setprevisualizarelectronica] = useState(false);
    const [previsualizarenviocorreo, setprevisualizarenviocorreo] = useState(false);
    const [abrirbodegatranslado, setabrirbodegatranslado] = useState(false);
    const [correosenvio, setcorreosenvio] = useState("");
    const [direccionestercero, setdireccionestercero] = useState([]);
    const closeModalPromiseResolveRef = useRef(null);
    const [vienedeafuera, setvienedeafuera] = useState(props.editarorden);
    const [saldoscliente, setsaldoscliente] = useState([]);
    const [saldosclientehoy, setsaldosclientehoy] = useState([]);
    const [certificado, setcertificado] = useState([])
    const [editarorden, seteditarorden] = useState(false);
    const [movimientoenvio, setmovimientoenvio] = useState(false);
    const [remisiones, setremisiones] = useState([]);
    const [puedoanular, setpuedoanular] = useState(false);
    const [creartercero, setcreartercero] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfDataUrl, setPdfDataUrl] = useState('');
    // const fm = new FormatMoney({ symbol: '' });
    // const fm2 = new FormatMoney({ symbol: '', decimals: 4 });
    // const fm3 = new FormatMoney({ symbol: '$', decimals: 4 });
    const toggleModaliframemovimiento = () => {
        setIsModalOpen(!isModalOpen)
        try {
            document.getElementById("movimientoreferencia" + fila).focus()
        } catch (error) {

        }
    };

    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)

            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerPlantillaspdf = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)

            let response = await fetch(props.servidor + '/PlantillapdfApi?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setplantillaspdf(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenertipodocumentomasrepetido = async () => {
        try {
            var turno = false
            props.permisosusuario.forEach(element => {
                if (element.tipo == "manejaturno" && element.acceso[0] == "2") {
                    turno = true
                }
            });
            let dt = JSON.parse(user)


            if (turno) {
                var turno = await obtenerturnos(dt.usuario.cedula)
                if (turno.length == 0) {
                    let response = await fetch(props.servidor + '/Fatipodocapi/masusado/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + '');

                    if (response.ok) {
                        let data = await response.json()
                        seleccionartipodocumentosmayor(data)
                        // setPendiente(false)
                    }
                } else {
                    var tipp = await buscarcoincidenciastipodocs(turno[0].tipodocumento)
                    seleccionartipodocumentosmayor(tipp)

                }
            } else {
                let response = await fetch(props.servidor + '/Fatipodocapi/masusado/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    seleccionartipodocumentosmayor(data)
                    // setPendiente(false)
                }
            }



        } catch (error) {
            console.log(error)
        }
    }

    const reestablecer = () => {
        setDocumentoCliente("");
        setNombreCliente("")
        setTipoDocumento("Boleta")
        setProductos([])
        setTotal(0)
        setSubTotal(0)
        setIgv(0)
    }
    const cargaricono = async () => {
        try {
            let dt = JSON.parse(user)
            const data = await import('./images/' + dt.empresa.config.ruta_logo);
            seticono2(data.default);
            console.log(data.default)
            console.log(Icono)
        } catch (error) {
            const data = await import('./images/blanco.png');
            seticono2(data.default);
            console.log(data.default)
            console.log(error)
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'F2') {
            // Aquí colocas lo que quieres que suceda
            document.getElementById("botonguardarmovimientoventascompras").click()
            // Puedes ejecutar cualquier acción aquí
        }
    };
    //busquedas
    useEffect(() => {
        let dt = JSON.parse(user)
        let dt2 = JSON.parse(permisos)
        props.permisosusuario.forEach(element => {
            if (element.tipo == "desactivarexistenciaeninventariofisico" && element.acceso[0] == "1") {
                setmostrarexistencia(false)
            }
        });
        setDataUser(dt)
        setDataUserpermi(dt2)
        buscartodastipodocumentos('');
        if (dt.empresa.modulos.ARTICULOS.electronico) {
            buscartodasterceros('');
            buscartodasarticulos('');
            buscartodoslosmovimientos('');
        }
        buscartodasvendedors('');
        buscartodasmediopagos('');
        buscartodasbodegas('');
        buscartodasccostos('');
        obtenerPlandecuentass('');
        obtenerPlantillaspdf('');

        cargaricono();
        try {

            props.correrSocket()
        } catch (error) {

        }
        try {
            //informetransladoo
            props.connection.on("informardetranslado", (comm) => {

                informardetransladoswal(comm)

            });
            props.permisosusuario.forEach(element => {
                if (element.tipo == "anular" && element.acceso[0] == "2") {
                    setpuedoanular(true)
                }
            });
        } catch (error) {

        }

        try {
            // Añadir el event listener cuando el componente se monte
            window.addEventListener('keydown', handleKeyDown);

            // Eliminar el event listener cuando el componente se desmonte
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        } catch (error) {

        }
    }, [])
    useEffect(() => {
        try {
            if (vienedeafuera) {
                var _movv = {}
                if (props.movimiento.faencmovi) {
                    _movv = props.movimiento
                } else {
                    _movv.faencmovi = props.movimiento
                    _movv.famovimiento = []
                }

                seleccionarMovimientos(_movv)

            }
        } catch (error) {

        }
    }, [vienedeafuera])

    useEffect(() => {
        try {
            if (!isModalOpen) {
                document.getElementById("movimientoreferencia" + fila).focus()
            }
        } catch (error) {

        }
    }, [isModalOpen])

    useEffect(() => {
        try {

            props.correrSocket()
        } catch (error) {

        }
        if (tipodocumento.busplu == 'si') {

            try {
                console.log("agregue item")
                if (!pararencantidad) {
                    if (Movimiento.famovimiento.length > 0) {
                        setfila(Movimiento.famovimiento.length - 1)

                        document.getElementById("movimientoreferencia" + fila).focus()
                    }
                } else {
                    if (Movimiento.famovimiento.length > 1) {
                        setfila(Movimiento.famovimiento.length - 2)
                        console.log("movimientocantidad" + (fila - 1))
                        document.getElementById("movimientocantidad" + (fila - 1)).focus()
                    }

                }

            } catch (error) {

            }
        }
        if (Movimiento.famovimiento.length == 0 && nuevo) {

            obtenertipodocumentomasrepetido()
        }

    }, [Movimiento.famovimiento.length])


    const informardetransladoswal = (comm) => {
        //informetransladoo
        let dt = JSON.parse(user)
        if (dt.usuario.departamento.includes(comm[1]) && dt.empresa.empresa.nit == '901187246') {
            Swal.fire(
                'Traslado!!',
                comm[0],
                'success'
            )
        }

    }
    const filtrartipodocumentos = async (coincidencia) => {
        var _codigomuns = tipodocumentos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.descripcion.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedatipodocumentos(_codigomuns)
    }
    const cerrarModaltipodocumentos = () => {
        setVerModaltipodocumentos(false);
        setbusquedatipodocumentos(tipodocumentos)
    }

    const base64certificado = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    dataJson = dataJson.filter((dato) => {
                        if (dato.tipodoc == 'compra' || dato.tipodoc == 'factura' || dato.tipodoc == 'cotizacion' || dato.tipodoc == 'devoluciones' || dato.tipodoc == 'devoluciones compra' || dato.tipodoc == 'pedidos' || dato.tipodoc == 'ordendecompra' || dato.tipodoc == 'plan separe' || dato.tipodoc == 'nota debito' || dato.tipodoc == 'translado' || dato.tipodoc == 'produccion' || dato.tipodoc == 'remision') {
                            var sipuedeusartipodedocumento = true
                            var sipuedeusartipodedocumento2 = false
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumento" && element.acceso[0] == "1" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento = false
                                }
                            });
                            var contt = true
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumentounico") {
                                    contt = false
                                }
                                if (element.tipo == "tipodocumentounico" && element.acceso[0] == "2" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento2 = true
                                } else {

                                }
                            });
                            if (contt) {
                                sipuedeusartipodedocumento2 = true
                            }
                            if (sipuedeusartipodedocumento && sipuedeusartipodedocumento2) {
                                return dato

                            }
                        }
                    })
                    settipodocumentos(dataJson)
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscartodastipodocumentosactualizar = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    dataJson = dataJson.filter((dato) => {
                        if (dato.tipodoc == 'compra' || dato.tipodoc == 'factura' || dato.tipodoc == 'cotizacion' || dato.tipodoc == 'devoluciones' || dato.tipodoc == 'devoluciones compra' || dato.tipodoc == 'pedidos' || dato.tipodoc == 'ordendecompra' || dato.tipodoc == 'plan separe' || dato.tipodoc == 'nota debito' || dato.tipodoc == 'translado' || dato.tipodoc == 'produccion' || dato.tipodoc == 'remision') {
                            var sipuedeusartipodedocumento = true
                            var sipuedeusartipodedocumento2 = false
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumento" && element.acceso[0] == "1" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento = false
                                }
                            });
                            var contt = true
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumentounico") {
                                    contt = false
                                }
                                if (element.tipo == "tipodocumentounico" && element.acceso[0] == "2" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento2 = true
                                } else {

                                }
                            });
                            if (contt) {
                                sipuedeusartipodedocumento2 = true
                            }
                            if (sipuedeusartipodedocumento && sipuedeusartipodedocumento2) {
                                return dato

                            }
                        }
                    })
                    settipodocumentos(dataJson)
                    try {
                        var tdd = dataJson.find(p => p.codigo == Movimiento.concepto)
                        if (tdd) {
                            settipodocumento(tdd)
                        }
                    } catch (error) {

                    }
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscartodoslosmovimientos23 = async (coincidencia, nume) => {
        try {
            try {


                let dt = JSON.parse(user)
                let response = await fetch(props.servidor + '/Movimientoapi/optimizado/unicosdevo/' + coincidencia + '/' + nume + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    return data
                }
            } catch (error) {
                console.log(error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const buscartodoslosmovimientos2 = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/Movimientoapi/optimizado/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '?llaveusuario=' + dt.usuario.cargo + ''
            const api = fetch(props.servidor + '/Movimientoapi/optimizado/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '&llaveusuario=' + dt.usuario.cargo + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setMovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodoslosmovimientos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/Movimientoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/Movimientoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setMovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodoslosmovimientosportercero = (coincidencia) => {
        try {
            // tipodocumento.noimpdupl
            let dt = JSON.parse(user)
            var url = props.servidor + '/Movimientoapi/tercero/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/Movimientoapi/tercero/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    var datosfiltrados = []
                    if (tipodocumento.noimpdupl == "si") {
                        for (let i = 0; i < dataJson.length; i++) {
                            const element = dataJson[i].faencmovi;
                            var tipodoc = ""
                            tipodocumentos.map((dato, index) => {
                                if (dato.codigo.toUpperCase() == element.concepto.toUpperCase()) {
                                    tipodoc = dato
                                }

                            })
                            if (tipodoc.tipodoc == "plan separe" || tipodoc.tipodoc == "cotizacion") {
                                datosfiltrados.push(dataJson[i])
                            }
                        }


                        setMovimientos(datosfiltrados)
                        setbusquedaMovimientos(datosfiltrados)
                    } else {
                        setMovimientos(dataJson)
                        setbusquedaMovimientos(dataJson)
                    }

                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodoslosmovimientosporterceroremisiones = (coincidencia, tipo) => {
        try {
            // tipodocumento.noimpdupl
            let dt = JSON.parse(user)

            var tipod = tipodocumento.tipodoc == "remision" ? tipo == "cliente" ? "pedidos" : "ordendecompra" : "remision"

            var url = props.servidor + '/Movimientoapi/tercero/remisiones/' + coincidencia + '/' + tipo + '/' + tipod + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(url)
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    var datosfiltrados = []
                    setMovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)

                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleChangeformapago = (e) => {
        if (tipodocumento.codigo != "") {

            let value = e.target.value
            console.log(e.target)
            var _Movimiento2 = { ...Movimiento }
            var _Movimiento = Mediodepagos.map((dato) => { return dato })
            _Movimiento = _Movimiento.map((dato) => {
                if (e.target.name == dato.codigo) {
                    var dat = {
                        ...dato,
                        ['valor']: Number(value + "")
                    }
                    return dat
                } else {
                    return dato
                }
            })
            if (e.target.name == 'descuentoc') {
                _Movimiento2['descuentoc'] = Number(value + "")
            }
            var tott = total
            if (e.target.name == "efectivo") {
                _Movimiento2['efectivo'] = Number(value + "")
                tott = Number(value + "")
                var efec = tott + Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec + Number(dato.valor + "")

                })
                efec = efec - total
                _Movimiento2['fletes'] = (efec > 0) ? (efec) : 0

            } else {
                var efec = tott - Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec - Number(dato.valor + "")

                })

                _Movimiento2['efectivo'] = (efec >= 0) ? efec : 0
                _Movimiento2['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            }
            if (e.target.name == 'bono') {
                setvalorbono(Number(e.target.value))
            }
            setMediodepagos(_Movimiento)
            setMovimiento(_Movimiento2)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    const handleChangeformapago2 = (value2) => {
        if (tipodocumento.codigo != "") {

            let value = value2
            var _Movimiento2 = { ...Movimiento }
            var _Movimiento = Mediodepagos.map((dato) => { return dato })
            _Movimiento = _Movimiento.map((dato) => {
                if ("bono" == dato.codigo) {
                    var dat = {
                        ...dato,
                        ['valor']: Number(value + "")
                    }
                    return dat
                } else {
                    return dato
                }
            })

            var tott = total
            if (false) {
                _Movimiento2['efectivo'] = Number(value + "")
                tott = Number(value + "")
                var efec = tott + Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec + Number(dato.valor + "")

                })
                efec = efec - total
                _Movimiento2['fletes'] = (efec > 0) ? (efec) : 0

            } else {
                var efec = tott - Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec - Number(dato.valor + "")

                })

                _Movimiento2['efectivo'] = (efec >= 0) ? efec : 0
                _Movimiento2['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            }

            setMediodepagos(_Movimiento)
            setMovimiento(_Movimiento2)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    const handleChange = async (e) => {
        if (tipodocumento.codigo != "") {
            let value = e.target.value
            if (e.target.name == 'fecha' && tipodocumento.ctrlmov == 'si') {

                var nopuedecambiarfecha = true
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "cambiarfecha" && element.acceso[0] == "2") {
                        nopuedecambiarfecha = false
                    }
                });
                if (nopuedecambiarfecha) {
                    Swal.fire(
                        'Restringido!',
                        'No puede cambiar la fecha',
                        'error'
                    )
                    var _Movimiento = { ...Movimiento }
                    _Movimiento[e.target.name] = fechahoy
                    setMovimiento(_Movimiento)
                    setRoles([])

                    return
                }
            }
            if (e.target.name == 'fecha') {
                if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "") {
                    let dt = JSON.parse(user)
                    var url = props.servidor + '/Cajaapi/cerrado/' + e.target.value + '/' + props.caja + '?llave=' + dt.usuario.llaveempresa + ''
                    try {
                        let response = await fetch(url);

                        if (response.ok) {
                            let data = await response.json()
                            if (data.length == 0) {
                                setcajaabierta(true)

                            } else {
                                setcajaabierta(false)

                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            }

            console.log(e.target)
            var _Movimiento = { ...Movimiento }
            _Movimiento[e.target.name] = value
            setMovimiento(_Movimiento)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    const calculartotalesmovimiento = (_enviodatos2) => {
        var totales = {
            bruto: 0,
            descuento: 0,
            subtotal: 0,
            iva: 0,
            consumo: 0,
            reteiva: 0,
            preivainc: 0,
            retefuente: 0,
            reteica: 0,
            total: 0,
        }
        _enviodatos2.map((dato, index) => {
            var bruto = 0
            var descuento = 0
            var subtotal = 0
            var iva = 0
            var preivainc = 0
            var consumo = 0
            var reteiva = 0
            var retefuente = 0
            var reteica = 0
            var total = 0
            var cantidad = Number(dato.cantidad)
            var precio = Number(dato.precio)
            var pordescuento = Number(dato.pordescuento)
            var poriva = Number(dato.poriva)
            var porimptoconsumo = Number(dato.porimptoconsumo)
            var poretefte = Number(dato.poretefte)
            var porica = Number(dato.porica)
            var poreteiva = Number(dato.poreteiva)
            var porpreivainc = Number(dato.preivainc)
            var valimpoconsumo = Number(dato.vlrimptoconsumo) * Number(dato.cantidad)

            bruto = (cantidad * precio)
            descuento = bruto * (pordescuento / 100)
            subtotal = bruto - descuento
            iva = subtotal * (poriva / 100)
            preivainc = subtotal * (porpreivainc / 100)
            consumo = subtotal * (porimptoconsumo / 100)
            reteiva = iva * (poreteiva / 100)
            retefuente = subtotal * (poretefte / 100)
            reteica = subtotal * (porica / 100)
            total = subtotal + iva + preivainc + consumo + valimpoconsumo - reteiva - retefuente - reteica
            totales.bruto = totales.bruto + bruto
            totales.descuento = totales.descuento + descuento
            totales.subtotal = totales.subtotal + subtotal
            totales.iva = totales.iva + iva
            totales.preivainc = totales.preivainc + preivainc
            totales.consumo = totales.consumo + consumo + valimpoconsumo
            totales.reteiva = totales.reteiva + reteiva
            totales.retefuente = totales.retefuente + retefuente
            totales.reteica = totales.reteica + reteica
            totales.total = totales.total + total
        })

        setbruto(totales.bruto)
        setdescuento(totales.descuento)
        setSubTotal(totales.subtotal)
        setiva(totales.iva)
        setconsumo(totales.consumo)
        setreteiva(totales.reteiva)
        setretefuente(totales.retefuente)
        setreteica(totales.reteica)
        setTotal(totales.total)
        return totales
    }
    const retefuentesugerida = () => {

    }


    function calcularDigitoVerificacion(myNit) {
        try {
            var vpri,
                x,
                y,
                z;

            // Se limpia el Nit
            myNit = myNit.replace(/\s/g, ""); // Espacios
            myNit = myNit.replace(/,/g, ""); // Comas
            myNit = myNit.replace(/\./g, ""); // Puntos
            myNit = myNit.replace(/-/g, ""); // Guiones

            // Se valida el nit
            if (isNaN(myNit)) {
                console.log("El nit/cédula '" + myNit + "' no es válido(a).");
                return "";
            };

            // Procedimiento
            vpri = new Array(16);
            z = myNit.length;

            vpri[1] = 3;
            vpri[2] = 7;
            vpri[3] = 13;
            vpri[4] = 17;
            vpri[5] = 19;
            vpri[6] = 23;
            vpri[7] = 29;
            vpri[8] = 37;
            vpri[9] = 41;
            vpri[10] = 43;
            vpri[11] = 47;
            vpri[12] = 53;
            vpri[13] = 59;
            vpri[14] = 67;
            vpri[15] = 71;

            x = 0;
            y = 0;
            for (var i = 0; i < z; i++) {
                y = (myNit.substr(i, 1));
                // console.log ( y + "x" + vpri[z-i] + ":" ) ;

                x += (y * vpri[z - i]);
                // console.log ( x ) ;
            }

            y = x % 11;
            // console.log ( y ) ;

            return (y > 1) ? 11 - y : y;
        } catch (error) {
            return ""
        }
    }


    const handleChangegrillafaencmovi2 = async (event, value, name, codi) => {

        var _Movimiento = { ...Movimiento }
        var subtabla = "famovimiento"
        if (event.target.name != undefined) {
            name = event.target.name
        }
        var vll = ''
        if (value) {
            vll = value[codi]
        }
        var _datos = { ...Movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })
        if (name == 'acreedor') {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['tercero']: value['tercero']
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })

    }
    const informardetranslado = async (id) => {
        try {
            //informetransladoo

            try {
                await props.connection.invoke("informardetranslado", id);
            } catch (err) {
                console.error(err);
            }
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(this.props.correrSocket(), 2000);
        }
    }
    const buscarlotesporarticuloseleccionado = async (e) => {

        try {


            if (Movimiento.famovimiento[fila]) {
                if (Movimiento.famovimiento[fila].referencia != "" && Movimiento.famovimiento[fila].bodega != "") {
                    let dt = JSON.parse(user)
                    let response = await fetch(props.servidor + '/Inarticulosapi/reporteexistenciaporlote/existencia/'
                        + "-" + "/" + "-" + "/" + "-"
                        + "/" + dt.empresa.config.identificador_software + "/" + Movimiento.famovimiento[fila].referencia
                        + "/" + "-" + "/" + "-" + "/" + "-" + "/" + Movimiento.famovimiento[fila].bodega + '?llave=' + dt.usuario.llaveempresa + '');

                    if (response.ok) {
                        let data = await response.json()
                        return data

                        // setPendiente(false)
                    }
                } else {
                    var mensaje = Movimiento.famovimiento[fila].referencia == "" ? 'Referencia en blanco' : Movimiento.famovimiento[fila].bodega == "" ? 'Bodega en Blanco' : ''
                    Swal.fire(
                        'Opps!',
                        mensaje,
                        'error'
                    )
                    return
                }
            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleChangegrillafaencmovi = async (e) => {
        if ((e.target.value.endsWith(" ") || e.target.value == " " || e.target.value.includes(" ")) && (e.target.name != 'descrip' && e.target.name != 'desadicional' && e.target.name != 'referencia')) {


        } else {



            if (e.target.name != 'descrip' && e.target.name != 'desadicional' && e.target.name != 'referencia'
                && e.target.name != 'lote' && e.target.name != 'fechavenclote'
            ) {
                if (isNaN(Number(e.target.value))) {
                    if (!e.target.value.includes("+")) {
                        Swal.fire(
                            'Opps!',
                            'Solo se aceptan Numeros 2',
                            'error'
                        )
                        return
                    } else {
                        return
                    }
                }
            }
            if (e.target.value.includes("+")) {
                return
            }
            if (e.target.value == " ") {
                return

            }



            if ((e.target.name == "precio" || e.target.name == "poriva") && (tipodocumento.tipodoc == 'factura' || tipodocumento.tipodoc == 'remision')) {
                var puedecamviarlistadeprecio = true
                var clavepermiso = ""
                props.permisosusuario.forEach(element => {
                    if ((element.tipo == "nodejarcambiaeprecio" || element.tipo == "nocambiarpreciocambiarlistadeprecio") && element.acceso[0] == "1") {
                        if (element.tipo == "nocambiarpreciocambiarlistadeprecio") {
                            if (!e.cancelable) {
                                puedecamviarlistadeprecio = false
                                clavepermiso = element.clave
                            }
                        } else {
                            puedecamviarlistadeprecio = false
                            clavepermiso = element.clave
                        }
                    }
                });
                try {
                    if (listaarticulo[fila].produccion == "domicilio") {
                        puedecamviarlistadeprecio = true
                    }
                } catch (error) {

                }
                if (!puedecamviarlistadeprecio) {
                    var ssdsdd = e.target.getAttribute('abrilistadeprecio')
                    if (e.target.getAttribute('abrilistadeprecio') == 'si') {
                        props.permisosusuario.forEach(element => {
                            if (element.tipo == "nocambiarpreciocambiarlistadeprecio" && element.acceso[0] == "1") {
                                clavepermiso = ""
                            }
                        });
                    }
                    if (clavepermiso != "" && clavepermiso != null) {
                        var claveigual = await clavepermisosdig(clavepermiso, "Digite Clave para Cambiar Precio")
                        if (!claveigual) {
                            Swal.fire(
                                'Opps!',
                                'No tiene permisos para cambiar  precio',
                                'error'
                            )
                            return
                        } else {
                            var valorprecio = await inputadigitar(clavepermiso, "Digite el Precio")
                            e.target.value = valorprecio
                            if (isNaN(Number(e.target.value))) {
                                if (!e.target.value.includes("+")) {
                                    Swal.fire(
                                        'Opps!',
                                        'Solo se aceptan Numeros 2',
                                        'error'
                                    )
                                    return
                                } else {
                                    return
                                }
                            }
                        }

                    } else {
                        var puedolistadeprecio = false
                        props.permisosusuario.forEach(element => {
                            if (element.tipo == "nocambiarpreciocambiarlistadeprecio" && element.acceso[0] == "1") {
                                puedolistadeprecio = true
                            }
                        });
                        if (puedolistadeprecio) {
                            if (e.target.getAttribute('abrilistadeprecio') == 'si') {

                            }
                        } else {
                            props.permisosusuario.forEach(element => {
                                if ((element.tipo == "nodejarcambiaeprecio") && element.acceso[0] == "1") {

                                    puedolistadeprecio = false

                                }
                            });
                            if (!puedolistadeprecio) {
                                Swal.fire(
                                    'Opps!',
                                    'No tiene permisos para cambiar  precio',
                                    'error'
                                )
                                return
                            }


                        }

                    }

                }
            }
            if (e.target.name == "precio" && (tipodocumento.tipodoc == 'factura' || tipodocumento.tipodoc == 'remision')) {
                var puedecamviarlistadeprecio2 = true
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "nodejarcambiaepreciohaciaabajo" && element.acceso[0] == "1") {
                        puedecamviarlistadeprecio2 = false
                    }
                });
                if (!puedecamviarlistadeprecio2) {
                    for (let i = 0; i < Movimiento.famovimiento.length; i++) {
                        const element = Movimiento.famovimiento[i];
                        if (i == fila) {
                            if (Number(element.precio + "") < Number(e.target.value)) {
                                puedecamviarlistadeprecio2 = true
                            }
                        }

                    }


                }
                if (!puedecamviarlistadeprecio2) {
                    Swal.fire(
                        'Opps!',
                        'No tiene permisos para cambiar  precio',
                        'error'
                    )
                    return
                }
            }


            var _articulo = listaarticulo[fila]
            e.target.setAttribute('cambio', 'si')
            var _Movimiento = { ...Movimiento }
            var subtabla = e.target.getAttribute('tabla')
            var _datos = { ...Movimiento }[subtabla]
            var _enviodatos = _datos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                    return dat
                } else {
                    return dato
                }
            })
            if (dataUser.empresa.config.especialidad == "drogueria" && e.target.name == "presentacion" && tipodocumento.tipodoc == "factura") {

                var preciousado = 0;
                if (tipodocumento.precsug != "") {
                    _articulo.inarticuloslistaprecio.map((precio) => {
                        if (precio.listadeprecio == tipodocumento.precsug && precio.presentacion == e.target.value) {
                            preciousado = precio.precio
                        }
                    })
                } else {
                    if (tercero.precsug != "") {
                        _articulo.inarticuloslistaprecio.map((precio) => {
                            if (precio.listadeprecio == tercero.precsug && precio.presentacion == e.target.value) {
                                preciousado = precio.precio
                            }
                        })
                    }
                }
                _enviodatos = _datos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            [e.target.name]: e.target.value,
                            ['precio']: preciousado,
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }
            var totales = calculartotalesmovimiento(_enviodatos)

            if (tipodocumento.rete == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.ica == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.reteiva == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            _enviodatos = _enviodatos.map((dato, index) => {
                var totalll2 = calcularsubtotalitem(_enviodatos[index])

                var dat = {
                    ...dato,
                    ['subtotal']: totalll2,
                }
                return dat

            })
            var total = calcularsubtotalitem(_enviodatos[fila])
            var _enviodatos2 = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['subtotal']: total
                    }
                    return dat
                } else {
                    return dato
                }
            })
            var totales = calculartotalesmovimiento(_enviodatos2)
            // if (tipodocumento.rete == "si" && e.target.name != "poretefte") {
            //     if (totales.total >= tercero.venbasefte) {
            //         _enviodatos2 = _enviodatos2.map((dato, index) => {
            //             var dat = {
            //                 ...dato,
            //                 ['poretefte']: dato.poretefte==0 ? Number(tercero.venporcfte + ""): dato.poretefte
            //             }
            //             return dat
            //         })
            //         totales = calculartotalesmovimiento(_enviodatos2)
            //     } else {
            //         _enviodatos2 = _enviodatos2.map((dato, index) => {
            //             var dat = {
            //                 ...dato,
            //                 ['poretefte']: Number(0 + "")
            //             }
            //             return dat
            //         })
            //         totales = calculartotalesmovimiento(_enviodatos2)
            //     }
            //     total = calcularsubtotalitem(_enviodatos2[fila])
            //     _enviodatos2 = _enviodatos2.map((dato, index) => {
            //         if (index == fila) {
            //             var dat = {
            //                 ...dato,
            //                 ['subtotal']: total
            //             }
            //             return dat
            //         } else {
            //             return dato
            //         }
            //     })
            // }

            _Movimiento[subtabla] = _enviodatos2
            // setMovimientos(_Movimientos)

            setMovimiento(_Movimiento)
            console.log(Movimiento)
            setRoles({ codig: '' })
            console.log(Roles)
            try {
                try {
                    if (e.target.getAttribute('abrilistadeprecio') == 'si') {
                        document.getElementById("movimiento" + "precio" + fila).blur()
                    }
                } catch (error) {

                }

            } catch (error) {

            }

            var ultimo = e.target.getAttribute('ultimo')
            var ultimo = e.target.getAttribute('ultimo')
        }
    }

    const ivaincluido = async (e) => {
        if (e.target.name != 'descrip') {
            if (isNaN(Number(e.target.value))) {
                // Swal.fire(
                //     'Opps!',
                //     'Solo se aceptan Numeros 3',
                //     'error'
                // )
                return
            }
        }

        var _Movimiento = { ...Movimiento }
        var subtabla = 'famovimiento'
        var _datos = { ...Movimiento }[subtabla]
        var porcentajeivaincluido = 0
        if (tipodocumento.ivasn == "si" && tipodocumento.ivaporc != 0) {
            porcentajeivaincluido = Number(tipodocumento.ivaporc)
        } else {
            porcentajeivaincluido = Number(listaarticulo[fila].iva)
        }


        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var preccv = Number(dato[e.target.name])
                if (tipodocumento.cambioiva == "si") {
                    preccv = Number(dato[e.target.name]) - Number(listaarticulo[fila].impoconsumovalor)
                }

                var dat = {
                    ...dato,
                    [e.target.name]: (preccv / ((100 + porcentajeivaincluido) / 100)),
                    ['poriva']: porcentajeivaincluido
                }
                return dat
            } else {
                return dato
            }
        })


        var totall = calcularsubtotalitem(_enviodatos[fila])
        var totales = calculartotalesmovimiento(_enviodatos)
        if (true) {
            if (tipodocumento.rete == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.ica == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.reteiva == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }

            }
        }

        let _enviodatos2 = _enviodatos.map((dato, index) => {
            var total = calcularsubtotalitem(dato)
            var dat = {
                ...dato,
                ['subtotal']: total
            }
            return dat

        })
        var totales = calculartotalesmovimiento(_enviodatos2)

        if (tipodocumento.cambioiva == "si") {
            if (listaarticulo[fila].impoconsumovalor != 0) {
                porcentajeivaincluido = Number(listaarticulo[fila].impoconsumovalor)
                _enviodatos2 = _enviodatos2.map((dato, index) => {
                    if (index == fila) {
                        var precc1 = dato.precio
                        var dat = {
                            ...dato,
                            [e.target.name]: precc1,
                            ['vlrimptoconsumo']: porcentajeivaincluido
                        }
                        return dat
                    } else {
                        return dato
                    }
                })


                var totall = calcularsubtotalitem(_enviodatos2[fila])
                var totales = calculartotalesmovimiento(_enviodatos2)
                if (true) {
                    if (tipodocumento.rete == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.ica == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.reteiva == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                }

                _enviodatos2 = _enviodatos2.map((dato, index) => {
                    var total = calcularsubtotalitem(dato)
                    var dat = {
                        ...dato,
                        ['subtotal']: total
                    }
                    return dat

                })
                var totales = calculartotalesmovimiento(_enviodatos2)


            } else {

            }

        }
        _Movimiento[subtabla] = _enviodatos2
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }

    const impoincluidosinevento = async (name, value2) => {
        if (name != 'descrip') {
            if (isNaN(Number(value2))) {
                // Swal.fire(
                //     'Opps!',
                //     'Solo se aceptan Numeros 3',
                //     'error'
                // )
                return
            }
        }

        var _Movimiento = { ...Movimiento }
        var subtabla = 'famovimiento'
        var _datos = { ...Movimiento }[subtabla]
        var porcentajeivaincluido = 0
        if (listaarticulo[fila].impoconsumovalor != 0) {
            porcentajeivaincluido = Number(listaarticulo[fila].impoconsumovalor)
        } else {
            return
        }


        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: Number(dato[name]) - porcentajeivaincluido,
                    ['vlrimptoconsumo']: porcentajeivaincluido
                }
                return dat
            } else {
                return dato
            }
        })


        var totall = calcularsubtotalitem(_enviodatos[fila])
        var totales = calculartotalesmovimiento(_enviodatos)
        if (true) {
            if (tipodocumento.rete == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.ica == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.reteiva == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= tercero.venbasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= tercero.combasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }

            }
        }

        let _enviodatos2 = _enviodatos.map((dato, index) => {
            var total = calcularsubtotalitem(dato)
            var dat = {
                ...dato,
                ['subtotal']: total
            }
            return dat

        })
        var totales = calculartotalesmovimiento(_enviodatos2)

        _Movimiento[subtabla] = _enviodatos2
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

    }

    const impoconsumoincluido = async (e) => {
        impoincluidosinevento(e.target.name, e.target.value)
    }

    const cambiardatosconimpuesto = async () => {
        var _Movimiento = { ...Movimiento }
        var subtabla = 'famovimiento'
        var _datos = { ...Movimiento }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var dat = {
                ...dato,
                ['porica']: porcentajeica,
                ['poreteiva']: porcentajereteiva
            }
            return dat

        })

        var totales = calculartotalesmovimiento(_enviodatos)

        _Movimiento[subtabla] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

    }
    const totalunidades = () => {
        var tot = 0
        for (let i = 0; i < Movimiento.famovimiento.length; i++) {
            const element = Movimiento.famovimiento[i];
            tot = tot + element.cantidad
        }
        return tot
    }

    const handleChangeimpuestosglobales = async (e) => {
        var _Movimiento = { ...Movimiento }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Movimiento }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var dat = {
                ...dato,
                [e.target.name]: e.target.value
            }
            return dat

        })
        if (e.target.name == "porica") {
            setporcentajeica(e.target.value)
            var r = fm1.from(e.target.value) + " %"
        }
        if (e.target.name == "poreteiva") {
            setporcentajereteiva(e.target.value)
            var r = fm1.from(e.target.value) + " %"
        }
        var totales = calculartotalesmovimiento(_enviodatos)

        _Movimiento[subtabla] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }


    const tipodedocumentoexiste = async (e) => {
        try {

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + e + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo != '') {
                    return true
                } else {
                    return false
                }

                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const cambiartipodocumento = async (e) => {
        try {


            if (e.target.value != '') {
                let dt = JSON.parse(user)
                let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + e.target.value + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    if (data.codigo != '') {
                        var sipuedeusartipodedocumento = true
                        var sipuedeusartipodedocumento2 = false
                        props.permisosusuario.forEach(element => {
                            if (element.tipo == "tipodocumento" && element.acceso[0] == "1" && element.tipodoc == data.codigo) {
                                sipuedeusartipodedocumento = false
                            }
                        });
                        var contt = true
                        props.permisosusuario.forEach(element => {
                            if (element.tipo == "tipodocumentounico") {
                                contt = false
                            }
                            if (element.tipo == "tipodocumentounico" && element.acceso[0] == "2" && element.tipodoc == data.codigo) {
                                sipuedeusartipodedocumento2 = true
                            } else {

                            }
                        });
                        if (contt) {
                            sipuedeusartipodedocumento2 = true
                        }
                        if (sipuedeusartipodedocumento && sipuedeusartipodedocumento2) {

                            seleccionartipodocumentos(data)

                        } else {
                            Swal.fire(
                                'Opps!',
                                'Sin Permisos',
                                'error'
                            )
                            var _Movimiento = { ...Movimiento }
                            _Movimiento['concepto'] = ''
                            setMovimiento(_Movimiento)
                            return
                        }
                    } else {
                        Swal.fire(
                            'Opps!',
                            'Tipo de Documento no Existe',
                            'error'
                        )
                        var _Movimiento = { ...Movimiento }
                        _Movimiento['concepto'] = ''
                        setMovimiento(_Movimiento)
                        return
                    }

                    // setPendiente(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const seleccionartipodocumentosmayor = async (e) => {

        let value = e
        var _Movimiento = { ...modelomovimiento }
        _Movimiento['concepto'] = value.codigo

        if (value.ctrlmov == 'si') {

            _Movimiento['fecha'] = fechahoy
        }

        if (value.afecaj != "nada" && value.afecaj != "") {
            let dt = JSON.parse(user)
            var url = props.servidor + '/Cajaapi/cerrado/' + _Movimiento.fecha + '/' + props.caja + '?llave=' + dt.usuario.llaveempresa + ''
            try {
                let response = await fetch(url);

                if (response.ok) {
                    let data = await response.json()
                    if (data.length == 0) {
                        setcajaabierta(true)

                    } else {
                        setcajaabierta(false)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setRoles([])
        cerrarModaltipodocumentos()
        settipodocumento(value)

        // Definir las fechas
        // var fecha1 = new Date(); // 24 de abril de 2024
        // var fecha2 = new Date(); // 15 de abril de 2024

        // // Calcular la diferencia en milisegundos
        // var diferenciaEnMilisegundos = fecha1 - fecha2;

        // // Convertir la diferencia de milisegundos a días
        // var diasDeDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

        // console.log("Diferencia en días: " + diasDeDiferencia);
        // if () {

        // }
        ponerterceroenblanco(_Movimiento, value)
        var sucursal2 = await buscarcoincidenciassucursal(value.compañia)
        setsucursal(sucursal2)
        try {
            if (value.tipodoc == "translado") {
                var tipodocc2 = await buscarcoincidenciastipodocs(value.tdpro)
                var bod = tipodocc2 ? tipodocc2.tbodega : ""
                setbodegatranslado(bod)
            }
        } catch (error) {

        }



    }


    const seleccionartipodocumentos = async (e) => {
        if (Movimiento.ndocumento == 0 || Movimiento.ndocumento == '') {
            let value = e
            var _Movimiento = { ...Movimiento }
            _Movimiento['concepto'] = value.codigo
            var _datos = { ...Movimiento }["famovimiento"]
            if (value.ctrlmov == 'si') {

                _Movimiento['fecha'] = fechahoy
            }
            let _enviodatos22 = _datos.map((dato, index) => {
                var dat = {
                    ...dato,
                    ["ccosto"]: value.centrocosto,
                    ["bodega"]: value.tbodegasn

                }
                return dat

            })
            _Movimiento.famovimiento = _enviodatos22
            if (value.afecaj != "nada" && value.afecaj != "") {
                let dt = JSON.parse(user)
                var url = props.servidor + '/Cajaapi/cerrado/' + _Movimiento.fecha + '/' + props.caja + '?llave=' + dt.usuario.llaveempresa + ''
                try {
                    let response = await fetch(url);

                    if (response.ok) {
                        let data = await response.json()
                        if (data.length == 0) {
                            setcajaabierta(true)

                        } else {
                            setcajaabierta(false)

                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }

            setRoles([])
            cerrarModaltipodocumentos()
            settipodocumento(value)

            // Definir las fechas
            // var fecha1 = new Date(); // 24 de abril de 2024
            // var fecha2 = new Date(); // 15 de abril de 2024

            // // Calcular la diferencia en milisegundos
            // var diferenciaEnMilisegundos = fecha1 - fecha2;

            // // Convertir la diferencia de milisegundos a días
            // var diasDeDiferencia = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

            // console.log("Diferencia en días: " + diasDeDiferencia);
            // if () {

            // }
            ponerterceroenblanco(_Movimiento, value)


            var sucursal2 = await buscarcoincidenciassucursal(value.compañia)
            setsucursal(sucursal2)
            try {
                if (value.tipodoc == "translado") {
                    var tipodocc2 = await buscarcoincidenciastipodocs(value.tdpro)
                    var bod = tipodocc2 ? tipodocc2.tbodega : ""
                    setbodegatranslado(bod)
                }
            } catch (error) {

            }


        }
    }

    const filtrarterceros = async (coincidencia) => {
        var _codigomuns = await buscarcoincidenciasterceros(coincidencia)
        if (_codigomuns) {
            setbusquedaterceros(_codigomuns)

        } else {
            setbusquedaterceros([])


        }
    }
    const cerrarModalterceros = () => {
        setVerModalterceros(false);
        setVerModaltercerosdocumento2(false);
        setbusquedaterceros(terceros)
    }
    const buscartodasterceros = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setterceros(dataJson)
                    if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == 'plan separe' || tipodocumento.tipodoc == 'cotizacion') {
                        setbusquedaterceros(dataJson.filter(e => e.tercero == "cliente"))

                    } else {
                        if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                            setbusquedaterceros(dataJson.filter(e => e.tercero == "proveedor"))

                        } else {
                            setbusquedaterceros(dataJson)

                        }
                    }

                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscarcaclientesunico = async (coincidencia) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra" || tipodocumento.tipodoc == "devoluciones compra" || tipodocumento.tipodoc == "translado") {
                tipotercero = "proveedor"

            }
            if (tipodocumento.tipodoc == "remision" || tipodocumento.tipodoc == "translado") {
                tipotercero = "-"
            }
            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }

    const buscarcaclientesunicocontercero = async (coincidencia, tdoc) => {
        try {
            var tipotercero = "cliente"
            if (tdoc.tipodoc == "compra" || tdoc.tipodoc == "ordendecompra" || tdoc.tipodoc == "devoluciones compra" || tdoc.tipodoc == "translado") {
                tipotercero = "proveedor"

            }
            if (tipodocumento.tipodoc == "remision" || tipodocumento.tipodoc == "translado") {
                tipotercero = "-"
            }
            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + coincidencia + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }

    const tercerosexiste = async (e) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo != '') {
                    return true
                } else {
                    return false
                }

                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const cambiartercerosporvalor = async (e) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                tipotercero = "proveedor"

            }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var data2 = data.caclientes
                if (data2.codigo != '') {
                    seleccionarterceros(data2)
                } else {
                    Swal.fire(
                        'Opps!',
                        'Tercero no existe',
                        'error'
                    )
                    return
                }

                // setPendiente(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const cambiarterceros = async (e) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                tipotercero = "proveedor"

            }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e.target.value + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var data2 = data.caclientes
                if (data2.codigo != '') {
                    seleccionarterceros(data2)
                } else {
                    Swal.fire(
                        'Opps!',
                        'Tercero no existe',
                        'error'
                    )
                    setVerModalterceros(true)
                    return
                }

                // setPendiente(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const seleccionartercerosconotromov = async (e, movant) => {
        //si cambio algo aqui lo deberia de cambiar tambien en   ponerterceroenblanco()
        let value = e
        var _Movimiento = movant
        _Movimiento['cliente'] = value.codigo
        _Movimiento['nombrecli'] = value.razsoc
        _Movimiento['tercero'] = value.tercero
        var clientecompleto = await buscarcaclientesunico(value.codigo)
        if (tipodocumento.facturapap == "electronica") {
            if (value.tel1 == "") {
                Swal.fire(
                    'Opps!',
                    'Tercero no tiene telefono, continue bajo su Responsabilidad',
                    'error'
                )
            }
            if (value.email == "") {
                Swal.fire(
                    'Opps!',
                    'Tercero no tiene Email, continue bajo su Responsabilidad',
                    'error'
                )
            }
        }
        if (clientecompleto.direcciones.length > 0) {
            _Movimiento['direccion'] = clientecompleto.direcciones[0].direccion

        }
        if (value.vendedor != "") {
            var vendedd = {}
            var _codigomuns = vendedors.filter((dato) => {
                if (dato.codigo == value.vendedor) {
                    vendedd = dato
                }

            })
            if (vendedd.codigo) {
                _Movimiento.vendedor = vendedd.codigo
                _Movimiento.nombreven = vendedd.nombre
            }

        }
        var _enviodatos = _Movimiento.famovimiento.map((dat, index) => {
            return dat
        })
        if (_enviodatos.length > 0) {




            var totales = calculartotalesmovimiento(_enviodatos)

            if (tipodocumento.rete == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(value.venporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(value.comporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.ica == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(value.venporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(value.comporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.reteiva == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(value.venporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(value.comporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            var totales = calculartotalesmovimiento(_enviodatos)

        }


        _enviodatos = _enviodatos.map((dato, index) => {
            var totalll2 = calcularsubtotalitem(_enviodatos[index])

            var dat = {
                ...dato,
                ['subtotal']: totalll2,
            }
            return dat

        })
        _Movimiento.famovimiento = _enviodatos

        setdireccionestercero(clientecompleto.direcciones)
        setRoles([])
        cerrarModalterceros()
        settercero(value)
        if (value.tercero == "cliente") {
            setporcentajereteiva(value.venporcreteiva)
            setporcentajeica(value.venporica)
        }
        if (value.tercero == "proveedor") {
            setporcentajereteiva(value.comporcreteiva)
            setporcentajeica(value.comporica)
        }
        if (tipodocumento.modidoc == "carta 3" || tipodocumento.modidoc == "carta 8") {
            buscarsaldocliente(_Movimiento.cliente, 'cliente', _Movimiento.fecha, _Movimiento.ndocumento)
            // buscarsaldoclientehoy(_Movimiento.cliente, 'cliente', _Movimiento.fecha)
        }
        return _Movimiento
    }
    const seleccionartercerosdocumento2 = async (e) => {
        try {
            let value = e
            var _Movimiento = { ...Movimiento }
            _Movimiento['documento2'] = value.codigo + " - " + value.razsoc
            setMovimiento(_Movimiento)
            setVerModaltercerosdocumento2(false)

        } catch (error) {

        }
    }

    const seleccionarterceros = async (e) => {
        //si cambio algo aqui lo deberia de cambiar tambien en   ponerterceroenblanco()
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['cliente'] = value.codigo
        _Movimiento['nombrecli'] = value.razsoc
        _Movimiento['tercero'] = value.tercero
        var clientecompleto = await buscarcaclientesunico(value.codigo)
        if (tipodocumento.facturapap == "electronica") {
            if (value.tel1 == "") {
                Swal.fire(
                    'Opps!',
                    'Tercero no tiene telefono, continue bajo su Responsabilidad',
                    'error'
                )
            }
            if (value.email == "") {
                Swal.fire(
                    'Opps!',
                    'Tercero no tiene Email, continue bajo su Responsabilidad',
                    'error'
                )
            }
        }
        if (clientecompleto.direcciones.length > 0) {
            _Movimiento['direccion'] = clientecompleto.direcciones[0].direccion

        }
        if (value.vendedor != "") {
            var vendedd = {}
            var _codigomuns = vendedors.filter((dato) => {
                if (dato.codigo == value.vendedor) {
                    vendedd = dato
                }

            })
            if (vendedd.codigo) {
                _Movimiento.vendedor = vendedd.codigo
                _Movimiento.nombreven = vendedd.nombre
            }

        }
        var _enviodatos = _Movimiento.famovimiento.map((dat, index) => {
            return dat
        })
        if (_enviodatos.length > 0) {




            var totales = calculartotalesmovimiento(_enviodatos)

            if (tipodocumento.rete == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(value.venporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combasefte) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: dato.poretefte == 0 ? Number(value.comporcfte + "") : dato.poretefte
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poretefte']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.ica == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(value.venporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combaseica) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: dato.porica == 0 ? Number(value.comporica + "") : dato.porica
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['porica']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            if (tipodocumento.reteiva == "si") {
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                    if (totales.bruto >= value.venbasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(value.venporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }
                if (tipodocumento.tipodoc == "compra") {
                    if (totales.bruto >= value.combasereteiva) {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: dato.poreteiva == 0 ? Number(value.comporcreteiva + "") : dato.poreteiva
                            }
                            return dat
                        })
                    } else {
                        _enviodatos = _enviodatos.map((dato, index) => {
                            var dat = {
                                ...dato,
                                ['poreteiva']: 0
                            }
                            return dat
                        })
                    }
                }

            }
            var totales = calculartotalesmovimiento(_enviodatos)

        }


        _enviodatos = _enviodatos.map((dato, index) => {
            var totalll2 = calcularsubtotalitem(_enviodatos[index])

            var dat = {
                ...dato,
                ['subtotal']: totalll2,
            }
            return dat

        })
        _Movimiento.famovimiento = _enviodatos
        setMovimiento(_Movimiento)
        setdireccionestercero(clientecompleto.direcciones)
        setRoles([])
        cerrarModalterceros()
        settercero(value)
        if (value.tercero == "cliente") {
            setporcentajereteiva(value.venporcreteiva)
            setporcentajeica(value.venporica)
        }
        if (value.tercero == "proveedor") {
            setporcentajereteiva(value.comporcreteiva)
            setporcentajeica(value.comporica)
        }
        if (tipodocumento.modidoc == "carta 3" || tipodocumento.modidoc == "carta 8") {
            buscarsaldocliente(_Movimiento.cliente, 'cliente', _Movimiento.fecha, _Movimiento.ndocumento)
            // buscarsaldoclientehoy(_Movimiento.cliente, 'cliente', _Movimiento.fecha)
        }
    }
    const ponerterceroenblanco = async (_Movimiento, tipod) => {

        _Movimiento['cliente'] = ''
        _Movimiento['nombrecli'] = ''
        _Movimiento['tercero'] = ''

        _Movimiento['direccion'] = ''

        for (let i = 0; i < _Movimiento.famovimiento.length; i++) {
            _Movimiento.famovimiento[i].bodega = tipod.tbodega;
            if (tipod.descsn != "si") {
                _Movimiento.famovimiento[i].pordescuento = 0;

            }
            if (tipod.ivasn != "si") {
                _Movimiento.famovimiento[i].poriva = 0;

            }
            if (tipod.imptosn != "si") {
                _Movimiento.famovimiento[i].porimptoconsumo = 0;

            }
            if (tipod.rete != "si") {

                _Movimiento.famovimiento[i].poretefte = 0;
            }
            if (tipod.ccosto != "si") {
                _Movimiento.famovimiento[i].ccosto = "";

            }
            if (tipod.tipodoc != "compra") {
                _Movimiento.famovimiento[i].cuentadebito = "";

            }
            if (tipod.tipodoc != "compra") {
                _Movimiento.famovimiento[i].cuentacredito = "";

            }







        }
        if (tipod.manejatercerounico == "si") {
            var ttt = await buscarcaclientesunicocontercero(tipod.tercerounico, tipod)
            if (ttt.caclientes.codigo != "") {
                _Movimiento = await seleccionartercerosconotromov(ttt.caclientes, _Movimiento)
                var fdfdfdf = ""
                settercero(ttt.caclientes)
            } else {
                Swal.fire(
                    'Opps!',
                    'Tercero Seleccionado en el Tipo de Documento No existe',
                    'error'
                )
                settercero(modeloTerceros)

            }

        } else {
            settercero(modeloTerceros)

        }
        if (tipod.puntoventa == "si" || tipod.busplu == "si") {
            agregaritemfuncionconparametro(_Movimiento, tipod)
        } else {
            setMovimiento(_Movimiento)

        }
        setdireccionestercero([])
        setRoles([])
        setporcentajereteiva(0)
        setporcentajeica(0)
    }
    const filtrarvendedors = async (coincidencia) => {
        var _codigomuns = vendedors.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedavendedors(_codigomuns)
    }
    const cerrarModalvendedors = () => {
        setVerModalvendedors(!verModalvendedors);
        setbusquedavendedors(vendedors)
    }
    const buscartodasvendedors = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Vendedorapi/nombres/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setvendedors(dataJson)
                    setbusquedavendedors(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setvendedors([])
                    setbusquedavendedors([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarvendedors = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['vendedor'] = value.codigo
        _Movimiento['nombreven'] = value.nombre
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalvendedors()
    }


    const filtrarmediopagos = async (coincidencia) => {
        var _codigomuns = mediopagos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedamediopagos(_codigomuns)
    }
    const cerrarModalmediopagos = () => {
        setVerModalmediopagos(!verModalmediopagos);
        setbusquedamediopagos(mediopagos)
    }
    const buscarnombremediopago = (codigo) => {
        var medd = mediopagos.filter(p => p.codigo == codigo)
        if (medd.length != 0) {
            return medd[0].nombre
        } else {
            return "Transferencia"
        }
    }
    const buscartodasmediopagos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Mediodepagoapi/nombres/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setmediopagos(dataJson)
                    setbusquedamediopagos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setmediopagos([])
                    setbusquedamediopagos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarmediopagos = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['mpago'] = value.codigo
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalmediopagos()
    }


    const filtrararticulos = async (coincidencia) => {
        var _codigomuns = await buscarcoincidenciasarticulounico(coincidencia)
        if (_codigomuns.length < 1) {
            Swal.fire(
                'Opps!',
                'La combinacion de palabras no dio ningun resultado exitoso',
                'error'
            )
        }
        setbusquedaarticulos(_codigomuns)

    }
    const cerrarModalarticulos = () => {
        setVerModalarticulos(!verModalarticulos);
        setbusquedaarticulos([])


    }
    const cerrarModalarticulosselec = (arti) => {
        setVerModalarticulos(!verModalarticulos);
        setbusquedaarticulos([])
        if (arti.descripcion == "si") {
            setabrirdescripcionadicional(true)
        }

    }
    const buscartodasarticulos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Inarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setarticulos(dataJson)
                    setbusquedaarticulos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setarticulos([])
                    setbusquedaarticulos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscararticulounico = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarclienteplansepare = async (codigo) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/CtabonoApi/movimientos/' + codigo + '/cliente' + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }

    const buscarcoincidenciasarticulounico = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/unicocoincidecniasconprecio/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '&bodega=' + Movimiento.famovimiento[fila].bodega);

            if (response.ok) {
                let data = await response.json()
                // try {

                //     let dt = JSON.parse(user)
                //     var ffff = props.servidor + '/Inarticulosapi/reporteexistenciaespecial/existencia/' + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "1" + "/" + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
                //     let response = await fetch(props.servidor + '/Inarticulosapi/reporteexistenciaespecial/existencia/' + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

                //     if (response.ok) {
                //         let data2 = await response.json()

                //         for (let index = 0; index < data.length; index++) {
                //             var precc = data2.find(p => p.referencia == data[index].codigo)
                //             if (precc) {
                //                 data[index].precio1 = precc.precio1 && Number(precc.precio1);
                //                 data[index].precio2 = precc.precio2 && Number(precc.precio2);
                //                 data[index].precio3 = precc.precio3 && Number(precc.precio3);
                //                 data[index].ultcosto = precc.ultcosto && precc.ultcosto;
                //                 data[index].existencia = precc.existencia;

                //             }

                //         }

                //         return data
                //     } else {
                //         return data

                //     }
                // } catch (error) {
                //     console.log(error)
                //     try {

                //         let dt = JSON.parse(user)
                //         var ffff = props.servidor + '/Inarticulosapi/reporteexistenciaespecial/existencia/' + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "1" + "/" + "-" + '?llave=' + dt.usuario.llaveempresa + ''
                //         let response = await fetch(props.servidor + '/Inarticulosapi/reporteexistenciaespecial/existencia/' + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + '?llave=' + dt.usuario.llaveempresa + '');

                //         if (response.ok) {
                //             let data2 = await response.json()

                //             for (let index = 0; index < data.length; index++) {
                //                 var precc = data2.find(p => p.referencia == data[index].codigo)
                //                 if (precc) {
                //                     data[index].precio1 = precc.precio1 && Number(precc.precio1);
                //                     data[index].precio2 = precc.precio2 && Number(precc.precio2);
                //                     data[index].precio3 = precc.precio3 && Number(precc.precio3);
                //                     data[index].ultcosto = precc.ultcosto && precc.ultcosto;
                //                     data[index].existencia = precc.existencia;

                //                 }

                //             }

                //             return data
                //         } else {
                //             return data

                //         }
                //     } catch (error) {
                //         console.log(error)
                //         return data

                //     }

                // }
                return data

            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los productos',
                'error'
            )
            return []

        }
    }
    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataj = []
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == 'plan separe' || tipodocumento.tipodoc == 'cotizacion') {
                    dataj = data.filter(e => e.tercero == "cliente")

                } else {
                    if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                        dataj = data.filter(e => e.tercero == "proveedor")

                    } else {
                        dataj = data

                    }
                }

                return dataj
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const buscarcoincidenciastipodocs = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }

    const buscarcoincidenciassucursal = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Sucursalapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }

    const buscarcoincidenciascaja = async (coincidencia, numero) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Movimientoapi/caja/' + coincidencia + '/' + numero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }

    const seleccionararticulos = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        eventosseleccionarticulo(e, _Movimiento, _datos)
        cerrarModalarticulosselec(e)

    }

    const buscarexistencia2 = async (referencia, bodd) => {

        let dt = JSON.parse(user)

        let response = await fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodd + '?llave=' + dt.usuario.llaveempresa + '')

        if (response.ok) {
            let data = await response.json()
            if (data.length != 0) {
                return data[0].existenciaunidad
            } else {
                return 0
            }

        }



    }
    const guardarimpreso = async (referencia, bodd) => {

        let dt = JSON.parse(user)

        let response = await fetch(props.servidor + '/MovimientoApi/imprimi/' + Movimiento.concepto + '/' + (Movimiento.ndocumento + '') + '/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + '')

        if (response.ok) {
            let data = await response.json()
            return data

        }



    }
    const buscarexistencia = (referencia, bodd) => {
        var bodega = Movimiento.famovimiento[fila].bodega
        if (bodd == "") {
            bodd = bodega
        }
        if (dataUser.empresa.config.especialidad == "drogueria") {
            if (bodega != "") {
                let dt = JSON.parse(user)
                const api = fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodega + '?llave=' + dt.usuario.llaveempresa + '')
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then((dataJson) => {
                        try {
                            var data = dataJson;
                            setexistenciaarticulocaja(data[0].existenciacaja)
                            setexistenciaarticuloblister(data[0].existenciablister)
                            setexistenciaarticulounidad(data[0].existenciaunidad)
                            var artt = listaexistenciaarticulo
                            artt[fila] = data.existenciaunidad
                            setlistaexistenciaarticulo(artt)
                            setlistaexistenciaarticulo(artt)
                        } catch (error) {
                            setexistenciaarticulocaja(0)
                            setexistenciaarticuloblister(0)
                            setexistenciaarticulounidad(0)
                            var artt = listaexistenciaarticulo
                            artt[fila] = 0
                            setlistaexistenciaarticulo(artt)
                            console.log(error)
                            console.log("di error al buscar existencia")
                        }
                    }).catch((error) => {
                        setexistenciaarticulocaja(0)
                        setexistenciaarticuloblister(0)
                        setexistenciaarticulounidad(0)
                        var artt = listaexistenciaarticulo
                        artt[fila] = 0
                        setlistaexistenciaarticulo(artt)
                    })
            } else {

            }
        } else {
            if (bodd != "") {
                let dt = JSON.parse(user)
                const api = fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodd + '?llave=' + dt.usuario.llaveempresa + '')
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then((dataJson) => {
                        try {
                            var data = dataJson;
                            setexistenciaarticulocaja(data[0].existenciacaja)
                            setexistenciaarticuloblister(data[0].existenciablister)
                            setexistenciaarticulounidad(data[0].existenciaunidad)
                            var artt = listaexistenciaarticulo
                            artt[fila] = data[0].existenciaunidad
                            setlistaexistenciaarticulo(artt)
                            setlistaexistenciaarticulo(artt)
                        } catch (error) {
                            setexistenciaarticulocaja(0)
                            setexistenciaarticuloblister(0)
                            setexistenciaarticulounidad(0)
                            var artt = listaexistenciaarticulo
                            artt[fila] = 0
                            setlistaexistenciaarticulo(artt)
                            console.log(error)
                        }
                    }).catch((error) => {
                        const api2 = fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodd + '?llave=' + dt.usuario.llaveempresa + '')
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then((dataJson) => {
                                try {
                                    var data = dataJson;
                                    setexistenciaarticulocaja(data[0].existenciacaja)
                                    setexistenciaarticuloblister(data[0].existenciablister)
                                    setexistenciaarticulounidad(data[0].existenciaunidad)
                                    var artt = listaexistenciaarticulo
                                    artt[fila] = data[0].existenciaunidad
                                    setlistaexistenciaarticulo(artt)
                                } catch (error) {
                                    setexistenciaarticulocaja(0)
                                    setexistenciaarticuloblister(0)
                                    setexistenciaarticulounidad(0)
                                    var artt = listaexistenciaarticulo
                                    artt[fila] = 0
                                    setlistaexistenciaarticulo(artt)
                                }
                            }).catch((error) => {
                                setexistenciaarticulocaja(0)
                                setexistenciaarticuloblister(0)
                                setexistenciaarticulounidad(0)
                                var artt = listaexistenciaarticulo
                                artt[fila] = 0
                                setlistaexistenciaarticulo(artt)
                                console.log(error)
                            })
                    })
            } else {

            }
        }
    }
    const buscarexistenciaenbodega = (bodega) => {
        var referencia = Movimiento.famovimiento[fila].referencia
        if (dataUser.empresa.config.especialidad == "drogueria") {
            if (bodega != "") {
                let dt = JSON.parse(user)
                const api = fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodega + '?llave=' + dt.usuario.llaveempresa + '')
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then((dataJson) => {
                        try {
                            var data = dataJson;
                            setexistenciaarticulocaja(data.existenciacaja)
                            setexistenciaarticuloblister(data.existenciablister)
                            setexistenciaarticulounidad(data.existenciaunidad)

                        } catch (error) {
                            setexistenciaarticulocaja(0)
                            setexistenciaarticuloblister(0)
                            setexistenciaarticulounidad(0)
                        }
                    }).catch((error) => {
                        setexistenciaarticulocaja(0)
                        setexistenciaarticuloblister(0)
                        setexistenciaarticulounidad(0)
                    })
            } else {

            }
        } else {
            if (bodega != "") {
                let dt = JSON.parse(user)
                const api = fetch(props.servidor + '/Inarticulosapi/reporteexistenciadrogueria/' + referencia + '/' + bodega + '?llave=' + dt.usuario.llaveempresa + '')
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then((dataJson) => {
                        try {
                            var data = dataJson;
                            setexistenciaarticulocaja(data.existenciacaja)
                            setexistenciaarticuloblister(data.existenciablister)
                            setexistenciaarticulounidad(data.existenciaunidad)

                        } catch (error) {
                            setexistenciaarticulocaja(0)
                            setexistenciaarticuloblister(0)
                            setexistenciaarticulounidad(0)
                        }
                    }).catch((error) => {
                        setexistenciaarticulocaja(0)
                        setexistenciaarticuloblister(0)
                        setexistenciaarticulounidad(0)
                    })
            } else {

            }
        }
    }

    const buscarcostocompuesto = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/costocompuesto/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const eventosseleccionarticulo = async (eee, _Movimiento, _datos) => {
        buscarexistencia(eee.codigo, "")
        var e = await buscararticulounico(eee.codigo)
        var _enviodatos = _datos.map((dato, index) => {

            if (index == fila) {
                var desad = dato.desadicional
                if (desad == "") {
                    desad = e.descadicional
                }
                if (desad) {

                } else {
                    desad = ""
                }
                var dat = {
                    ...dato,
                    ['referencia']: e.codigo,
                    ['descrip']: e.nombre,
                    ['desadicional']: desad
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos

        if (tipodocumento.tipodoc == "compra" || (tipodocumento.tipodoc == "remision" && (tercero && tercero.tercero == "proveedor"))) {
            var ivacompra = 0
            if (tipodocumento.ivasn == "si") {
                if (tipodocumento.ivaporc != 0) {
                    ivacompra = Number(tipodocumento.ivaporc)
                }
                if (e.iva != 0) {
                    ivacompra = Number(e.iva)
                }

            }

            var _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var desad = dato.desadicional
                    if (desad == "") {
                        desad = e.descadicional
                    }
                    if (desad) {

                    } else {
                        desad = ""
                    }
                    var dat = {
                        ...dato,
                        ['referencia']: e.codigo,
                        ['descrip']: e.nombre,
                        ['desadicional']: desad,
                        ['precio']: e.preccost,
                        ['poriva']: ivacompra,
                    }
                    return dat
                } else {
                    return dato
                }
            })
            _Movimiento['famovimiento'] = _enviodatos
        }
        if (tipodocumento.tipodoc == "produccion" || tipodocumento.camdescenc == "si") {
            var ivacompra = 0
            if (tipodocumento.ivasn == "si") {
                if (tipodocumento.ivaporc != 0) {
                    ivacompra = Number(tipodocumento.ivaporc)
                }
                if (e.iva != 0) {
                    ivacompra = Number(e.iva)
                }

            }
            var costocom = await buscarcostocompuesto(eee.codigo)
            var _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var desad = dato.desadicional
                    if (desad == "") {
                        desad = e.descadicional
                    }
                    if (desad) {

                    } else {
                        desad = ""
                    }
                    var dat = {
                        ...dato,
                        ['referencia']: e.codigo,
                        ['descrip']: e.nombre,
                        ['desadicional']: desad,
                        ['precio']: costocom,
                        ['poriva']: ivacompra,
                    }
                    return dat
                } else {
                    return dato
                }
            })
            _Movimiento['famovimiento'] = _enviodatos
        }
        if (tipodocumento.cambioiva == "si") {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {

                    var dat = {
                        ...dato,
                        ['porimptoconsumo']: tipodocumento.imptosn == "si" ? e.imptocons : 0,
                        ['vlrimptoconsumo']: tipodocumento.modidocenc == "si" ? e.impoconsumovalor : 0
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == 'cotizacion' || (tipodocumento.tipodoc == "remision" && (tercero && tercero.tercero == "cliente")) || tipodocumento.tipodoc == 'devoluciones' || tipodocumento.tipodoc == 'plan separe' || tipodocumento.tipodoc == 'nota debito') {
            var preciousado = 0
            var ivausado = 0
            var impoconsumousado = 0
            if (dataUser.empresa.config.especialidad != "drogueria") {
                var primerotercero = false
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "primeroterceroenprecio" && element.acceso[0] == "2") {
                        primerotercero = true
                    }
                });
                if (primerotercero) {
                    if (tercero.precsug != "") {
                        e.inarticuloslistaprecio.map((precio) => {
                            if (precio.listadeprecio == tercero.precsug) {
                                preciousado = precio.precio
                            }
                        })
                    } else {
                        if (tipodocumento.precsug != "") {
                            e.inarticuloslistaprecio.map((precio) => {
                                if (precio.listadeprecio == tipodocumento.precsug) {
                                    preciousado = precio.precio
                                }
                            })
                        }
                    }
                } else {
                    if (tipodocumento.precsug != "") {
                        e.inarticuloslistaprecio.map((precio) => {
                            if (precio.listadeprecio == tipodocumento.precsug) {
                                preciousado = precio.precio
                            }
                        })
                    } else {
                        if (tercero.precsug != "") {
                            e.inarticuloslistaprecio.map((precio) => {
                                if (precio.listadeprecio == tercero.precsug) {
                                    preciousado = precio.precio
                                }
                            })
                        }
                    }
                }

            } else {
                var primerotercero = false
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "primeroterceroenprecio" && element.acceso[0] == "2") {
                        primerotercero = true
                    }
                });
                if (primerotercero) {
                    if (tercero.precsug != "") {
                        e.inarticuloslistaprecio.map((precio) => {
                            if (precio.listadeprecio == tercero.precsug && precio.presentacion == "3") {
                                preciousado = precio.precio
                            }
                        })
                    } else {
                        if (tipodocumento.precsug != "") {

                            e.inarticuloslistaprecio.map((precio) => {
                                if (precio.listadeprecio == tipodocumento.precsug && precio.presentacion == "3") {
                                    preciousado = precio.precio
                                }
                            })
                        }
                    }

                } else {
                    if (tipodocumento.precsug != "") {

                        e.inarticuloslistaprecio.map((precio) => {
                            if (precio.listadeprecio == tipodocumento.precsug && precio.presentacion == "3") {
                                preciousado = precio.precio
                            }
                        })
                    } else {
                        if (tercero.precsug != "") {
                            e.inarticuloslistaprecio.map((precio) => {
                                if (precio.listadeprecio == tercero.precsug && precio.presentacion == "3") {
                                    preciousado = precio.precio
                                }
                            })
                        }
                    }
                }
            }
            if (tipodocumento.ivaincluido == "si") {
                if (tipodocumento.ivasn == "si") {
                    if (tipodocumento.ivaporc != 0) {
                        ivausado = Number(tipodocumento.ivaporc)
                    }
                    if (e.iva != 0) {
                        ivausado = Number(e.iva)
                    }

                }

            }
            if (tipodocumento.cambioiva == "si") {
                if (tipodocumento.modidocenc == "si") {
                    if (e.impoconsumovalor != 0) {
                        impoconsumousado = Number(e.impoconsumovalor)
                    }

                }

            }
            var _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var desad = dato.desadicional
                    if (desad == "") {
                        desad = e.descadicional
                    }
                    if (desad) {

                    } else {
                        desad = ""
                    }
                    var dat = {
                        ...dato,
                        ['referencia']: e.codigo,
                        ['descrip']: e.nombre,
                        ['desadicional']: desad,
                        ['precio']: preciousado,
                        ['cantidad']: (dato.cantidad == "" || dato.cantidad == 0 || dato.cantidad == "0") ? 1 : dato.cantidad
                    }
                    return dat
                } else {
                    return dato
                }
            })
            // if (tipodocumento.puntoventa == "si") {
            //     _enviodatos = _enviodatos.map((dato, index) => {
            //         if (index == fila) {
            //             var dat = {
            //                 ...dato,
            //                 ['cantidad']: 1
            //             }
            //             return dat
            //         } else {
            //             return dato
            //         }
            //     })
            // }
            if (tipodocumento.ivaincluido != "si" && tipodocumento.ivasn == "si" && e.iva != 0) {
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            ['poriva']: e.iva
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }
            var totales = calculartotalesmovimiento(_enviodatos)


            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var desad = dato.desadicional
                    if (desad == "") {
                        desad = e.descadicional
                    }
                    if (desad) {

                    } else {
                        desad = ""
                    }
                    var dat = {
                        ...dato,
                        ['referencia']: e.codigo,
                        ['descrip']: e.nombre,
                        ['desadicional']: desad,
                        ['precio']: preciousado,
                        ['subtotal']: totalll,
                        ['porimptoconsumo']: e.imptocons
                    }
                    return dat
                } else {
                    return dato
                }
            })
            var _enviodatos2 = []
            if (tipodocumento.ivaincluido == "si" && preciousado != 0) {
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        if (tipodocumento.cambioiva == "si") {
                            preciousado = preciousado - Number(e.impoconsumovalor)

                        }
                        var dat = {
                            ...dato,
                            ['precio']: (Number(preciousado) / ((100 + ivausado) / 100)),
                            ['poriva']: ivausado
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
                var totall = calcularsubtotalitem(_enviodatos[fila])
                var totales = calculartotalesmovimiento(_enviodatos)
                if (true) {
                    if (tipodocumento.rete == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.ica == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.reteiva == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                }
                var totalll = calcularsubtotalitem(_enviodatos[fila])

                _enviodatos = _enviodatos.map((dato, index) => {
                    var totalll2 = calcularsubtotalitem(_enviodatos[index])

                    var dat = {
                        ...dato,
                        ['subtotal']: totalll2,
                    }
                    return dat

                })
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            ['subtotal']: totall
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }
            //impoconsumo incluido
            if (tipodocumento.cambioiva == "si" && preciousado != 0) {
                var preciousado2 = preciousado - impoconsumousado

                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        if (tipodocumento.ivaincluido == "si" && preciousado != 0) {
                            preciousado2 = dato.precio
                        }
                        var dat = {
                            ...dato,
                            ['precio']: Number(preciousado2),
                            ['vlrimptoconsumo']: impoconsumousado
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
                var totall = calcularsubtotalitem(_enviodatos[fila])
                var totales = calculartotalesmovimiento(_enviodatos)
                if (true) {
                    if (tipodocumento.rete == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.venporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasefte) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: dato.poretefte == 0 ? Number(tercero.comporcfte + "") : dato.poretefte
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poretefte']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.ica == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.venporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combaseica) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: dato.porica == 0 ? Number(tercero.comporica + "") : dato.porica
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['porica']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                    if (tipodocumento.reteiva == "si") {
                        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
                            if (totales.bruto >= tercero.venbasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.venporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }
                        if (tipodocumento.tipodoc == "compra") {
                            if (totales.bruto >= tercero.combasereteiva) {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: dato.poreteiva == 0 ? Number(tercero.comporcreteiva + "") : dato.poreteiva
                                    }
                                    return dat
                                })
                            } else {
                                _enviodatos = _enviodatos.map((dato, index) => {
                                    var dat = {
                                        ...dato,
                                        ['poreteiva']: 0
                                    }
                                    return dat
                                })
                            }
                        }

                    }
                }
                var totalll = calcularsubtotalitem(_enviodatos[fila])

                _enviodatos = _enviodatos.map((dato, index) => {
                    var totalll2 = calcularsubtotalitem(_enviodatos[index])

                    var dat = {
                        ...dato,
                        ['subtotal']: totalll2,
                    }
                    return dat

                })
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            ['subtotal']: totall
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }

            if (_enviodatos2.length == 0) {
                var existearticul = _enviodatos.filter((dato) => {
                    if (dato.referencia == "") {
                        return dato
                    }
                })
                if (existearticul.length == 0) {
                    var totales = calculartotalesmovimiento(_enviodatos)
                    // if (tipodocumento.puntoventa == "si") {
                    //     var bodega = ""
                    //     var ccosto = ""
                    //     if (tipodocumento.tbodegasn == "si") {
                    //         bodega = tipodocumento.tbodega
                    //     }
                    //     if (tipodocumento.ccosto == "si") {
                    //         ccosto = tipodocumento.centrocosto
                    //     }

                    //     _enviodatos = _enviodatos.concat(
                    //         {
                    //             item: "",
                    //             referencia: "",
                    //             descrip: "",
                    //             bodega: bodega,
                    //             cantidad: "",
                    //             precio: "",
                    //             pordescuento: "",
                    //             poriva: "",
                    //             porimptoconsumo: "",
                    //             subtotal: "",
                    //             compañia: "",
                    //             concepto: "",
                    //             nrodocumento: "",
                    //             costo: "",
                    //             desadicional: "",
                    //             unimed: "",
                    //             ccosto: ccosto,
                    //             fecha: "",
                    //             preivainc: "",//se usa como aumento comercial en gas, en faencmovi se guarda el total como dsctovapp1
                    //             codprod: "",
                    //             canprod: "",
                    //             horafecha: "",
                    //             tipoitem: "",
                    //             tercero: "",
                    //             cliente: "",
                    //             vlr_desc: "",
                    //             vlr_iva: "",
                    //             vlr_base_iva: "",
                    //             costodolar: "",
                    //             tasa: "",
                    //             vlrimptoconsumo: "",
                    //             poretefte: "",
                    //             vlretefte: "",
                    //             porica: "",
                    //             vlrica: "",
                    //             poreteiva: "",
                    //             vlreteiva: "",
                    //             cantdig: "",
                    //             presentacion: "3",
                    //             cuentadebito: "",
                    //             cuentacredito: ""
                    //         }
                    //     )
                    // }
                }
                _enviodatos = _enviodatos.map((dato, index) => {
                    var totalll2 = calcularsubtotalitem(_enviodatos[index])

                    var dat = {
                        ...dato,
                        ['subtotal']: totalll2,
                    }
                    return dat

                })

                _Movimiento['famovimiento'] = _enviodatos


            } else {
                // var existearticul=_enviodatos2.filter((dato)=>{
                //     if (dato.referencia=="") {
                //         return dato
                //     }
                //  })
                //  if (existearticul.length==0) {
                //     var totales = calculartotalesmovimiento(_enviodatos2)
                //     if (tipodocumento.puntoventa == "si") {

                //         var totales = calculartotalesmovimiento(_enviodatos)
                //         _enviodatos2 = _enviodatos2.concat(
                //             {
                //                 item: "",
                //                 referencia: "",
                //                 descrip: "",
                //                 bodega: "",
                //                 cantidad: "",
                //                 precio: "",
                //                 pordescuento: "",
                //                 poriva: "",
                //                 porimptoconsumo: "",
                //                 subtotal: "",
                //                 compañia: "",
                //                 concepto: "",
                //                 nrodocumento: "",
                //                 costo: "",
                //                 desadicional: "",
                //                 unimed: "",
                //                 ccosto: "",
                //                 fecha: "",
                //                 preivainc: "",
                //                 codprod: "",
                //                 canprod: "",
                //                 horafecha: "",
                //                 tipoitem: "",
                //                 tercero: "",
                //                 cliente: "",
                //                 vlr_desc: "",
                //                 vlr_iva: "",
                //                 vlr_base_iva: "",
                //                 costodolar: "",
                //                 tasa: "",
                //                 vlrimptoconsumo: "",
                //                 poretefte: "",
                //                 vlretefte: "",
                //                 porica: "",
                //                 vlrica: "",
                //                 poreteiva: "",
                //                 vlreteiva: "",
                //                 cantdig: "",
                //                 presentacion: "3"
                //             })



                //     }
                //  }

                // _Movimiento['famovimiento'] = _enviodatos2

            }
        }

        // setMovimientos(_Movimientos)
        var artt = listaarticulo
        artt[fila] = e
        setlistaarticulo(artt)
        setlistaarticulocostoprecio(artt)
        var presentacion = "3"
        if (tipodocumento.tipodoc == "compra" || (tipodocumento.tipodoc == "remision" && (tercero && tercero.tercero == "proveedor"))) {
            if (e.blisterxcaja != 0) {
                presentacion = "1"

            } else {
                if (e.unidadxblister) {
                    presentacion = "2"

                }
            }
        }
        var _enviodatos2 = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['presentacion']: presentacion,
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos2
        if (tipodocumento.puntoventa == "si" || tipodocumento.busplu == "si") {
            agregaritemfuncionconparametro(_Movimiento)
        } else {
            setMovimiento(_Movimiento)

        }
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        // setacabodeseleccionararticulo(true)
    }


    const filtrarbodegas = async (coincidencia) => {
        var _codigomuns = bodegas.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedabodegas(_codigomuns)
    }
    const cerrarModalbodegas = () => {
        setVerModalbodegas(!verModalbodegas);
        setbusquedabodegas(bodegas)
    }
    const cerrarModalbodegas2 = () => {
        setVerModalbodegas2(!verModalbodegas2);
        setbusquedabodegas(bodegas)

    }

    const totalsaldocliente = () => {
        var total = 0
        saldoscliente.forEach(element => {
            if (element.vlrfactur < 0) {
                total = total - element.credito
            } else {
                total = total + (element.vlrfactur + element.debito - element.credito)

            }
        });
        return total
    }
    const totalsaldoclientecupocredito = (saldd) => {
        var total = 0
        saldd.forEach(element => {
            if (element.vlrfactur < 0) {
                total = total - element.credito
            } else {
                total = total + (element.vlrfactur + element.debito - element.credito)

            }
        });
        return total
    }
    const totalsaldoclientehoy = () => {
        var total = 0
        saldosclientehoy.forEach(element => {
            total = total + (element.credito)
        });
        return total
    }
    const buscarsaldocliente = (cliente, tercero, fechaa, ndocumento) => {
        var ndoc = 9999999
        if (ndocumento != 0 && ndocumento != '') {
            ndoc = Number(ndocumento + "")
        }
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        const api = fetch(props.servidor + '/CtabonoApi/movimientoscompletoshorafecha/2000-01-01/' + fechaa + '/-/-/' + cliente + '/-/' + tercero + '/' + ndoc + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setsaldoscliente(data)
            }).catch((error) => {


            })
    }


    const saldoparacupocredito = async (cliente, tercero, fechaa, ndocumento) => {
        var ndoc = 9999999
        if (ndocumento != 0 && ndocumento != '') {
            ndoc = Number(ndocumento + "")
        }

        let dt = JSON.parse(user)
        var coincidencia = ''


        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/CtabonoApi/movimientosparacupocredito/2000-01-01/' + fechaa + '/-/-/' + cliente + '/-/' + tercero + '/' + ndoc + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }


    const facturasamasdendias = async (cliente, tercero, fechaa, ndocumento) => {
        var ndoc = Number(ndocumento + "")

        let dt = JSON.parse(user)
        var coincidencia = ''


        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/CtabonoApi/movimientosparamasdendias/2000-01-01/' + fechaa + '/-/-/' + cliente + '/-/' + tercero + '/' + ndoc + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const editarunlote = (_Movimiento, lotes, filamov, filalote) => {
        var _enviodatos = _Movimiento.famovimiento.map((dato, index) => {
            if (index == filamov) {

                var dat = {
                    ...dato,
                    ['cantidad']: lotes[filalote].cantidad,
                    ['lote']: lotes[filalote].lote,
                    ['fechavenclote']: lotes[filalote].fechavenc,
                    ['subtotal']: totalll2,
                }
                return dat
            } else {
                return dato
            }

        })

        var totalll2 = calcularsubtotalitem(_enviodatos[filamov])
        _enviodatos = _enviodatos.map((dato, index) => {
            if (index == filamov) {

                var dat = {
                    ...dato,
                    ['subtotal']: totalll2,
                }
                return dat
            } else {
                return dato
            }

        })
        _Movimiento.famovimiento = _enviodatos
        return _Movimiento

    }

    const agregaritemfuncionconretorno = (_Movimientonuevo) => {
        if (_Movimientonuevo.concepto != "" && tipodocumento.codigo != "") {
            var existearticul = _Movimientonuevo.famovimiento.filter((dato) => {
                if (dato.referencia == "") {
                    return dato
                }
            })
            if (existearticul.length == 0) {
                var _Movimiento = { ..._Movimientonuevo }
                var _datos = { ..._Movimientonuevo }['famovimiento']
                var bodega = ""
                var ccosto = ""
                var presentacion = "3"
                var retefuente = 0
                if (tipodocumento.tbodegasn == "si") {
                    bodega = tipodocumento.tbodega
                }
                if (tipodocumento.ccosto == "si") {
                    ccosto = tipodocumento.centrocosto
                }
                if (tipodocumento.tipodoc == "compra") {
                    presentacion = "1"
                }
                if (_Movimiento.famovimiento.length == 0) {
                    var _nuevosdatos = _datos.concat(
                        {
                            item: "",
                            referencia: "",
                            descrip: "",
                            bodega: bodega,
                            cantidad: "",
                            precio: "",
                            pordescuento: "",
                            poriva: "",
                            porimptoconsumo: "",
                            subtotal: "",
                            compañia: "",
                            concepto: "",
                            nrodocumento: "",
                            costo: "",
                            desadicional: "",
                            unimed: "",
                            ccosto: ccosto,
                            fecha: "",
                            preivainc: "",
                            codprod: "",
                            canprod: "",
                            horafecha: "",
                            tipoitem: "",
                            tercero: "",
                            cliente: "",
                            vlr_desc: "",
                            vlr_iva: "",
                            vlr_base_iva: "",
                            costodolar: "",
                            tasa: "",
                            vlrimptoconsumo: "",
                            poretefte: retefuente,
                            vlretefte: "",
                            porica: "",
                            vlrica: "",
                            poreteiva: "",
                            vlreteiva: "",
                            cantdig: "",
                            presentacion: presentacion,
                            cuentadebito: "",
                            cuentacredito: ""


                        }

                    )
                    _Movimiento['famovimiento'] = _nuevosdatos
                    return _Movimiento
                } else {
                    var _nuevosdatos2 = _datos.concat(
                        _Movimiento.famovimiento[fila]
                    )
                    _Movimiento['famovimiento'] = _nuevosdatos2
                    return _Movimiento
                }


            }
        } else {
            Swal.fire(
                'Opps!',
                'No ha seleccionado un Tipo de Documento',
                'error'
            )
        }
    }
    const agregaritemfuncion = () => {
        if (Movimiento.concepto != "" && tipodocumento.codigo != "") {
            var existearticul = Movimiento.famovimiento.filter((dato) => {
                if (dato.referencia == "") {
                    return dato
                }
            })
            if (existearticul.length == 0) {
                var _Movimiento = { ...Movimiento }
                var _datos = { ...Movimiento }['famovimiento']
                var bodega = ""
                var ccosto = ""
                var presentacion = "3"
                var retefuente = 0
                if (tipodocumento.tbodegasn == "si") {
                    bodega = tipodocumento.tbodega
                }
                if (tipodocumento.ccosto == "si") {
                    ccosto = tipodocumento.centrocosto
                }
                if (tipodocumento.tipodoc == "compra") {
                    presentacion = "1"
                }

                var _nuevosdatos = _datos.concat(
                    {
                        item: "",
                        referencia: "",
                        descrip: "",
                        bodega: bodega,
                        cantidad: "",
                        precio: "",
                        pordescuento: "",
                        poriva: "",
                        porimptoconsumo: "",
                        subtotal: "",
                        compañia: "",
                        concepto: "",
                        nrodocumento: "",
                        costo: "",
                        desadicional: "",
                        unimed: "",
                        ccosto: ccosto,
                        fecha: "",
                        preivainc: "",
                        codprod: "",
                        canprod: "",
                        horafecha: "",
                        tipoitem: "",
                        tercero: "",
                        cliente: "",
                        vlr_desc: "",
                        vlr_iva: "",
                        vlr_base_iva: "",
                        costodolar: "",
                        tasa: "",
                        vlrimptoconsumo: "",
                        poretefte: retefuente,
                        vlretefte: "",
                        porica: "",
                        vlrica: "",
                        poreteiva: "",
                        vlreteiva: "",
                        cantdig: "",
                        presentacion: presentacion,
                        cuentadebito: "",
                        cuentacredito: ""


                    }

                )

                _Movimiento['famovimiento'] = _nuevosdatos
                setMovimiento(_Movimiento)

            }
        } else {
            Swal.fire(
                'Opps!',
                'No ha seleccionado un Tipo de Documento',
                'error'
            )
        }
    }
    const agregaritemfuncionconparametro = (_Movimiento2, tipod) => {
        if (!tipod) {
            if (tipodocumento.codigo != "") {
                tipod = tipodocumento
            }
        }
        if (_Movimiento2.concepto != "") {
            var existearticul = _Movimiento2.famovimiento.filter((dato) => {
                if (dato.referencia == "") {
                    return dato
                }
            })
            if (existearticul.length == 0) {
                var _Movimiento = { ..._Movimiento2 }
                var _datos = { ..._Movimiento2 }['famovimiento']
                var bodega = ""
                var ccosto = ""
                var presentacion = "3"
                var retefuente = 0
                if (tipod.tbodegasn == "si") {
                    bodega = tipod.tbodega
                }
                if (tipod.ccosto == "si") {
                    ccosto = tipod.centrocosto
                }
                if (tipod.tipodoc == "compra") {
                    presentacion = "1"
                }

                var _nuevosdatos = _datos.concat(
                    {
                        item: "",
                        referencia: "",
                        descrip: "",
                        bodega: bodega,
                        cantidad: "",
                        precio: "",
                        pordescuento: "",
                        poriva: "",
                        porimptoconsumo: "",
                        subtotal: "",
                        compañia: "",
                        concepto: "",
                        nrodocumento: "",
                        costo: "",
                        desadicional: "",
                        unimed: "",
                        ccosto: ccosto,
                        fecha: "",
                        preivainc: "",
                        codprod: "",
                        canprod: "",
                        horafecha: "",
                        tipoitem: "",
                        tercero: "",
                        cliente: "",
                        vlr_desc: "",
                        vlr_iva: "",
                        vlr_base_iva: "",
                        costodolar: "",
                        tasa: "",
                        vlrimptoconsumo: "",
                        poretefte: retefuente,
                        vlretefte: "",
                        porica: "",
                        vlrica: "",
                        poreteiva: "",
                        vlreteiva: "",
                        cantdig: "",
                        presentacion: presentacion,
                        cuentadebito: "",
                        cuentacredito: ""


                    }

                )

                _Movimiento['famovimiento'] = _nuevosdatos
                setMovimiento(_Movimiento)
                setfila(_nuevosdatos.length - 1)
            } else {
                setMovimiento(_Movimiento2)
            }
        } else {
            Swal.fire(
                'Opps!',
                'No ha seleccionado un Tipo de Documento',
                'error'
            )
        }
    }
    const obtenerturnos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TurnoApi/abiertosporusuario/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            } else {
                return []

            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarsaldoclientehoy = (cliente, tercero, fechaa) => {

        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var datt = new Date(fechaa)
        datt = datt.setDate(new Date(fechaa).getDate() - 1)
        datt = new Date(datt)
        datt = datt.toISOString().split('T')[0]

        const api = fetch(props.servidor + '/CtabonoApi/movimientoscompletos/' + fechaa + '/' + fechaa + '/-/-/' + cliente + '/-/' + tercero + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setsaldosclientehoy(data)
            }).catch((error) => {


            })
    }
    const buscarnombremun = (codigo) => {
        var muni = municipios.filter(p => p.codigo == codigo)
        if (muni.length != 0) {
            return muni[0].municipio + " - " + muni[0].departamento
        } else {

            return ""
        }
    }
    const buscartodasbodegas = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Bodegaapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setbodegas(dataJson)
                    setbusquedabodegas(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setbodegas([])
                    setbusquedabodegas([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarbodegas = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['bodega']: e.codigo,
                    ['nombrebodega']: e.nombre,
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        cerrarModalbodegas()
        // buscarexistenciaenbodega(e.codigo)
        var referencia = Movimiento.famovimiento[fila].referencia
        buscarexistencia(referencia, e.codigo)
    }




    const filtrarccostos = async (coincidencia) => {
        var _codigomuns = ccostos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaccostos(_codigomuns)
    }
    const cerrarModalccostos = () => {
        setVerModalccostos(!verModalccostos);
        setbusquedaccostos(ccostos)
    }
    const buscartodasccostos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setccostos(dataJson)
                    setbusquedaccostos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setccostos([])
                    setbusquedaccostos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarccostos = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['ccosto']: e.codigo
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        cerrarModalccostos()
    }

    const filtrarMovimientos = async (coincidencia) => {
        var nn = ""
        try {
            var splitcoincidencia = coincidencia.split(' ')
            if (splitcoincidencia.length > 1) {
                var _codigomuns = Movimientos.filter((dato) => {
                    if (dato.faencmovi.concepto.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.fecha.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.documento1.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || (dato.faencmovi.ndocumento + "").toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.cliente.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.nombrecli.toUpperCase().includes(splitcoincidencia[0].toUpperCase())) { return dato }

                })
                splitcoincidencia.forEach(element => {
                    _codigomuns = _codigomuns.filter((dato) => {
                        if (dato.faencmovi.concepto.toUpperCase().includes(element.toUpperCase()) || dato.faencmovi.fecha.toUpperCase().includes(element.toUpperCase()) || dato.faencmovi.documento1.toUpperCase().includes(element.toUpperCase()) || (dato.faencmovi.ndocumento + "").toUpperCase().includes(element.toUpperCase()) || dato.faencmovi.cliente.toUpperCase().includes(element.toUpperCase()) || dato.faencmovi.nombrecli.toUpperCase().includes(element.toUpperCase())) { return dato }

                    })
                });
                setbusquedaMovimientos(_codigomuns)
            } else {
                var _codigomuns = Movimientos.filter((dato) => {
                    nn = dato.faencmovi.concepto + " - " + dato.faencmovi.ndocumento
                    if (dato.faencmovi.concepto.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.fecha.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.documento1.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || (dato.faencmovi.ndocumento + "").toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.cliente.toUpperCase().includes(splitcoincidencia[0].toUpperCase()) || dato.faencmovi.nombrecli.toUpperCase().includes(splitcoincidencia[0].toUpperCase())) { return dato }

                })
                setbusquedaMovimientos(_codigomuns)

            }
        } catch (error) {
            console.log(error)
            console.log(nn)
        }
    }

    const cerrarModalMovimientos = () => {
        setVerModalMovimientos(false);
        // setbusquedaMovimientos(Movimientos)
    }
    const cerrarModalMovimientosremision = () => {
        setVerModalMovimientosremision(false);
        // setbusquedaMovimientos(Movimientos)
    }
    const cerrarModalMovimientosDevoluciones = () => {
        setVerModalMovimientosDevoluciones(false);
        // setbusquedaMovimientos(Movimientos)
    }

    const seleccionarMovimientos = async (e) => {

        try {
            var _faencmovi = e.faencmovi
            var _movimiento = _faencmovi
            var tipodocc = await buscarcoincidenciastipodocs(_faencmovi.concepto)
            var sucursal2 = await buscarcoincidenciassucursal(tipodocc.compañia)
            var pagoscaja = await buscarcoincidenciascaja(_faencmovi.concepto, _faencmovi.ndocumento)
            settipodocumento(tipodocc)
            setsucursal(sucursal2)
            setvalorescaja(pagoscaja)
            var tercc = await buscarcoincidenciasterceros(_faencmovi.cliente)
            settercero(tercc[0])
            if (tipodocc.modidoc == "carta 3" || tipodocc.modidoc == "carta 8") {
                buscarsaldocliente(_movimiento.cliente, _movimiento.tercero, _movimiento.fecha, _movimiento.ndocumento)
                // buscarsaldoclientehoy(_movimiento.cliente, _movimiento.tercero, _movimiento.fecha)
            }

            var movimientocredit = await buscartodoslosmovimientos23(_faencmovi.concepto, _faencmovi.ndocumento)
            var notascredito2 = movimientocredit.map((dato) => {
                return <Col sm={2}>
                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {
                        setmovimientoenvio(dato)
                        seteditarorden(true)
                    }} >
                        <i className="fa fa-clone">{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                </Col>
            })
            setnotascredito2(movimientocredit)
            setnotascredito(notascredito2)
            var remisioness = await buscarremisionesfactura(_movimiento.concepto, _movimiento.ndocumento)
            setremisionesfactura(remisioness)
            var remisioness2 = await buscarremisionesfactura2(_movimiento.concepto, _movimiento.ndocumento)
            setfacturaremisiones(remisioness2)

            try {

                let dt = JSON.parse(user)
                var url = props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + ''
                const api = fetch(props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(e)
                })
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then(async (dataJson) => {
                        _movimiento.famovimiento = dataJson
                        var artt = []
                        var artt2 = []

                        for (let i = 0; i < _movimiento.famovimiento.length; i++) {
                            const element = _movimiento.famovimiento[i];
                            var e = {}
                            e = await buscararticulounico(element.referencia)

                            artt[i] = e
                            // var dattt = await buscarexistencia2(element.referencia, element.bodega)
                            // artt2.push(dattt)
                        }

                        setlistaarticulo(artt)
                        setlistaarticulocostoprecio(artt)
                        setlistaexistenciaarticulo(artt2)
                        calculartotalesmovimiento(dataJson)
                        setMovimiento(_movimiento)
                        cerrarModalMovimientos()
                        let dt = JSON.parse(permisos)

                        if (!dt.FACTURA.editar) {
                            var nofacturaruvt = true
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumentoeditable" && element.acceso[0] == "2" && element.tipodoc == _faencmovi.concepto) {
                                    nofacturaruvt = false
                                }
                            });
                            if (nofacturaruvt) {
                                seteditar(false)
                                setnuevo(false)
                            } else {
                                seteditar(true)
                                setnuevo(false)
                            }
                        } else {
                            seteditar(true)
                            setnuevo(false)
                        }

                        console.log(dataJson)
                    }).catch((error) => {
                        Swal.fire(
                            'Opps!',
                            'No pudieron cargar los movimientos',
                            'error'
                        )
                        setMovimientos([])
                        setbusquedaMovimientos([])
                    })
            } catch (error) {
                console.log(error)
            }

        } catch (error3) {
        }
    }
    const seleccionarMovimientosremisiones = async (selc) => {
        var movimientosss = []
        var rems = []
        var _Movimiento = { ...Movimiento }
        if (tipodocumento.muesdescbanc == "si") {
            movimientosss = _Movimiento.famovimiento
        }

        var artt = []
        var artt2 = []
        for (let h = 0; h < selc.length; h++) {
            const e = selc[h];

            var _faencmovi = e.faencmovi
            var tipodoc = ""

            tipodocumentos.map((dato, index) => {
                if (dato.codigo.toUpperCase() == _faencmovi.concepto.toUpperCase()) {
                    tipodoc = dato
                }

            })
            _Movimiento.vendedor = _faencmovi.vendedor
            _Movimiento.nombreven = _faencmovi.nombreven

            rems.push({

                concepto: _Movimiento.concepto,
                ndocumento: 0,
                conceptoremi: _faencmovi.concepto,
                ndocumentoremi: _faencmovi.ndocumento,

            })

            if ((tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") && tipodocumento.noimpdupl == "si") {
                var facturass = await buscarclienteplansepare(_faencmovi.cliente)
                if (facturass.length == 0) {

                } else {
                    for (let i = 0; i < facturass.length; i++) {
                        const element = facturass[i];
                        if (element.tipodoc == _faencmovi.concepto && element.doc == _faencmovi.ndocumento) {
                            i = facturass.length + 123
                            Swal.fire(
                                'Opps!',
                                'Todavia tiene saldo pendiente',
                                'error'
                            )
                            return
                        }
                    }
                }
            }
            try {
                let dt = JSON.parse(user);
                const url = props.servidor + '/Movimientoapi/cuerpo/?llave=' + dt.usuario.llaveempresa;

                const requestData = {
                    // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
                };

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(e),
                };

                const response = await fetch(url, requestOptions);

                if (response.ok) {
                    const dataJson = await response.json();
                    _Movimiento.famovimiento = dataJson;
                    dataJson.forEach(element2 => {
                        movimientosss.push(element2)
                    });

                    for (let index = 0; index < dataJson.length; index++) {
                        const element = dataJson[index];
                        var dattt = await buscarexistencia2(element.referencia, element.bodega)
                        artt.push(dattt)

                        var eeee = {}
                        eeee = await buscararticulounico(element.referencia)
                        artt2.push(eeee)
                        // artt2[index] = eeee
                    }

                    console.log(dataJson);
                } else {
                    const error = await response.text();
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos: ' + error,
                        'error'
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
        setlistaarticulo(artt2)
        setlistaarticulocostoprecio(artt2)
        setlistaexistenciaarticulo(artt)
        calculartotalesmovimiento(movimientosss);
        _Movimiento.famovimiento = movimientosss
        setMovimiento(_Movimiento);
        setremisiones(rems)
        cerrarModalMovimientosremision();
    }

    const seleccionarMovimientosDevoluciones = async (e) => {

        var _faencmovi = e.faencmovi
        var _Movimiento = { ...Movimiento }
        var tipodoc = ""

        tipodocumentos.map((dato, index) => {
            if (dato.codigo.toUpperCase() == _faencmovi.concepto.toUpperCase()) {
                tipodoc = dato
            }

        })
        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
            var facturass = await buscarclienteplansepare(_faencmovi.cliente)
            if (facturass.length == 0) {

            } else {
                for (let i = 0; i < facturass.length; i++) {
                    const element = facturass[i];
                    if (element.tipodoc == _faencmovi.concepto && element.doc == _faencmovi.ndocumento) {
                        i = facturass.length + 123
                        Swal.fire(
                            'Opps!',
                            'Todavia tiene saldo pendiente',
                            'error'
                        )
                        return
                    }
                }
            }
        }
        if (tipodocumento.tipodoc == "factura" && tipodoc.tipodoc != "devoluciones" && tipodocumento.noimpdupl != "si") {
            Swal.fire(
                'Opps!',
                'Debe de Seleccionar una Devolucion',
                'error'
            )
            return
        }
        if ((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") && tipodoc.tipodoc != "factura") {
            Swal.fire(
                'Opps!',
                'Debe de Seleccionar una Factura',
                'error'
            )
            return
        }

        if ((tipodocumento.tipodoc == "devoluciones compra") && tipodoc.tipodoc != "compra") {
            Swal.fire(
                'Opps!',
                'Debe de Seleccionar una Factura',
                'error'
            )
            return
        }
        _Movimiento['conceptodevo'] = _faencmovi.concepto
        _Movimiento['ndocumentodevo'] = _faencmovi.ndocumento
        if ((tipodocumento.tipodoc == "devoluciones compra" || tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") || (tipodocumento.tipodoc == "factura" && tipodocumento.noimpdupl == "si")) {
            try {

                let dt = JSON.parse(user)
                var url = props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + ''
                const api = fetch(props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(e)
                })
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then(async (dataJson) => {
                        _Movimiento.famovimiento = dataJson
                        var contt = 0
                        var artt = []
                        var artt2 = []
                        for (let index = 0; index < dataJson.length; index++) {
                            const element = dataJson[index];
                            var dattt = await buscarexistencia2(element.referencia, element.bodega)
                            artt.push(dattt)

                            var eeee = {}
                            eeee = await buscararticulounico(element.referencia)
                            artt2[index] = eeee
                        }
                        setlistaarticulo(artt2)
                        setlistaarticulocostoprecio(artt2)
                        setlistaexistenciaarticulo(artt)
                        calculartotalesmovimiento(dataJson)
                        setMovimiento(_Movimiento)

                        cerrarModalMovimientosDevoluciones()
                        console.log(dataJson)
                    }).catch((error) => {
                        Swal.fire(
                            'Opps!',
                            'No pudieron cargar los movimientos: ' + error,
                            'error'
                        )
                    })
            } catch (error) {
                console.log(error)
            }
        }
        if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "remision") {
            _Movimiento['descuentoc'] = _faencmovi.total
            setMovimiento(_Movimiento)
            cerrarModalMovimientosDevoluciones()

        }
    }
    //para obtener la lista de sugerencias
    const onSuggestionsFetchRequested = ({ value }) => {

        const api = fetch("api/venta/Productos/" + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setA_Productos(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }

    //funcion que nos permite borrar las sugerencias
    const onSuggestionsClearRequested = () => {
        setA_Productos([])
    }

    //devuelve el texto que se mostrara en la caja de texto del autocomplete cuando seleccionas una sugerencia (item)
    const getSuggestionValue = (sugerencia) => {

        return sugerencia.codigo + " - " + sugerencia.marca + " - " + sugerencia.descripcion
    }

    //como se debe mostrar las sugerencias - codigo htmlf
    const renderSuggestion = (sugerencia) => (
        <span>
            {sugerencia.codigo + " - " + sugerencia.marca + " - " + sugerencia.descripcion}
        </span>
    )

    //evento cuando cambie el valor del texto de busqueda
    const onChange = (e, { newValue }) => {
        setA_Busqueda(newValue)
    }

    const inputProps = {
        placeholder: "Buscar producto",
        value: a_Busqueda,
        onChange
    }


    const previsualizarpdf = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('portrait', 'mm', [80, 297],);
        // const doc = new jsPDF('p', 'pt', 'letter');
        doc.page = 1; // use this as a counter.

        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                // if (true) {

                // } else {
                //     doc.autoPrint();
                //     doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
                // }


                if (!estoybuscando) {
                    // if (tipodocumento.facturapap != "electronica") {
                    if (true) {
                        const pdfUrl = doc.output('dataurlstring', { filename: 'comprobante.pdf' });
                        const pdfBlob = doc.output("blob");

                        // Crear una URL de Blob
                        const pdfUrl2 = URL.createObjectURL(pdfBlob);

                        setPdfDataUrl(pdfUrl2); // Guarda el URL de datos en el estado de React
                        setIsModalOpen(true); // Abre el modal
                        document.getElementById("movimientobotonnuevoventacompra").click()
                        setIsModalOpen(true); // Abre el modal
                    } else {
                        doc.autoPrint();
                        doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
                    }

                } else {
                    doc.autoPrint();
                    doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
                }

                // if (!estoybuscando) {
                //     if (tipodocumento.facturapap != "electronica") {
                //         document.getElementById("movimientobotonnuevoventacompra").click()
                //     }

                // }

            },
            html2canvas: {
                scale: 0.3
            }
        })
    }
    const previsualizarpdflargo = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        // const doc = new jsPDF('p', 'pt', [612, 15 * 72]);
        const doc = new jsPDF('p', 'pt', [652, 15 * 72]);
        doc.page = 1; // use this as a counter.

        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();
                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            }
        })
    }

    const buscarhoraminuto = () => {
        // Crear una nueva fecha
        var fecha = new Date();

        // Obtener la hora y los minutos
        var hora = fecha.getHours();
        var minutos = fecha.getMinutes();

        // Convertir la hora a formato de 12 horas
        var am_pm = hora >= 12 ? 'PM' : 'AM';
        hora = hora % 12;
        hora = hora ? hora : 12; // Si es 0, se cambia a 12
        return hora + ":" + (minutos < 10 ? '0' : '') + minutos + " " + am_pm
        // Imprimir la hora y los minutos en formato de 12 horas
        // console.log("Hora: " + hora + ":" + (minutos < 10 ? '0' : '') + minutos + " " + am_pm);
    }

    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const enviocorreofacturaelectronica = async () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: async function (pdf) {
                var buffer = doc.output('arraybuffer');
                var butes = doc.output();
                var blob = doc.output('blob')
                var reader = new FileReader();
                var base64data = ""
                base64data = await convertBlobToBase64(blob);
                console.log(base64data)
                var pdf = {
                    pdfbase64: base64data,
                    adjuntos: certificado
                }
                let dt = JSON.parse(user)
                var clave = dt.empresa.config.clave_certificado == "" ? "-" : dt.empresa.config.clave_certificado
                var ids = tipodocumento.identificadorsw == "" ? "-" : tipodocumento.identificadorsw
                let response = fetch(props.servidor + '/EnvioApi/Correo/' + correosenvio + '/' + Movimiento.concepto + '/' + (Movimiento.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + clave + '/' + ids + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(pdf)
                })
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then(async (dataJson) => {
                        // reestablecer();
                        var data2 = dataJson;
                        reiniciar()
                        Swal.fire(
                            'Exito!!',
                            data2.mensaje,
                            'success'
                        )
                        setprevisualizar(false)

                    }).catch(async (error) => {
                        var eerr = await error.json()
                        Swal.fire(
                            'Opps!',
                            'No se pudo crear la venta: ' + eerr.mensaje,
                            'error'
                        )
                        console.log("No se pudo enviar la venta ", eerr.mensaje,)
                    })


            },
            html2canvas: {
                scale: escala
            }
        })


    }

    const enviocorreofacturaelectronica2 = async (correo) => {
        if (nuevo) {

            const doc = new jsPDF('p', 'pt', 'a4');
            doc.page = 1; // use this as a counter.
            console.log(doc.getFontList())
            var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
            doc.html(document.querySelector("#pruebapdfventaelectronica"), {
                callback: async function (pdf) {
                    var buffer = doc.output('arraybuffer');
                    var butes = doc.output();
                    var blob = doc.output('blob')
                    var reader = new FileReader();
                    var base64data = ""
                    base64data = await convertBlobToBase64(blob);
                    console.log(base64data)
                    var pdf = {
                        pdfbase64: base64data,
                        adjuntos: certificado
                    }
                    let dt = JSON.parse(user)
                    var clave = dt.empresa.config.clave_certificado == "" ? "-" : dt.empresa.config.clave_certificado
                    var ids = tipodocumento.identificadorsw == "" ? "-" : tipodocumento.identificadorsw
                    let response = fetch(props.servidor + '/EnvioApi/Correo/' + correo + '/' + Movimiento.concepto + '/' + (Movimiento.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + clave + '/' + ids + '?llave=' + dt.usuario.llaveempresa + '', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(pdf)
                    })
                        .then((response) => {
                            return response.ok ? response.json() : Promise.reject(response);
                        })
                        .then(async (dataJson) => {
                            // reestablecer();
                            var data2 = dataJson;
                            reiniciar()
                            Swal.fire(
                                'Exito!!',
                                data2.mensaje,
                                'success'
                            )
                            setprevisualizar(false)

                        }).catch(async (error) => {
                            var eerr = await error.json()
                            Swal.fire(
                                'Opps!',
                                'No se pudo crear la venta: ' + eerr.mensaje,
                                'error'
                            )
                            console.log("No se pudo enviar la venta ", eerr.mensaje,)
                        })


                },
                html2canvas: {
                    scale: escala
                }
            })



        }

    }
    const previsualizarpdfelectronicadescargar = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();
                doc.save(tipodocumento.descripcion + Movimiento.ndocumento + '.pdf');
                // doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const buscarremisionesfactura = async (concepto, ndocumento) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/MovimientoApi/buscarremisiones/' + concepto + '/' + ndocumento + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarremisionesfactura2 = async (concepto, ndocumento) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/MovimientoApi/buscarremisiones2/' + concepto + '/' + ndocumento + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const validarfacturas = async (concepto, ndocumento) => {
        try {


            let dt = JSON.parse(user)
            // let dt = JSON.parse(user)
            // let response = await fetch(props.servidor + '/EnvioApi/' + data.movimiento.faencmovi.concepto + '/' + (data.movimiento.faencmovi.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + tipodocumento.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

            let response = await fetch(props.servidor + '/EnvioApi/validarfactura/' + Movimiento.cufe + '/' + dt.empresa.config.clave_certificado + '/' + Movimiento.concepto + '/' + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo == "200") {
                    Swal.fire(
                        'Exito!!',
                        data.mensaje,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error!!',
                        data.mensaje,
                        'error'
                    )
                }
            } else {
                let data = await response.json()
                if (data.codigo == "200") {
                    Swal.fire(
                        'Exito!!',
                        data.mensaje,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error!!',
                        data.mensaje,
                        'error'
                    )
                }
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Exito!!',
                error,
                'error'
            )
        }
    }
    const imprimirfacturagas = async (concepto, ndocumento) => {
        try {


            let dt = JSON.parse(user)
            // let dt = JSON.parse(user)
            // let response = await fetch(props.servidor + '/EnvioApi/' + data.movimiento.faencmovi.concepto + '/' + (data.movimiento.faencmovi.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + tipodocumento.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

            let response = await fetch(props.servidor + '/Movimientoapi/buscarpdffacturaindividualgas/' + Movimiento.concepto + '/' + Movimiento.ndocumento + '/' + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                var textoerrores = await response.json()
                var data = textoerrores;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);

                const newWindow = window.open(url, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
                // Crear un enlace para descargar el archivo PDF
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'facturas.pdf');
                // // Simular clic en el enlace para iniciar la descarga
                // document.body.appendChild(link);
                // link.click();
                // // Liberar el objeto URL creado
                // window.URL.revokeObjectURL(url);


            } else {
                let data = await response.json()
                if (data.codigo == "200") {
                    Swal.fire(
                        'Exito!!',
                        data.mensaje,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error!!',
                        data.mensaje,
                        'error'
                    )
                }
            }
        } catch (error) {
            console.log(error)
            Swal.fire(
                'Exito!!',
                error,
                'error'
            )
        }
    }
    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }

    const calcularsubtotalitem = (item) => {
        var base = Number(item.cantidad + "") * Number(item.precio + "")
        var descuento = base * (Number(item.pordescuento + "") / 100)
        var basecondescuento = base - descuento
        var iva = basecondescuento * (Number(item.poriva + "") / 100)
        var impoconsumo = basecondescuento * (Number(item.porimptoconsumo + "") / 100)
        var retefuente = basecondescuento * (Number(item.poretefte + "") / 100)
        var total = basecondescuento + iva + impoconsumo + (Number(item.vlrimptoconsumo + "") * Number(item.cantidad + "")) - retefuente
        return total
    }
    const sugerenciaSeleccionada = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        Swal.fire({
            title: suggestion.marca + " - " + suggestion.descripcion,
            text: "Ingrese la cantidad",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {


                if (isNaN(parseFloat(inputValue))) {
                    setA_Busqueda("")
                    Swal.showValidationMessage(
                        "Debe ingresar un valor númerico"
                    )
                } else {

                    let producto = {
                        idProducto: suggestion.idProducto,
                        descripcion: suggestion.descripcion,
                        cantidad: parseInt(inputValue),
                        precio: suggestion.precio,
                        total: suggestion.precio * parseFloat(inputValue)
                    }
                    let arrayProductos = []
                    arrayProductos.push(...productos)
                    arrayProductos.push(producto)

                    setProductos((anterior) => [...anterior, producto])
                    calcularTotal(arrayProductos)
                }


            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then((result) => {
            if (result.isConfirmed) {
                setA_Busqueda("")
            } else {
                setA_Busqueda("")
            }
        })
    }

    const eliminarProducto = (id) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos.filter(p => p != undefined)
        // setMovimientos(_Movimientos)
        let _listaexistenciaarticulo = listaexistenciaarticulo.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _listaexistenciaarticulo = _listaexistenciaarticulo.filter(p => p != undefined)

        setlistaexistenciaarticulo(_listaexistenciaarticulo)
        if (_Movimiento['famovimiento'].length > 0) {

            setMovimiento([])
        }
        setMovimiento(_Movimiento)
        calculartotalesmovimiento(_Movimiento['famovimiento'])
        // let listaproductos = productos.filter(p => p.idProducto != id)

        // setProductos(listaproductos)

        // calcularTotal(listaproductos)
    }

    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let imp = 0;

        if (arrayProductos.length > 0) {

            arrayProductos.forEach((p) => {
                t = p.total + t
            })

            st = t / (1.18)
            imp = t - st
        }

        //Monto Base = (Monto con IGV) / (1.18)

        //IGV = (Monto con IGV) – (Monto Base)

        setSubTotal(st.toFixed(2))
        setIgv(imp.toFixed(2))
        setTotal(t.toFixed(2))
    }
    const ingresecantidadmensaje = () => {
        Swal.fire(
            'Opps!',
            'Seleccione un Articulo Primero',
            'error'
        )
    }

    const encodeSvg = (reactElement) => {
        return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
    }

    const convertirsvgaimagen = (svg) => {
        try {
            var renderr = encodeSvg(svg)
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.innerHTML = renderr
            var img = document.createElement("img");
            img.setAttribute("src", renderr);

            img.onload = function () {
                // ctx.drawImage(img, 0, 0);

                // // Now is done
                // console.log(canvas.toDataURL("image/png"));
            };

            var fdf = canvas.toDataURL("image/gif")
            console.log(fdf)
            console.log(renderr)
            // var ggg=base64encode
            return <img src={fdf}></img>
        } catch (error) {
            return <img src={""}></img>
        }

    }
    const nombrebodegaf = () => {
        if (Movimiento.famovimiento.length > 0) {
            var cod = Movimiento.famovimiento[0].bodega
            var bod = bodegas.find(p => p.codigo == cod)
            if (bod) {
                return bod.nombre
            } else {
                return ""
            }
        }
    }
    const rutanombre = () => {
        if (Movimiento.famovimiento.length > 0) {
            return Movimiento.famovimiento[0].descrip
        } else {
            return ""
        }
    }
    const rutacantidad = () => {
        if (Movimiento.famovimiento.length > 0) {
            return Movimiento.famovimiento[0].cantidad
        } else {
            return 0
        }
    }
    const rutaseguros = () => {
        if (Movimiento.famovimiento.length > 0) {
            return Movimiento.famovimiento[0].desadicional
        } else {
            return 0
        }
    }
    const handlerchangegrillaarticulo = async (eventt) => {
        if (eventt.target.value != "") {
            var er = await buscararticulounico(eventt.target.value)
            if (er) {
                if (er.codigo != "") {
                } else {
                    if (er.codigo == "") {
                        setvalorabuscarproducto(eventt.target.value)
                        if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                            buscartodasarticulos('');
                        }

                        setVerModalarticulos(true)
                        // Swal.fire(
                        //     'Opps!',
                        //     'Articulo seleccionado no existe',
                        //     'error'
                        // )
                        return
                    }
                }
            } else {
                Swal.fire(
                    'Opps!',
                    'Articulo seleccionado no existe',
                    'error'
                )
                return
            }
            if (er.codigo) {
                if (er.length == 0) {
                    Swal.fire(
                        'Opps!',
                        'Articulo seleccionado no existe',
                        'error'
                    )
                    return
                }
            }
            var _Movimiento = { ...Movimiento }
            var _datos = { ...Movimiento }['famovimiento']
            eventosseleccionarticulo(er, _Movimiento, _datos)


            setacabodeseleccionararticulo(true)
            if (er.descripcion == "si") {
                setabrirdescripcionadicional(true)
            }
        }
    }

    const handlerchangegrillabodega = (eventt) => {
        var er = bodegas.filter((dato) => {
            if (dato.codigo == eventt.target.value) { return dato }

        })
        if (er.length == 1) {
            er = er[0]
        } else {
            if (er.length == 0) {
                Swal.fire(
                    'Opps!',
                    'Bodega seleccionada no existe',
                    'error'
                )
                return
            }
            if (er.length > 1) {
                Swal.fire(
                    'Opps!',
                    'Existe mas de una bodega con este codigo',
                    'error'
                )
                return
            }
        }
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['bodega']: er.codigo,
                    ['nombrebodega']: er.nombre,
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        buscarexistenciaenbodega(er.codigo)

    }

    const handleChangegrillainarticuloslistapreciorentabilidad = async (e, ind) => {
        var _Inarticulos = listaarticulocostoprecio.map((dat) => { return dat })
        var subtabla = e.target.getAttribute('tabla')
        var _datos = _Inarticulos[fila].inarticuloslistaprecio
        _datos = _datos.map((dato, index) => {
            var fff = listaarticulocostoprecio[fila].costonuevo * (1 + (Number(e.target.value + "") / 100)) * (1 + (Number(Movimiento.famovimiento[fila].poriva + "") / 100))
            if (index == ind) {
                var dat = {
                    ...dato,
                    ['precionuevo']: Number(fff.toFixed(2))
                }
                return dat
            } else {
                return dato
            }

        })
        let _enviodatos = _datos.map((dato, index) => {
            if (index == ind) {
                var dat = {
                    ...dato,
                    [e.target.name]: e.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[fila][subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setlistaarticulocostoprecio(_Inarticulos)

    }
    const handleChangegrillainarticuloslistaprecio = async (e, ind) => {
        var _Inarticulos = listaarticulocostoprecio.map((dat) => { return dat })
        var subtabla = e.target.getAttribute('tabla')
        var _datos = _Inarticulos[fila].inarticuloslistaprecio
        _datos = _datos.map((dato, index) => {
            var fff = 100 * (-1 + (Number(e.target.value) / (listaarticulocostoprecio[fila].costonuevo * (1 + (Number(Movimiento.famovimiento[fila].poriva + "") / 100)))))
            if (index == ind) {
                var dat = {
                    ...dato,
                    ['rentabilidad']: Number(fff.toFixed(2))
                }
                return dat
            } else {
                return dato
            }

        })
        let _enviodatos = _datos.map((dato, index) => {
            if (index == ind) {
                var dat = {
                    ...dato,
                    [e.target.name]: e.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[fila][subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setlistaarticulocostoprecio(_Inarticulos)

    }
    const inputadigitar = (clave, mensaje) => {
        var clavedig = ""
        var cancelado = false
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: mensaje,
                input: "text",
                icon: 'warning',
                showCancelButton: true,
                inputPlaceholder: "Digite",
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Por favor escribe algo.");
                    } else {
                        return value
                    }
                },
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Cambiar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                clavedig = result.value
                resolve(clavedig)
                // if (result.isDismissed) {
                //     cancelado = true
                //     resolve(false)
                // }
                // if (result.isDenied) {
                //     cancelado = true
                //     resolve(false)
                // }
                // if (result.isConfirmed) {
                //     clavedig = result.value
                //     resolve(clavedig)
                // }
            })
        })
    }
    const clavepermisosdig = (clave, mensaje) => {
        var clavedig = ""
        var cancelado = false
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: 'Digite la Contraseña' + mensaje,
                input: "password",
                icon: 'warning',
                showCancelButton: true,
                inputPlaceholder: "Digite la Contraseña",
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Por favor escribe algo.");
                    } else {
                        return value
                    }
                },
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Acceder',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isDismissed) {
                    cancelado = true
                    resolve(false)
                }
                if (result.isDenied) {
                    cancelado = true
                    resolve(false)
                }
                if (result.isConfirmed) {
                    clavedig = result.value
                    cancelado = false
                    if (clave == clavedig) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                }
            })
        })
    }
    const guardarmovimiento = async () => {
        document.getElementById("campoguardardemovimiento").blur()
        if (Movimiento.ndocumento == 0) {
            if (tercero.cupocre > 0) {
                try {
                    var manejacupocredito = false
                    var clavecupocredito = ""
                    props.permisosusuario.forEach(element => {
                        if (element.tipo == "cupocredito" && element.acceso[0] == "2" && element.tipodoc == Movimiento.concepto) {
                            manejacupocredito = true
                            clavecupocredito = element.clave
                        }
                    });
                    if (manejacupocredito) {
                        var saldo = await saldoparacupocredito(Movimiento.cliente, Movimiento.tercero, Movimiento.fecha, Movimiento.ndocumento)
                        var saldo2 = totalsaldoclientecupocredito(saldo)
                        var total2 = tercero.cupocre - (saldo2 + total)
                        if (total2 < 0) {
                            if (clavecupocredito != "") {
                                var condig = ""
                                var claveigual = await clavepermisosdig(clavecupocredito, "ya que se excedio el cupo credito")
                                if (!claveigual) {
                                    Swal.fire(
                                        'Opps!',
                                        'Cancelado',
                                        'error'
                                    )
                                    return
                                }

                            } else {
                                Swal.fire(
                                    'Opps!',
                                    'Cupo Credito Excedido',
                                    'error'
                                )
                                return
                            }
                        }
                    }
                } catch (error) {

                }

            }
            try {
                var manejacupocredito2 = false
                var clavecupocredito2 = ""
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "facturasamasdendias" && element.acceso[0] == "2" && element.tipodoc == Movimiento.concepto) {
                        manejacupocredito2 = true
                        clavecupocredito2 = element.clave
                    }
                });
                if (manejacupocredito2) {
                    var saldo = await facturasamasdendias(Movimiento.cliente, Movimiento.tercero, Movimiento.fecha, tipodocumento.numitem)

                    if (saldo.length > 0) {
                        if (clavecupocredito2 != "") {
                            var condig = ""
                            var claveigual = await clavepermisosdig(clavecupocredito2, "ya que hay facturas vencidas a mas de " + tipodocumento.numitem + " dias")
                            if (!claveigual) {
                                Swal.fire(
                                    'Opps!',
                                    'Cancelado',
                                    'error'
                                )
                                return
                            }

                        } else {
                            Swal.fire(
                                'Opps!',
                                'Cupo Credito Excedido',
                                'error'
                            )
                            return
                        }
                    }
                }
            } catch (error) {

            }
        }
        try {
            var dddd = new Date(Movimiento.fecha)
            if (parseInt(Movimiento.fecha.split("-")[0]) > 2000) {

            } else {
                Swal.fire(
                    'Opps!',
                    'Fecha no Existe',
                    'error'
                )
                return
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'Fecha no Existe',
                'error'
            )
            return
        }

        var clienttt = await buscarcaclientesunico(Movimiento.cliente)
        if (clienttt) {
            if (clienttt.caclientes.codigo == "") {
                Swal.fire(
                    'Opps!',
                    'Tercero no Existe,Seleccionelo Nuevamente',
                    'error'
                )
                return
            }
        } else {
            Swal.fire(
                'Opps!',
                'Tercero no Existe,Seleccionelo Nuevamente',
                'error'
            )
            return
        }
        var valortotaluvt = cantidadvaloruvt * valoruvt
        // if (subTotal > valortotaluvt && tipodocumento.facturapap != "electronica" && tipodocumento.tipodoc == "factura") {
        if (false) {

            Swal.fire({
                title: 'Factura Supera las 5 UVT, Cambie a un Tipo de Documento que sea Electronico',
                text: "Desea continuar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isDenied) {
                    return
                }
                if (result.isConfirmed) {
                    if (permisos) {
                        let dt = JSON.parse(permisos)

                        if (!dt.FACTURA.editar && editar) {
                            var nofacturaruvt = true
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumentoeditable" && element.acceso[0] == "2" && element.tipodoc == Movimiento.concepto) {
                                    nofacturaruvt = false
                                }
                            });
                            if (nofacturaruvt) {
                                Swal.fire(
                                    'Opps!',
                                    'No tiene permisos para editar',
                                    'error'
                                )
                                return
                            }
                        }

                    }
                    if (permisos) {
                        let dt = JSON.parse(permisos)
                        var nofacturaruvt = false
                        props.permisosusuario.forEach(element => {
                            if (element.tipo == "facturarconuvt" && element.acceso[0] == "1") {
                                nofacturaruvt = true
                            }
                        });
                        if (nofacturaruvt) {
                            Swal.fire(
                                'Opps!',
                                'No tiene permisos para Guardar Facturas Superiores a 5 UVT como POS',
                                'error'
                            )
                            return
                        }

                    }
                    if (tipodocumento.ica == "si" && ((tercero.venbaseica < subTotal || tercero.venbasereteiva < subTotal) && (tercero.venporica > 0 || tercero.venporcreteiva > 0)) || (tercero.combaseica < subTotal || tercero.combasereteiva < subTotal) && (tercero.comporica > 0 || tercero.comporcreteiva > 0)) {

                        setabrirotrosimpuestos(true)
                    } else {
                        if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "") {
                            var _Movimiento = { ...Movimiento }
                            var efec = total - Number(_Movimiento['cheque'] + "") - Number(_Movimiento['debito'] + "") - Number(_Movimiento['credito'] + "") - Number(_Movimiento['descuentoc'] + "")

                            _Movimiento['efectivo'] = (efec >= 0) ? efec : 0
                            _Movimiento['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
                            setMovimiento(_Movimiento)
                            obtenerMediodepagos('')
                            setabrirabrirmediosdepago(true)

                        } else {
                            if ((tipodocumento.tipodoc == "produccion" || tipodocumento.tipodoc == "translado" || tipodocumento.camdescenc == "si") && tipodocumento.tipoprod == "origen") {

                                setabrirbodegatranslado(true)
                            } else {
                                setesperando(true)

                                terminarVenta()

                            }


                        }
                    }
                }
            })



        } else {

            if (permisos) {
                let dt = JSON.parse(permisos)


                if (!dt.FACTURA.editar && editar) {
                    var nofacturaruvt = true
                    props.permisosusuario.forEach(element => {
                        if (element.tipo == "tipodocumentoeditable" && element.acceso[0] == "2" && element.tipodoc == Movimiento.concepto) {
                            nofacturaruvt = false
                        }
                    });
                    if (nofacturaruvt) {
                        Swal.fire(
                            'Opps!',
                            'No tiene permisos para editar',
                            'error'
                        )
                        return
                    }
                }


            }
            if (tipodocumento.ica == "si" && ((tercero.venbaseica < subTotal && tercero.venporica > 0) || (tercero.venbasereteiva < subTotal && tercero.venporcreteiva > 0)) || (tercero.combaseica < subTotal || tercero.combasereteiva < subTotal) && (tercero.comporica > 0 || tercero.comporcreteiva > 0)) {

                setabrirotrosimpuestos(true)
            } else {
                if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "") {
                    var _Movimiento = { ...Movimiento }
                    var efec = total - Number(_Movimiento['cheque'] + "") - Number(_Movimiento['debito'] + "") - Number(_Movimiento['credito'] + "") - Number(_Movimiento['descuentoc'] + "") + Number(_Movimiento['convoridoc'] + "")

                    _Movimiento['efectivo'] = (efec >= 0) ? efec : 0
                    _Movimiento['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
                    setMovimiento(_Movimiento)
                    obtenerMediodepagos('')
                    setabrirabrirmediosdepago(true)

                } else {
                    if ((tipodocumento.tipodoc == "produccion" || tipodocumento.tipodoc == "translado" || tipodocumento.camdescenc == "si") && tipodocumento.tipoprod == "origen") {

                        setabrirbodegatranslado(true)
                    } else {
                        setesperando(true)

                        terminarVenta()

                    }


                }
            }


        }



    }
    const verificar = (_movimiento) => {
        var faencmovi = _movimiento.faencmovi
        var famovimiento = _movimiento.famovimiento
        var verificado = true
        if (faencmovi.concepto == "" || faencmovi.fecha == "" || faencmovi.cliente == "") {
            return false
        }
        if ((tipodocumento.captionvend != "" && (tipodocumento.mvendedor == "si"))) {
            if (faencmovi.vendedor == "") {
                return false
            }
        }

        famovimiento.map((dato, index) => {
            if (dato.referencia == "" || dato.bodega == "" || dato.cantidad <= 0 || dato.precio <= 0 || dato.concepto == "") {
                verificado = false
            }
            if (tipodocumento.centrodecostoobligatorio == "si") {
                if (dato.ccosto == "") {
                    verificado = false
                }
            }

        })
        return verificado
    }
    const reiniciar = () => {

        setnuevo(false)
        seteditar(false)
        setMovimiento(modelomovimiento)
        setSubTotal(0)
        setbruto(0)
        setdescuento(0)
        setconsumo(0)
        setiva(0)
        setretefuente(0)
        setreteica(0)
        setreteiva(0)
        setTotal(0)
        setexistenciaarticulocaja(0)
        setexistenciaarticuloblister(0)
        setexistenciaarticulounidad(0)
        setnotascredito([])

    }

    const reiniciaraeditar = () => {
        setnuevo(false)
        seteditar(true)
        // setMovimiento(modelomovimiento)
        setSubTotal(0)
        setbruto(0)
        setdescuento(0)
        setconsumo(0)
        setiva(0)
        setretefuente(0)
        setreteica(0)
        setreteiva(0)
        setTotal(0)
        setexistenciaarticulocaja(0)
        setexistenciaarticuloblister(0)
        setexistenciaarticulounidad(0)
        setnotascredito([])
    }
    const obtenerMediodepagos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Mediodepagoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataenvio = data.filter(p => p.semanejaencaja == "Si")
                dataenvio = dataenvio.map((dato) => {
                    var dat = {
                        ...dato,
                        ['valor']: 0,
                        ['concepto']: tipodocumento.codigo,
                        ['documento']: Number(Movimiento.ndocumento + ""),
                        ['caja']: props.caja,
                        ['fecha']: Movimiento.fecha,
                        ['afecta']: 'debito'
                    }
                    return dat
                })
                setMediodepagos(dataenvio)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const enviaradian = async () => {
        try {

            if (Movimiento.cufe == "") {

                let dt = JSON.parse(user)
                if (dt.empresa.config.clave_certificado == "") {
                    Swal.fire(
                        'Opps!',
                        'No tiene la clave del certificado.Si ya se coloco salga del usuario y vuelva a entrar',
                        'error'
                    )
                    return
                }
                if (tipodocumento.identificadorsw == "") {
                    Swal.fire(
                        'Opps!',
                        'No tiene el identificador de software el tipo de documento. Si ya se coloco salga del usuario y vuelva a entrar',
                        'error'
                    )
                    return

                }
                let response = await fetch(props.servidor + '/EnvioApi/' + Movimiento.concepto + '/' + (Movimiento.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + tipodocumento.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data2 = await response.json()
                    // reiniciar()
                    var _movv = data2.movimiento.faencmovi
                    _movv.famovimiento = data2.movimiento.famovimiento
                    setMovimiento(_movv)
                    Swal.fire(
                        '' + Movimiento.concepto + ' Emitido Exitosamente!',
                        'Numero de ' + Movimiento.concepto + ' : ' + Movimiento.ndocumento,
                        'success'
                    )
                    setprevisualizar(true)
                } else {
                    Swal.fire(
                        'Opps!',
                        'No se logro enviar a la Dian',
                        'error'
                    )
                }
            } else {
                Swal.fire(
                    'Opps!',
                    'Este Documento ya se emitio',
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'Este Documento ya se emitio',
                'error'
            )
        }
    }
    const guardarlogdeerrores = (contenido) => {
        const a = document.createElement("a");
        const archivo = new Blob([contenido], { type: 'text/plain' });
        const url = URL.createObjectURL(archivo);
        a.href = url;
        a.download = 'log';
        a.click();
        URL.revokeObjectURL(url);
    }

    const fechagarantia = (plazo) => {
        try {
            var datt = new Date(Movimiento['fecha'])
            datt = datt.setDate(new Date(Movimiento['fecha']).getDate() + Number(plazo))
            datt = new Date(datt)
            datt = datt.toISOString().split('T')[0]
            return datt
        } catch (error) {
            return "0"
        }
    }
    const terminarVenta = async () => {

        let dt222 = JSON.parse(user)
        try {
            let dtp = JSON.parse(permisos)
            if (!dtp.FACTURA.editar && Number(Movimiento.ndocumento + '') != 0) {
                Swal.fire(
                    'Opps!',
                    'No tiene Permisos para editar',
                    'error'
                )
                setesperando(false)
                return
            }
        } catch (error) {

        }
        if (dt222.empresa.empresa.nit == '901187246') {
            if (Movimiento.concepto == 'FE1' || Movimiento.concepto == 'FE2') {
                if (Movimiento.ndocumento > 500000) {
                    Swal.fire(
                        'Opps!',
                        'NO TOCAR NADA Y LLAMAR A SOPORTE FACTIN URGENTE',
                        'error'
                    )
                    setesperando(false)
                    return
                }
            }
        }

        var existencianegativa = false
        var codigosnegativos = ""
        var codigospordebajodelminimo = ""
        if (tipodocumento.afecinv == "resta") {
            try {

                props.permisosusuario.forEach(element => {
                    if (element.tipo == "facturarennegativo" && element.acceso[0] == "1") {
                        var conttt = 0
                        console.log(conttt + "  primero")

                        listaexistenciaarticulo.forEach(element => {
                            var conrestamenora0 = true
                            console.log(Movimiento)
                            console.log(Movimiento.famovimiento)
                            console.log(Movimiento.famovimiento)
                            if ((element < Movimiento.famovimiento[conttt].cantidad) && (listaarticulo[conttt].artserv == "no" || listaarticulo[conttt].artserv == "venta" || listaarticulo[conttt].artserv == "consumoventa")) {
                                existencianegativa = true
                                console.log(conttt + "  interno")
                                codigosnegativos = codigosnegativos + "El codigo:" + Movimiento.famovimiento[conttt].referencia
                            }


                            conttt = conttt + 1
                            console.log(conttt + "  suma")
                        });
                    }
                });
                var usacantminima = false
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "cantidadminima" && element.acceso[0] == "2") {
                        usacantminima = true
                    }
                });
                if (usacantminima) {
                    for (let i = 0; i < listaarticulo.length; i++) {
                        const articc = listaarticulo[i];
                        var conttt = i
                        if (articc.cantmin != 0) {
                            if ((articc.cantmin < Movimiento.famovimiento[conttt].cantidad) && (listaarticulo[conttt].artserv == "no" || listaarticulo[conttt].artserv == "venta" || listaarticulo[conttt].artserv == "consumoventa")) {

                                console.log(conttt + "  interno")
                                codigospordebajodelminimo = codigospordebajodelminimo + "El codigo:" + Movimiento.famovimiento[conttt].referencia
                            }
                        }
                    }
                    if (codigospordebajodelminimo != "") {
                        Swal.fire(
                            'Advertencia!',
                            'Va a Facturar Por debajo del minimo permitido ' + codigospordebajodelminimo,
                            'error'
                        )
                    }

                }

            } catch (error) {
                console.log(error)
                console.log(listaarticulo)
                console.log(Movimiento)
                console.log(listaexistenciaarticulo)
                Swal.fire(
                    'Opps!',
                    'Error al buscar codigos negativos',
                    'error'
                )
                setesperando(false)
                return
            }
        }



        if (existencianegativa) {
            Swal.fire(
                'Opps!',
                'No puede facturar con existencia negativa ' + codigosnegativos,
                'error'
            )
            setesperando(false)
            return
        }
        var turno = false
        props.permisosusuario.forEach(element => {
            if (element.tipo == "manejaturno" && element.acceso[0] == "2") {
                turno = true
            }
        });
        let dt222s = JSON.parse(user)

        if (turno) {
            var turno = await obtenerturnos(dt222s.usuario.cedula)
            if (turno.length == 0) {
                Swal.fire(
                    'Opps!',
                    'No tiene un Turno Abierto',
                    'error'
                )
                setesperando(false)
                return
            }
        } else {
            if (!cajaabierta) {
                Swal.fire(
                    'Opps!',
                    'La caja esta cerrada para este dia',
                    'error'
                )
                setesperando(false)
                return
            }
        }
        if (Movimiento.famovimiento.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen productos',
                'error'
            )
            setesperando(false)
            return
        }
        if (Movimiento.cufe != "" && tipodocumento.facturapap == "electronica") {
            Swal.fire(
                'Opps!',
                'Este Documento ya fue emitido a la dian y por lo tanto no se puede editar',
                'error'
            )
            setesperando(false)
            return
        }
        var tipodcoumentoecxiste = tipodedocumentoexiste(Movimiento.concepto)
        if (!tipodcoumentoecxiste) {
            Swal.fire(
                'Opps!',
                'Este tipo de documento no existe',
                'error'
            )
            setesperando(false)
            return
        }

        if (tipodocumento.captiondoc1 != "" && tipodocumento.tipodoc == "compra" && tipodocumento.transfer == "si") {
            if (Movimiento.documento1 == "") {
                Swal.fire(
                    'Opps!',
                    'Documento 1 no puede estar en blanco',
                    'error'
                )
                setesperando(false)
                return
            }
        }
        var _Mediosdepago = Mediodepagos.map((dato) => { return dato })
        var movimientoguardado = {
            faencmovi: {},
            famovimiento: [],
            caja: [],
            remisiones: remisiones,
        }
        movimientoguardado.caja = _Mediosdepago.map((dato) => {
            var dat = {
                ['caja']: dato.caja,
                ['fecha']: dato.fecha,
                ['concepto']: dato.concepto,
                ['numero']: dato.documento,
                ['formapago']: dato.codigo,
                ['afecta']: dato.afecta,
                ['valor']: dato.valor
            }
            if (dato.esbono == "true") {
                dat.bono = codigobono
                dat.valor = valorbono
            }
            return dat
        })
        var descuento2 = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'descuento',
            ['afecta']: 'credito',
            ['valor']: Number(Movimiento.descuentoc + "")
        }
        var devolucion = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'devolucion',
            ['afecta']: 'credito',
            ['valor']: Number(Movimiento.fletes + "")
        }
        var efectivo2 = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'efectivo',
            ['afecta']: 'debito',

            ['valor']: Number(Movimiento.efectivo + "")
        }
        movimientoguardado.caja.push(descuento2)
        movimientoguardado.caja.push(devolucion)
        movimientoguardado.caja.push(efectivo2)
        movimientoguardado.faencmovi = { ...Movimiento }
        movimientoguardado.faencmovi['ndocumento'] = Number(movimientoguardado.faencmovi.ndocumento)
        movimientoguardado.faencmovi['bruto'] = bruto
        movimientoguardado.faencmovi['iva'] = Number(iva)
        movimientoguardado.faencmovi['descuento'] = Number(descuento)
        movimientoguardado.faencmovi['despiefac'] = Number(movimientoguardado.faencmovi.despiefac)
        movimientoguardado.faencmovi['retefuente'] = Number(retefuente)
        movimientoguardado.faencmovi['reteiva'] = Number(reteiva)
        movimientoguardado.faencmovi['ica'] = Number(reteica)
        movimientoguardado.faencmovi['retefte'] = Number(retefuente)
        movimientoguardado.faencmovi['impconsumo'] = Number(consumo)
        movimientoguardado.faencmovi['subtotal'] = Number(subTotal)
        movimientoguardado.faencmovi['total'] = Number(total + Movimiento.convoridoc)
        movimientoguardado.faencmovi['cuotai'] = Number(movimientoguardado.faencmovi.cuotai)
        movimientoguardado.faencmovi['ncuotasi'] = Number(movimientoguardado.faencmovi.ncuotasi)
        movimientoguardado.faencmovi['diferidasi'] = Number(movimientoguardado.faencmovi.diferidasi)
        movimientoguardado.faencmovi['tipocre'] = Number(movimientoguardado.faencmovi.tipocre)
        movimientoguardado.faencmovi['ncuotasf'] = Number(movimientoguardado.faencmovi.ncuotasf)
        movimientoguardado.faencmovi['tasa'] = Number(movimientoguardado.faencmovi.tasa)
        movimientoguardado.faencmovi['diferidas'] = Number(movimientoguardado.faencmovi.diferidas)
        movimientoguardado.faencmovi['efectivo'] = Number(movimientoguardado.faencmovi.efectivo)
        movimientoguardado.faencmovi['cheque'] = Number(movimientoguardado.faencmovi.cheque)
        movimientoguardado.faencmovi['debito'] = Number(movimientoguardado.faencmovi.debito)
        movimientoguardado.faencmovi['credito'] = Number(movimientoguardado.faencmovi.credito)
        movimientoguardado.faencmovi['descuentoc'] = Number(movimientoguardado.faencmovi.descuentoc)
        movimientoguardado.faencmovi['cree'] = Number(movimientoguardado.faencmovi.cree)
        movimientoguardado.faencmovi['fletes'] = Number(movimientoguardado.faencmovi.fletes)
        movimientoguardado.faencmovi['tcambio'] = Number(movimientoguardado.faencmovi.tcambio)
        movimientoguardado.faencmovi['dsctopp1'] = Number(movimientoguardado.faencmovi.dsctopp1)
        movimientoguardado.faencmovi['dsctopp2'] = Number(movimientoguardado.faencmovi.dsctopp2)
        movimientoguardado.faencmovi['dsctopp3'] = Number(movimientoguardado.faencmovi.dsctopp3)
        movimientoguardado.faencmovi['dsctovapp1'] = Number(movimientoguardado.faencmovi.dsctovapp1)
        movimientoguardado.faencmovi['dsctovapp2'] = Number(movimientoguardado.faencmovi.dsctovapp2)
        movimientoguardado.faencmovi['dsctovapp3'] = Number(movimientoguardado.faencmovi.dsctovapp3)
        movimientoguardado.faencmovi['convdesdoc'] = Number(movimientoguardado.faencmovi.convdesdoc)
        movimientoguardado.faencmovi['convoridoc'] = Number(movimientoguardado.faencmovi.convoridoc)
        movimientoguardado.faencmovi['poretefte'] = Number(movimientoguardado.faencmovi.poretefte)
        movimientoguardado.faencmovi['poreteiva'] = Number(movimientoguardado.faencmovi.poreteiva)
        movimientoguardado.faencmovi['porica'] = Number(movimientoguardado.faencmovi.porica)
        movimientoguardado.faencmovi['porimptoconsumo'] = Number(movimientoguardado.faencmovi.porimptoconsumo)
        movimientoguardado.faencmovi['vlr_dolar'] = Number(movimientoguardado.faencmovi.vlr_dolar)
        movimientoguardado.faencmovi['ndocprod'] = Number(movimientoguardado.faencmovi.ndocprod)
        movimientoguardado.faencmovi['ndoctrasl'] = Number(movimientoguardado.faencmovi.ndoctrasl)
        movimientoguardado.faencmovi['ndocodcped'] = Number(movimientoguardado.faencmovi.ndocodcped)
        movimientoguardado.faencmovi['plazo'] = Number(movimientoguardado.faencmovi.plazo ? movimientoguardado.faencmovi.plazo : "0")
        var datt = new Date(movimientoguardado.faencmovi['fecha'])
        datt = datt.setDate(new Date(movimientoguardado.faencmovi['fecha']).getDate() + Number(movimientoguardado.faencmovi['plazo']))
        datt = new Date(datt)
        datt = datt.toISOString().split('T')[0]
        movimientoguardado.faencmovi['fechpost'] = datt

        movimientoguardado.famovimiento = { ...Movimiento }['famovimiento']
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.map((dato, index) => {

            if (dato.referencia != "") {
                var dat = {
                    ...dato,
                    ['nrodocumento']: movimientoguardado.faencmovi.ndocumento,
                    ['concepto']: movimientoguardado.faencmovi.concepto,
                    ['item']: (index + 1),
                    ['cantidad']: Number(dato.cantidad),
                    ['precio']: Number(dato.precio),
                    ['pordescuento']: Number(dato.pordescuento),
                    ['poriva']: Number(dato.poriva),
                    ['porimptoconsumo']: Number(dato.porimptoconsumo),
                    ['subtotal']: Number(dato.subtotal),
                    ['costo']: Number(dato.costo),
                    ['preivainc']: Number(dato.preivainc),
                    ['canprod']: Number(dato.canprod),
                    ['vlr_desc']: Number(dato.vlr_desc),
                    ['vlr_iva']: Number(dato.vlr_iva),
                    ['vlr_base_iva']: Number(dato.vlr_base_iva),
                    ['costodolar']: Number(dato.costodolar),
                    ['tasa']: Number(dato.tasa),
                    ['vlrimptoconsumo']: Number(dato.vlrimptoconsumo),
                    ['poretefte']: Number(dato.poretefte),
                    ['vlretefte']: Number(dato.vlretefte),
                    ['porica']: Number(dato.porica),
                    ['vlrica']: Number(dato.vlrica),
                    ['poreteiva']: Number(dato.poreteiva),
                    ['vlreteiva']: Number(dato.vlreteiva),
                    ['cantdig']: Number(dato.cantdig),
                    ['fecha']: movimientoguardado.faencmovi.fecha,
                    ['horafecha']: movimientoguardado.faencmovi.horafecha,
                }
                return dat

            }
        })
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.filter(p => p != undefined)
        console.log(Movimiento)
        console.log(modelomovimiento)
        var bodegg = bodegatranslado
        if (bodegg == "") {
            bodegg = "-"
        }
        delete movimientoguardado.faencmovi.famovimiento;

        // let venta = {
        //     documentoCliente: documentoCliente,
        //     nombreCliente: nombreCliente,
        //     tipoDocumento: tipoDocumento,
        //     idUsuario: JSON.parse(user).idUsuario,
        //     subTotal: parseFloat(subTotal),
        //     igv: parseFloat(igv),
        //     total: parseFloat(total),
        //     listaProductos: productos
        // }
        let dt = JSON.parse(user)
        var verificado = verificar(movimientoguardado)
        if (!verificado) {
            Swal.fire(
                'Opps!',
                'Datos sin llenar',
                'error'
            )
            setesperando(false)
            return
        }
        var conss = nuevo
        if (nuevo && movimientoguardado.faencmovi.ndocumento != 0) {
            conss = false
        }
        if (!nuevo && movimientoguardado.faencmovi.ndocumento == 0) {
            conss = true
        }
        if (true) {
            var totalderespaldo = calculartotalesmovimiento(movimientoguardado.famovimiento)
            if (true) {
                if (totalderespaldo.bruto != movimientoguardado.faencmovi.bruto) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del Bruto no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }

                if (totalderespaldo.iva != movimientoguardado.faencmovi.iva) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del iva no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.descuento != movimientoguardado.faencmovi.descuento) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del descuento no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.retefuente != movimientoguardado.faencmovi.retefuente) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del retefuente no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.reteiva != movimientoguardado.faencmovi.reteiva) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del reteiva no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.reteica != movimientoguardado.faencmovi.ica) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del reteica no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.consumo != movimientoguardado.faencmovi.impconsumo) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del impconsumo no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if (totalderespaldo.subtotal != movimientoguardado.faencmovi.subtotal) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del subtotal no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
                if ((totalderespaldo.total + movimientoguardado.faencmovi.convoridoc) != movimientoguardado.faencmovi.total) {
                    Swal.fire(
                        'Opps!',
                        'El recuento del total no coincide',
                        'error'
                    )
                    setesperando(false)
                    return
                }
            }


        }
        var contcajj = 0
        for (let i = 0; i < movimientoguardado.caja.length; i++) {
            const element = movimientoguardado.caja[i];
            if (element.valor == 0) {
                contcajj = contcajj + 1
            }

        }
        if (contcajj == movimientoguardado.caja.length && (tipodocumento.afecaj == 'suma' || tipodocumento.afecaj == 'resta')) {
            Swal.fire(
                'Opps!',
                'Todos los valores de caja estan en 0',
                'error'
            )
            setesperando(false)
            return
        }

        if (tipodocumento.busplu == 'si') {
            var nuevmodtemp = { ...Movimiento }
            nuevmodtemp.famovimiento = nuevmodtemp.famovimiento.filter(p => p.referencia != "")
            setMovimiento(nuevmodtemp)
        }
        movimientoguardado.faencmovi['modificadopor'] = dt.usuario.cedula
        if (movimientoguardado.faencmovi.ndocumento == 0) {
            movimientoguardado.faencmovi['nomusuario'] = dataUser.usuario.nombre
        }
        document.getElementById("campoguardardemovimiento").blur()
        //identificador_software esto es la variable del costo
        const api = fetch("" + props.servidor + "/Movimientoapi?llave=" + dt.usuario.llaveempresa + "&nuevo=" + conss + "&bodegg=" + bodegg + "&usuario=" + dt.usuario.cedula + "&tipocosto=" + dt.empresa.config.identificador_software + "&llaveusuario=" + dt.usuario.cargo + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(movimientoguardado)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {
                // reestablecer();
                //informetransladoo
                document.getElementById("campoguardardemovimiento").blur()

                if (tipodocumento.modidoc == "carta 3" || tipodocumento.modidoc == "carta 8") {
                    await buscarsaldocliente(dataJson.movimiento.faencmovi.cliente, 'cliente', dataJson.movimiento.faencmovi.fecha, dataJson.movimiento.faencmovi.ndocumento)
                    // buscarsaldoclientehoy(data.movimiento.faencmovi.cliente, 'cliente', data.movimiento.faencmovi.fecha)
                }

                var data = dataJson;
                setcodigobono("")
                setvalorbono(0)
                if (tipodocumento.tipodoc == "translado") {
                    var tipodocc2 = await buscarcoincidenciastipodocs(tipodocumento.tdpro)
                    var sucursal2 = await buscarcoincidenciassucursal(tipodocumento.compañia)
                    var sucursal22 = await buscarcoincidenciassucursal(tipodocc2.compañia)
                    var itttt = ""
                    data.movimiento.famovimiento.forEach(element => {
                        itttt = itttt + element.cantidad + " " + element.descrip + ","
                    });
                    var mensajestring = ["La sucursal " + sucursal2.descripcion + " te envio : " + itttt, sucursal22.descripcion]
                    informardetranslado(mensajestring)
                }
                var _movv = data.movimiento.faencmovi
                _movv.famovimiento = data.movimiento.famovimiento
                setMovimiento(_movv)

                if (data.movimiento.caja) {
                    setvalorescaja(data.movimiento.caja.filter(p => p.valor != 0))

                }
                // if (nuevo) {

                if (true) {
                    if (tipodocumento.tipodoc == "compra" && nuevo) {
                        try {

                            let dt = JSON.parse(user);
                            const url = props.servidor + '/Inarticulosapi/actualizarcostoprecio/?llave=' + dt.usuario.llaveempresa;

                            const requestData = {
                                // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
                            };

                            const requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json;charset=utf-8',
                                },
                                body: JSON.stringify(listaarticulocostoprecio),
                            };

                            const response = await fetch(url, requestOptions);

                            if (response.ok) {
                                let data2 = await response.json()


                            }
                        } catch (error) {

                        }
                    }
                    if (tipodocumento.facturapap == "electronica") {
                        try {


                            let dt = JSON.parse(user)
                            let response = await fetch(props.servidor + '/EnvioApi/' + data.movimiento.faencmovi.concepto + '/' + (data.movimiento.faencmovi.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + tipodocumento.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

                            if (response.ok) {
                                let data2 = await response.json()
                                // reiniciar()
                                var _movv = data2.movimiento.faencmovi
                                _movv.famovimiento = data2.movimiento.famovimiento
                                setMovimiento(_movv)

                                Swal.fire({
                                    title: data.movimiento.faencmovi.concepto + ' Creado!',
                                    text: 'Número de ' + data.movimiento.faencmovi.concepto + ' : ' + data.movimiento.faencmovi.ndocumento,
                                    icon: 'success',
                                    timer: 1000,
                                    showConfirmButton: true
                                }).then((result) => {
                                    if (result.isDismissed || result.isConfirmed) {
                                        if (tipodocumento.busplu == 'si') {
                                            document.getElementById("movimientopdfventacompra").click()
                                        } else {
                                            setprevisualizar(true)

                                        }
                                    }
                                });
                                setesperando(false)

                                // reiniciar()

                            } else {
                                try {
                                    var ee = await response.json()
                                    Swal.fire(
                                        'Opps!',
                                        'Se creo el ' + Movimiento.concepto + ' con numero: ' + data.movimiento.concepto + ' pero no se logro enviar a la Dian',
                                        'error'
                                    )
                                    guardarlogdeerrores(ee.mensaje)
                                    setesperando(false)
                                    // reiniciar()
                                } catch (error) {
                                    Swal.fire(
                                        'Opps!',
                                        'Se creo el ' + Movimiento.concepto + ' con numero:' + data.movimiento.concepto + ' pero no se logro enviar a la Dian, error de conexion',
                                        'error'
                                    )
                                    setesperando(false)
                                    // reiniciar()
                                }
                            }
                        } catch (error) {
                            console.log(error)
                            try {
                                Swal.fire({
                                    title: 'Opps!',
                                    text: 'Se creo el ' + data.movimiento.faencmovi.concepto + ' pero no se logro enviar a la Dian ',
                                    icon: 'error',
                                    timer: 1000,
                                    showConfirmButton: true
                                }).then((result) => {
                                    if (result.isDismissed || result.isConfirmed) {
                                        if (tipodocumento.busplu == 'si') {
                                            document.getElementById("movimientopdfventacompra").click()
                                        }
                                    }
                                });

                            } catch (error2) {

                            }
                            setesperando(false)
                            // reiniciar()
                        }

                    } else {
                        Swal.fire({
                            title: data.movimiento.faencmovi.concepto + ' Creado!',
                            text: 'Número de ' + data.movimiento.faencmovi.concepto + ' : ' + data.movimiento.faencmovi.ndocumento,
                            icon: 'success',
                            timer: 1000,
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isDismissed || result.isConfirmed) {
                                if (tipodocumento.busplu == 'si') {
                                    document.getElementById("movimientopdfventacompra").click()
                                }
                            }
                        });
                        // reiniciar()

                        setesperando(false)

                    }
                } else {
                    Swal.fire(
                        '' + data.movimiento.concepto + ' Editado!',
                        'Numero de ' + data.movimiento.concepto + ' : ' + data.movimiento.ndocumento,
                        'success'
                    )
                    // reiniciar()

                    setesperando(false)
                }
                let dt2 = JSON.parse(permisos)
                var aaa = dt2.FACTURA.editar
                if (!dt2.FACTURA.editar) {
                    var nofacturaruvt = true
                    props.permisosusuario.forEach(element => {
                        if (element.tipo == "tipodocumentoeditable" && element.acceso[0] == "2" && element.tipodoc == tipodocumento.codigo) {
                            nofacturaruvt = false
                        }
                    });
                    if (nofacturaruvt) {
                        seteditar(false)
                        setnuevo(false)
                    } else {
                        seteditar(true)
                        setnuevo(false)
                    }
                } else {
                    seteditar(true)
                    setnuevo(false)
                }
            }).catch(async (error) => {
                setcodigobono("")
                setvalorbono(0)
                try {
                    var ee = await error.json()
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + ee.mensaje,
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", ee.mensaje)
                    guardarlogdeerrores(ee.mensaje)
                    setesperando(false)
                } catch (error) {
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + 'Error de conexion',
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", error)
                    guardarlogdeerrores('Error de conexion')
                    setesperando(false)
                }

            })

    }

    const ponerarticuloplu = () => {

        return true
    }
    const grillaproductosmov = (
        <Row>
            <Col sm={12}>
                <Card style={{ borderColor: '#188BAD' }}>
                    <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                        {/* {(dataUser.empresa.config.especialidad != "drogueria")
                                    ? "Productos" + "  Existencia: " + existenciaarticulounidad :
                                    (Movimiento.famovimiento.length >= 1) ?
                                        "Productos" + "  Existencia Caja: " + existenciaarticulocaja
                                        + " -  Blister: " + existenciaarticuloblister +
                                        " - Unidad: " + existenciaarticulounidad : "Productos"
                                } */}
                        <Row>
                            <Col sm={2}> Productos</Col>
                            {
                                tipodocumento.busplu == "si" &&
                                <Col sm={3}>
                                    <FormGroup>

                                        <Input
                                            type='checkbox'
                                            checked={pararencantidad}
                                            value={pararencantidad}
                                            onClick={(e) => {
                                                setpararencantidad(e.target.checked)
                                            }}
                                        />
                                        <Label check>Cambiar Cantidad</Label>

                                    </FormGroup>
                                </Col>

                            }
                        </Row>
                    </CardHeader>
                    <CardBody >

                        <div className="table-responsive" style={{ height: '300px', overflow: 'auto' }}>
                            <Table striped  >
                                <thead>
                                    <tr style={{ zoom: '80%' }}>
                                        <th >I</th>
                                        <th>Producto</th>
                                        <th >Descripcion</th>
                                        {tipodocumento.tdpro1 == "si" && <th >Exis</th>}
                                        <th >Bodega</th>
                                        {(dataUser.empresa.config.especialidad == "drogueria") && <th >Presentacion</th>}

                                        {/* <th style={{
                                            position: 'absolute',
                                            width: '1px',
                                            height: '1px',
                                            border: 'none',
                                            padding: 0,
                                            margin: 0,
                                            opacity: 0,
                                            pointerEvents: 'none',
                                        }} >.</th> */}
                                        <th>Cantidad</th>
                                        <th >{tipodocumento.tipodoc == "compra" ? "Costo" : "Precio"}</th>

                                        {(tipodocumento.descsn == "si") && <th >Desc</th>}
                                        {(tipodocumento.ivasn == "si") && <th >Iva</th>}

                                        {(tipodocumento.imptosn == "si") && <th >Cons</th>}
                                        {(tipodocumento.modidocenc == "si") && <th >Cons</th>}

                                        {(tipodocumento.rete == "si") && <th>rfte</th>}
                                        {(tipodocumento.ccosto == "si") && <th >C.C</th>}
                                        {(tipodocumento.lotes == "si") && <th >Lote</th>}
                                        {(tipodocumento.lotes == "si") && <th >F. Venc</th>}

                                        <th >Subtotal</th>
                                        {(tipodocumento.tipodoc == "compra") && <th >Debito</th>}
                                        {(tipodocumento.tipodoc == "compra") && <th >Credito</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (Movimiento.famovimiento.length < 1) ? (
                                            <tr>
                                                <td colSpan="12">Sin productos</td>
                                            </tr>
                                        ) :
                                            (
                                                Movimiento.famovimiento.map((item, index) => (
                                                    <tr key={index} style={{ zoom: '80%' }}>

                                                        <td>
                                                            <a href="#" onFocus={() => { setfila(index) }} style={{ fontSize: '11px' }} id={"movimiento" + "item" + index} className="fas fa-pen-alt" onClick={() => {
                                                                setabriropcionespuntoventa(true)
                                                                // eliminarProducto(index)
                                                            }}>{index + 1}</ a >
                                                        </td>

                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                            <Input tabIndex={0} onChange={handleChangegrillafaencmovi} id={"movimiento" + "referencia" + index} onFocus={(e) => {
                                                                setfila(index)
                                                                // setacabodeseleccionararticulo(false)
                                                            }} style={{ width: '100px' }} tabla='famovimiento' className="inputmovgrilla" name='referencia' value={item.referencia}



                                                                onKeyUp={(e) => {
                                                                    if (tipodocumento.busplu == "si") {
                                                                        if (e.target.value == "" || e.target.value == "?") {
                                                                            if (e.key == "Enter" || e.target.value == "?") {
                                                                                // if (tipodocumento.puntoventa == 'si' && e.target.value != '') {
                                                                                if (false) {
                                                                                    e.target.blur()

                                                                                } else {
                                                                                    if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                                        buscartodasarticulos('');
                                                                                    }

                                                                                    setVerModalarticulos(true)
                                                                                }


                                                                            }
                                                                        }
                                                                    } else {
                                                                        if (e.key == "Enter" || e.target.value == "?") {
                                                                            // if (tipodocumento.puntoventa == 'si' && e.target.value != '') {
                                                                            if (false) {
                                                                                e.target.blur()

                                                                            } else {
                                                                                if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                                    buscartodasarticulos('');
                                                                                }

                                                                                setVerModalarticulos(true)
                                                                            }


                                                                        }
                                                                    }


                                                                    // if (e.key == "Tab") {
                                                                    //     e.target.blur()
                                                                    //     handlerchangegrillaarticulo(e)

                                                                    // }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (e.relatedTarget == null && tipodocumento.puntoventa != 'si' && tipodocumento.busplu != "si") {
                                                                        handlerchangegrillaarticulo(e)
                                                                    }


                                                                }}
                                                                onKeyDown={(e) => {


                                                                    if (e.key == "Tab" && tipodocumento.puntoventa != 'si') {


                                                                    }
                                                                    if (e.key == "Tab") {

                                                                        handlerchangegrillaarticulo(e)
                                                                        if (tipodocumento.busplu == "si") {
                                                                            e.preventDefault();
                                                                            try {
                                                                                if (!pararencantidad) {
                                                                                    if (document.getElementById("movimientoitem" + (fila + 1))) {
                                                                                        document.getElementById("movimientoitem" + (fila + 1)).focus()
                                                                                    }
                                                                                } else {
                                                                                    if (document.getElementById("movimientocantidad" + (fila))) {
                                                                                        var ddd = document.getElementById("movimientocantidad" + (fila))
                                                                                        document.getElementById("movimientocantidad" + (fila)).focus()

                                                                                    }
                                                                                }
                                                                            } catch (error) {

                                                                            }
                                                                        }
                                                                    }
                                                                    if (e.key == "Enter" && e.target.value != "") {
                                                                        handlerchangegrillaarticulo(e)
                                                                    }

                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                            <abbr title={item.descrip + " " + item.desadicional}><Input onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "descrip" + index} name='descrip' style={{ width: tipodocumento.puntoventa != "si" ? '350px' : '100px' }} value={item.descrip + " " + item.desadicional} /></abbr>
                                                        </td>
                                                        {tipodocumento.tdpro1 == "si" && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onFocus={() => { setfila(index) }} style={{ width: '100px' }} tabla='famovimiento' disabled className="inputmovgrilla" value={listaexistenciaarticulo[index]} /></td>}


                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                            <abbr title={item.bodega + " " + item.nombrebodega}><Input onChange={handleChangegrillafaencmovi} id={"movimiento" + "bodega" + index} disabled={(tipodocumento.tbodegafija == "si" ? true : false)} style={{ width: '40px' }} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" name='bodega' value={item.bodega} onKeyUp={(item.referencia != "") ? (e) => {
                                                                if (e.key == "Enter" || e.target.value == "?") {
                                                                    e.preventDefault()
                                                                    buscartodasbodegas('');
                                                                    setbusquedabodegas(bodegas)
                                                                    setVerModalbodegas(true)

                                                                }
                                                                if (e.key == "Tab") {
                                                                    e.target.blur()

                                                                    handlerchangegrillabodega(e)
                                                                }
                                                            } : ingresecantidadmensaje} /></abbr>
                                                        </td>


                                                        {(dataUser.empresa.config.especialidad == "drogueria") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                            <Input onFocus={(e) => {
                                                                setfila(index)
                                                            }}
                                                                // disabled={tipodocumento.tipodoc == "compra"}
                                                                onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" value={item.presentacion} type="select" style={{ fontSize: '13px' }} name="presentacion"  >
                                                                {(listaarticulo[index]) && (listaarticulo[index].blisterxcajanombre != "") && <option value="1">{listaarticulo[index].blisterxcajanombre}</option>}
                                                                {(listaarticulo[index]) && (listaarticulo[index].unidadxblisternombre != "") && <option value="2">{listaarticulo[index].unidadxblisternombre}</option>}
                                                                <option value="3">Unidad</option>
                                                            </Input></td>}
                                                        {/* <td>
                                                            <a href="#" onFocus={() => { setfila(index) }} style={{
                                                                position: 'absolute',
                                                                width: '1px',
                                                                height: '1px',
                                                                border: 'none',
                                                                padding: 0,
                                                                margin: 0,
                                                                opacity: 0,
                                                                pointerEvents: 'none',
                                                            }} id={"movimientoalgoparaelfoco" + index} onClick={() => {
                                                                setabriropcionespuntoventa(true)
                                                                // eliminarProducto(index)
                                                            }}>{index + 1}</ a >
                                                        </td> */}
                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onFocus={(e) => {
                                                            setfila(index)
                                                            e.target.value = (item.cantidad + "").replace(/\,/g, '')
                                                            setpuntodeventacambiarcantidad(false)
                                                            e.target.select();
                                                        }}
                                                            onKeyUp={(e) => {
                                                                try {
                                                                    if (e.key == " " && tipodocumento.busplu == "si") {
                                                                        if (document.getElementById("movimientoreferencia" + (fila + 1))) {
                                                                            document.getElementById("movimientoreferencia" + (fila + 1)).focus()
                                                                        }
                                                                    }
                                                                } catch (error) {

                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value = fm2.from(Number(item.cantidad))
                                                            }} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" id={"movimientocantidad" + index} name='cantidad' value={item.cantidad} /></td>
                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                            <abbr title={fm2.from(Number(item.precio))}>
                                                                <Input onFocus={(e) => {
                                                                    setfila(index)
                                                                    console.log(item.precio)
                                                                    e.target.value = (item.precio + "").replace(/\,/g, '')
                                                                    console.log(e.target.value + "focus")
                                                                    if (e.relatedTarget != null) {
                                                                        e.target.setAttribute('cambio', 'no')

                                                                    }
                                                                    setpuntodeventacambiarprecio(false)
                                                                }}
                                                                    onBlur={(e) => {
                                                                        if (e.relatedTarget == null) {
                                                                            var dffff = e.target.getAttribute('cambio')
                                                                            if (tipodocumento.ivaincluido == "si" && (e.target.getAttribute('cambio') == 'si' || e.target.getAttribute('abrilistadeprecio') == 'si')) {
                                                                                ivaincluido(e)

                                                                            } else {
                                                                                if (tipodocumento.cambioiva == "si" && (e.target.getAttribute('cambio') == 'si' || e.target.getAttribute('abrilistadeprecio') == 'si')) {
                                                                                    impoconsumoincluido(e)

                                                                                }
                                                                            }

                                                                            e.target.setAttribute('abrilistadeprecio', 'no')

                                                                            e.target.value = fm2.from(Number(item.precio))
                                                                        } else {
                                                                            if (e.relatedTarget.name == 'poriva' || e.relatedTarget.name == 'pordescuento' || e.relatedTarget.name == 'referencia') {
                                                                                var dffff = e.target.getAttribute('cambio')
                                                                                if (tipodocumento.ivaincluido == "si" && (e.target.getAttribute('cambio') == 'si' || e.target.getAttribute('abrilistadeprecio') == 'si')) {
                                                                                    ivaincluido(e)

                                                                                } else {
                                                                                    if (tipodocumento.cambioiva == "si" && (e.target.getAttribute('cambio') == 'si' || e.target.getAttribute('abrilistadeprecio') == 'si')) {
                                                                                        impoconsumoincluido(e)

                                                                                    }
                                                                                }

                                                                                e.target.setAttribute('abrilistadeprecio', 'no')

                                                                                e.target.value = fm2.from(Number(item.precio))
                                                                            }
                                                                        }
                                                                    }}
                                                                    onKeyUp={async (e) => {
                                                                        if (e.key == "+") {
                                                                            if (document.getElementById("agregaritemmovimiento")) {
                                                                                if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                                    document.getElementById("agregaritemmovimiento").click()

                                                                                }
                                                                            }
                                                                        }
                                                                        //precio grilla
                                                                        var ee = listaarticulo
                                                                        if ((e.key == "Enter" || e.target.value == "?") && tipodocumento.tipodoc != "compra") {
                                                                            e.preventDefault()
                                                                            var puedecamviarlistadeprecio = true
                                                                            var clavepermiso = ""
                                                                            props.permisosusuario.forEach(element => {
                                                                                if (element.tipo == "nodejarcambiaeprecio" && element.acceso[0] == "1") {
                                                                                    puedecamviarlistadeprecio = false
                                                                                    clavepermiso = element.clave
                                                                                }
                                                                            });

                                                                            if (puedecamviarlistadeprecio) {
                                                                                setbusquedalistaprecioarticulo(ee[fila].inarticuloslistaprecio)
                                                                                setVerModallistaprecioarticulo(true)
                                                                                e.target.setAttribute('abrilistadeprecio', 'si')
                                                                            } else {
                                                                                props.permisosusuario.forEach(element => {
                                                                                    if (element.tipo == "nocambiarpreciocambiarlistadeprecio" && element.acceso[0] == "1") {
                                                                                        clavepermiso = ""
                                                                                    }
                                                                                });
                                                                                if (clavepermiso != "" && clavepermiso != null) {
                                                                                    var claveigual = await clavepermisosdig(clavepermiso, "Digite Clave para Cambiar Precio")
                                                                                    if (!claveigual) {
                                                                                        Swal.fire(
                                                                                            'Opps!',
                                                                                            'Cancelado',
                                                                                            'error'
                                                                                        )
                                                                                        return
                                                                                    } else {
                                                                                        setbusquedalistaprecioarticulo(ee[fila].inarticuloslistaprecio)
                                                                                        setVerModallistaprecioarticulo(true)
                                                                                        e.target.setAttribute('abrilistadeprecio', 'si')
                                                                                    }

                                                                                } else {
                                                                                    setbusquedalistaprecioarticulo(ee[fila].inarticuloslistaprecio)
                                                                                    setVerModallistaprecioarticulo(true)
                                                                                    e.target.setAttribute('abrilistadeprecio', 'si')
                                                                                }
                                                                            }
                                                                        }
                                                                        if (e.key == "Enter" && tipodocumento.tipodoc == "compra") {
                                                                            if (tipodocumento.rentab == "si") {
                                                                                try {
                                                                                    var _listaarticulocostoprecio = listaarticulocostoprecio.map((dat) => { return dat })
                                                                                    var aa = listaarticulocostoprecio[fila]
                                                                                    var exis = await buscarexistencia2(Movimiento.famovimiento[fila].referencia, Movimiento.famovimiento[fila].bodega)
                                                                                    var costonuevo = ((exis * aa.preccost) + (Number(Movimiento.famovimiento[fila].cantidad + "") * Number(Movimiento.famovimiento[fila].precio + ""))) / (exis + Number(Movimiento.famovimiento[fila].cantidad + ""))
                                                                                    _listaarticulocostoprecio[fila].costonuevo = Number(costonuevo.toFixed(2))
                                                                                    aa.inarticuloslistaprecio = aa.inarticuloslistaprecio.map((dato, index) => {
                                                                                        var fff = costonuevo * (1 + (Number(dato.rentabilidad + "") / 100)) * (1 + (Number(Movimiento.famovimiento[fila].poriva + "") / 100))
                                                                                        var dat = {
                                                                                            ...dato,
                                                                                            ['precionuevo']: Number(fff.toFixed(2))
                                                                                        }
                                                                                        return dat



                                                                                    })
                                                                                    _listaarticulocostoprecio[fila] = aa
                                                                                    setlistaarticulocostoprecio(_listaarticulocostoprecio)
                                                                                    setvercambiodecostoprecio(true)
                                                                                } catch (error) {

                                                                                }
                                                                            }


                                                                        }
                                                                        try {
                                                                            if (e.key == " " && tipodocumento.busplu == "si") {
                                                                                if (document.getElementById("movimientoreferencia" + (fila + 1))) {
                                                                                    document.getElementById("movimientoreferencia" + (fila + 1)).focus()
                                                                                }
                                                                            }
                                                                        } catch (error) {

                                                                        }
                                                                    }} cambio="no" id={"movimiento" + "precio" + index} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" name='precio' style={{ width: '150px' }} value={item.precio} /></abbr>
                                                        </td>
                                                        {(tipodocumento.descsn == "si") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onKeyDown={(e) => {
                                                            if (e.key == "+") {
                                                                if (document.getElementById("agregaritemmovimiento")) {
                                                                    if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                        document.getElementById("agregaritemmovimiento").click()

                                                                    }
                                                                }
                                                            }
                                                        }} onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='pordescuento' value={item.pordescuento} /></td>}

                                                        {(tipodocumento.ivasn == "si") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onKeyDown={(e) => {
                                                            if (e.key == "+") {
                                                                if (document.getElementById("agregaritemmovimiento")) {
                                                                    if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                        document.getElementById("agregaritemmovimiento").click()

                                                                    }
                                                                }
                                                            }
                                                        }} onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" style={{ width: '50px' }} name='poriva' value={item.poriva} /></td>}
                                                        {(tipodocumento.imptosn == "si") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onKeyDown={(e) => {
                                                            if (e.key == "+") {
                                                                if (document.getElementById("agregaritemmovimiento")) {
                                                                    if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                        document.getElementById("agregaritemmovimiento").click()

                                                                    }
                                                                }
                                                            }
                                                        }} onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='porimptoconsumo' style={{ width: '50px' }} value={item.porimptoconsumo} /></td>}

                                                        {(tipodocumento.modidocenc == "si") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onKeyDown={(e) => {
                                                            if (e.key == "+") {
                                                                if (document.getElementById("agregaritemmovimiento")) {
                                                                    if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                        document.getElementById("agregaritemmovimiento").click()

                                                                    }
                                                                }
                                                            }
                                                        }} onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='vlrimptoconsumo' style={{ width: '100px' }} value={item.vlrimptoconsumo} /></td>}


                                                        {(tipodocumento.rete == "si") && <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onKeyDown={(e) => {
                                                            if (e.key == "+") {
                                                                if (document.getElementById("agregaritemmovimiento")) {
                                                                    if (listaarticulo[fila] && Movimiento.famovimiento[fila].bodega != "" && Movimiento.famovimiento[fila].precio != "") {
                                                                        document.getElementById("agregaritemmovimiento").click()

                                                                    }
                                                                }
                                                            }
                                                        }} onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='poretefte' style={{ width: '50px' }} value={item.poretefte} /></td>}
                                                        {(tipodocumento.ccosto == "si") &&
                                                            <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                                <abbr title={item.ccosto}><Input disabled={(tipodocumento.tcostofijo == "si") ? true : false} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" style={{ width: '50px' }} name='ccosto' value={item.ccosto} onKeyUp={(item.referencia != "") ? (e) => {
                                                                    if (e.key == "Enter" || e.target.value == "?") {
                                                                        e.preventDefault()
                                                                        buscartodasccostos('');
                                                                        setbusquedaccostos(ccostos)
                                                                        setVerModalccostos(true)
                                                                    }
                                                                } : ingresecantidadmensaje} /></abbr>
                                                            </td>}

                                                        {(tipodocumento.lotes == "si") &&
                                                            <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                                <abbr title={item.lote}><Input onKeyUp={async (e) => {
                                                                    if (e.key == "Enter") {
                                                                        var lotes = await buscarlotesporarticuloseleccionado(e)
                                                                        setlotesporarticulo(lotes)
                                                                        setabrirlotes(true)
                                                                    }
                                                                }} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" style={{ width: '150px' }} name='lote' value={item.lote}
                                                                /></abbr>
                                                            </td>}
                                                        {(tipodocumento.lotes == "si") &&
                                                            <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                                <abbr title={item.fechavenclote}><Input disabled={tipodocumento.tipodoc != "compra" && tipodocumento.tipodoc != "remision"} type="date" onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" style={{ width: '150px' }} name='fechavenclote' value={item.fechavenclote}
                                                                /></abbr>
                                                            </td>}
                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' disabled className="inputmovgrilla" name='referencia' style={{ width: '150px' }} value={fm.from(item.subtotal)} /></td>
                                                        {(tipodocumento.tipodoc == "compra") &&
                                                            <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                                <abbr >  <Autocomplete
                                                                    onFocus={(e) => { setfila(index) }}
                                                                    onChange={(e, value) => { handleChangegrillafaencmovi2(e, value, 'cuentadebito', 'codcuenta') }}

                                                                    disablePortal
                                                                    options={Plandecuentass}
                                                                    getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                                    value={Plandecuentass.filter(p => p.codcuenta == item.cuentadebito)[0]}
                                                                    renderInput={(params) => <TextField {...params} size="small" style={{ width: '100px', borderRadius: '4px' }} />}
                                                                    maxHeight={200}
                                                                /></abbr>
                                                            </td>}
                                                        {(tipodocumento.tipodoc == "compra") &&
                                                            <td style={{ padding: '2px 5px', lineHeight: '1' }}>
                                                                <abbr >  <Autocomplete
                                                                    onFocus={(e) => { setfila(index) }}
                                                                    onChange={(e, value) => { handleChangegrillafaencmovi2(e, value, 'cuentacredito', 'codcuenta') }}

                                                                    disablePortal
                                                                    options={Plandecuentass}
                                                                    getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                                    value={Plandecuentass.filter(p => p.codcuenta == item.cuentacredito)[0]}
                                                                    renderInput={(params) => <TextField {...params} size="small" style={{ width: '100px', borderRadius: '4px' }} />}
                                                                    maxHeight={200}
                                                                /></abbr>
                                                            </td>}
                                                        <td style={{ padding: '2px 5px', lineHeight: '1' }}>  {((tipodocumento.tipodoc == "devoluciones" && tipodocumento.mdevolucio == "si") || (tipodocumento.tipodoc == "devoluciones compra" && tipodocumento.mdevolucio == "si") || (tipodocumento.tipodoc == "nota debito" && tipodocumento.mdevolucio == "si") || (tipodocumento.noimpdupl == "si" && tipodocumento.tipodoc == "factura")) ? false : <Col sm={3}>
                                                            <FormGroup>
                                                                <Button id={"agregaritemmovimiento"} style={{ fontSize: '12px', color: 'black', backgroundColor: 'white', border: 'none' }} block
                                                                    onClick={() => {
                                                                        if (Movimiento.concepto != "" && tipodocumento.codigo != "") {
                                                                            var existearticul = Movimiento.famovimiento.filter((dato) => {
                                                                                if (dato.referencia == "") {
                                                                                    return dato
                                                                                }
                                                                            })
                                                                            if (existearticul.length == 0) {
                                                                                var _Movimiento = { ...Movimiento }
                                                                                var _datos = { ...Movimiento }['famovimiento']
                                                                                var bodega = ""
                                                                                var ccosto = ""
                                                                                var presentacion = "3"
                                                                                var retefuente = 0
                                                                                if (tipodocumento.tbodegasn == "si") {
                                                                                    bodega = tipodocumento.tbodega
                                                                                }
                                                                                if (tipodocumento.ccosto == "si") {
                                                                                    ccosto = tipodocumento.centrocosto
                                                                                }
                                                                                if (tipodocumento.tipodoc == "compra") {
                                                                                    presentacion = "1"
                                                                                }

                                                                                var _nuevosdatos = _datos.concat(
                                                                                    {
                                                                                        item: "",
                                                                                        referencia: "",
                                                                                        descrip: "",
                                                                                        bodega: bodega,
                                                                                        cantidad: "",
                                                                                        precio: "",
                                                                                        pordescuento: "",
                                                                                        poriva: "",
                                                                                        porimptoconsumo: "",
                                                                                        subtotal: "",
                                                                                        compañia: "",
                                                                                        concepto: "",
                                                                                        nrodocumento: "",
                                                                                        costo: "",
                                                                                        desadicional: "",
                                                                                        unimed: "",
                                                                                        ccosto: ccosto,
                                                                                        fecha: "",
                                                                                        preivainc: "",
                                                                                        codprod: "",
                                                                                        canprod: "",
                                                                                        horafecha: "",
                                                                                        tipoitem: "",
                                                                                        tercero: "",
                                                                                        cliente: "",
                                                                                        vlr_desc: "",
                                                                                        vlr_iva: "",
                                                                                        vlr_base_iva: "",
                                                                                        costodolar: "",
                                                                                        tasa: "",
                                                                                        vlrimptoconsumo: "",
                                                                                        poretefte: retefuente,
                                                                                        vlretefte: "",
                                                                                        porica: "",
                                                                                        vlrica: "",
                                                                                        poreteiva: "",
                                                                                        vlreteiva: "",
                                                                                        cantdig: "",
                                                                                        presentacion: presentacion,
                                                                                        cuentadebito: "",
                                                                                        cuentacredito: ""


                                                                                    }

                                                                                )

                                                                                _Movimiento['famovimiento'] = _nuevosdatos
                                                                                setMovimiento(_Movimiento)

                                                                            }
                                                                        } else {
                                                                            Swal.fire(
                                                                                'Opps!',
                                                                                'No ha seleccionado un Tipo de Documento',
                                                                                'error'
                                                                            )
                                                                        }
                                                                    }} >
                                                                    <i style={{ fontSize: '12px', color: 'blue' }} className="fa fa-plus"></i></Button>
                                                            </FormGroup>
                                                        </Col>}</td>

                                                        <Modal size="lg" isOpen={abrirdescripcionadicional}>
                                                            <ModalHeader>
                                                                Descripcion Adicional
                                                            </ModalHeader>
                                                            <ModalBody>
                                                                <Input sm="lg" onChange={handleChangegrillafaencmovi} value={Movimiento.famovimiento[fila] && Movimiento.famovimiento[fila].desadicional} tabla='famovimiento' type="textarea" name="desadicional" />
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button size="sm" color="primary" onClick={() => { setabrirdescripcionadicional(false) }}>Listo</Button>
                                                            </ModalFooter>
                                                        </Modal>
                                                    </tr>
                                                ))
                                            )


                                    }
                                </tbody>
                            </Table>

                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
    return (
        <Row


        >
            <Col id="pruebaidparaqr" sm={12} >
                <Card style={{ borderColor: '#fff' }}>
                    <CardBody>
                        <Row>
                            <Col sm={2}>
                                <Button id="movimientobotonnuevoventacompra" style={{ fontSize: '12px', color: 'black' }} color={nuevo ? "warning" : "success"} block onClick={() => {
                                    if (true) {
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setlistaarticulo([])
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setestoybuscando(false)
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setremisiones([])
                                        setremisionesfactura([])
                                        if (Movimiento.famovimiento.length == 0) {
                                            obtenertipodocumentomasrepetido()
                                        }
                                    }
                                    if (true) {
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setlistaarticulo([])
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setestoybuscando(false)
                                        setnuevo(true)
                                        seteditar(false)
                                        setMovimiento(modelomovimiento)
                                        setSubTotal(0)
                                        setbruto(0)
                                        setdescuento(0)
                                        setconsumo(0)
                                        setiva(0)
                                        setretefuente(0)
                                        setreteica(0)
                                        setreteiva(0)
                                        setTotal(0)
                                        setexistenciaarticulocaja(0)
                                        setexistenciaarticuloblister(0)
                                        setexistenciaarticulounidad(0)
                                        setnotascredito([])
                                        setfacturaremisiones([])

                                        setlistaexistenciaarticulo([])
                                        setcodigobono("")
                                        setvalorbono(0)
                                        setlistaarticulocostoprecio([])
                                        setremisiones([])
                                        setremisionesfactura([])
                                        setremisionesfactura([])
                                        setnotascredito([])
                                        setfacturaremisiones([])
                                        // if (Movimiento.famovimiento.length == 0) {
                                        obtenertipodocumentomasrepetido()
                                        // }
                                    }
                                }} >
                                    <i className="fa fa-plus"></i> Nuevo</Button>
                            </Col>
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                    if (dataUser.empresa.modulos.ARTICULOS.electronico) {

                                        buscartodoslosmovimientos('');
                                    }
                                    seteditar(true)
                                    setVerModalMovimientos(true)
                                }} >
                                    <i className="fa fa-search-plus"></i> Buscar</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block id="botonguardarmovimientoventascompras" onClick={guardarmovimiento} >
                                    <i className="fa fa-cloud"></i> {tipodocumento.facturapap == "electronica" ? "Guardar/Enviar" : "Guardar"}</Button>
                            </Col>}

                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {
                                    var _movimiento = { ...Movimiento }
                                    _movimiento.ndocumento = 0
                                    _movimiento.cufe = ""
                                    _movimiento.qr = ""
                                    _movimiento.conceptodevo = ""
                                    _movimiento.anulado = ""
                                    _movimiento.anuladopor = ""
                                    _movimiento.ndocumentodevo = 0
                                    _movimiento.fecha = fechahoy
                                    setMovimiento(_movimiento)
                                    setnuevo(true)
                                    seteditar(false)
                                    setremisiones([])
                                }} >
                                    <i className="fa fa-clone"></i> Utilizar Plantilla</Button>
                            </Col>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {
                                    enviaradian()
                                }} >
                                    <i className="fa fa-clone"></i> Emitir</Button>
                            </Col>
                            {(true) && <Col sm={1}>
                                <Button id="movimientopdfventacompra" style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }} block onClick={async (e) => {
                                    console.log(e)
                                    var primerotercero = false
                                    if (e.clientX != 0) {
                                        props.permisosusuario.forEach(element => {
                                            if (element.tipo == "nodejarimprimirsinguardar" && element.acceso[0] == "2") {
                                                primerotercero = true
                                            }
                                        });



                                        if (primerotercero) {
                                            if (Movimiento.ndocumento == 0) {
                                                Swal.fire(
                                                    'Opps!',
                                                    'No se Puede Imprimir Sin Guardar',
                                                    'error'
                                                )
                                                return
                                            }
                                        }
                                        primerotercero = false
                                        try {
                                            props.permisosusuario.forEach(element => {
                                                if (element.tipo == "dejarimprimir2veces" && element.acceso[0] == "1" && Movimiento.impreso != "") {
                                                    primerotercero = true
                                                }
                                            });

                                            if (primerotercero) {
                                                Swal.fire(
                                                    'Opps!',
                                                    'No se Puede Imprimir 2 Veces',
                                                    'error'
                                                )
                                                return

                                            }
                                            primerotercero = false
                                            props.permisosusuario.forEach(element => {
                                                if (element.tipo == "dejarimprimir2veces" && element.acceso[0] == "1" && Movimiento.impreso == "") {
                                                    primerotercero = true
                                                }
                                            });
                                            if (primerotercero) {
                                                var usu = await guardarimpreso()
                                                var _mov = { ...Movimiento }
                                                _mov.impreso = usu + ""
                                                setMovimiento(_mov)
                                            }

                                        } catch (error) {

                                        }
                                    }
                                    if (tipodocumento.plantilladoc == "si") {
                                        var plantilla = plantillaspdf.find(p => p.codigo == tipodocumento.modidoc)
                                        if (plantilla) {
                                            setplantillapdf(plantilla)
                                            if (tipodocumento.modidoc.includes("tirilla")) {
                                                setprevisualizartirillaplantilla(true)

                                            } else {
                                                setprevisualizartirillaplantillacarta(true)
                                            }
                                        } else {
                                            setprevisualizar(true)

                                        }
                                    } else {
                                        setprevisualizar(true)

                                    }

                                }} >
                                    <i className="fa fa-paper-plane"></i> PDF</Button>
                            </Col>}
                            {/* <Col>
                                <Button onClick={() => {

                                }}>
                                    <i className="fa fa-paper-plane"></i> prueba</Button>
                            </Col> */}
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setventa(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

                <ModalBody >

                    <Venn caja={props.caja} permisosusuario={props.permisosusuario} movimiento={movimientoenvio} cerrar={() => {
                        seteditarorden(false)
                    }} editarorden={editarorden} servidor={props.servidor} />

                </ModalBody>
                <ModalFooter>

                    <Button size="lg" color="primary" onClick={() => {
                        seteditarorden(false)
                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <Col sm={tipodocumento.puntoventa == "si" ? 6 : 8} className={((nuevo || editar)) ? "" : "desabilitar"} >
                {/* donde van los terceros */}
                <Row className="mb-2">
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardHeader s tyle={{ backgroundColor: '#188BAD', color: "white" }}>
                                <Row>
                                    <Col sm={2}>
                                        {"Detalles" + tabespacio}
                                    </Col>
                                    {Movimiento.anulado == "anulado" && <Col sm={2}>
                                        <div style={{ color: 'red' }}> {Movimiento.anulado == "anulado" && "ANULADA"}</div>

                                    </Col>}
                                    {
                                        notascredito.map((dato) => {
                                            return dato
                                        })
                                    }
                                    {remisionesfactura && remisionesfactura.length > 0 &&
                                        remisionesfactura.map((dato) => {
                                            return <Col sm={2}>
                                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                }} >
                                                    <i className="fa fa-clone">{dato.conceptoremi + " - " + dato.ndocumentoremi}</i> </Button>
                                            </Col>
                                        })
                                    }
                                    {facturaremisiones && facturaremisiones.length > 0 &&
                                        facturaremisiones.map((dato) => {
                                            return <Col sm={2}>
                                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                }} >
                                                    <i className="fa fa-clone">{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                            </Col>
                                        })
                                    }

                                    {(true) && <Col sm={3}>
                                        <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black', pointerEvents: "all" }} block onClick={() => {

                                            if (tipodocumento.plantilladoc == "si") {
                                                var plantilla = plantillaspdf.find(p => p.codigo == tipodocumento.allinformes)
                                                if (plantilla) {
                                                    setplantillapdf(plantilla)
                                                    if (tipodocumento.allinformes.includes("tirilla")) {
                                                        setprevisualizartirillaplantilla(true)

                                                    } else {
                                                        setprevisualizartirillaplantillacarta(true)
                                                    }
                                                } else {
                                                    if (!previsualizar) {

                                                        setprevisualizar2(true)

                                                    }

                                                }
                                            } else {
                                                if (!previsualizar) {

                                                    setprevisualizar2(true)

                                                }

                                            }


                                        }} >
                                            <i className="fa fa-paper-plane"></i> PDF 2</Button>
                                    </Col>}
                                </Row>

                            </CardHeader>
                            <CardBody className="mb-2 table-responsive" style={{ height: tipodocumento.puntoventa != "si" ? '210px' : '800px' }}>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tipo Doc</Label>
                                            <InputGroup >
                                                <Input onChange={(e) => {
                                                    if (nuevo) {
                                                        var _Movimiento = { ...Movimiento }
                                                        _Movimiento['concepto'] = e.target.value
                                                        setMovimiento(_Movimiento)
                                                    }
                                                }} name="concepto" value={Movimiento.concepto} bsSize="sm" onKeyDown={(e) => {
                                                    var tieneimpuestositems = false

                                                    if (e.key == "Enter" && e.target.value == '' && (!tieneimpuestositems || Movimiento.cliente == "")) {
                                                        e.preventDefault()
                                                        buscartodastipodocumentos('');
                                                        setbusquedatipodocumentos(tipodocumentos)
                                                        setVerModaltipodocumentos(true)


                                                    } else {
                                                        var tieneimpuestositems = false

                                                        if (tieneimpuestositems) {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }
                                                    }
                                                }} onBlur={(e) => {
                                                    cambiartipodocumento(e)
                                                }} />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        var tieneimpuestositems = false

                                                        if (!tieneimpuestositems || Movimiento.cliente == "") {
                                                            buscartodastipodocumentos('');

                                                            setbusquedatipodocumentos(tipodocumentos)
                                                            setVerModaltipodocumentos(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )

                                                        }

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha</Label>
                                            <Input name="fecha" value={Movimiento.fecha} onChange={handleChange} type="date" bsSize="sm" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Consecutivo</Label>
                                            <Input disabled onChange={handleChange} value={Movimiento.ndocumento} type="text" bsSize="sm" name="ndocumento" />
                                        </FormGroup>
                                    </Col>
                                    {(tipodocumento.mdevolucio == "si" || tipodocumento.asicont == "si" || (tipodocumento.noimpdupl == "si" && tipodocumento.tipodoc == "factura")) ? false : <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Button id={"agregaritemmovimiento"} style={{ fontSize: '12px', color: 'black' }} color="success" block
                                                onClick={() => {
                                                    agregaritemfuncion()
                                                }} >
                                                <i className="fa fa-plus"></i> Agregar Item</Button>
                                        </FormGroup>
                                    </Col>}

                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tercero</Label>
                                            <InputGroup>
                                                <Input onChange={(e) => {
                                                    var _Movimiento = { ...Movimiento }
                                                    _Movimiento['cliente'] = e.target.value
                                                    setMovimiento(_Movimiento)
                                                }} value={Movimiento.cliente} bsSize="sm" onKeyDown={(e) => {
                                                    var tieneimpuestositems = false
                                                    Movimiento.famovimiento.map((dato, index) => {
                                                        if (Number(dato.poretefte) != 0) {
                                                            tieneimpuestositems = true
                                                        }
                                                    })
                                                    if (e.key == "Enter" && e.target.value == "" && (!tieneimpuestositems || Movimiento.cliente == "") && tipodocumento.codigo != "") {
                                                        e.preventDefault()
                                                        if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                            buscartodasterceros('');
                                                        }
                                                        // buscartodasterceros('');
                                                        setbusquedaterceros(terceros)
                                                        setVerModalterceros(true)

                                                    } else {
                                                        if (tieneimpuestositems) {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }
                                                        if (tipodocumento.codigo == "") {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe seleccionar un Tipo de Documento',
                                                                'error'
                                                            )
                                                        }

                                                    }
                                                }} onBlur={(e) => {
                                                    if (e.target.value != "") {
                                                        try {
                                                            console.log(e.relatedTarget)
                                                            if (e.relatedTarget.className.includes("modal")) {

                                                            } else {
                                                                cambiarterceros(e)
                                                            }

                                                        } catch (error) {
                                                            cambiarterceros(e)

                                                        }
                                                    }
                                                }} name="cliente" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        var tieneimpuestositems = false
                                                        Movimiento.famovimiento.map((dato, index) => {
                                                            if (Number(dato.poretefte) != 0) {
                                                                tieneimpuestositems = true
                                                            }
                                                        })
                                                        if (!tieneimpuestositems || Movimiento.cliente == "") {
                                                            if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                buscartodasterceros('');
                                                            }
                                                            // buscartodasterceros('');
                                                            setbusquedaterceros(terceros)
                                                            setVerModalterceros(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }


                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>



                                            </InputGroup>

                                        </FormGroup>
                                    </Col>


                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Input onChange={handleChange} value={Movimiento.nombrecli} type="text" bsSize="sm" name="nombrecli" />
                                        </FormGroup>
                                    </Col>
                                    {((tipodocumento.mdevolucio == "si" || tipodocumento.noimpdupl == "si") && (tipodocumento.tipodoc == "devoluciones compra" || tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito")) && <Col sm={3}>

                                        <FormGroup inline={true}>
                                            <Label>_</Label>
                                            <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                                buscartodoslosmovimientosportercero(Movimiento.cliente)
                                                setVerModalMovimientosDevoluciones(true)
                                            }} >
                                                <i className="fa fa-search-plus"></i> {(tipodocumento.tipodoc == "factura") ? (tipodocumento.noimpdupl == "si") ? 'Factura' : "Devolucion" : (tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? 'Factura' : ""}</Button>


                                        </FormGroup>
                                    </Col>}
                                    {((tipodocumento.asicont == "si")) && <Col sm={3}>

                                        <FormGroup inline={true}>
                                            <Label>_</Label>
                                            <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                                buscartodoslosmovimientosporterceroremisiones(Movimiento.cliente, Movimiento.tercero)
                                                setVerModalMovimientosremision(true)
                                            }} >
                                                <i className="fa fa-search-plus"></i> Agrupar</Button>


                                        </FormGroup>
                                    </Col>}

                                    {tipodocumento.afecar != "" &&
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>{'Plazo'}</Label>
                                                <Input onChange={handleChange} value={Movimiento.plazo} type="number" bsSize="sm" name="plazo" />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {tipodocumento.exportacion == "dolar" &&
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>{'TRM'}</Label>
                                                <Input onChange={handleChange} value={Movimiento.vlr_dolar} type="text" bsSize="sm" name="vlr_dolar" />
                                            </FormGroup>
                                        </Col>
                                    }
                                    {(tipodocumento.docu1 == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>{(tipodocumento.captiondoc1 == "") ? 'Documento 1' : tipodocumento.captiondoc1}</Label>
                                            <Input onChange={handleChange} value={Movimiento.documento1} type="text" bsSize="sm" name="documento1" />
                                        </FormGroup>
                                    </Col>}

                                    {(tipodocumento.docu2 == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>{(tipodocumento.captiondoc2 == "") ? 'Documento 2' : tipodocumento.captiondoc2}</Label>
                                            <Input onChange={handleChange} onKeyUp={(e) => {
                                                if (e.key == "Enter") {
                                                    setVerModaltercerosdocumento2(true)

                                                }
                                            }} value={Movimiento.documento2} type="text" bsSize="sm" name="documento2" />
                                        </FormGroup>
                                    </Col>}
                                    {(tipodocumento.mvendedor == "si") && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>{(tipodocumento.captionvend == "") ? 'Vendedor' : tipodocumento.captionvend}</Label>
                                            <InputGroup>
                                                <Input onChange={(e) => {
                                                    try {
                                                        var _Movimiento = { ...Movimiento }
                                                        var _vendedor = vendedors.find(p => p.codigo == e.target.value)
                                                        _Movimiento['vendedor'] = e.target.value
                                                        try {
                                                            _Movimiento['nombreven'] = _vendedor.nombre
                                                        } catch (error) {

                                                        }
                                                        setMovimiento(_Movimiento)
                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                }} value={Movimiento.vendedor} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        e.preventDefault()
                                                        buscartodasvendedors('');
                                                        setbusquedavendedors(vendedors)
                                                        setVerModalvendedors(true)

                                                    }
                                                }} name="vendedor" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        buscartodasvendedors('');
                                                        setbusquedavendedors(vendedors)
                                                        setVerModalvendedors(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                    {(tipodocumento.mvendedor == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Input onChange={handleChange} value={Movimiento.nombreven} type="text" bsSize="sm" name="nombreven" />
                                        </FormGroup>
                                    </Col>}

                                    {(tipodocumento.formapago == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>Forma de Pago</Label>
                                            <Input onChange={handleChange} value={Movimiento.fpago} type="select" style={{ fontSize: '13px' }} name="fpago"  >
                                                <option value="contado">Contado</option>
                                                <option value="credito">Credito</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>}
                                    {(true) && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Medio de Pago</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.mpago} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        e.preventDefault()
                                                        buscartodasmediopagos('');
                                                        setbusquedamediopagos(mediopagos)
                                                        setVerModalmediopagos(true)

                                                    }
                                                }} name="mpago" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        buscartodasmediopagos('');
                                                        setbusquedamediopagos(mediopagos)
                                                        setVerModalmediopagos(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Direccion</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="direccion" value={Movimiento.direccion}  >
                                                {direccionestercero.map((e) => {
                                                    return <option value={e.direccion}>{e.direccion}</option>


                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    {(tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>Concepto Devo</Label>
                                            <Input onChange={handleChange} value={Movimiento.conceptodevo} type="text" bsSize="sm" name="conceptodevo" />
                                        </FormGroup>
                                    </Col>}
                                    {(tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>Documento Devo</Label>
                                            <Input onChange={handleChange} value={Movimiento.ndocumentodevo} type="text" bsSize="sm" name="ndocumentodevo" />
                                        </FormGroup>
                                    </Col>}
                                    {(false) && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Sucursal</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.sucursal} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        e.preventDefault()


                                                    }
                                                }} name="sucursal" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                </Row>
                                {tipodocumento.puntoventa == "si" &&
                                    <Row>
                                        <MenuCard
                                            ponerarticuloplu={() => {
                                                ponerarticuloplu()
                                            }}
                                            agregaritemfuncion={agregaritemfuncion}
                                            filaenmenu={filaenmenu} setfila={(index) => {
                                                setfila(index)
                                                setfilaenmenu(index)
                                            }
                                            } buscararticulounico={async (e) => { return await buscararticulounico(e) }} seleccionararticulo={(articuloss, primero) => {
                                                var _Movimiento = { ...Movimiento }
                                                var _datos = { ...Movimiento }['famovimiento']
                                                eventosseleccionarticulo(articuloss, _Movimiento, _datos)

                                            }} total2={total233} mostrarimagenes={mostrarimagenes} barrio={tipodocumento.precsug} calculartotalesmovimiento={(datt, propina) => { calculartotalesmovimiento(datt) }} servidor={props.servidor} tipodocumento={tipodocumento} Movimiento={Movimiento} setMovimiento={(va) => { setMovimiento(va) }} />

                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Col>

            <Col sm={tipodocumento.puntoventa == "si" ? 6 : 4} className={(nuevo || editar) ? "" : "desabilitar"} >
                {/* donde van los totales */}
                {tipodocumento.puntoventa == "si" && grillaproductosmov}
                <Row>
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                Totales
                            </CardHeader>
                            <CardBody className="mb-2 table-responsive" style={{ height: '210px' }}>
                                <Row >
                                    {/* <Col sm={12 }>
                                        <div style={{ display: 'inline' }}>
                                            <a className="labelmov">Bruto:</a>
                                            <a className="inputmov">0</a>
                                        </div>
                                    </Col>
                                    <Col style={{marginTop:'-1px'}} sm={12}>
                                        <div style={{ display: 'inline' }}>
                                            <a className="labelmov">Bruto:</a>
                                            <a className="inputmov">0</a>
                                        </div>
                                    </Col> */}
                                    <InputGroup size="sm" style={{ marginTop: '-20px' }}>
                                        <InputGroupText className="labelmov" >Bruto:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(bruto)} />
                                    </InputGroup>
                                    {(tipodocumento.descsn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov" >-Descuento:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(descuento)} />
                                    </InputGroup>}
                                    <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">Sub Total:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(subTotal)} />
                                    </InputGroup>
                                    {(tipodocumento.ivasn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">+IVA:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(iva)} />
                                    </InputGroup>}
                                    {(tipodocumento.imptosn == "si" || tipodocumento.modidocenc == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">+Consumo:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(consumo)} />
                                    </InputGroup>}
                                    {(tipodocumento.reteiva == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteIva:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(reteiva)} />
                                    </InputGroup>}
                                    {(tipodocumento.rete == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteFuente:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(retefuente)} />
                                    </InputGroup>}
                                    {(tipodocumento.ica == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteIca:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(reteica)} />
                                    </InputGroup>}
                                    <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmovtotal">Total:</InputGroupText>
                                        <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                                    </InputGroup>
                                </Row>





                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col sm={12} className={(nuevo || editar) ? (tipodocumento.noimpdupl == "si" && tipodocumento.tipodoc == "factura") ? "desabilitar" : "" : "desabilitar"} >
                {/* donde van los movimientos */}
                {tipodocumento.puntoventa != "si" && grillaproductosmov}
            </Col>
            {(nuevo || editar) && <Col sm={2}>
                <Button id="campoguardardemovimiento" onFocus={(e) => { e.target.blur() }} style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={(e) => {
                    guardarmovimiento(e)
                    document.getElementById("campoguardardemovimiento").blur()

                }} >
                    <i className="fa fa-cloud"></i> {tipodocumento.facturapap == "electronica" ? "Guardar/Enviar" : "Guardar"}</Button>
            </Col>}
            {(nuevo || editar) &&
                <Col sm={3}>
                    <FormGroup>
                        <Button id={"agregaritemmovimiento"} style={{ fontSize: '12px', color: 'black' }} color="success" block
                            onClick={() => {
                                if (Movimiento.concepto != "" && tipodocumento.codigo != "") {
                                    var existearticul = Movimiento.famovimiento.filter((dato) => {
                                        if (dato.referencia == "") {
                                            return dato
                                        }
                                    })
                                    if (existearticul.length == 0) {
                                        var _Movimiento = { ...Movimiento }
                                        var _datos = { ...Movimiento }['famovimiento']
                                        var bodega = ""
                                        var ccosto = ""
                                        var presentacion = "3"
                                        var retefuente = 0
                                        if (tipodocumento.tbodegasn == "si") {
                                            bodega = tipodocumento.tbodega
                                        }
                                        if (tipodocumento.ccosto == "si") {
                                            ccosto = tipodocumento.centrocosto
                                        }
                                        if (tipodocumento.tipodoc == "compra") {
                                            presentacion = "1"
                                        }

                                        var _nuevosdatos = _datos.concat(
                                            {
                                                item: "",
                                                referencia: "",
                                                descrip: "",
                                                bodega: bodega,
                                                cantidad: "",
                                                precio: "",
                                                pordescuento: "",
                                                poriva: "",
                                                porimptoconsumo: "",
                                                subtotal: "",
                                                compañia: "",
                                                concepto: "",
                                                nrodocumento: "",
                                                costo: "",
                                                desadicional: "",
                                                unimed: "",
                                                ccosto: ccosto,
                                                fecha: "",
                                                preivainc: "",
                                                codprod: "",
                                                canprod: "",
                                                horafecha: "",
                                                tipoitem: "",
                                                tercero: "",
                                                cliente: "",
                                                vlr_desc: "",
                                                vlr_iva: "",
                                                vlr_base_iva: "",
                                                costodolar: "",
                                                tasa: "",
                                                vlrimptoconsumo: "",
                                                poretefte: retefuente,
                                                vlretefte: "",
                                                porica: "",
                                                vlrica: "",
                                                poreteiva: "",
                                                vlreteiva: "",
                                                cantdig: "",
                                                presentacion: presentacion,
                                                cuentadebito: "",
                                                cuentacredito: ""


                                            }

                                        )

                                        _Movimiento['famovimiento'] = _nuevosdatos
                                        setMovimiento(_Movimiento)

                                    }
                                } else {
                                    Swal.fire(
                                        'Opps!',
                                        'No ha seleccionado un Tipo de Documento',
                                        'error'
                                    )
                                }
                            }} >
                            <i className="fa fa-plus"></i> Agregar Item</Button>
                    </FormGroup>
                </Col>
            }
            {puedoanular && <Col sm={1}>
                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'white' }} block onClick={() => {
                    let dt = JSON.parse(user)
                    var movimientoguardado = {
                        faencmovi: {},
                        famovimiento: [],
                        caja: []
                    }
                    if (tipodocumento.facturapap == "electronica") {
                        Swal.fire(
                            'Opps!',
                            'No puede anular un documento electronico',
                            'error'
                        )
                        return
                    }
                    movimientoguardado.faencmovi = { ...Movimiento }
                    movimientoguardado.faencmovi['ndocumento'] = Number(movimientoguardado.faencmovi.ndocumento)
                    movimientoguardado.faencmovi['bruto'] = bruto
                    movimientoguardado.faencmovi['iva'] = Number(iva)
                    movimientoguardado.faencmovi['descuento'] = Number(descuento)
                    movimientoguardado.faencmovi['despiefac'] = Number(movimientoguardado.faencmovi.despiefac)
                    movimientoguardado.faencmovi['retefuente'] = Number(retefuente)
                    movimientoguardado.faencmovi['reteiva'] = Number(reteiva)
                    movimientoguardado.faencmovi['ica'] = Number(reteica)
                    movimientoguardado.faencmovi['retefte'] = Number(retefuente)
                    movimientoguardado.faencmovi['impconsumo'] = Number(consumo)
                    movimientoguardado.faencmovi['subtotal'] = Number(subTotal)
                    movimientoguardado.faencmovi['total'] = Number(total)
                    movimientoguardado.faencmovi['cuotai'] = Number(movimientoguardado.faencmovi.cuotai)
                    movimientoguardado.faencmovi['ncuotasi'] = Number(movimientoguardado.faencmovi.ncuotasi)
                    movimientoguardado.faencmovi['diferidasi'] = Number(movimientoguardado.faencmovi.diferidasi)
                    movimientoguardado.faencmovi['tipocre'] = Number(movimientoguardado.faencmovi.tipocre)
                    movimientoguardado.faencmovi['ncuotasf'] = Number(movimientoguardado.faencmovi.ncuotasf)
                    movimientoguardado.faencmovi['tasa'] = Number(movimientoguardado.faencmovi.tasa)
                    movimientoguardado.faencmovi['diferidas'] = Number(movimientoguardado.faencmovi.diferidas)
                    movimientoguardado.faencmovi['efectivo'] = Number(movimientoguardado.faencmovi.efectivo)
                    movimientoguardado.faencmovi['cheque'] = Number(movimientoguardado.faencmovi.cheque)
                    movimientoguardado.faencmovi['debito'] = Number(movimientoguardado.faencmovi.debito)
                    movimientoguardado.faencmovi['credito'] = Number(movimientoguardado.faencmovi.credito)
                    movimientoguardado.faencmovi['descuentoc'] = Number(movimientoguardado.faencmovi.descuentoc)
                    movimientoguardado.faencmovi['cree'] = Number(movimientoguardado.faencmovi.cree)
                    movimientoguardado.faencmovi['fletes'] = Number(movimientoguardado.faencmovi.fletes)
                    movimientoguardado.faencmovi['tcambio'] = Number(movimientoguardado.faencmovi.tcambio)
                    movimientoguardado.faencmovi['dsctopp1'] = Number(movimientoguardado.faencmovi.dsctopp1)
                    movimientoguardado.faencmovi['dsctopp2'] = Number(movimientoguardado.faencmovi.dsctopp2)
                    movimientoguardado.faencmovi['dsctopp3'] = Number(movimientoguardado.faencmovi.dsctopp3)
                    movimientoguardado.faencmovi['dsctovapp1'] = Number(movimientoguardado.faencmovi.dsctovapp1)
                    movimientoguardado.faencmovi['dsctovapp2'] = Number(movimientoguardado.faencmovi.dsctovapp2)
                    movimientoguardado.faencmovi['dsctovapp3'] = Number(movimientoguardado.faencmovi.dsctovapp3)
                    movimientoguardado.faencmovi['convdesdoc'] = Number(movimientoguardado.faencmovi.convdesdoc)
                    movimientoguardado.faencmovi['convoridoc'] = Number(movimientoguardado.faencmovi.convoridoc)
                    movimientoguardado.faencmovi['poretefte'] = Number(movimientoguardado.faencmovi.poretefte)
                    movimientoguardado.faencmovi['poreteiva'] = Number(movimientoguardado.faencmovi.poreteiva)
                    movimientoguardado.faencmovi['porica'] = Number(movimientoguardado.faencmovi.porica)
                    movimientoguardado.faencmovi['porimptoconsumo'] = Number(movimientoguardado.faencmovi.porimptoconsumo)
                    movimientoguardado.faencmovi['vlr_dolar'] = Number(movimientoguardado.faencmovi.vlr_dolar)
                    movimientoguardado.faencmovi['ndocprod'] = Number(movimientoguardado.faencmovi.ndocprod)
                    movimientoguardado.faencmovi['ndoctrasl'] = Number(movimientoguardado.faencmovi.ndoctrasl)
                    movimientoguardado.faencmovi['ndocodcped'] = Number(movimientoguardado.faencmovi.ndocodcped)
                    movimientoguardado.faencmovi['plazo'] = Number(movimientoguardado.faencmovi.plazo ? movimientoguardado.faencmovi.plazo : "0")

                    movimientoguardado.famovimiento = { ...Movimiento }['famovimiento']
                    delete movimientoguardado.faencmovi.famovimiento;
                    const api = fetch("" + props.servidor + "/Movimientoapi/anular/" + dt.usuario.cedula + "?llave=" + dt.usuario.llaveempresa + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(movimientoguardado)
                    })
                        .then((response) => {
                            return response.ok ? response.json() : Promise.reject(response);
                        })
                        .then(async (dataJson) => {
                            Swal.fire(
                                'Exito!',
                                'Se anulo el movimiento: ' + Movimiento.concepto + " - " + Movimiento.ndocumento,
                                'success'
                            )

                        }).catch(async (error) => {
                            try {
                                var ee = await error.json()
                                Swal.fire(
                                    'Opps!',
                                    'No se pudo anular el movimiento: ' + ee.mensaje,
                                    'error'
                                )
                                console.log("No se pudo enviar la venta ", ee.mensaje)
                                guardarlogdeerrores(ee.mensaje)
                                setesperando(false)
                            } catch (error2) {
                                Swal.fire(
                                    'Opps!',
                                    'No se pudo anular el movimiento: ' + 'Error de conexion',
                                    'error'
                                )
                                console.log("No se pudo enviar la venta ", error2)
                                guardarlogdeerrores('Error de conexion')
                                setesperando(false)
                            }

                        })

                }} >
                    Anular</Button>
            </Col>}
            {tipodocumento.facturapap == "electronica" && <Col sm={2}>
                <Button style={{ fontSize: '12px', backgroundColor: '#0CA20E', color: 'white' }} block
                    onClick={() => { validarfacturas() }}
                >
                    Validar En Dian</Button>
            </Col>}
            {true && <Col sm={2}>
                <Button style={{ fontSize: '12px', backgroundColor: '#0CA20E', color: 'white' }} block
                    onClick={() => { imprimirfacturagas() }}
                >
                    PDF G</Button>
            </Col>}
            {true && <Col sm={2}>
                <Button style={{ fontSize: '12px', backgroundColor: '#0CA20E', color: 'white' }} block
                    onClick={() => { buscartodastipodocumentosactualizar() }}
                >
                    Actualizar T. Docs</Button>
            </Col>}
            {
                tipodocumento.tipodoc == "devoluciones" && <Col sm={2}> <Input placeholder="Propina" value={Movimiento.convoridoc} onChange={(e) => {
                    try {
                        var _Movimiento = { ...Movimiento }
                        _Movimiento.porimptoconsumo = 0
                        _Movimiento.convoridoc = Number(e.target.value)
                        setMovimiento(_Movimiento)
                    } catch (error) {
                        var _Movimiento = { ...Movimiento }
                        _Movimiento.porimptoconsumo = 0
                        _Movimiento.convoridoc = 0
                        setMovimiento(_Movimiento)
                    }
                }} /> </Col>
            }
            <Col sm={12}>
                <label>Observaciones</label>
                <Input type="textarea" value={Movimiento.observacion} onChange={handleChange} name={"observacion"}></Input>
            </Col>
            {tipodocumento.puntoventa != "si" &&
                <Col sm={tipodocumento.puntoventa == "si" ? 6 : 4} className={(nuevo || editar) ? "" : "desabilitar"} >
                    {/* donde van los totales */}
                    {tipodocumento.puntoventa == "si" && grillaproductosmov}
                    <Row>
                        <Col sm={12}>
                            <Card style={{ borderColor: '#188BAD' }}>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Totales
                                </CardHeader>
                                <CardBody className="mb-2 table-responsive" style={{ height: '210px' }}>
                                    <Row >
                                        {/* <Col sm={12 }>
                                    <div style={{ display: 'inline' }}>
                                        <a className="labelmov">Bruto:</a>
                                        <a className="inputmov">0</a>
                                    </div>
                                </Col>
                                <Col style={{marginTop:'-1px'}} sm={12}>
                                    <div style={{ display: 'inline' }}>
                                        <a className="labelmov">Bruto:</a>
                                        <a className="inputmov">0</a>
                                    </div>
                                </Col> */}
                                        <InputGroup size="sm" style={{ marginTop: '-20px' }}>
                                            <InputGroupText className="labelmov" >Bruto:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(bruto)} />
                                        </InputGroup>
                                        {(tipodocumento.descsn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov" >-Descuento:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(descuento)} />
                                        </InputGroup>}
                                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">Sub Total:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(subTotal)} />
                                        </InputGroup>
                                        {(tipodocumento.ivasn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">+IVA:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(iva)} />
                                        </InputGroup>}
                                        {(tipodocumento.imptosn == "si" || tipodocumento.modidocenc == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">+Consumo:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(consumo)} />
                                        </InputGroup>}
                                        {(tipodocumento.reteiva == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">-ReteIva:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(reteiva)} />
                                        </InputGroup>}
                                        {(tipodocumento.rete == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">-ReteFuente:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(retefuente)} />
                                        </InputGroup>}
                                        {(tipodocumento.ica == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmov">-ReteIca:</InputGroupText>
                                            <Input disabled className="inputmov" value={fm2.from(reteica)} />
                                        </InputGroup>}
                                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                            <InputGroupText className="labelmovtotal">Total:</InputGroupText>
                                            <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                                        </InputGroup>
                                    </Row>





                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>}
            <Modal size="lg" isOpen={abrirotrosimpuestos}>
                <ModalHeader>
                    Otros Impuestos
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {tercero && ((tercero.venporcreteiva > 0) || (tercero.comporcreteiva > 0)) && <Col sm={12}>
                            <Card style={{ borderColor: '#188BAD' }}>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    ReteIva
                                </CardHeader>
                                <CardBody >
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Base Minina</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(tercero.venbasereteiva)} />
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Porcentaje</InputGroupText>
                                        <Input name="poreteiva" onBlur={(e) => { e.target.value = fm1.from(Number(porcentajereteiva + "")) + " %" }} tabla="famovimiento" onChange={handleChangeimpuestosglobales} onFocus={(e) => { e.target.value = (porcentajereteiva + "").replace(/\,/g, '') }} className="inputmov" value={porcentajereteiva} />
                                    </InputGroup>
                                </CardBody >
                            </Card >
                        </Col>}
                        {tercero && ((tercero.venporica > 0) || (tercero.comporica > 0)) && <Col sm={12}>
                            <Card style={{ borderColor: '#188BAD' }}>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Reteica
                                </CardHeader>
                                <CardBody >
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Base Minina</InputGroupText>
                                        <Input className="inputmov" value={fm2.from(tercero.venbaseica)} />
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Porcentaje</InputGroupText>
                                        <Input name="porica" onBlur={(e) => { e.target.value = fm1.from(Number(porcentajeica + "")) + " %" }} tabla="famovimiento" onChange={handleChangeimpuestosglobales} onFocus={(e) => { e.target.value = (porcentajeica + "").replace(/\,/g, '') }} className="inputmov" value={porcentajeica} />
                                    </InputGroup>
                                </CardBody >
                            </Card >
                        </Col>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        cambiardatosconimpuesto()
                        setabrirotrosimpuestos(false)
                        if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "") {
                            var _Movimiento = { ...Movimiento }
                            var efec = total - Number(_Movimiento['cheque'] + "") - Number(_Movimiento['debito'] + "") - Number(_Movimiento['credito'] + "") - Number(_Movimiento['descuentoc'] + "") + Number(_Movimiento['convoridoc'] + "")

                            _Movimiento['efectivo'] = (efec >= 0) ? efec : 0
                            _Movimiento['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
                            setMovimiento(_Movimiento)
                            obtenerMediodepagos('')
                            setabrirabrirmediosdepago(true)

                        } else {
                            setesperando(true)
                            terminarVenta()

                        }
                    }}>Listo</Button>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={abrirmediosdepago} onOpened={() => {
                document.getElementById("movimientomediosdepagopagarventacomopra").focus()
            }}>
                <ModalHeader>
                    Caja
                </ModalHeader>
                <ModalBody>
                    <Row >
                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                            <InputGroupText className="labelmovtotalmediopago">El Total es:</InputGroupText>
                            <Input disabled className="inputmovtotal" value={fm.from(total)} />
                        </InputGroup>
                        <InputGroup size="sm" >
                            <InputGroupText className="labelmovtotalmediopago">Efectivo:</InputGroupText>
                            <Input onFocus={(e) => {
                                e.target.value = (Movimiento.efectivo + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm3.from(Number(Movimiento.efectivo + ""))
                            }} value={Movimiento.efectivo} name="efectivo" onChange={handleChangeformapago} className="inputmovtotal" />
                        </InputGroup>
                        {
                            Mediodepagos.map((dato) => {
                                if (dato.esbono == "true") {
                                    return <><InputGroup size="sm" >
                                        <InputGroupText className="labelmovtotalmediopago">{dato.nombre}:</InputGroupText>
                                        <Row >
                                            <Col sm={6} >
                                                <Input type="text" name={'bono'} value={valorbono} onChange={handleChangeformapago} />
                                            </Col>
                                            <Col sm={6}>
                                                <Input value={codigobono} onKeyDown={() => { setabrirbonos(true) }} />
                                            </Col>
                                        </Row>
                                    </InputGroup>

                                    </>
                                } else {
                                    return <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmovtotalmediopago">{dato.nombre}:</InputGroupText>
                                        <Input onFocus={(e) => {
                                            e.target.value = (dato.valor + "").replace(/\,/g, '')
                                        }} onBlur={(e) => {
                                            e.target.value = fm.from(Number(dato.valor))
                                        }} value={dato.valor} name={dato.codigo} onChange={handleChangeformapago} className="inputmovtotal" />
                                    </InputGroup>
                                }
                            })
                        }

                        <InputGroup size="sm">
                            <InputGroupText className="labelmovtotalmediopago">Descuento:</InputGroupText>
                            <Input disabled={tipodocumento.tipodoc == "factura" && tipodocumento.mdevolucio == "si"} onFocus={(e) => {
                                e.target.value = (Movimiento.descuentoc + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(Movimiento.descuentoc))
                            }} className="inputmovtotal" value={Movimiento.descuentoc} name="descuentoc" onChange={handleChangeformapago} />
                        </InputGroup>
                        <InputGroup size="sm">
                            <InputGroupText className="labelmovtotalmediopago">Devolucion:</InputGroupText>
                            <Input onFocus={(e) => {
                                e.target.value = (Movimiento.fletes + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(Movimiento.fletes))
                            }} disabled className="inputmovtotal" value={fm.from(Number(Movimiento.fletes + ""))} onChange={handleChangeformapago} />
                        </InputGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" id="movimientomediosdepagopagarventacomopra" color="primary" onClick={() => {
                        // cambiardatosconimpuesto()
                        var totalsuma = Mediodepagos.reduce((sum, pago) => sum + pago.valor, 0);
                        totalsuma = totalsuma + Movimiento.efectivo + Movimiento.descuentoc
                        if (totalsuma >= total) {
                            setabrirabrirmediosdepago(false)
                            // var efec = total - Number(Movimiento['cheque'] + "") - Number(Movimiento['debito'] + "") - Number(Movimiento['credito'] + "") - Number(Movimiento['descuentoc'] + "")
                            // if (efec > 0) {
                            //     Swal.fire(
                            //         'Opps!',
                            //         'Falta Dinero',
                            //         'error'
                            //     )
                            // } else {

                            // }
                            if ((tipodocumento.tipodoc == "produccion" || tipodocumento.tipodoc == "translado" || tipodocumento.camdescenc == "si") && tipodocumento.tipoprod == "origen") {
                                setabrirbodegatranslado(true)
                            } else {
                                setesperando(true)

                                terminarVenta()
                            }


                        } else {
                            Swal.fire(
                                'Opps!',
                                'El valor en Caja es menor al facturado ',
                                'error'
                            )
                            return
                        }

                    }}>Listo</Button>
                    <Button size="sm" color="danger" onClick={() => {
                        // cambiardatosconimpuesto()
                        setabrirabrirmediosdepago(false)
                        setvalorbono(0)
                        setcodigobono("")
                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={abrirlotes}>
                <ModalHeader>
                    Lotes
                </ModalHeader>
                <ModalBody>
                    {lotesporarticulo.map((dato, index) => (
                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                            <InputGroupText className="labelmovtotalmediopago">{"Lote: " + dato.lote + " Existencia: " + dato.existencia}</InputGroupText>
                            <Input onFocus={(e) => {
                                e.target.value = dato.cantidad ? (dato.cantidad + "").replace(/\,/g, '') : ""
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(dato.cantidad ? dato.cantidad : "0"))
                            }} value={dato.cantidad} name={"cantidad"} onChange={(e) => {
                                if (lotesporarticulo[index].existencia >= Number(e.target.value)) {
                                    var _lotesporarticulo = lotesporarticulo.map((dato) => { return dato })
                                    _lotesporarticulo[index].cantidad = Number(e.target.value)
                                    setlotesporarticulo(_lotesporarticulo)
                                }
                            }} className="inputmovtotal" />
                        </InputGroup>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {

                        if (Movimiento.famovimiento[fila + 1]) {
                            Swal.fire(
                                'Opps!',
                                'Ya hizo el proceso automatico de lotes o agrego otro item',
                                'error'
                            )
                            return
                        } else {
                            var multipless = Movimiento.famovimiento.filter(p => p.referencia == Movimiento.famovimiento[fila].referencia)
                            if (multipless.length > 1) {
                                Swal.fire(
                                    'Opps!',
                                    'Ya hizo el proceso automatico de lotes o agrego otro item Igual',
                                    'error'
                                )
                                return
                            }
                            var _Movimiento = { ...Movimiento }
                            var lotes = lotesporarticulo.filter(p => p.cantidad != 0 && p.cantidad != undefined)
                            if (lotes.length == 0) {
                                Swal.fire(
                                    'Opps!',
                                    'No coloco ninguna cantidad',
                                    'error'
                                )
                            }
                            if (lotes.length == 1) {
                                _Movimiento = editarunlote(_Movimiento, lotes, fila, 0)
                                calculartotalesmovimiento(_Movimiento.famovimiento)

                            }
                            if (lotes.length > 1) {
                                for (let i = 0; i < lotes.length; i++) {
                                    const element = lotes[i];
                                    if (i == 0) {
                                        _Movimiento = editarunlote(_Movimiento, lotes, fila, i)
                                    } else {
                                        _Movimiento = agregaritemfuncionconretorno(_Movimiento)
                                        _Movimiento = editarunlote(_Movimiento, lotes, (_Movimiento.famovimiento.length - 1), i)

                                    }

                                }
                                calculartotalesmovimiento(_Movimiento.famovimiento)

                            }
                            setMovimiento(_Movimiento)
                            setlotesporarticulo([])
                            setlotesescritos([])
                            setabrirlotes(false)
                        }

                    }}>Listo</Button>
                    <Button size="sm" color="danger" onClick={() => {
                        setabrirlotes(false)

                        setlotesescritos([])
                    }}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={abriropcionespuntoventa}>
                <ModalHeader>
                    Opciones
                    {/* {(tipodocumento.puntoventa == "si") && " Punto Venta"} */}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {/* {(tipodocumento.puntoventa == "si") && <Col sm={6}>

                            <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {
                                setpuntodeventacambiarcantidad(true); setabriropcionespuntoventa(false);
                                //  document.getElementById('movimientoreferencia' + (fila)).focus()
                            }} >
                                <i className="fa fa-refresh"></i> Cambiar Cantidad</Button>
                        </Col>} */}
                        {/* {(tipodocumento.puntoventa == "si") && <Col sm={6}>
                            <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black', marginTop: '5px' }} block onClick={() => {
                                setpuntodeventacambiarprecio(true); setabriropcionespuntoventa(false);
                                //  document.getElementById('movimientoreferencia' + (fila)).focus()
                            }} >
                                <i className="fa fa-refresh"></i> Cambiar Precio</Button>
                        </Col>

                        } */}
                        <Col sm={6}>
                            <Button style={{ fontSize: '12px', backgroundColor: 'red', color: 'white' }} block onClick={() => {
                                eliminarProducto(fila);
                                setabriropcionespuntoventa(false);
                                try {
                                    // if (document.getElementById('movimientoreferencia' + (fila))) {
                                    //     document.getElementById('movimientoreferencia' + (fila)).focus()

                                    // }
                                } catch (error) {
                                    console.log(error)
                                }
                            }} >
                                <i className="fa fa-time"></i> Eliminar Articulo</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button size="sm" color="primary" onClick={() => {
                        setabriropcionespuntoventa(false)
                    }}>cerrar</Button>
                </ModalFooter>
            </Modal>
            {/* pdf factura */}
            {/* //factura pdf */}

            {tipodocumento.modidoc == "tirilla 1" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()

                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)

                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && ".Valido hasta " + tipodocumento.vencresolu}{tipodocumento.num_res != "" && <br />}
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.horafecha}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12} style={{ marginBottom: '-15px' }}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => {

                                                                return <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>

                                                            })
                                                        )


                                                }

                                            </tbody>
                                        </Table>

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 2" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black', fontWeight: 'bold' }}>
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'bold' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {Movimiento.nombreven != "" && "Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '14px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Uni</th>
                                                    <th style={{ width: '10%' }}>Tot</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '14px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional.length <= 20 ? item.desadicional : item.desadicional.substring(0, 19) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '14px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody style={{ color: 'black' }}>
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 4" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={2}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody style={{ color: 'black' }}>
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 5" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {Movimiento.nombreven != "" && "Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '12px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Uni</th>
                                                    <th style={{ width: '10%' }}>Tot</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '12px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional.length <= 20 ? item.desadicional : item.desadicional.substring(0, 19) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '12px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody style={{ color: 'black' }}>
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 6" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip + " " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 7" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        No responsable de Iva

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + (listaarticulo[index] && listaarticulo[index].tipoitem != "" ? (tabespacio + "Garantia hasta:" + fechagarantia(listaarticulo[index].tipoitem)) : "")}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />
                                        {"Siguenos en nuestras redes sociales"}<br />
                                        {"@fixmyapples"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 7 electronica" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        No responsable de Iva

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " +tipodocumento.prefijo+" " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + (listaarticulo[index] && listaarticulo[index].tipoitem != "" ? (tabespacio + "Garantia hasta:" + fechagarantia(listaarticulo[index].tipoitem)) : "")}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '9px', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && ".Valido hasta " + tipodocumento.vencresolu}{tipodocumento.num_res != "" && <br />}
                                     
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />
                                        {"Siguenos en nuestras redes sociales"}<br />
                                        {"@fixmyapples"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 8" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + (listaarticulo[index] && listaarticulo[index].tipoitem != "" ? (tabespacio + "Garantia hasta:" + fechagarantia(listaarticulo[index].tipoitem)) : "")}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>

                                    <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={2} >

                                    </Col>


                                </Row >
                                <Row >
                                    <Col sm={3} >

                                    </Col>

                                    <Col sm={7} style={{ color: 'black' }}>
                                        FIRMA
                                    </Col>
                                    <Col sm={1} >

                                    </Col>


                                </Row >
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>



                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 9" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 10" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + sucursal.nombrecomercial} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {/* {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br /> */}
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        No responsable de Iva

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + (listaarticulo[index] && listaarticulo[index].tipoitem != "" ? (tabespacio + "Garantia hasta:" + fechagarantia(listaarticulo[index].tipoitem)) : "")}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />
                                        {"Siguenos en nuestras redes sociales"}<br />
                                        {"@fixmyapples"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 11" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal ? sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.empresa.nombre : JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad + " " + (listaarticulo[index] && listaarticulo[index].unimed)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 12" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '8px', color: 'black' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '8px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '8px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '8px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 13" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad + " " + (listaarticulo[index] && listaarticulo[index].unimed)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip + " - " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 14" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + (tercero && tercero.cedula)}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                        {"Cajero: " + Movimiento.nomusuario}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Cufe:"}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad + " " + (listaarticulo[index] && listaarticulo[index].unimed)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 15" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {"xxxxx"}<br />
                                        {"PARA QUEJAS Y RECLAMOS"}<br />
                                        {JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Servido por " + dataUser.usuario.nombre}<br />
                                        {Movimiento.ndocumento + " - Fecha: " + Movimiento.horafecha}<br />
                                        {"-------------------------------------"}<br />
                                        {(Movimiento.nombrecli != "222222222" && Movimiento.nombrecli != "222222222222") && "Cliente:" + Movimiento.nombrecli}<br />
                                        {"-------------------------------------"}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                {iva != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 16" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + (listaarticulo[index] && listaarticulo[index].tipoitem != "" ? (tabespacio + "Garantia hasta:" + fechagarantia(listaarticulo[index].tipoitem)) : "")}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                    </tr>
                                                }
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                    </tr>
                                                }
                                                {iva != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {/* {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />*/}
                                        {JSON.parse(user).empresa.empresa.nit == "22300927" &&
                                            "Siguenos en nuestras redes sociales"}<br />
                                        {JSON.parse(user).empresa.empresa.nit == "22300927" &&
                                            "@angelsthperfumeria"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 19" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)

                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}
                                        {" - Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />

                                        {"Factura de Venta No: " + Movimiento.ndocumento + " - Fecha: " + Movimiento.horafecha}
                                        {" Cliente: " + Movimiento.nombrecli + " Caja:" + (valorescaja.length > 0 ? valorescaja[0].caja : props.caja)}<br />

                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12} style={{ marginBottom: '-15px' }}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 1 Electronica" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black', wordWrap: 'break-word' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && ".Valido hasta " + tipodocumento.vencresolu}{tipodocumento.num_res != "" && <br />}
                                        {"Factura de Venta No: " + Movimiento.ndocumento + " Caja:" + (valorescaja.length > 0 ? valorescaja[0].caja : props.caja)}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />

                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12} style={{ marginBottom: '-15px' }}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    {consumo != 0 && <th style={{ width: '10%', color: 'black' }}>Cons</th>}
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio)}</td>
                                                                    {item.porimptoconsumo != 0 && <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.porimptoconsumo)}</td>}
                                                                    {item.vlrimptoconsumo != 0 && <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.vlrimptoconsumo)}</td>}
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                {consumo != 0 && <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"ImpoConsumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(consumo)}</th>
                                                </tr>}
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }}>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla transporte 1" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + " - " + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {tipodocumento.consecfac + ": " + Movimiento.ndocumento}<br />
                                        {"Fecha de expedición: " + Movimiento.fecha}<br />
                                        {"Fecha y Hora de salida: " + Movimiento.horafecha}<br />
                                        {"Origen Ruta: " + Movimiento.documento1}<br />
                                        {"Origen Destino: " + Movimiento.documento2}<br />
                                        {"Embarcacion: "}<br />
                                        {nombrebodegaf()}<br />

                                        {"Operario: " + Movimiento.nombreven}<br />

                                        {"DATOS PASAJERO"}<br />
                                        {"Nombre: " + Movimiento.nombrecli}<br />
                                        {"Cedula: " + Movimiento.cliente}<br />
                                        {tercero && tercero.caclientes ? "Origen: " + tercero.caclientes.municipio : "Origen: " + tercero.municipio}<br />
                                        {"RUTA"}<br />
                                        {rutanombre()}<br />
                                        {"Tipo de " + tipodocumento.descripcion}<br />
                                        {"Nivel de servicio:NORMAL"}<br />
                                        {"Cantidad:1"}<br />
                                        {"Numero de pasajeros:" + rutacantidad()}<br />
                                        {"VALOR: $" + fm.from(bruto)}<br />
                                        {"DESCUENTO: $" + fm.from(descuento)}<br />
                                        {"VALOR A PAGAR: $" + fm.from(total)}<br />
                                        {Movimiento.observacion}<br />
                                        {tipodocumento.camdescpp}<br />
                                        {/* {"POLIZA No "}<br />
                                        {"LRCG-121472414-1"}<br /> */}
                                        {/* {"ASEGURDORA ZURICH"}<br />
                                        {"POLIZA No "}<br />
                                        {"LRCG-121472414-1"}<br /> */}
                                        {"Numero de seguros:" + rutaseguros()}<br />
                                        {"Taquillera: " + dataUser.usuario.nombre}<br />
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/logoministeriotransporte.png"} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/logosupertransporte.png"} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>

                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}<br />

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla Electronica transporte 1" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>


                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + " - " + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {tipodocumento.consecfac + ": " + Movimiento.ndocumento}<br />
                                        {"Fecha de expedición: " + Movimiento.fecha}<br />
                                        {"Fecha y Hora de salida: " + Movimiento.horafecha}<br />
                                        {"Origen Ruta: " + Movimiento.documento1}<br />
                                        {"Origen Destino: " + Movimiento.documento2}<br />
                                        {"Embarcacion: "}<br />
                                        {nombrebodegaf()}<br />

                                        {"Operario: " + Movimiento.nombreven}<br />

                                        {"DATOS PASAJERO"}<br />
                                        {"Nombre: " + Movimiento.nombrecli}<br />
                                        {"Cedula: " + Movimiento.cliente}<br />
                                        {tercero && tercero.caclientes ? "Origen: " + tercero.caclientes.municipio : "Origen: " + tercero.municipio}<br />
                                        {"RUTA"}<br />
                                        {rutanombre()}<br />
                                        {"Tipo de " + tipodocumento.descripcion}<br />
                                        {"Nivel de servicio:NORMAL"}<br />
                                        {"Cantidad:1"}<br />
                                        {"Numero de pasajeros:" + rutacantidad()}<br />
                                        {"VALOR: $" + fm.from(bruto)}<br />
                                        {"DESCUENTO: $" + fm.from(descuento)}<br />
                                        {"VALOR A PAGAR: $" + fm.from(total)}<br />
                                        {Movimiento.observacion}<br />
                                        {tipodocumento.camdescpp}<br />
                                        {/* {"POLIZA No "}<br />
                                        {"LRCG-121472414-1"}<br /> */}
                                        {/* {"ASEGURDORA ZURICH"}<br />
                                        {"POLIZA No "}<br />
                                        {"LRCG-121472414-1"}<br /> */}
                                        {"Numero de seguros:" + rutaseguros()}<br />
                                        {"Taquillera: " + dataUser.usuario.nombre}<br />
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/logoministeriotransporte.png"} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                            {<img src={"imagen/logosupertransporte.png"} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                            {/* {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                            {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br /> */}

                                            <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                        </Col>
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>

                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}<br />

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.facturapap == "electronica" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {sucursal ? sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.empresa.nombre : JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />

                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + (tercero && tercero.cedula)}<br />
                                        {"Telefono: " + (tercero && tercero.tel1)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + (tercero && tercero.pais)}<br />
                                        {"Ciudad: " + (tercero && tercero.municipio) + "-" + (tercero && tercero.nompais)}<br />
                                        {"Direccion: " + (tercero && tercero.direccion)}<br />
                                        {"Email: " + (tercero && tercero.email)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    {tipodocumento.modidocenc == "si" && <th style={{ width: '10%' }}>Impo Consumo</th>}
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    {tipodocumento.modidocenc == "si" && <td style={{ textAlign: 'left' }} >{fm.from(item.vlrimptoconsumo)}</td>}
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn == "si" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.modidocenc == "si" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar2(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.facturapap == "pos" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + " - " + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar2(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 1" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 2" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {sucursal.nombrecomercial && sucursal.nombrecomercial.includes('.') ? <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + sucursal.nombrecomercial} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col> :
                                            <img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"Email: " + sucursal && sucursal.nit != "" ? sucursal.email : JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 3" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"Email: " + sucursal && sucursal.nit != "" ? sucursal.email : JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : tipodocumento.tipodoc == "cotizacion" ? 'COTIZACION' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {/* //eectronica mas grande en letra */}
            {tipodocumento.modidoc == "electronica 4" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : tipodocumento.tipodoc == "cotizacion" ? 'COTIZACION' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />

                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '12px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '12px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '12px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 5" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '350px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 6" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Responsable de IVA - No Autorretenedor, No gran Contribuyente"}<br />

                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + tercero ? tercero.cedula : Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 7" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '250px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 8" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip + "  " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 5 responsable de iva" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '350px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {" Responsable de Iva - Actividad Económica 1102 Tarifa 11.04 X 1000"}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 1" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? (item.descrip + " " + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {/* el señor edwin tiene esto para mostrar el nombre de otra cosa que no sea el real y ocultar el nit y correo */}
            {tipodocumento.modidoc == "carta 2" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ?
                                            "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' :
                                                tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                    tipodocumento.tipodoc == "plan separe" ? 'Plan Separe' :
                                                        tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                            tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 3" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdfelectronica()
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre.replace(' ', tabespacio)}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Anterior:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(total)}</th> */}
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldocliente())}</th>

                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total + totalsaldocliente())}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 3 lote" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdfelectronica()
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre.replace(' ', tabespacio)}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>lote</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.lote}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Anterior:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(total)}</th> */}
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldocliente())}</th>

                                                </tr>
                                                {/* <tr>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total + totalsaldocliente())}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 4" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd.toUpperCase()}<br />
                                        {"NIT: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Regimen Simplificado: "}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Correo: " + JSON.parse(user).empresa.empresa.email}<br />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ?
                                            "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' :
                                                tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                    tipodocumento.tipodoc == "plan separe" ? 'Plan Separe' :
                                                        tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                            tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.referencia + ' - '}{item.desadicional && item.desadicional != "" ? (item.descrip + "\n" + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 5" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>

                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }

                                                {/* <tr>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 6" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Foto</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    {listaarticulo[index] ?
                                                                        <td style={{ textAlign: 'left' }} >
                                                                            <img
                                                                                style={{ maxWidth: '170px', maxHeight: '170px', margin: 'auto', display: 'block' }}
                                                                                width="90%"
                                                                                src={`${props.servidor + listaarticulo[index].clas10 + '?llave=' + dataUser.usuario.llaveempresa}`}
                                                                                alt="factin"
                                                                            />
                                                                        </td> : <td style={{ textAlign: 'left' }} ></td>
                                                                    }
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? (item.descrip + " " + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronicadescargar()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 7" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd.toUpperCase()}<br />
                                        {"NIT: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Regimen Simplificado: "}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Correo: " + JSON.parse(user).empresa.empresa.email}<br />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.consecfac}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Equipo</th>
                                                    <th style={{ width: '20%' }}>Descripcion</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.desadicional + ' - '}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>

                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 8" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre.replace(' ', tabespacio)}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}<br />


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '15px', color: 'black' }}>

                                        {"El valor a pagar despues de la Fecha seria:"}<br />
                                        {" 6 AL 10:" + fm2.from((total * 1.01))}<br />
                                        {" 11 AL 15:" + fm2.from(((total * 1.01) * 1.005))}<br />
                                        {"16 AL 28:" + fm2.from((((total * 1.01) * 1.005) * 1.02))}<br />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Anterior:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(total)}</th> */}
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldocliente())}</th>

                                                </tr>
                                                {/* <tr>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total + totalsaldocliente())}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 9" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>

                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />
                                        {
                                            notascredito2.map((dato) => {
                                                return <Col sm={12}>
                                                    <Button style={{ fontSize: '13px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                                    }} >
                                                        <i >{dato.concepto + " - " + dato.ndocumento}</i> </Button>
                                                </Col>
                                            })
                                        }
                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Medio de Pago:" + tabespacio + buscarnombremediopago(Movimiento.mpago) + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }

                                                {/* <tr>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '13px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '13px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "comanda 1" &&
                <Modal size="lg" isOpen={previsualizar} onOpened={(e) => {
                    if (tipodocumento.busplu == "si") {
                        previsualizarpdf()
                        setprevisualizar(false)
                        if (!estoybuscando) {
                            if (tipodocumento.facturapap != "electronica") {
                                setprevisualizar(false)


                            }

                        }
                    }
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />

                                        {"Comanda No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + Movimiento.nomusuario}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th >Cant</th>
                                                    <th >Descr</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.filter(p => p.referencia != "").map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip + " - " + item.desadicional}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Este no es numero para pedir servicio al restaurante"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            <Modal size="lg" isOpen={previsualizarenviocorreo}>
                <ModalHeader>
                    Correo
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <Input value={correosenvio} onChange={(dato) => {
                                setcorreosenvio(dato.target.value)
                            }}></Input>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        enviocorreofacturaelectronica()
                        setprevisualizarenviocorreo(false)
                        setcorreosenvio("")
                    }}>Enviar</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setprevisualizarenviocorreo(false)
                        setcorreosenvio("")
                    }}>cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={abrirbodegatranslado}>
                <ModalHeader>
                    Bodega
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup inline={true}>
                                <Label>{'Bodega'}</Label>
                                <InputGroup>
                                    <Input value={bodegatranslado} bsSize="sm" onKeyUp={(e) => {
                                        if ((e.key == "Enter" || e.target.value == "?") && e.target.value == "") {
                                            e.preventDefault()

                                            setVerModalbodegas2(true)

                                        }
                                    }} />
                                    <Button color="primary" size="sm" className="mr-2"
                                        onClick={() => {
                                            setVerModalbodegas2(true)

                                        }}
                                    >
                                        <i className="fas fa-pen-alt"></ i >
                                    </Button>
                                </InputGroup>

                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button size="sm" color="primary" onClick={() => {
                        if (bodegatranslado != "" && bodegatranslado != "-") {
                            var bodegayaesta = false
                            Movimiento.famovimiento.forEach(element => {
                                if (element.bodega == bodegatranslado) {
                                    bodegayaesta = true
                                }
                            });
                            if (bodegayaesta && tipodocumento.tipodoc == "translado") {
                                Swal.fire(
                                    'Opps!',
                                    'No Puede Seleccionar Esta Bodega',
                                    'error'
                                )
                            } else {
                                setabrirbodegatranslado(false)
                                terminarVenta()
                            }

                        } else {
                            Swal.fire(
                                'Opps!',
                                'Debe Seleccionar Una Bodega',
                                'error'
                            )
                        }
                    }}>Guardar</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setabrirbodegatranslado(false)

                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={creartercero}>
                <ModalHeader>
                    Tercero
                </ModalHeader>
                <ModalBody>
                    <CrearTercero escribirenterceromov={(rrr) => { cambiartercerosporvalor(rrr) }} servidor={props.servidor} setcreartercero={(creartercero) => {
                        setcreartercero(creartercero)

                    }} />
                </ModalBody>
                <ModalFooter>

                    <Button size="sm" color="primary" onClick={() => {
                        setcreartercero(false)

                    }}>cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={vercambiodecostoprecio}>
                <ModalHeader>
                    Precios
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >{listaarticulocostoprecio[fila] && Movimiento.famovimiento[fila] &&
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Costo Promedio</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="codigo" value={listaarticulocostoprecio[fila].preccost} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Ultimo Costo</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="nombre" value={listaarticulocostoprecio[fila].ultcosto} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Nuevo Costo Promedio</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="codigo" value={listaarticulocostoprecio[fila].costonuevo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Nuevo Ultimo Costo</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Movimiento.famovimiento[fila].precio} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Referencia</th>
                                                <th>Lista de Precio</th>
                                                <th>Precio</th>
                                                <th>Rentabilidad</th>
                                                <th>Nuevo Precio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (listaarticulocostoprecio[fila] && listaarticulocostoprecio[fila].inarticuloslistaprecio.length < 1) ? (
                                                    <tr>
                                                    </tr>
                                                ) :
                                                    (
                                                        listaarticulocostoprecio[fila] && listaarticulocostoprecio[fila].inarticuloslistaprecio.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input disabled bsSize="sm" type='text' ultimo={false} tabla={'inarticuloslistaprecio'} name="referencia" value={listaarticulocostoprecio[fila].codigo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup inline={true}>
                                                                        <InputGroup>
                                                                            <Input disabled bsSize="sm" name="listadeprecio" index={index} value={listaarticulocostoprecio[fila].inarticuloslistaprecio[index]['listadeprecio']} />

                                                                        </InputGroup>

                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input disabled bsSize="sm" type='text' ultimo={true} tabla={'inarticuloslistaprecio'} name="precio" value={listaarticulocostoprecio[fila].inarticuloslistaprecio[index].precio} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={true} onChange={(e) => { handleChangegrillainarticuloslistapreciorentabilidad(e, index) }} tabla={'inarticuloslistaprecio'} name="rentabilidad" value={listaarticulocostoprecio[fila].inarticuloslistaprecio[index].rentabilidad} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={true} onChange={(e) => { handleChangegrillainarticuloslistaprecio(e, index) }} tabla={'inarticuloslistaprecio'} name="precionuevo" value={listaarticulocostoprecio[fila].inarticuloslistaprecio[index].precionuevo} />
                                                                    </FormGroup>
                                                                </td>


                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>}


                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => { setvercambiodecostoprecio(false) }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isModalOpen} toggle={toggleModaliframemovimiento} onOpened={() => {
                try {
                    const iframe = document.getElementById("pdfIframe");
                    if (iframe) {
                        if (tipodocumento.docauto != "si") {
                            iframe.contentWindow.print(); // Abre el diálogo de impresión
                        }
                        try {
                            document.getElementById("movimientoreferencia" + fila).focus();
                        } catch (error) {
                        }
                    }
                } catch (error) {
                }


            }} size="lg">
                <ModalHeader toggle={toggleModaliframemovimiento} >PDF Viewer</ModalHeader>
                <ModalBody>
                    <iframe
                        id="pdfIframe"
                        src={pdfDataUrl}
                        width="100%"
                        height="500px"
                        title="PDF Viewer"
                    ></iframe>
                </ModalBody>
                <ModalFooter>
                    <Button id="modaliframemovimiento" size="sm" color="danger" onClick={() => {
                        setIsModalOpen(false)
                        try {
                            document.getElementById("movimientoreferencia" + fila).focus()
                        } catch (error) {

                        }
                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Buscar tituloencabezado={"Buscar Tipo Documento"} filtrar={(coincidencia) => { filtrartipodocumentos(coincidencia) }} busqueda={busquedatipodocumentos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"descripcion"} dato={""} dato2={""} verModal={verModaltipodocumentos} cerrarModal={() => { cerrarModaltipodocumentos() }} seleccionar={(seleccionado) => {
                seleccionartipodocumentos(seleccionado)
            }} />

            <Buscar tituloencabezado={"Buscar Vendedor"} filtrar={(coincidencia) => { filtrarvendedors(coincidencia) }} busqueda={busquedavendedors} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalvendedors} cerrarModal={() => { cerrarModalvendedors() }} seleccionar={(seleccionado) => {
                seleccionarvendedors(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Medio de Pago"} filtrar={(coincidencia) => { filtrarmediopagos(coincidencia) }} busqueda={busquedamediopagos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalmediopagos} cerrarModal={() => { cerrarModalmediopagos() }} seleccionar={(seleccionado) => {
                seleccionarmediopagos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Productos"} filtrar={(coincidencia) => { filtrararticulos(coincidencia) }} busqueda={busquedaarticulos} encabezado={["Codigo", "Nombre", "Ref", "Col", mostrarexistencia ? "Existencia" : "Prec3", "Prec1", "Prec2", "Prec3", "Ult Costo"]} codigo={"codigo"} nombre={"nombre"} dato={"clas1"} dato2={"clas2"} dato3={"precio1"} dato4={"precio2"} dato5={"precio3"} dato6={mostrarexistencia ? "existencia" : "precio3"} dato7={dataUserpermi.FACTURA && dataUserpermi.FACTURA.editar ? "ultcosto" : "precio1"} valorabuscar={valorabuscarproducto} verModal={verModalarticulos} cerrarModal={() => { cerrarModalarticulos() }} seleccionar={(seleccionado) => {
                seleccionararticulos(seleccionado)
                setvalorabuscarproducto("")
            }} />
            <Buscar tituloencabezado={"Buscar Bodega"} filtrar={(coincidencia) => { filtrarbodegas(coincidencia) }} busqueda={busquedabodegas} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalbodegas} cerrarModal={() => { cerrarModalbodegas() }} seleccionar={(seleccionado) => {
                seleccionarbodegas(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Bodega"} filtrar={(coincidencia) => { filtrarbodegas(coincidencia) }} busqueda={busquedabodegas} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalbodegas2} cerrarModal={() => { cerrarModalbodegas2() }} seleccionar={(seleccionado) => {
                setbodegatranslado(seleccionado.codigo)
                cerrarModalbodegas2()
            }} />
            <Buscar tituloencabezado={"Buscar Centro de Costo"} filtrar={(coincidencia) => { filtrarccostos(coincidencia) }} busqueda={busquedaccostos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalccostos} cerrarModal={() => { cerrarModalccostos() }} seleccionar={(seleccionado) => {
                seleccionarccostos(seleccionado)
            }} />
            <BuscarMovimiento tituloencabezado={"Buscar Movimientos"} filtrar={(coincidencia) => { buscartodoslosmovimientos2(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Tercero", "Nombre", "Fecha", "Doc 1", "Total"]} sub={"faencmovi"} codigo={"concepto"} nombre={"ndocumento"} dato={"cliente"} dato2={"nombrecli"} dato3={"fecha"} dato5={"documento1"} dato6={"total"} verModal={verModalMovimientos} cerrarModal={() => {
                cerrarModalMovimientos()
                setestoybuscando(false)
            }} seleccionar={(seleccionado) => {
                seleccionarMovimientos(seleccionado)
                setestoybuscando(true)
            }} />
            <Buscarmovimientomultiple seleccion={[]} tituloencabezado={"Buscar "} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Tercero", "Nombre"]} sub={"faencmovi"} codigo={"concepto"} nombre={"ndocumento"} dato={"cliente"} dato2={"nombrecli"} verModal={verModalMovimientosremision} cerrarModal={() => { cerrarModalMovimientosremision() }} seleccionar={(seleccionado) => {
                seleccionarMovimientosremisiones(seleccionado)
            }} />
            <Buscar setcreartercero={(dd) => { setcreartercero(dd) }} creartercero={true} tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social", "Tipo"]} codigo={"codigo"} nombre={"razsoc"} dato={"tercero"} dato2={""} verModal={verModalterceros} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                seleccionarterceros(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social", "Tipo"]} codigo={"codigo"} nombre={"razsoc"} dato={"tercero"} dato2={""} verModal={verModaltercerosdocumento2} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                seleccionartercerosdocumento2(seleccionado)
            }} />
            <Esperando verModal={esperando} />
            <Buscar tituloencabezado={"Buscar Precio"} filtrar={(coincidencia) => { }} busqueda={busquedalistaprecioarticulo} encabezado={["Lista", "Precio"]} codigo={"listadeprecio"} nombre={"precio"} dato={""} dato2={""} verModal={verModallistaprecioarticulo} cerrarModal={() => { setVerModallistaprecioarticulo(false) }} seleccionar={(seleccionado) => {
                document.getElementById("movimiento" + "precio" + fila).value = seleccionado.precio
                var simulatedEvent = {
                    target: document.getElementById("movimiento" + "precio" + fila),
                    type: "change",
                    bubbles: true,
                    cancelable: true,
                    value: seleccionado.precio // Cambia el valor si lo deseas
                };

                handleChangegrillafaencmovi(simulatedEvent)
                console.log("seleccionado")
                document.getElementById("movimiento" + "precio" + fila).setAttribute('abrilistadeprecio', 'si')

                setVerModallistaprecioarticulo(false)

            }} />
            <BuscarMovimiento tituloencabezado={(tipodocumento.tipodoc == "factura") ? 'Buscar Devolucion' : (tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? 'Buscar Factura' : ""} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Tercero", "Nombre", "Fecha", "Doc 1", "total"]} sub={"faencmovi"} codigo={"concepto"} nombre={"ndocumento"} dato={"cliente"} dato2={"nombrecli"} dato3={"fecha"} dato5={"documento1"} dato6={"total"} verModal={verModalMovimientosDevoluciones} cerrarModal={() => { cerrarModalMovimientosDevoluciones() }} seleccionar={(seleccionado) => {
                seleccionarMovimientosDevoluciones(seleccionado)
            }} />
            <BuscarBono concepto={Movimiento.concepto} ndocumento={Movimiento.ndocumento} tituloencabezado={"Buscar Bono"} abrir={abrirbonos} servidor={props.servidor} setabrirbanco={(ab) => { setabrirbonos(ab) }} seleccionar={(value) => {
                setvalorbono(value.valor)
                setcodigobono(value.codigo)
                handleChangeformapago2(value.valor)
                setabrirbonos(false)
            }} />
            <Tipopdf setprevisualizar={() => { setprevisualizartirillaplantilla(false) }} previsualizarpdf={previsualizarpdf} previsualizar={previsualizartirillaplantilla} bruto={bruto} descuento={descuento} subTotal={subTotal} iva={iva} consumo={consumo}
                retefuente={retefuente} reteiva={reteiva} reteica={reteica} total={total} dataUser={dataUser}
                tercero={tercero} valorescaja={valorescaja} certificado={certificado}
                setprevisualizarenviocorreo={(DD) => { setprevisualizarenviocorreo(DD) }}
                enviocorreofacturaelectronica={() => { enviocorreofacturaelectronica2(tercero.email) }}
                setcorreosenvio={(DD) => { setcorreosenvio(DD) }}
                calcularDigitoVerificacion={(DD) => { calcularDigitoVerificacion(DD) }}
                totalunidades={(DD) => { totalunidades(DD) }}
                sucursal={sucursal}
                plantillapdf={plantillapdf} user={user} tipodocumento={tipodocumento} Movimiento={Movimiento} setcertificado={(dd) => { setcertificado(dd) }} />
            <TipopdfCarta setprevisualizar={() => { setprevisualizartirillaplantillacarta(false) }} previsualizarpdf={previsualizarpdfelectronica} previsualizar={previsualizartirillaplantillacarta} bruto={bruto} descuento={descuento} subTotal={subTotal} iva={iva} consumo={consumo}
                retefuente={retefuente} reteiva={reteiva} reteica={reteica} total={total} dataUser={dataUser}
                tercero={tercero} valorescaja={valorescaja} certificado={certificado}
                setprevisualizarenviocorreo={(DD) => { setprevisualizarenviocorreo(DD) }}
                setcorreosenvio={(DD) => { setcorreosenvio(DD) }}
                calcularDigitoVerificacion={(DD) => {
                    calcularDigitoVerificacion(DD)
                }}
                totalunidades={(DD) => {
                    totalunidades(DD)
                }}

                sucursal={sucursal}
                plantillapdf={plantillapdf} user={user} tipodocumento={tipodocumento} Movimiento={Movimiento} buscarnombremediopago={(pp) => {
                    buscarnombremediopago(pp)
                }}
                mediopagos={mediopagos}
                setcertificado={(dd) => { setcertificado(dd) }} buscarnombremun={(gg) => { buscarnombremun(gg) }} notascredito2={notascredito2} />

        </Row>
    )
}

export default Venta;