import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Autosuggest from 'react-autosuggest';


const modeloEmpresa = {
    empresa: {
        nit: '',
        nombre: '',
        descripcion: '',
        telefono: '',
        direccion: '',
        email: '',
        celular: '',
        llave: '',
        servidor: '10.108.0.2',
        basededatos: '',
        activo: 'true',
        fechaultimopago: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
        maximafechadeuso: (new Date().getFullYear()+1 )+ "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
        identificador: ''
    },
    config: {
        contribuyente_nit: '',
        carpeta_documentos: '',
        pin_software: '20191',
        ruta_certificado: '',
        clave_certificado: '',
        smtp_host: 'smtp-relay.sendinblue.com',
        smtp_puerto: '587',
        smtp_ssl: 'True',
        smtp_usuario: 'eventosdianelec@gmail.com',
        smtp_clave: 'rYs5G3n1qmCBFXjg',
        identificador_software: 'promedio',
        carpeta_bd: '',
        contribuyente_razon_social: '',
        contribuyente_tipo: '1',
        especialidad: '',
        contribuyente_responsabilidad_fiscal: 'R-99-PN',
        contribuyente_regimen_fiscal: 'No aplica',
        contribuyente_matricula_mercantil: '',
        direccion_contribuyente_linea: '',
        direccion_contribuyente_municipio: '',
        direccion_contribuyente_departamento: '',
        direccion_contribuyente_pais: '',
        contribuyente_email: '',
        contribuyente_telefono: '',
        software_en_pruebas: 'false',
        usar_set_pruebas: 'false',
        identificador_set_pruebas: '',
        rango_numero_resolucion: '',
        rango_fecha_resolucion: '2000-01-01',
        rango_vigencia_desde: '2000-01-01',
        rango_vigencia_hasta: '2000-01-01',
        rango_clave_tecnica: '',
        rango_prefijo: '0',
        rango_desde: '0',
        rango_hasta: '0',
        encabezado_pdf: '',
        ruta_logo: '',
        nusuarios: '3',


    }
}
// if (e.target.name == "idRol") {
//     value = e.target.value
// } else if (e.target.name == "esActivo") {
//     value = (e.target.value == "true" ? true : false)
// } else {
//     value = e.target.value;
// }

const Empresa = (props) => {

    const [empresa, setEmpresa] = useState(modeloEmpresa);
    const [pendiente, setPendiente] = useState(true);
    const [empresas, setEmpresas] = useState([]);
    const [roles, setRoles] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [key, setKey] = useState('Datos');
    const [modulos, setModulos] = useState([])
    const [modulosdefecto, setmodulosdefecto] = useState([])
    const [modulo, setModulo] = useState(modeloEmpresa)
    const [municipios, setmunicipio] = useState([])
    const [nombremunicipios, setnombremunicipios] = useState("")
    const [departamentos, setdepartamento] = useState([])
    const [editar, seteditar] = useState(false)
    const [certificado, setcertificado] = useState(false)

    const handleChange = (e) => {

        console.log(e.target.value)
        console.log(empresas)
        let value;
        value = e.target.value;
        if (e.target.getAttribute('config') == "true") {
            let conf = { ...empresa.config }

            conf[e.target.name] = value
            if (e.target.name == 'direccion_contribuyente_municipio') {
                conf['direccion_contribuyente_departamento'] = value.substring(0, 2)
                setnombremunicipios(value)
            }
            setEmpresa({
                ...empresa,
                ['config']: conf
            })
        } else {
            var target = e.target.name
            if (e.target.name == 'contribuyente_nit') {
                target = 'nit'
            }
            if (e.target.name == 'contribuyente_razon_social') {
                target = 'nombre'
            }
            if (e.target.name == 'contribuyente_telefono') {
                target = 'telefono'
            }
            if (e.target.name == 'direccion_contribuyente_linea') {
                target = 'direccion'
            }
            if (e.target.name == 'contribuyente_email') {
                target = 'email'
            }
            let __empresa = { ...empresa.empresa }

            __empresa[target] = value

            setEmpresa({
                ...empresa,
                ['empresa']: __empresa
            })
        }

        console.log(empresa)
    }

    const handleChangemodulos = (e, index) => {

        console.log(e.target.getAttribute('value'))

        var value;
        value = e.target.getAttribute('value');
        var moduloscambio = modulos.map((modulo, index2) => {
            if (index2 == index) {
                if (value != 'false') {
                    var mod = {
                        ...modulo,
                        [e.target.getAttribute('name')]: false
                    }
                    return mod
                } else {
                    var mod = {
                        ...modulo,
                        [e.target.getAttribute('name')]: true
                    }
                    return mod

                }

            } else {
                return modulo
            }
        });

        var empresa = []
        setModulo(empresa)
        setModulos(moduloscambio)

        console.log(modulos)
    }

    const obtenerRoles = async () => {
        let response = await fetch("api/rol/Lista");
        if (response.ok) {
            let data = await response.json()
            setRoles(data)
        }
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Empresaapi/' + coincidencia);

        if (response.ok) {
            let data = await response.json()
            setEmpresas(data)
            setPendiente(false)
        }
    }
    const obtenermunicipiosdepartamentos = async () => {
        let response = await fetch(props.servidor + '/Empresaapi/municipio');

        if (response.ok) {
            let data = await response.json()
            setmunicipio(data.municipio)
            setdepartamento(data.departamento)
            console.log(data.municipio)
            console.log(data.departamento)
        }
    }
    const obtenerModulos = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Moduloapi/' + coincidencia);
        var modulosconpermisos = [];
        var modulosconpermisosdefecto = [];
        if (response.ok) {
            let data = await response.json()
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                modulosconpermisos.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    buscar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    imprimir: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    eliminar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    adicionar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    electronico: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    nitempresa: empresa.empresa.nit,

                })
                modulosconpermisosdefecto.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    buscar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    imprimir: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    eliminar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    adicionar: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    electronico: element.codigo != "MAESTROS" ? element.codigo != "RESTAURANTE" ? true : false : false,
                    nitempresa: empresa.empresa.nit,

                })
            }
            setModulos(modulosconpermisos)
            setmodulosdefecto(modulosconpermisosdefecto)
        }
    }
    useEffect(() => {
        // obtenerRoles();
        obtenerEmpresas('');
        obtenerModulos('');
        obtenermunicipiosdepartamentos();
    }, [])
    const getSuggestionValue = (sugerencia) => {

        return sugerencia.codigo + " - " + sugerencia.nombre
    }
    const onSuggestionsFetchRequested = ({ value }) => {

        const api = fetch(props.servidor + "/Empresaapi/municipio/" + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setmunicipio(dataJson.municipio)

            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    //funcion que nos permite borrar las sugerencias
    const onSuggestionsClearRequested = () => {
        setmunicipio([])
    }


    const sugerenciaSeleccionada = (e, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        console.log(e.currentTarget)
        console.log(suggestion)

        let value;
        value = suggestion.codigo;
        let conf = empresa.config;

        conf['direccion_contribuyente_municipio'] = value
        conf['direccion_contribuyente_departamento'] = value.substring(0, 2)

        setnombremunicipios(suggestion.nombre)

        setEmpresa({
            ...empresa,
            ['config']: conf
        })


        // Swal.fire({
        //     title: suggestion.marca +" - " + suggestion.descripcion,
        //     text:"Ingrese la cantidad",
        //     input: 'text',
        //     inputAttributes: {
        //         autocapitalize: 'off'
        //     },
        //     showCancelButton: true,
        //     confirmButtonText: 'Aceptar',
        //     cancelButtonText: 'Volver',
        //     showLoaderOnConfirm: true,
        //     preConfirm: (inputValue) => {


        //         if (isNaN(parseFloat(inputValue))) {
        //             setA_Busqueda("")
        //             Swal.showValidationMessage(
        //                 "Debe ingresar un valor númerico"
        //             )
        //         } else {

        //             let producto = {
        //                 idProducto: suggestion.idProducto,
        //                 descripcion: suggestion.descripcion,
        //                 cantidad: parseInt(inputValue),
        //                 precio: suggestion.precio,
        //                 total: suggestion.precio * parseFloat(inputValue)
        //             }
        //             let arrayProductos = []
        //             arrayProductos.push(...productos)
        //             arrayProductos.push(producto)

        //             setProductos((anterior) => [...anterior, producto])
        //             calcularTotal(arrayProductos)
        //         }


        //     },
        //     allowOutsideClick: () => !Swal.isLoading()

        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         setA_Busqueda("")
        //     } else {
        //         setA_Busqueda("")
        //     }
        // })
    }


    const columns = [
        {
            name: 'Nombre',
            selector: row => row.empresa.nombre,
            sortable: true,
        },
        {
            name: 'Correo',
            selector: row => row.empresa.email,
            sortable: true,
        },
        {
            name: 'Telefono',
            selector: row => row.empresa.telefono,
            sortable: true,
        },
        {
            name: 'Fecha de Uso',
            selector: row => row.empresa.maximafechadeuso,
            sortable: true,
        },
        {
            name: 'Estado',
            // selector: row => row.esActivo,
            selector: row => true,
            sortable: true,
            cell: row => {
                let clase;
                // clase = row.esActivo ? "badge badge-info p-2" : "badge badge-danger p-2"
                clase = true ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    // <span className={clase}>{row.esActivo ? "Activo" : "No Activo"}</span>
                    <span className={clase}>{true ? "Activo" : "No Activo"}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarEmpresa(row.idEmpresa)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModal = (data) => {
        var datauso = { ...data }
        var empresa = {
            empresa: datauso.empresa,
            config: datauso.config
        }
        setEmpresa(empresa);
        setModulos(datauso.modulos);
        let mun = municipios.filter(p => p.codigo == empresa.config.direccion_contribuyente_municipio)
        var nombremun = ""
        if (mun.length != 0) {
            nombremun = mun[0].nombre

        }
        setnombremunicipios(nombremun)

        setVerModal(!verModal);
        seteditar(true)

    }

    const cerrarModal = () => {
        setEmpresa(modeloEmpresa)
        setVerModal(!verModal);
        seteditar(false)
        setKey('Datos')
        setModulos(modulosdefecto)
    }

    const guardarCambios = async () => {

        // delete empresa.idRolNavigation;
        var empresaenvio = empresa;
        empresaenvio.modulos = modulos
        empresaenvio.certificado = certificado

        let response;
        if (!editar) {
            response = await fetch(props.servidor + "/Empresaapi", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(empresaenvio)
            })

        } else {
            response = await fetch(props.servidor + "/Empresaapi", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(empresaenvio)
            })
        }

        if (response.ok) {
            await obtenerEmpresas('');
            setEmpresa(modeloEmpresa)
            setVerModal(!verModal);
            Swal.fire(
                'Guardado Exitosamente!',
                'La empresa fue guardada exitosamente',
                'success'
            )
        } else {
            let data = await response.text()
            Swal.fire(
                'Error al Guardar!',
                data,
                'error'
            )
        }

    }

    const base64certificado = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const renderSuggestion = (sugerencia) => (
        <span>
            {sugerencia.codigo + " - " + sugerencia.nombre}
        </span>
    )
    const eliminarEmpresa = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el empresa",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/empresa/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            // obtenerEmpresas();

                            Swal.fire(
                                'Eliminado!',
                                'El empresa fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        })
    }


    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Empresas
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataEmpresas" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerEmpresas(document.getElementById('buscardataEmpresas').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={empresas}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        progressComponent={<a>Cargando...</a>}
                        noDataComponent={<a>No se encontraron datos</a>}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModal}>
                <ModalHeader>
                    Empresa
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        {(true) &&
                            <Tab eventKey="Datos" title="Datos">
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Nit</Label>
                                            <Input disabled={editar} bsSize="sm" name="contribuyente_nit" onChange={handleChange} value={empresa.empresa.nit} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Razon Social</Label>
                                            <Input bsSize="sm" name="contribuyente_razon_social" onChange={handleChange} value={empresa.empresa.nombre} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Telefono</Label>
                                            <Input bsSize="sm" name="contribuyente_telefono" onChange={handleChange} value={empresa.empresa.telefono} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Celular</Label>
                                            <Input bsSize="sm" name="celular" onChange={handleChange} value={empresa.empresa.celular} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Direccion</Label>
                                            <Input bsSize="sm" name="direccion_contribuyente_linea" onChange={handleChange} value={empresa.empresa.direccion} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input bsSize="sm" name="contribuyente_email" onChange={handleChange} value={empresa.empresa.email} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Servidor</Label>
                                            <Input disabled={editar} bsSize="sm" name="servidor" onChange={handleChange} value={empresa.empresa.servidor} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Base Datos</Label>
                                            <Input disabled={editar} bsSize="sm" name="basededatos" onChange={handleChange} value={empresa.empresa.basededatos} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Niveles Cuentas</Label>
                                            <Input bsSize="sm" name="identificador" onChange={handleChange} value={empresa.empresa.identificador} />
                                        </FormGroup>
                                    </Col>

                                </Row>

                            </Tab>}
                        {(true) &&
                            <Tab eventKey="Configuraciones" title="Configuraciones">
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Carpeta Documentos</Label>
                                            <Input bsSize="sm" name="carpeta_documentos" config={"true"} onChange={handleChange} value={empresa.config.carpeta_documentos} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Tipo de Costo</Label>
                                            <Input type="select" config={"true"} onChange={handleChange} style={{ fontSize: '13px' }} name="identificador_software" value={empresa.config.identificador_software}  >
                                                <option value="promedio">Costo Promedio</option>
                                                <option value="ultimo">Ultimo Costo</option>
                                            </Input>
                                            {/* <Input bsSize="sm" name="identificador_software" config={"true"} onChange={handleChange} value={empresa.config.identificador_software} /> */}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Ruta Certificado</Label>
                                            <Input bsSize="sm" name="ruta_certificado" config={"true"} onChange={handleChange} value={empresa.config.ruta_certificado} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Clave Certificado</Label>
                                            <Input bsSize="sm" name="clave_certificado" config={"true"} onChange={handleChange} value={empresa.config.clave_certificado} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>BD Factin</Label>
                                            <Input bsSize="sm" name="carpeta_bd" config={"true"} onChange={handleChange} value={empresa.config.carpeta_bd} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>En Pruebas</Label>
                                            <Input bsSize="sm" type={"select"} name="software_en_pruebas" config={"true"} onChange={handleChange} value={empresa.config.software_en_pruebas} >
                                                <option value={"false"}>No</option>
                                                <option value={"true"}>Si</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Identificador Set de Pruebas</Label>
                                            <Input bsSize="sm" name="identificador_set_pruebas" config={"true"} onChange={handleChange} value={empresa.config.identificador_set_pruebas} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Encabezado PDF</Label>
                                            <Input bsSize="sm" name="encabezado_pdf" config={"true"} onChange={handleChange} value={empresa.config.encabezado_pdf} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Logo</Label>
                                            <Input bsSize="sm" name="ruta_logo" config={"true"} onChange={handleChange} value={empresa.config.ruta_logo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Matricula Mercantil</Label>
                                            <Input bsSize="sm" name="contribuyente_matricula_mercantil" config={"true"} onChange={handleChange} value={empresa.config.contribuyente_matricula_mercantil} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Tab>}
                        <Tab eventKey="Datos Especificos" title="Datos Especificos">
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Tipo</Label>
                                        <Input bsSize="sm" type={"select"} config={"true"} name="contribuyente_tipo" onChange={handleChange} value={empresa.config.contribuyente_tipo} >
                                            <option value={"1"}>Persona Juridica</option>
                                            <option value={"2"}>Persona Natural</option>


                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Responsabilidad Fiscal</Label>
                                        <Input bsSize="sm" type={"select"} name="contribuyente_responsabilidad_fiscal" config={"true"} onChange={handleChange} value={empresa.config.contribuyente_responsabilidad_fiscal} >
                                            <option value={"R-99-PN"}>No responsable</option>
                                            <option value={"O-13"}>Gran contribuyente</option>
                                            <option value={"O-15"}>Autorretenedor</option>
                                            <option value={"O-23"}>Agente de retención IVA</option>
                                            <option value={"O-47"}>Régimen simple de tributación</option>


                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Regimen Fiscal</Label>
                                        <Input bsSize="sm" type={"select"} name="contribuyente_regimen_fiscal" config={"true"} onChange={handleChange} value={empresa.config.contribuyente_regimen_fiscal} >
                                            <option value={"No aplica"}>No aplica</option>
                                            <option value={"R-99-PN"}>Otro</option>
                                            <option value={"O-13"}>Gran contribuyente</option>
                                            <option value={"O-15"}>Autorretenedor</option>
                                            <option value={"O-23"}>Agente de retención IVA</option>
                                            <option value={"O-47"}>Régimen simple de tributación</option>


                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Municipio</Label>
                                        {/* <Input value={empresa.config.DIRECCION_CONTRIBUYENTE_MUNICIPIO} bsSize="sm" type={"select"} name="DIRECCION_CONTRIBUYENTE_MUNICIPIO" config={"true"} onChange={handleChange}  >
                                            <option value={0}>Seleccionar</option>
                                            {
                                                municipios.map((item) => (<option value={item.codigo}>{item.nombre}</option>))
                                            }

                                        </Input> */}
                                        <Autosuggest
                                            suggestions={municipios}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={{
                                                placeholder: "Buscar producto",
                                                value: nombremunicipios,
                                                name: "direccion_contribuyente_municipio",
                                                config: "true",
                                                onChange: handleChange
                                            }}
                                            onSuggestionSelected={sugerenciaSeleccionada}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Especialidad</Label>
                                        <Input bsSize="sm" type={"select"} config={"true"} name="especialidad" onChange={handleChange} value={empresa.config.especialidad} >
                                            <option value={""}>Otro</option>
                                            <option value={"drogueria"}>Drogueria</option>


                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Departamento</Label>
                                        <Input value={empresa.config.direccion_contribuyente_departamento} bsSize="sm" disabled type={"select"} name="direccion_contribuyente_departamento" config={"true"} onChange={handleChange}  >
                                            <option value={0}>Seleccionar</option>
                                            {
                                                departamentos.map((item) => (<option value={item.codigo}>{item.nombre}</option>))
                                            }

                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Certificado</Label>
                                        <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                            var archivoxml = e.target.files[0]
                                            var cerbase64 = await base64certificado(archivoxml)
                                            setcertificado(cerbase64)

                                        }} >


                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Pais</Label>
                                        <Input bsSize="sm" disabled type={"select"} name="direccion_contribuyente_pais" config={"true"} onChange={handleChange} value={"CO"} >
                                            <option value={"CO"}>Colombia</option>


                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                        </Tab>
                        <Tab eventKey="Modulos" title="Modulos">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Codigo</th>
                                                <th>Nombre</th>
                                                <th>Editar</th>
                                                <th>Buscar</th>
                                                <th>Imprimir</th>
                                                <th>Eliminar</th>
                                                <th>Adicionar</th>
                                                <th>Buscar Todo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (modulos.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">Sin modulos</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        modulos.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >{item.codigomodulo}</td>
                                                                <td>{item.nombremodulo}</td>
                                                                <td>{<a href="#" value={item.editar} onClick={(e) => { handleChangemodulos(e, index) }} name='editar' className={item.editar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.editar ? "Activo" : "No Activo"}</a>}</td>
                                                                <td>{<a href="#" value={item.buscar} onClick={(e) => { handleChangemodulos(e, index) }} name='buscar' className={item.buscar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.buscar ? "Activo" : "No Activo"}</a>}</td>
                                                                <td>{<a href="#" value={item.imprimir} onClick={(e) => { handleChangemodulos(e, index) }} name='imprimir' className={item.imprimir ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.imprimir ? "Activo" : "No Activo"}</a>}</td>
                                                                <td>{<a href="#" value={item.eliminar} onClick={(e) => { handleChangemodulos(e, index) }} name='eliminar' className={item.eliminar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.eliminar ? "Activo" : "No Activo"}</a>}</td>
                                                                <td>{<a href="#" value={item.adicionar} onClick={(e) => { handleChangemodulos(e, index) }} name='adicionar' className={item.adicionar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.adicionar ? "Activo" : "No Activo"}</a>}</td>
                                                                <td>{<a href="#" value={item.electronico} onClick={(e) => { handleChangemodulos(e, index) }} name='electronico' className={item.electronico ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.electronico ? "Activo" : "No Activo"}</a>}</td>

                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>
                        </Tab>
                    </Tabs>





                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Empresa;