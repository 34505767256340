
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect } from "react";
import "./css/Venta.css"
import { UserContext } from "../context/UserProvider";
import { jsPDF } from "jspdf";
import { FormatMoney } from 'format-money-js'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';
import BuscarMovimiento from '../componentes/Buscarmovimiento'
import Buscarmovimientomultiple from '../componentes/Buscarmovimientomultiple2'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
import BuscarCentrodeCosto from '../componentes/Buscar2/CentrodeCosto'
import BuscarTerceros from '../componentes/Buscar2/Terceros'
const tabespacio = '\u00A0';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modelotipodocumento = {
    codigo: "",
    descripcion: "",
    tipodoc: "",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "nada",
    formapago: "si",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "si",
    descporc: "",
    ivasn: "si",
    ivaporc: "",
    rete: "si",
    ica: "si",
    reteiva: "si",
    imptosn: "si",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "",
    mvendedor: "si",
    captionvend: "",
    docu1: "si",
    captiondoc1: "",
    val_doc1: "",
    docu2: "si",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",

}

const modeloTerceros = {
    caclientes: {
        tercero: "",
        clase: "",
        codigo: "",
        cedula: "",
        digito: "",
        razsoc: "",
        nom1: "",
        nom2: "",
        ape1: "",
        ape2: "",
        codigomun: "",
        coddepar: "",
        codpais: "",
        email: "",
        contacto: "",
        beneficiario: "",
        repleg: "",
        reg_merc: "",
        direccion: "",
        tel1: "",
        tel2: "",
        tel3: "",
        barrio: "",
        fechnac: "",
        interes: "",
        cupocre: "",
        plazo: "",
        vendedor: "",
        ccosto: "",
        precsug: "",
        actecono: "",
        feching: "",
        fechulne: "",
        regtrib: "",
        resfis: "",
        desccom: "",
        desccom2: "",
        desccom3: "",
        desccom4: "",
        autoreteft: "",
        estado: "",
        salant1: "",
        saldeb1: "",
        salcre1: "",
        venretefuente: "",
        venbasefte: "",
        venporcfte: "",
        veniva: "",
        venbaseiva: "",
        venporciva: "",
        venreteiva: "",
        venbasereteiva: "",
        venporcreteiva: "",
        venreteica: "",
        venbaseica: "",
        venporica: "",
        salact1: "",
        ciudad: "",
        pais: "",
        clas1: "",
        clas2: "",
        clas3: "",
        clas4: "",
        clas5: "",
        reteica: "",
        personat: "",
        auto: "",
        salant2: "",
        saldeb2: "",
        salcre2: "",
        salact2: "",
        brutop1: "",
        brutop2: "",
        ivap: "",
        desctop1: "",
        desctop2: "",
        reteftep: "",
        reteivap: "",
        reteica2p: "",
        imptoconsumop: "",
        brutoa1: "",
        brutoa2: "",
        ivaa: "",
        desctoa1: "",
        desctoa2: "",
        retftea: "",
        reteivaa: "",
        reteicaa: "",
        imptoconsa: "",
        creadopor: "",
        borradopor: "",
        modificpor: "",
        municipio: "",
        cambioimp: "",
        flete: "",
        cumplemes: "",
        cumpledia: "",
        cumpleano: "",
        horafecha: "",
        sexo: "",
        zona: "",
        clas6: "",
        clas7: "",
        clas8: "",
        clas9: "",
        clas10: "",
        autorexpro: "",
        cod_postal: "",
        nompais: "",
        campo: "",
        comretefte: "",
        combasefte: "",
        comporcfte: "",
        comreteiva: "",
        combasereteiva: "",
        comporcreteiva: "",
        comiva: "",
        combaseiva: "",
        comporciva: "",
        comreteica: "",
        combaseica: "",
        comporica: "",
        vencree: "",
        venbacree: "",
        venporcree: "",
        comcree: "",
        combacree: "",
        comporcree: "",
        Eliminado: "",
    },
    direcciones: []
}
const modelonat = [
    {
        codigo: '1',
        nombre: 'Colgaap'
    }, {
        codigo: '2',
        nombre: 'Axl'
    }, {
        codigo: '3',
        nombre: 'Depreciacion'
    }, {
        codigo: '4',
        nombre: 'Aj.D'
    }, {
        codigo: '5',
        nombre: 'Amortizacion'
    }, {
        codigo: '6',
        nombre: 'Aj.Am'
    }, {
        codigo: '7',
        nombre: 'IFRS/NIC'
    }, {
        codigo: '8',
        nombre: 'Depreciacion IFRS'
    }, {
        codigo: '9',
        nombre: 'Revaluacion'
    }, {
        codigo: '10',
        nombre: 'Depreciacion Revaluacion'
    }, {
        codigo: '11',
        nombre: 'Costo Atribuido'
    }, {
        codigo: '12',
        nombre: 'Depreciacion Costo Atribuido'
    }, {
        codigo: '13',
        nombre: 'Deterioro'
    }
]
const modelocontmovidet = {

    item: '',
    codcuenta: '',
    nat: '1',
    documentoref: '',
    valordeb: '',
    valorcre: '',
    acreedor: '',
    valorbase: '',
    codcentrocostos: '',
    comentarios: '',
    codact: '',//ahora esto es la fecha de conciliacion
    coddif: '',
    codcompro: '',
    tercero: '',
    ano: '',
    periodo: '',
    tipodoc: '',
    ndocumento: '',
    descripcion: '',
    conciliado: '',
    activosfij: '',
    horafecha: '',
    sucursal: '',
}
const modelocontabilidad = {
    tipodoc: '',
    ndocumento: '',
    ano: new Date().getFullYear() + '',
    sucursal: '',
    periodo: ("0" + (new Date().getMonth() + 1)).slice(-2) + '',
    fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
    codcompro: '',
    descripcion: '',
    debito: '',
    credito: '',
    diferencia: '',
    origen: '',
    niifdb: '',
    niifcr: '',
    niifdif: '',
    revelacion: '',
    horafecha: '',
    banco: '',
    acreedor: '',
    nomacreedor: '',
    ndocumentoref: 0,
    bcdetbanco: []
}

const Venta = (props) => {
    const { user } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [documentoCliente, setDocumentoCliente] = useState("")
    const [nombreCliente, setNombreCliente] = useState("")
    const [movimiento, setmovimiento] = useState(modelocontabilidad)
    const [puedoanular, setpuedoanular] = useState(true)

    const [movimientos, setmovimientos] = useState(modelocontabilidad)
    const [roles, setRoles] = useState(modelocontabilidad)
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [busquedatipodocumentos, setbusquedatipodocumentos] = useState([]);
    const [verModaltipodocumentos, setVerModaltipodocumentos] = useState(false);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [Ctcomprobantess, setCtcomprobantess] = useState([]);
    const [fila, setfila] = useState(0);
    const [busquedaMovimientos, setbusquedaMovimientos] = useState([]);
    const [busquedaBcbancos, setbusquedaBcbancos] = useState([]);
    const [Bcbancos, setBcbancos] = useState([]);
    const [previsualizarenviocorreo, setprevisualizarenviocorreo] = useState(false);


    const [nuevo, setnuevo] = useState(false);
    const [editar, seteditar] = useState(false);
    const [tipodocumento, settipodocumento] = useState(modelotipodocumento)
    const [previsualizar, setprevisualizar] = useState(false);

    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [Caclientess, setCaclientess] = useState([]);
    const [Facturasvivas, setFacturasvivas] = useState([]);
    const [busquedacaclientes, setbusquedacaclientes] = useState([]);
    const [contadorcaclientes, setcontadorcaclientes] = useState(100);
    const [Ctcentrocostos, setCtcentrocostos] = useState([]);
    const [verModalMovimientos, setVerModalMovimientos] = useState(false);
    const [verModalBcbancos, setVerModalBcbancos] = useState(false);
    const [cuentabanco, setcuentabanco] = useState("");
    const [tercero, settercero] = useState(modeloTerceros)
    const [busquedaterceros, setbusquedaterceros] = useState([]);
    const [verModalterceros, setVerModalterceros] = useState(false);
    const [verModalmovimientostercero, setVerModalmovimientostercero] = useState(false);
    const [terceros, setterceros] = useState([]);
    const [movimientostercero, setmovimientostercero] = useState([])
    const [verModalMovimientosremision, setVerModalMovimientosremision] = useState(false);
    const [abriricuentas, setabriricuentas] = useState(false);
    const [abrirterceros, setabrirterceros] = useState(false);
    const [abriricentrodecosto, setabriricentrodecosto] = useState(false);
    const [nombrecliente, setnombrecliente] = useState("");
    const [nombrecliente2, setnombrecliente2] = useState("");
    const reestablecer = () => {

    }
    const guardarmovimiento = () => {
        try {
            var dddd = new Date(movimiento.fecha)
            if (parseInt(movimiento.fecha.split("-")[0]) > 2000) {

            } else {
                Swal.fire(
                    'Opps!',
                    'Fecha no Existe',
                    'error'
                )
                return
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'Fecha no Existe',
                'error'
            )
            return
        }
        terminarVenta();
    }
    const cerrarModalMovimientos = () => {
        setVerModalMovimientos(!verModalMovimientos);
        // setbusquedaMovimientos(Movimientos)
    }

    const filtrarterceros = async (coincidencia) => {
        var _codigomuns = await buscarcoincidenciasterceros(coincidencia)
        setbusquedaterceros(_codigomuns)
    }
    const cerrarModalMovimientosremision = () => {
        setVerModalMovimientosremision(false);
        // setbusquedaMovimientos(Movimientos)
    }
    const cerrarModalterceros = () => {
        setVerModalterceros(false);
        setbusquedaterceros(terceros)
    }
    const cerrarModalmovimientostercero = () => {
        setVerModalmovimientostercero(false);
    }
    const buscartodasterceros = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setterceros(dataJson)
                    if (tipodocumento.tipodoc == "factura") {
                        setbusquedaterceros(dataJson.filter(e => e.tercero == "proveedor"))
                    } else {
                        if (tipodocumento.tipodoc == "compra") {
                            setbusquedaterceros(dataJson.filter(e => e.tercero == "proveedor"))

                        } else {
                            setbusquedaterceros(dataJson.filter(e => e.tercero == "proveedor"))

                        }
                    }

                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscarcaclientesunico = async (coincidencia, tercero) => {
        try {

            if (!tercero) {
                tercero = "proveedor"
            }
            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico2/' + coincidencia + '/' + tercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarsaldodecuenta = async (coincidencia, tercero) => {
        try {

            if (!tercero) {
                tercero = "proveedor"
            }
            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TesoreriaApi/saldocuenta/' + coincidencia + '/' + tercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const cambiarterceros = async (e) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e.target.value + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var data2 = data.caclientes
                if (data2.codigo != '') {
                    seleccionarterceros(data2)
                } else {
                    Swal.fire(
                        'Opps!',
                        'Tercero no existe',
                        'error'
                    )
                    return
                }

                // setPendiente(false)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const cerrarModalBcbanco = () => {
        setVerModalBcbancos(!verModalBcbancos);
        // setbusquedaMovimientos(Movimientos)
    }

    const filtrarMovimientos = async (coincidencia) => {
        var _codigomuns = movimientos.filter((dato) => {
            if (dato.bcencbanco.tipodoc.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.bcencbanco.ndocumento + "").toUpperCase().includes(coincidencia.toUpperCase()) || (dato.bcencbanco.acreedor + "").toUpperCase().includes(coincidencia.toUpperCase()) || (dato.bcencbanco.fecha + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaMovimientos(_codigomuns)
    }
    const filtrarBancos = async (coincidencia) => {
        var _codigomuns = movimientos.filter((dato) => {
            if (dato.codbanco.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.razonsocia + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaMovimientos(_codigomuns)
    }
    const filtrarmovimientostercero = async (coincidencia) => {
        var _codigomuns = movimientos.filter((dato) => {
            if (dato.tipodoc.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.doc + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaMovimientos(_codigomuns)
    }

    const seleccionarMovimientos = (e) => {

        var _faencmovi = e.bcencbanco
        var _movimiento = _faencmovi
        tipodocumentos.map((dato, index) => {
            if (dato.codigo.toUpperCase() == _faencmovi.tipodoc.toUpperCase()) {
                settipodocumento(dato)
            }

        })
        // terceros.map((dato, index) => {

        //     if (dato.codigo.toUpperCase() == _faencmovi.cliente.toUpperCase()) {
        //         settercero(dato)
        //     }

        // })
        buscarterceronombre2(_faencmovi.acreedor, "proveedor")
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/TesoreriaApi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/TesoreriaApi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(e)
            })
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then(async (dataJson) => {
                    _movimiento.bcdetbanco = dataJson
                    var buscli = []
                    _movimiento.bcdetbanco.forEach(element => {
                        buscli.push(element.acreedor)
                    });
                    var clientes = []
                    for (let i = 0; i < buscli.length; i++) {
                        const element = buscli[i];
                        var client = await buscarcoincidenciasterceros(element)
                        if (client) {
                            for (let j = 0; j < client.length; j++) {
                                const element2 = client[j];
                                clientes.push(element2)
                            }
                        }
                    }
                    setbusquedacaclientes(clientes);

                    // for (let i = 0; i < _movimiento.bcdetbanco.length; i++) {
                    //     const element = _movimiento.bcdetbanco[i];
                    //     var artt = listaarticulo
                    //     var e = {}
                    //     e = await buscararticulounico(element.referencia)
                    //     artt[i] = e
                    //     setlistaarticulo(artt)
                    // }

                    // calculartotalesmovimiento(dataJson)
                    setmovimiento(_movimiento)
                    cerrarModalMovimientos()
                    seteditar(true)
                    setnuevo(false)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setmovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }

    }

    const obtenerBcbancos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Bcbancoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setBcbancos(data)
                setbusquedaBcbancos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarterceronombre2 = async (codigo, terce) => {
        try {
            var client = await buscarcaclientesunico(codigo, terce)
            // return `${acreedor} - ${razsoc}`;
            var puente = codigo + " - " + client.caclientes.razsoc
            console.log(puente)
            setnombrecliente2(client.caclientes.razsoc)
            // return ""
        } catch (error) {
            // return ""
        }
    }
    const buscarterceronombre = async (codigo, terce) => {
        try {
            var client = await buscarcaclientesunico(codigo, terce)
            // return `${acreedor} - ${razsoc}`;
            var puente = codigo + " - " + client.caclientes.razsoc
            console.log(puente)
            setnombrecliente(puente)
            // return ""
        } catch (error) {
            // return ""
        }
    }
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const seleccionarMovimientosremisiones = async (coincidencia) => {

        if (coincidencia.length > 0) {

            agregarcuentasmultiples(coincidencia)

            cerrarModalMovimientosremision()
        }
    }

    const totaldebito = () => {
        var debito = 0
        movimiento.bcdetbanco.forEach(element => {
            if (element.nat == "1" || element.nat == "2" || element.nat == "3" || element.nat == "4" || element.nat == "5" || element.nat == "6") {
                debito = debito + Number(element.valordeb + "")

            }
        });
        return debito
    }
    const totalcredito = () => {
        var credito = 0
        movimiento.bcdetbanco.forEach(element => {
            if (element.nat == "1" || element.nat == "2" || element.nat == "3" || element.nat == "4" || element.nat == "5" || element.nat == "6") {
                credito = credito + Number(element.valorcre)
            }
        });
        return credito
    }
    const totaldebitoniff = () => {
        var debito = 0
        movimiento.bcdetbanco.forEach(element => {
            if (element.nat == "7" || element.nat == "8" || element.nat == "9" || element.nat == "10" || element.nat == "11" || element.nat == "12" || element.nat == "13") {
                debito = debito + Number(element.valordeb + "")

            }
        });
        return debito
    }
    const totalcreditoniff = () => {
        var credito = 0
        movimiento.bcdetbanco.forEach(element => {
            if (element.nat == "7" || element.nat == "8" || element.nat == "9" || element.nat == "10" || element.nat == "11" || element.nat == "12" || element.nat == "13") {
                credito = credito + Number(element.valorcre)

            }
        });
        return credito
    }
    const obtenerCaclientess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCaclientess(data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const obtenersaldosvivos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TesoreriaApi/saldovivo/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setFacturasvivas(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerCtcentrocostos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcentrocostos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscartodastipodocumentos('');
        obtenerCtcomprobantess('');
        obtenerPlandecuentass('');
        obtenerBcbancos('');
        obtenerCtcentrocostos('');
    }, [])

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        try {
            buscarterceronombre(movimiento.bcdetbanco[fila].acreedor, movimiento.bcdetbanco[fila].tercero)

        } catch (error) {

        }



    }, [fila])
    const obtenerCtcomprobantess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcomprobantess(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionartipodocumentos2 = (e) => {
        var tii = e.target.value.toUpperCase()

        let value = tipodocumentos.find(p => p.codigo == tii)
        if (value) {
            var _Movimiento = { ...movimiento }
            _Movimiento['tipodoc'] = value.codigo
            _Movimiento['codcompro'] = value.comprob
            // if (value.ctrlmov == 'si') {

            //     _Movimiento['fecha'] = fechahoy
            // }
            setmovimiento(_Movimiento)
            setRoles([])
            settipodocumento(value)
        } else {
            Swal.fire(
                'Opps!',
                'Tipo de Documento no Existe',
                'error'
            )
        }
    }
    const seleccionartipodocumentos = async (e) => {
        let value = e
        var _Movimiento = { ...movimiento }
        _Movimiento['tipodoc'] = value.codigo
        _Movimiento['codcompro'] = value.comprob

        setmovimiento(_Movimiento)
        setRoles([])
        cerrarModaltipodocumentos()
        settipodocumento(value)
    }
    const seleccionarbcbancos = async (e) => {
        let value = e
        var _Movimiento = { ...movimiento }
        _Movimiento['banco'] = value.codbanco

        setmovimiento(_Movimiento)
        setRoles([])
        cerrarModalBcbanco()
        setcuentabanco(value.numcuentap)
    }
    const eliminarProducto = (id) => {
        var _Movimiento = { ...movimiento }
        var _datos = { ...movimiento }['bcdetbanco']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _Movimiento['bcdetbanco'] = _enviodatos.filter(p => p != undefined)
        // setMovimientos(_Movimientos)
        setmovimiento([])
        setmovimiento(_Movimiento)

        // let listaproductos = productos.filter(p => p.idProducto != id)

        // setProductos(listaproductos)

        // calcularTotal(listaproductos)
    }
    const seleccionarterceros = async (e) => {
        let value = e
        var _Movimiento = { ...movimiento }
        _Movimiento['acreedor'] = value.codigo
        _Movimiento['nomacreedor'] = value.razsoc
        _Movimiento['tercero'] = value.tercero

        setmovimiento(_Movimiento)
        setRoles([])
        cerrarModalterceros()
        settercero(value)
        obtenersaldosvivos(value.codigo)
    }

    const seleccionarmovimientostercero = async (e) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "bcdetbanco"
        var name = "documentoref"
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ["documentoref"]: e.doc,
                    ["tipodocref"]: e.tipodoc,

                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        setVerModalmovimientostercero(false)
        console.log(movimiento)
        setRoles({ codig: '' })
    }
    const cerrarModaltipodocumentos = () => {
        setVerModaltipodocumentos(!verModaltipodocumentos);
        setbusquedatipodocumentos(tipodocumentos)
    }
    const filtrartipodocumentos = async (coincidencia) => {
        var _codigomuns = tipodocumentos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.descripcion.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedatipodocumentos(_codigomuns)
    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    dataJson = dataJson.filter((dato) => {
                        if (dato.tipodoc == 'bancos' || dato.tipodoc == "causaciones" || dato.tipodoc == "anticipoaproveedores") {
                            var sipuedeusartipodedocumento = true
                            var sipuedeusartipodedocumento2 = false
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumento" && element.acceso[0] == "1" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento = false
                                }
                            });
                            var contt = true
                            props.permisosusuario.forEach(element => {
                                if (element.tipo == "tipodocumentounico") {
                                    contt = false
                                }
                                if (element.tipo == "tipodocumentounico" && element.acceso[0] == "2" && element.tipodoc == dato.codigo) {
                                    sipuedeusartipodedocumento2 = true
                                } else {
        
                                }
                            });
                            if (contt) {
                                sipuedeusartipodedocumento2 = true
                            }
                            if (sipuedeusartipodedocumento && sipuedeusartipodedocumento2) {
                                return dato
        
                            }
                        
                        }
                    })
                    settipodocumentos(dataJson)
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChangegrillafaencmovi22 = async (event, value, name, codi) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "bcdetbanco"

        var vll = event.target.value
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })

        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }


    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const handleChangegrillafaencmoviconsaldo = async (event, value, name, codi, saldo) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "bcdetbanco"
        // if (event.target.name != undefined) {
        //     name = event.target.name
        // }
        var vll = ''
        if (value) {
            vll = value[codi]
        }
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })
        if (name == 'acreedor') {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['tercero']: value['tercero']
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        if (name == 'codcuenta') {
            if (value.idanexo == 's' || value.idanexo == 'b') {
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            ['acreedor']: movimiento.acreedor,
                            ['valordeb']: saldo
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }

        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }
    const handleChangegrillafaencmovi = async (event, value, name, codi) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "bcdetbanco"
        // if (event.target.name != undefined) {
        //     name = event.target.name
        // }
        var vll = ''
        if (value) {
            vll = value[codi]
        }
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })
        if (name == 'acreedor') {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['tercero']: value['tercero']
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        if (name == 'codcuenta') {
            if (value.idanexo == 's' || value.idanexo == 'b') {
                _enviodatos = _enviodatos.map((dato, index) => {
                    if (index == fila) {
                        var dat = {
                            ...dato,
                            ['acreedor']: movimiento.acreedor
                        }
                        return dat
                    } else {
                        return dato
                    }
                })
            }

        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }
    const buscarcuentanombre = (codigo) => {
        var caclientes2 = Plandecuentass.map((dat) => {
            return dat
        })
        var tercero = caclientes2.filter(e => e.codcuenta == codigo)
        return tercero.length != 0 ? tercero[0].descripcion : ""
    }
    const handleChangegrillafaencmovi2 = async (event, name) => {

        var _Movimiento = { ...movimiento }
        var subtabla = "bcdetbanco"
        if (event.target.name != undefined) {
            name = event.target.name
        }
        var _datos = { ...movimiento }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: event.target.value
                }
                return dat
            } else {
                return dato
            }
        })

        if (event.target.name == "valordeb" || event.target.name == "valorcre") {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var value = Plandecuentass.find(p => p.codcuenta == dato['codcuenta'])
                    if (value) {
                        if (value.idanexo == "b") {
                            var dat = {
                                ...dato,
                                ['valorbase']: Number(event.target.value) ? Number(event.target.value) * (100 / (Number(value.porcbase + ""))) : 0
                            }
                            return dat
                        } else {
                            return dato
                        }
                    } else {
                        return dato
                    }

                } else {
                    return dato
                }
            })
        }
        if (tipodocumento.tipodoc == "causaciones" && name == "documentoref") {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ["documentoref2"]: event.target.value
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        _Movimiento[subtabla] = _enviodatos
        // setmovimientos(_Movimientos)

        setmovimiento(_Movimiento)
        console.log(movimiento)
        setRoles({ codig: '' })

    }
    const handleOpen = async (e) => {
        if (e) {
            var value = e.target.value
            if (value) {
                if (value.length >= 3) {
                    var clientes = await buscarcoincidenciasterceros(value)
                    setbusquedacaclientes(clientes);
                }
            }
        }
    };

    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataj = []
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == 'plan separe') {
                    dataj = data.filter(e => e.tercero == "proveedor")

                } else {
                    if (tipodocumento.tipodoc == "compra") {
                        dataj = data.filter(e => e.tercero == "proveedor")

                    } else {
                        dataj = data.filter(e => e.tercero == "proveedor")

                    }
                }

                return dataj
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const handleChange = (e) => {
        // if (tipodocumento.codigo == "") {

        let value = e.target.value
        console.log(e.target)
        var _Movimiento = { ...movimiento }
        _Movimiento[e.target.name] = value
        if (e.target.name == "fecha") {
            try {
                _Movimiento['periodo'] = value.split('-')[1]
                _Movimiento['ano'] = value.split('-')[0]
            } catch (error) {

            }

        }
        setmovimiento(_Movimiento)

        setRoles([])
        // } else {
        //     Swal.fire(
        //         'Opps!',
        //         'Tiene que seleccionar un Tipo de Documento primero',
        //         'error'
        //     )
        // }



    }


    const tipodedocumentoexiste = async (e) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + e + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo != '') {
                    return true
                } else {
                    return false
                }

                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const verificar = (_movimiento) => {
        var bcencbanco = _movimiento.bcencbanco
        var famovimiento = _movimiento.bcdetbanco
        var verificado = true
        var mensg = ''
        if (bcencbanco.tipodoc == "" || bcencbanco.fecha == "") {
            console.log(bcencbanco.tipodoc)
            console.log(bcencbanco.fecha)
            return false
        }

        famovimiento.map((dato, index) => {
            if (dato.codcuenta == "" || dato.nat == "") {
                verificado = false
                mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " esta en blanco o la naturaleza lo esta. Item: " + (index + 1)
            } else {
                var cuents = Plandecuentass.map((dat) => {
                    return dat
                })
                var cuent = cuents.filter(p => p.codcuenta == dato.codcuenta)
                if (cuent.length > 0) {
                    if (cuent[0].idanexo == "s" || cuent[0].idanexo == "b") {
                        if (dato.acreedor == "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " si maneja tercero. Item: " + (index + 1)
                        }
                    } else {
                        if (dato.acreedor != "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " no maneja tercero. Item: " + (index + 1)
                        }
                    }
                    if (cuent[0].idcentrocosto == "s") {
                        if (dato.codcentrocostos == "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " si maneja Centro de Costo. Item: " + (index + 1)
                        }
                    } else {
                        if (dato.codcentrocostos != "") {
                            verificado = false
                            mensg = mensg + " " + " Cuenta: " + dato.codcuenta + " no maneja Centro de Costo. Item: " + (index + 1)
                        }
                    }
                }
            }
            if (tipodocumento.tipodoc == "causaciones" && dato.documentoref == "") {
                verificado = false

            }

        })
        var verff = {
            verificado: verificado,
            mensaje: mensg
        }
        return verff
    }
    const reiniciar = () => {
        setnuevo(false)
        seteditar(false)
        setmovimiento(modelocontabilidad)
    }

    const guardarlogdeerrores = (contenido) => {
        const a = document.createElement("a");
        const archivo = new Blob([contenido], { type: 'text/plain' });
        const url = URL.createObjectURL(archivo);
        a.href = url;
        a.download = 'log';
        a.click();
        URL.revokeObjectURL(url);
    }

    const buscartodoslosmovimientos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/TesoreriaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/TesoreriaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setmovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setmovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const buscarmovimientostercero = (tercero2) => {
        try {
            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/CtabonoApi/movimientosproveedor/' + tercero.codigo + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    setmovimientostercero(dataJson)

                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const agregarcuentasmultiples = async (facturas) => {
        if (movimiento.tipodoc != "") {
            var _Movimiento = { ...movimiento }
            var _nuevosdatos = []
            facturas.forEach(element => {
                _nuevosdatos.push(
                    {

                        item: '',
                        codcuenta: element.codcuenta,
                        nat: '1',
                        documentoref: element.documentoref,
                        valordeb: element.valor,
                        valorcre: '',
                        acreedor: element.acreedor,
                        valorbase: '',
                        codcentrocostos: '',
                        comentarios: '',
                        codact: '',//ahora esto es la fecha de conciliacion
                        coddif: '',
                        codcompro: movimiento.codcompro,
                        tercero: '',
                        ano: '',
                        periodo: '',
                        tipodoc: movimiento.tipodoc,
                        ndocumento: movimiento.ndocumento,
                        descripcion: '',
                        conciliado: '',
                        activosfij: '',
                        horafecha: '',
                        sucursal: '',
                        documentorefcon: element.tipodoc,
                        documentoref2: element.ndocumento,

                    }

                )


            });
            var nohaycuentabanco = _nuevosdatos.filter(p => p.codcuenta == cuentabanco)
            if (nohaycuentabanco.length == 0) {
                var valortotal = 0
                _nuevosdatos.forEach(element => {
                    valortotal = valortotal + element.valordeb
                });
                _nuevosdatos.push(
                    {

                        item: '',
                        codcuenta: cuentabanco,
                        nat: '1',
                        documentoref: '',
                        valordeb: '',
                        valorcre: valortotal,
                        acreedor: '',
                        valorbase: '',
                        codcentrocostos: '',
                        comentarios: '',
                        codact: '',//ahora esto es la fecha de conciliacion
                        coddif: '',
                        codcompro: movimiento.codcompro,
                        tercero: '',
                        ano: '',
                        periodo: '',
                        tipodoc: movimiento.tipodoc,
                        ndocumento: movimiento.ndocumento,
                        descripcion: '',
                        conciliado: '',
                        activosfij: '',
                        horafecha: '',
                        sucursal: '',

                    }

                )
            }
            _Movimiento['bcdetbanco'] = _nuevosdatos

            var buscli = []
            _nuevosdatos.forEach(element => {
                buscli.push(element.acreedor)
            });
            var clientes = []
            for (let i = 0; i < buscli.length; i++) {
                const element = buscli[i];
                var client = await buscarcoincidenciasterceros(element)
                if (client) {
                    for (let j = 0; j < client.length; j++) {
                        const element2 = client[j];
                        clientes.push(element2)
                    }
                }
            }
            setbusquedacaclientes(clientes);

            setmovimiento(_Movimiento)
        } else {
            Swal.fire(
                'Opps!',
                'No ha seleccionado un Tipo de Documento',
                'error'
            )
        }
    }
    const agregarcuentas = () => {
        if (movimiento.tipodoc != "") {
            var _Movimiento = { ...movimiento }
            var _datos = { ...movimiento }['bcdetbanco']
            var _nuevosdatos = _datos.concat(
                {

                    item: '',
                    codcuenta: '',
                    nat: '1',
                    documentoref: '',
                    valordeb: '',
                    valorcre: '',
                    acreedor: '',
                    valorbase: '',
                    codcentrocostos: '',
                    comentarios: movimiento.descripcion,
                    codact: '',//ahora esto es la fecha de conciliacion
                    coddif: '',
                    codcompro: _Movimiento.codcompro,
                    tercero: '',
                    ano: '',
                    periodo: '',
                    tipodoc: _Movimiento.tipodoc,
                    ndocumento: _Movimiento.ndocumento,
                    descripcion: '',
                    conciliado: '',
                    activosfij: '',
                    horafecha: '',
                    sucursal: '',
                    documentorefcon: '',
                    documentoref2: 0
                }

            )
            if (_nuevosdatos.length == 1) {
                _nuevosdatos = _nuevosdatos.concat(
                    {

                        item: '',
                        codcuenta: cuentabanco,
                        nat: '1',
                        documentoref: '',
                        valordeb: '',
                        valorcre: '',
                        acreedor: '',
                        valorbase: '',
                        codcentrocostos: '',
                        comentarios:  movimiento.descripcion,
                        codact: '',//ahora esto es la fecha de conciliacion
                        coddif: '',
                        codcompro: _Movimiento.codcompro,
                        tercero: '',
                        ano: '',
                        periodo: '',
                        tipodoc: _Movimiento.tipodoc,
                        ndocumento: _Movimiento.ndocumento,
                        descripcion: '',
                        conciliado: '',
                        activosfij: '',
                        horafecha: '',
                        sucursal: '',

                    }

                )
            }
            _Movimiento['bcdetbanco'] = _nuevosdatos
            setmovimiento(_Movimiento)

        } else {
            Swal.fire(
                'Opps!',
                'No ha seleccionado un Tipo de Documento',
                'error'
            )
        }
    }
    const terminarVenta = () => {
        // if (!cajaabierta) {
        //     Swal.fire(
        //         'Opps!',
        //         'La caja esta cerrada para este dia',
        //         'error'
        //     )
        //     return
        // }
        if (movimiento.bcdetbanco.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen Movimientos',
                'error'
            )
            return
        }
        var tott = totaldebito() - totalcredito()
        var tott2 = totaldebitoniff() - totalcreditoniff()
        var tottredondeado = Math.round(tott)
        var tottredondeado2 = Math.round(tott2)
        if (tottredondeado != 0 || tottredondeado2 != 0) {
            Swal.fire(
                'Opps!',
                'Asiento Descuadrado',
                'error'
            )
            return
        }
        var tipodcoumentoecxiste = tipodedocumentoexiste(movimiento.tipodoc)
        if (!tipodcoumentoecxiste) {
            Swal.fire(
                'Opps!',
                'Este tipo de documento no existe',
                'error'
            )
            return
        } var movimientoguardado = {
            bcencbanco: { ...movimiento },
            bcdetbanco: movimiento.bcdetbanco
        }

        delete movimientoguardado.bcencbanco.bcdetbanco;
        movimientoguardado.bcencbanco.ndocumento = Number(movimientoguardado.bcencbanco.ndocumento + "")
        movimientoguardado.bcencbanco.debito = Number(movimientoguardado.bcencbanco.debito + "")
        movimientoguardado.bcencbanco.credito = Number(movimientoguardado.bcencbanco.credito + "")
        movimientoguardado.bcencbanco.diferencia = Number(movimientoguardado.bcencbanco.diferencia + "")

        for (let i = 0; i < movimientoguardado.bcdetbanco.length; i++) {
            movimientoguardado.bcdetbanco[i].item = 0
            movimientoguardado.bcdetbanco[i].ndocumento = Number(movimientoguardado.bcdetbanco[i].ndocumento + "")
            movimientoguardado.bcdetbanco[i].documentoref = Number(movimientoguardado.bcdetbanco[i].documentoref + "")
            movimientoguardado.bcdetbanco[i].valordeb = Number(movimientoguardado.bcdetbanco[i].valordeb + "")
            movimientoguardado.bcdetbanco[i].valorcre = Number(movimientoguardado.bcdetbanco[i].valorcre + "")
            movimientoguardado.bcdetbanco[i].valorbase = Number(movimientoguardado.bcdetbanco[i].valorbase + "")
            movimientoguardado.bcdetbanco[i].activosfij = Number(movimientoguardado.bcdetbanco[i].activosfij + "")

        }
        movimientoguardado.bcdetbanco.forEach(element => {

        });
        let dt = JSON.parse(user)
        var verificado = verificar(movimientoguardado)
        if (!verificado.verificado) {
            Swal.fire(
                'Opps!',
                'Datos sin llenar' + ' ' + verificado.mensaje,
                'error'
            )
            return
        }
        const api = fetch("" + props.servidor + "/TesoreriaApi?llave=" + dt.usuario.llaveempresa + "&nuevo=" + nuevo + "&usuario=" + dt.usuario.cedula + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(movimientoguardado)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {
                // reestablecer();
                var data = dataJson;
                if (nuevo) {
                    Swal.fire(
                        '' + movimiento.tipodoc + ' Creado!',
                        'Numero de ' + movimiento.tipodoc + ' : ' + data.movimiento.bcencbanco.ndocumento,
                        'success'
                    )
                    setnuevo(false)
                    seteditar(true)
                } else {
                    Swal.fire(
                        '' + movimiento.tipodoc + ' Editado!',
                        'Numero de ' + movimiento.tipodoc + ' : ' + movimiento.ndocumento,
                        'success'
                    )
                    setnuevo(false)
                    seteditar(true)
                }
                var _movv = data.movimiento.bcencbanco
                _movv.bcdetbanco = data.movimiento.bcdetbanco
                setmovimiento(_movv)




            }).catch(async (error) => {
                try {
                    var ee = await error.json()
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + ee.mensaje,
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", ee.mensaje)
                    guardarlogdeerrores(ee.mensaje)
                } catch (error) {
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + 'Error de conexion',
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", error)
                    guardarlogdeerrores('Error de conexion')
                }

            })

    }
    return (
        <Row>
            <Col sm={12} >
                <Card style={{ borderColor: '#fff' }}>
                    <CardBody>
                        <Row>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', color: 'black' }} color={nuevo ? "warning" : "success"} block onClick={() => {
                                    setnuevo(true)
                                    setmovimiento(modelocontabilidad)
                                    setcuentabanco("")
                                    setnombrecliente2("")
                                }} >
                                    <i className="fa fa-plus"></i> Agregar</Button>
                            </Col>
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                    setnuevo(false)
                                    buscartodoslosmovimientos('')
                                    seteditar(true)
                                    setVerModalMovimientos(true)
                                }} >
                                    <i className="fa fa-search-plus"></i> Buscar</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={guardarmovimiento} >
                                    <i className="fa fa-cloud"></i> Guardar</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }} block onClick={() => {
                                    setprevisualizar(true)

                                }} >
                                    <i className="fa fa-paper-plane"></i> PDF</Button>
                            </Col>}
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => {

                                    setnuevo(true)
                                }} >
                                    <i className="fa fa-clone"></i> Utilizar Plantilla</Button>
                            </Col>

                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.settesoreria(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </Row>
                       
                    </CardBody>
                </Card>
            </Col>
            <Col sm={12} className={(nuevo || editar) ? "" : "desabilitar"}>

                <Row className="mb-2">
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tipo Doc</Label>
                                            <InputGroup >
                                                <Input name="concepto" value={movimiento.tipodoc} bsSize="sm"

                                                    onChange={(e) => {
                                                        var _Movimiento = { ...movimiento }
                                                        _Movimiento['tipodoc'] = e.target.value

                                                        setmovimiento(_Movimiento)

                                                    }}
                                                    onBlur={(e) => {
                                                        seleccionartipodocumentos2(e)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key == "Enter" && e.target.value == "" && movimiento.bcdetbanco.length < 1) {
                                                            buscartodastipodocumentos('');
                                                            setbusquedatipodocumentos(tipodocumentos)
                                                            setVerModaltipodocumentos(true)


                                                        } else {
                                                            if (movimiento.bcdetbanco.length > 1) {
                                                                Swal.fire(
                                                                    'Opps!',
                                                                    'Al tener Movimientos agregados no puede cambiar el tipo de documento',
                                                                    'error'
                                                                )
                                                            }
                                                        }
                                                    }} />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        if (movimiento.bcdetbanco.length < 1) {
                                                            buscartodastipodocumentos('');

                                                            setbusquedatipodocumentos(tipodocumentos)
                                                            setVerModaltipodocumentos(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Al tener Movimientos agregados no puede cambiar el tipo de documento',
                                                                'error'
                                                            )

                                                        }

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Comprobante</Label>
                                            <Input type="select" bsSize="sm" value={movimiento.codcompro} name="codcompro" onChange={handleChange} >
                                                <option value="">--</option>
                                                {Ctcomprobantess.map((dato) => {
                                                    return <option value={dato.codigo}>{dato.nombre}</option>
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Fecha</Label>
                                            <Input type="date" bsSize="sm" value={movimiento.fecha} name="fecha" onChange={handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Numero de Documento</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '200px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.ndocumento}</Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Descripcion</Label>
                                            <Input bsSize="sm" value={movimiento.descripcion} name="descripcion" onChange={handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Año</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '130px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.ano}</Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Periodo</Label>
                                            <Label style={{ backgroundColor: '#188BAD', width: '130px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.periodo}</Label>
                                        </FormGroup>
                                    </Col>
                                    {tipodocumento.tipodoc != "causaciones" && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Banco</Label>
                                            <InputGroup >
                                                <Input name="banco" value={movimiento.banco} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        obtenerBcbancos('');
                                                        setVerModalBcbancos(true)


                                                    } else {

                                                    }
                                                }} />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        obtenerBcbancos('');
                                                        setVerModalBcbancos(true)

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}

                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tercero</Label>
                                            <InputGroup>
                                                <Input

                                                    onChange={(e) => {
                                                        var _Movimiento = { ...movimiento }
                                                        _Movimiento['acreedor'] = e.target.value

                                                        setmovimiento(_Movimiento)
 
                                                    }}
                                                    value={movimiento.acreedor} bsSize="sm" onKeyDown={(e) => {

                                                        if (e.key == "Enter" && e.target.value == "" && tipodocumento.codigo != "") {
                                                            if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                buscartodasterceros('');
                                                            }
                                                            // buscartodasterceros('');
                                                            setbusquedaterceros(terceros)
                                                            setVerModalterceros(true)

                                                        } else {

                                                            if (tipodocumento.codigo == "") {
                                                                Swal.fire(
                                                                    'Opps!',
                                                                    'Debe seleccionar un Tipo de Documento',
                                                                    'error'
                                                                )
                                                            }

                                                        }
                                                    }} onBlur={(e) => {
                                                        cambiarterceros(e)
                                                    }} name="acreedor" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        var tieneimpuestositems = false

                                                        if (!tieneimpuestositems) {
                                                            if (dataUser.empresa.modulos.ARTICULOS.electronico) {
                                                                buscartodasterceros('');
                                                            }
                                                            // buscartodasterceros('');
                                                            setbusquedaterceros(terceros)
                                                            setVerModalterceros(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }


                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Nombre</Label>
                                            <Input bsSize="sm" value={nombrecliente2} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>F.Venc</Label>
                                            <Input type="date" bsSize="sm" value={movimiento.horafecha} name="horafecha" onChange={handleChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Doc. Referencia</Label>
                                            <Input onChange={handleChange} bsSize="sm" name="ndocumentoref" value={movimiento.ndocumentoref} />
                                        </FormGroup>
                                    </Col>

                                    {tipodocumento.tipodoc != "causaciones" && <Col sm={3}>

                                        <FormGroup inline={true}>
                                            <Label>_</Label>
                                            <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => {
                                                // buscartodoslosmovimientosporterceroremisiones(movimiento.cliente, movimiento.tercero)
                                                setVerModalMovimientosremision(true)
                                            }} >
                                                <i className="fa fa-search-plus"></i> Buscar</Button>


                                        </FormGroup>
                                    </Col>}
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Button style={{ fontSize: '12px', color: 'black' }} color="success" block
                                                onClick={() => {
                                                    agregarcuentas()
                                                }} >
                                                <i className="fa fa-plus"></i> Agregar Cuenta</Button>
                                        </FormGroup>
                                    </Col>
                                    {movimiento.anulado == "anulado" && <div style={{ color: 'red' }}>ANULADA</div>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive" style={{ height: '400px', zoom: '70%' }}>

                                            <Table striped size="sm">
                                                <thead>
                                                    <tr>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>It</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '10%' }}>Cuenta</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Naturaleza</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>C. Refe</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Doc.Referencia</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '200px' }}>Debito</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold', width: '10%' }}>Credito</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Tercero</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Valor Base</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>C.C.</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Comentarios</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Activo</th>
                                                        <th style={{ color: 'black', textAlign: 'center', fontWeight: 'bold' }}>Diferido</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimiento.bcdetbanco.length < 1) ? (
                                                            <tr  >
                                                                <td style={{ color: 'black', fontWeight: 'bold' }} colSpan="15">Sin movimientos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimiento.bcdetbanco.map((item, index) => (
                                                                    <tr key={item.idProducto}>
                                                                        <td>
                                                                            <Button color="danger" size="sm"
                                                                                onClick={() => { eliminarProducto(index) }}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </Button>
                                                                        </td>
                                                                        {/* <td>
                                                                        <Autocomplete
                                                                            onFocus={(e) => { setfila(index) }}
                                                                            onChange={(e, value) => { handleChangegrillafaencmovi(e, value, 'codcuenta', 'codcuenta') }}

                                                                            disablePortal
                                                                            options={Plandecuentass}
                                                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                                            value={Plandecuentass.filter(p => p.codcuenta == item.codcuenta)[0]}
                                                                            renderInput={(params) => <TextField {...params} size="small" style={{ width: '200px', borderRadius: '4px' }} label="Cuenta" />}
                                                                            maxHeight={200}
                                                                        />
                                                                    </td> */}
                                                                        <td>
                                                                            <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="text"

                                                                                onChange={(e) => {
                                                                                    var _Movimiento = { ...movimiento }
                                                                                    var subtabla = "bcdetbanco"
                                                                                    var _datos = { ...movimiento }[subtabla]
                                                                                    var _enviodatos = _datos.map((dato, index2) => {
                                                                                        if (index2 == index) {
                                                                                            var dat = {
                                                                                                ...dato, 
                                                                                                ['codcuenta']: e.target.value
                                                                                            }
                                                                                            return dat
                                                                                        } else {
                                                                                            return dato
                                                                                        }
                                                                                    })
                                                                                    _Movimiento[subtabla] = _enviodatos
                                                                                    setmovimiento(_Movimiento)

                                                                                }}
                                                                                onBlur={async (e) => {

                                                                                    var value = Plandecuentass.find(p => p.codcuenta == e.target.value.toUpperCase())
                                                                                    if (value) {
                                                                                        if (tipodocumento.camdescenc == "si") {

                                                                                            var value2 = await buscarsaldodecuenta(movimiento.acreedor, e.target.value.toUpperCase())

                                                                                            handleChangegrillafaencmoviconsaldo('', value, 'codcuenta', 'codcuenta', value2)

                                                                                        } else {
                                                                                            handleChangegrillafaencmovi('', value, 'codcuenta', 'codcuenta')

                                                                                        }
                                                                                    } else {
                                                                                        Swal.fire(
                                                                                            'Opps!',
                                                                                            'No Existe la Cuenta',
                                                                                            'error'
                                                                                        )
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key == "Enter"  || e.target.value == "?") {
                                                                                        setabriricuentas(true)
                                                                                        obtenerPlandecuentass('');
                                                                                    }
                                                                                }} name="codcuenta" value={item.codcuenta} placeholder="Presione Enter Para Buscar" />
                                                                        </td>
                                                                        {/* <td>
                                                                        <Autocomplete
                                                                            onFocus={(e) => { setfila(index) }}
                                                                            onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'nat', 'codigo') }}
                                                                            name="tercero"
                                                                            disablePortal
                                                                            options={modelonat}
                                                                            getOptionLabel={(clasi) => clasi.nombre}
                                                                            sx={{ width: 150, height: 50, borderRadius: 4 }}
                                                                            value={modelonat.filter(p => p.codigo == item.nat)[0]}
                                                                            style={{ fontSize: 14 }}
                                                                            renderInput={(params) => <TextField {...params} size="small" name="nat" label="Naturaleza" />}
                                                                            onInputChange={handleOpen}
                                                                        />
                                                                    </td> */}
                                                                        <td>
                                                                            <Input onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="select" onChange={(e) => {
                                                                                handleChangegrillafaencmovi22(e, modelonat, 'nat', 'codigo')
                                                                            }} name="nat" value={item.nat} >
                                                                                {modelonat.map((dat) => {
                                                                                    return <option value={dat.codigo}>{dat.nombre}</option>
                                                                                })}

                                                                            </Input>
                                                                        </td>
                                                                        <td> <Input onFocus={(e) => { setfila(index) }} style={{ width: '200px' }} name="documentorefcon" value={item.documentorefcon} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'documentorefcon') }}></Input>
                                                                        </td>
                                                                        <td> <Input onFocus={(e) => { setfila(index) }} style={{ width: '200px' }} name="documentoref" value={item.documentoref} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'documentoref') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => {
                                                                            setfila(index)
                                                                            e.target.value = (item.valordeb + "").replace(/\,/g, '')
                                                                        }} onBlur={(e) => {
                                                                            e.target.value = fm2.from(Number(item.valordeb))
                                                                        }} disabled={item.valorcre != "" && item.valorcre != 0 && item.valorcre != "0"} name="valordeb" value={item.valordeb} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valordeb') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onBlur={(e) => {
                                                                            e.target.value = fm2.from(Number(item.valorcre))
                                                                        }} onFocus={(e) => {
                                                                            setfila(index)
                                                                            e.target.value = (item.valorcre + "").replace(/\,/g, '')

                                                                        }} disabled={item.valordeb != "" && item.valordeb != 0 && item.valordeb != "0"} name="valorcre" value={item.valorcre} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valorcre') }}></Input>
                                                                        </td>
                                                                        {/* <td>
                                                                        <Autocomplete
                                                                            onFocus={(e) => { setfila(index) }}
                                                                            onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'acreedor', 'codigo') }}
                                                                            name="acreedor"
                                                                            disablePortal
                                                                            options={busquedacaclientes}
                                                                            getOptionLabel={(clasi) => clasi.codigo + ' - ' + clasi.razsoc}
                                                                            sx={{ width: 200, height: 50, borderRadius: 4 }}
                                                                            value={busquedacaclientes.filter(p => p.codigo == item.acreedor)[0]}
                                                                            style={{ fontSize: 14 }}
                                                                            renderInput={(params) => <TextField {...params} size="small" name="tercero" label="Tercero" />}
                                                                            onInputChange={handleOpen}
                                                                        />
                                                                    </td> */}
                                                                        <Input disabled={Plandecuentass.find(p => p.codcuenta == item.codcuenta) ?
                                                                            Plandecuentass.find(p => p.codcuenta == item.codcuenta).idanexo == "n" ? true : false : false} onFocus={() => { setfila(index) }} style={{ width: '200px' }} type="text"

                                                                            onChange={(e) => {
                                                                                var _Movimiento = { ...movimiento }
                                                                                var subtabla = "bcdetbanco"
                                                                                var _datos = { ...movimiento }[subtabla]
                                                                                var _enviodatos = _datos.map((dato, index2) => {
                                                                                    if (index2 == index) {
                                                                                        var dat = {
                                                                                            ...dato,
                                                                                            ['acreedor']: e.target.value
                                                                                        }
                                                                                        return dat
                                                                                    } else {
                                                                                        return dato
                                                                                    }
                                                                                })
                                                                                _Movimiento[subtabla] = _enviodatos
                                                                                setmovimiento(_Movimiento)

                                                                            }}
                                                                            onBlur={async (e) => {

                                                                                if (e.target.value != "") {
                                                                                    var value = await buscarcaclientesunico(e.target.value.toUpperCase())

                                                                                    if (value) {
                                                                                        if (value.caclientes.codigo != "") {
                                                                                            handleChangegrillafaencmovi('', value.caclientes, 'acreedor', 'codigo')
                                                                                            buscarterceronombre(value.caclientes.codigo, value.caclientes.tercero)
                                                                                        } else {

                                                                                            if (!abrirterceros) {
                                                                                                Swal.fire(
                                                                                                    'Opps!',
                                                                                                    'No Existe el acreedor',
                                                                                                    'error'
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }}

                                                                            onKeyDown={(e) => {
                                                                                if (e.key == "Enter"  || e.target.value == "?") {

                                                                                    setabrirterceros(true)
                                                                                }
                                                                            }} placeholder="Presione Enter Para Buscar" name="acreedor" value={item.acreedor} />
                                                                        <td> <Input disabled={Plandecuentass.find(p => p.codcuenta == item.codcuenta) ?
                                                                            Plandecuentass.find(p => p.codcuenta == item.codcuenta).idanexo != "b" ? true : false : false} style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="valorbase" value={item.valorbase} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'valorbase') }}></Input>
                                                                        </td>
                                                                        {/* <td>
                                                                        <Autocomplete
                                                                            onFocus={(e) => { setfila(index) }}
                                                                            onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'codcentrocostos', 'codigo') }}
                                                                            name="tercero"
                                                                            disablePortal
                                                                            options={Ctcentrocostos.filter(p => p.subcodigo != '')}
                                                                            getOptionLabel={(clasi) => clasi.nombre}
                                                                            sx={{ width: 150, height: 50, borderRadius: 4 }}
                                                                            value={Ctcentrocostos.filter(p => p.codigo == item.codcentrocostos)[0]}
                                                                            style={{ fontSize: 14 }}
                                                                            renderInput={(params) => <TextField {...params} size="small" name="codcentrocostos" label="Centro de Costo" />}
                                                                            onInputChange={handleOpen}
                                                                        />
                                                                    </td> */}
                                                                        <td>
                                                                            <Input style={{ width: '200px' }} type="text" onKeyDown={(e) => {
                                                                                if (e.key == "Enter"  || e.target.value == "?") {
                                                                                    setabriricentrodecosto(true)
                                                                                }
                                                                            }} onBlur={async (e) => {

                                                                                if (e.target.value != "") {

                                                                                    var existe = Ctcentrocostos.find(p => p.codigo == e.target.value)
                                                                                    if (existe) {

                                                                                    } else {
                                                                                        Swal.fire(
                                                                                            'Opps!',
                                                                                            'No Existe el Centro de Costo',
                                                                                            'error'
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }} disabled={Plandecuentass.find(p => p.codcuenta == item.codcuenta) ?
                                                                                Plandecuentass.find(p => p.codcuenta == item.codcuenta).idcentrocosto == "n" ? true : false : false} onFocus={(e) => { setfila(index) }} name="codcentrocostos" onChange={(e) => { handleChangegrillafaencmovi2(e, 'codcentrocostos') }} value={item.codcentrocostos} placeholder="Presione Enter Para Buscar" />
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="comentarios" value={item.comentarios} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'comentarios') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="activosfij" value={item.activosfij} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'activosfij') }}></Input>
                                                                        </td>
                                                                        <td> <Input style={{ width: '200px' }} onFocus={(e) => { setfila(index) }} name="conciliado" value={item.conciliado} tabla={'contmovidet'} onChange={(e) => { handleChangegrillafaencmovi2(e, 'conciliado') }}></Input>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {puedoanular && <Col sm={1}>
                        <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'white' }} block onClick={() => {
                            let dt = JSON.parse(user)
                            var movimientoguardado = {
                                bcencbanco: { ...movimiento },
                                bcdetbanco: movimiento.bcdetbanco
                            }

                            delete movimientoguardado.bcencbanco.bcdetbanco;
                            movimientoguardado.bcencbanco.ndocumento = Number(movimientoguardado.bcencbanco.ndocumento + "")
                            movimientoguardado.bcencbanco.debito = Number(movimientoguardado.bcencbanco.debito + "")
                            movimientoguardado.bcencbanco.credito = Number(movimientoguardado.bcencbanco.credito + "")
                            movimientoguardado.bcencbanco.diferencia = Number(movimientoguardado.bcencbanco.diferencia + "")

                            for (let i = 0; i < movimientoguardado.bcdetbanco.length; i++) {
                                movimientoguardado.bcdetbanco[i].item = 0
                                movimientoguardado.bcdetbanco[i].ndocumento = Number(movimientoguardado.bcdetbanco[i].ndocumento + "")
                                movimientoguardado.bcdetbanco[i].documentoref = Number(movimientoguardado.bcdetbanco[i].documentoref + "")
                                movimientoguardado.bcdetbanco[i].valordeb = Number(movimientoguardado.bcdetbanco[i].valordeb + "")
                                movimientoguardado.bcdetbanco[i].valorcre = Number(movimientoguardado.bcdetbanco[i].valorcre + "")
                                movimientoguardado.bcdetbanco[i].valorbase = Number(movimientoguardado.bcdetbanco[i].valorbase + "")
                                movimientoguardado.bcdetbanco[i].activosfij = Number(movimientoguardado.bcdetbanco[i].activosfij + "")

                            }
                            const api = fetch("" + props.servidor + "/TesoreriaApi/anular/" + dt.usuario.cedula + "?llave=" + dt.usuario.llaveempresa + "", {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json;charset=utf-8'
                                },
                                body: JSON.stringify(movimientoguardado)
                            })
                                .then((response) => {
                                    return response.ok ? response.json() : Promise.reject(response);
                                })
                                .then(async (dataJson) => {
                                    Swal.fire(
                                        'Exito!',
                                        'Se anulo el movimiento: ' + movimiento.tipodoc + " - " + movimiento.ndocumento,
                                        'success'
                                    )

                                }).catch(async (error) => {
                                    try {
                                        var ee = await error.json()
                                        Swal.fire(
                                            'Opps!',
                                            'No se pudo anular el movimiento: ' + ee.mensaje,
                                            'error'
                                        )
                                        console.log("No se pudo enviar la venta ", ee.mensaje)
                                        guardarlogdeerrores(ee.mensaje)

                                    } catch (error2) {
                                        Swal.fire(
                                            'Opps!',
                                            'No se pudo anular el movimiento: ' + 'Error de conexion',
                                            'error'
                                        )
                                        console.log("No se pudo enviar la venta ", error2)
                                        guardarlogdeerrores('Error de conexion')
                                    }

                                })

                        }} >
                            Anular</Button>
                    </Col>}
                </Row>
                <Row >
                    <Col sm={7}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardBody className="mb-2 " >
                                <Col sm={12}>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Cuenta:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.bcdetbanco[fila] ? movimiento.bcdetbanco[fila].codcuenta + " - " + buscarcuentanombre(movimiento.bcdetbanco[fila].codcuenta) : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Tercero:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.bcdetbanco[fila] ? nombrecliente : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"C.Costo:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.bcdetbanco[fila] ? movimiento.bcdetbanco[fila].codcentrocostos : ''}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Comprob:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{movimiento.codcompro}</Label>
                                    </div>
                                    <hr></hr>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Activo:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}></Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferido:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}></Label>
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={5}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardBody className="mb-2 " >
                                <Col sm={12}>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Debito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebito())}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Credito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totalcredito())}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '60px', color: 'black', textAlign: 'right', fontWeight: 'bold' }}>{"Colgaap"}</Label>


                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferencia:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebito() - totalcredito())}</Label>
                                    </div>
                                    <hr></hr>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Debito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebitoniff())}</Label>

                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Credito:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totalcreditoniff())}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '60px', color: 'black', textAlign: 'right', fontWeight: 'bold' }}>{"Niif"}</Label>
                                    </div>
                                    <div >
                                        <Label style={{ display: 'inline-block', borderRadius: '10px', backgroundColor: '#188BAD', width: '90px', color: 'white', textAlign: 'center', fontWeight: 'bold' }}>{"Diferencia:"}</Label>
                                        <Label style={{ display: 'inline-block', marginLeft: '10px', color: 'black', textAlign: 'center', fontWeight: 'bold' }}>{fm3.from(totaldebitoniff() - totalcreditoniff())}</Label>
                                    </div>
                                </Col>







                            </CardBody>

                        </Card>
                    </Col>
                </Row>

            </Col >

            {tipodocumento.modidoc == "carta 1" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Arial,monoespace' }} isOpen={previsualizar}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                        {"Fecha: " + movimiento.fecha}<br />
                                        {"Fecha Venc.: " + movimiento.horafecha}<br />




                                    </Col>
                                    <Col sm={8} style={{ textAlign: 'left', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                        {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />
                                        {"A Favor De: " + movimiento.nomacreedor}<br />
                                        {"Nit: " + movimiento.acreedor}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Cuenta</th>
                                                    <th style={{ width: '5%' }}>C.C.</th>
                                                    <th style={{ width: '15%' }}>DESCRIPCION</th>
                                                    <th style={{ width: '10%' }}>DOC/REF</th>
                                                    <th style={{ width: '10%' }}>DEBITO</th>
                                                    <th style={{ width: '10%' }}>CREDITO</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (movimiento.bcdetbanco.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            movimiento.bcdetbanco.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.comentarios.toUpperCase()}</td>
                                                                    <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                    <td style={{ textAlign: 'left' }} >{Number(item.valordeb + "") == 0 ? "" : fm.from(Number(item.valordeb + ""))}</td>
                                                                    <td style={{ textAlign: 'left' }}>{Number(item.valorcre + "") == 0 ? "" : fm.from(Number(item.valorcre + ""))}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebito())}</th>
                                                </tr>

                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcredito())}</th>
                                                </tr>



                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        {/* <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            // setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button> */}
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 2" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Kelpt A2 Medio, monospace' }} isOpen={previsualizar}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                        {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />
                                        {"A Favor De: " + movimiento.nomacreedor}<br />
                                        {"Nit: " + movimiento.acreedor}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Cuenta</th>
                                                    <th style={{ width: '20%' }}>C.C.</th>
                                                    <th style={{ width: '10%' }}>DESCRIPCION</th>
                                                    <th style={{ width: '10%' }}>DOC/REF</th>
                                                    <th style={{ width: '10%' }}>DEBITO</th>
                                                    <th style={{ width: '10%' }}>CREDITO</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (movimiento.bcdetbanco.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            movimiento.bcdetbanco.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                    <td style={{ textAlign: 'left' }} >{movimiento.descripcion.toUpperCase()}</td>
                                                                    <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.valordeb == 0 ? "" : fm.from(item.valordeb)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{item.valorcre == 0 ? "" : fm.from(item.valorcre)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebito())}</th>
                                                </tr>

                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcredito())}</th>
                                                </tr>



                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        {/* <Button size="sm" color="primary" onClick={() => {
            setprevisualizarenviocorreo(true)
            // setcorreosenvio(tercero.email)
        }}>Enviar Correo</Button> */}
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }

            {tipodocumento.modidoc == "carta 3" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%', fontFamily: 'Kelpt A2 Medio, monospace' }} isOpen={previsualizar}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion.toUpperCase() + " - " + movimiento.ndocumento}<br />
                                        {"Fecha: " + movimiento.fecha}<br />
                                        {"Fecha Venc.: " + movimiento.horafecha}<br />



                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Kelpt A2 Medio, monospace' }}>
                                        {"Concepto: " + movimiento.descripcion.toUpperCase()}<br />
                                        {"A Favor De: " + movimiento.nomacreedor}<br />
                                        {"Nit: " + movimiento.acreedor}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Cuenta</th>
                                                    <th style={{ width: '20%' }}>C.C.</th>
                                                    <th style={{ width: '10%' }}>DESCRIPCION</th>
                                                    <th style={{ width: '10%' }}>DOC/REF</th>
                                                    <th style={{ width: '10%' }}>DEBITO</th>
                                                    <th style={{ width: '10%' }}>CREDITO</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (movimiento.bcdetbanco.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            movimiento.bcdetbanco.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.codcuenta}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.codcentrocostos}</td>
                                                                    <td style={{ textAlign: 'left' }} >{movimiento.descripcion.toUpperCase()}</td>
                                                                    <td style={{ textAlign: 'left' }}>{item.documentoref}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.valordeb == 0 ? "" : fm.from(item.valordeb)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{item.valorcre == 0 ? "" : fm.from(item.valorcre)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Debito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totaldebitoniff())}</th>
                                                </tr>

                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Credito:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(totalcreditoniff())}</th>
                                                </tr>



                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        {/* <Button size="sm" color="primary" onClick={() => {
setprevisualizarenviocorreo(true)
// setcorreosenvio(tercero.email)
}}>Enviar Correo</Button> */}
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }

            <Buscar tituloencabezado={"Buscar Tipo Documento"} filtrar={(coincidencia) => { filtrartipodocumentos(coincidencia) }} busqueda={busquedatipodocumentos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"descripcion"} dato={""} dato2={""} verModal={verModaltipodocumentos} cerrarModal={() => { cerrarModaltipodocumentos() }} seleccionar={(seleccionado) => {
                seleccionartipodocumentos(seleccionado)
            }} />
            <BuscarMovimiento tituloencabezado={"Buscar Movimientos"} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Acreedor", "D. Referencia", "Año", "Periodo"]} sub={"bcencbanco"} codigo={"tipodoc"} nombre={"ndocumento"} dato={"acreedor"} dato2={"ndocumentoref"} dato3={"ano"} dato5={"periodo"} verModal={verModalMovimientos} cerrarModal={() => { cerrarModalMovimientos() }} seleccionar={(seleccionado) => {
                seleccionarMovimientos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Banco"} filtrar={(coincidencia) => { filtrarBancos(coincidencia) }} busqueda={busquedaBcbancos} encabezado={["Codigo", "Nombre"]} codigo={"codbanco"} nombre={"razonsocia"} dato={""} dato2={""} verModal={verModalBcbancos} cerrarModal={() => { cerrarModalBcbanco() }} seleccionar={(seleccionado) => {
                seleccionarbcbancos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social", "Tipo"]} codigo={"codigo"} nombre={"razsoc"} dato={"tercero"} dato2={""} verModal={verModalterceros} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                seleccionarterceros(seleccionado)
                buscarterceronombre2(seleccionado.codigo, seleccionado.tercero)

            }} />
            <Buscar tituloencabezado={"Buscar Documento"} filtrar={(coincidencia) => { filtrarmovimientostercero(coincidencia) }} busqueda={movimientostercero} encabezado={["Concepto", "Numero", "Valor"]} codigo={"tipodoc"} nombre={"doc"} dato={"vlrfactur"} dato2={""} verModal={verModalmovimientostercero} cerrarModal={() => { cerrarModalmovimientostercero() }} seleccionar={(seleccionado) => {
                seleccionarmovimientostercero(seleccionado)
            }} />
            <Buscarmovimientomultiple seleccion={[]} tituloencabezado={"Buscar "} filtrar={(coincidencia) => { filtrarMovimientos(coincidencia) }} busqueda={Facturasvivas} encabezado={["Concepto", "Num. Factura Proveedor", "Consecutivo Interno", "Cuenta", "Valor"]} sub={"faencmovi"} codigo={"tipodoc"} nombre={"documentoref2"} dato={"ndocumento"} dato2={"codcuenta"} dato3={"valor"} verModal={verModalMovimientosremision} cerrarModal={() => { cerrarModalMovimientosremision() }} seleccionar={(seleccionado) => {
                seleccionarMovimientosremisiones(seleccionado)
            }} />
            <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'codcuenta', 'codcuenta')
                setabriricuentas(false)
            }} />
            <BuscarCentrodeCosto tituloencabezado={"Buscar Centro de Costo"} abrir={abriricentrodecosto} servidor={props.servidor} setabrirbanco={(ab) => { setabriricentrodecosto(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'codcentrocostos', 'codigo')
                setabriricentrodecosto(false)
            }} />
            <BuscarTerceros tituloencabezado={"Buscar Tercero"} abrir={abrirterceros} servidor={props.servidor} setabrirbanco={(ab) => { setabrirterceros(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'acreedor', 'codigo')
                setabrirterceros(false)
                buscarterceronombre(value.codigo, value.tercero)
            }} />
        </Row >
    )
}

export default Venta;