
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect } from "react";
import "./css/Venta.css"
import { UserContext } from "../context/UserProvider";
import DataTable from 'react-data-table-component';
import { FormatMoney } from 'format-money-js'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { jsPDF } from "jspdf";

const modeloInicio = [{
    fecha: "",
    numeroDocumento: "",
    tipoDocumento: "",
    documentoCliente: "",
    nombreCliente: "",
    subTotalVenta: "",
    impuestoTotalVenta: "",
    totalVenta: "",
    producto: "",
    cantidad: "",
    precio: "",
    total: ""
}]

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}


const Venta = (props) => {
    const { user } = useContext(UserContext)
    const [fechaFin, setFechaFin] = useState("");
    const [a_Productos, setA_Productos] = useState([])
    const [a_Busqueda, setA_Busqueda] = useState("")
    const [busqueda, setbusqueda] = useState("");
    const [key, setKey] = useState('home');

    const [documentoCliente, setDocumentoCliente] = useState("")
    const [nombreCliente, setNombreCliente] = useState("")
    const [fila, setfila] = useState(0);
    const [tecnicofila, settecnicofila] = useState("");
    const [fechafila, setfechafila] = useState("");
    const [tipoDocumento, setTipoDocumento] = useState("Boleta")
    const [productos, setProductos] = useState([])
    const [total, settotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [base, setbase] = useState(0)
    const [iva, setiva] = useState(0)
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [igv, setIgv] = useState(0)
    const [pendiente, setPendiente] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventashijo, setVentashijo] = useState(modeloInicio)
    const [tecnicos, settecnicos] = useState([])
    const [movimientosmediosdepago, setmovimientosmediosdepago] = useState([])
    const [movimientoscajaiva, setmovimientoscajaiva] = useState([])
    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [cajaseleccionada, setcajaseleccionada] = useState(props.caja);
    const [cajasbusqueda, setcajasbusqueda] = useState([]);
    const [usuario, setDataUser] = useState(modelo);
    const [previsualizar, setprevisualizar] = useState(false);
    const [previsualizar2, setprevisualizar2] = useState(false);

    const columns = [
        {
            name: 'Concepto',
            selector: row => row.concepto,
        },
        {
            name: 'Concecutivo',
            selector: row => row.numero,
            grow: 2
        }, {
            name: 'Tercero',
            selector: row => row.nomcliente,
            grow: 4
        },
        {
            name: 'Medio de Pago',
            selector: row => row.formapago,
            grow: 2
        },
        {
            name: 'Debito',
            selector: row => (row.afecta == "debito") ? fm.from(Number(row.valor + "")) : 0,
            grow: 3
        },
        {
            name: 'Credito',
            selector: row => (row.afecta == "credito") ? fm.from(Number(row.valor + "")) : 0,
        }
    ];

    const columns2 = [
        {
            name: 'Referencia',
            selector: row => row.referencia,
        },
        {
            name: 'nombre',
            selector: row => row.descrip,
            grow: 2
        }, {
            name: 'Cantidad',
            selector: row => row.cantidad,
            grow: 4
        }
    ];
    const buscar = () => {

        try {
            let dt = JSON.parse(user)
            var fechai = fechaInicio
            var url = props.servidor + '/Cajaapi/' + fechai + '/' + cajaseleccionada + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(url)
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then(async (dataJson) => {
                    setVentas(dataJson)

                    var resumen = []
                    var saldoinicialefec = 0
                    for (let i = 0; i < dataJson.length; i++) {
                        const element = dataJson[i];

                        var dato = {
                            mediopago: element.formapago,
                            saldoinicial: 0,
                            debito: element.afecta == "debito" ? element.valor : 0,
                            credito: element.afecta == "credito" ? element.valor : 0,
                            afecta: element.afecta,
                            total: element.afecta == "debito" ? element.valor : element.afecta == "credito" ? -element.valor : 0,
                        }
                        if (dato.mediopago == "efectivo") {
                            dato.saldoinicial = element.saldoinicial
                            dato.total = dato.total + element.saldoinicial
                        }
                        saldoinicialefec = element.saldoinicial != 0 ? element.saldoinicial : saldoinicialefec
                        var existe = resumen.filter(p => p.mediopago == dato.mediopago)

                        if (existe.length == 0) {
                            resumen.push(dato)
                        } else {
                            for (let j = 0; j < resumen.length; j++) {
                                const element2 = resumen[j];
                                if (element2.mediopago == dato.mediopago) {
                                    if (dato.afecta == "debito") {
                                        resumen[j].debito = resumen[j].debito + dato.debito
                                        resumen[j].total = resumen[j].total + dato.debito
                                        // resumen[j].saldoinicial = dato.saldoinicial != 0 ? dato.saldoinicial : resumen[j].saldoinicial
                                    }
                                    if (dato.afecta == "credito") {
                                        resumen[j].credito = resumen[j].credito + dato.credito
                                        resumen[j].total = resumen[j].total - dato.credito
                                    }
                                }
                            }
                        }
                    }
                    var saldototal = 0
                    for (let i = 0; i < resumen.length; i++) {
                        const element = resumen[i];
                        saldototal = saldototal + element.total
                    }
                    setbase(saldototal)
                    var existeefectivo = resumen.filter(p => p.mediopago == "efectivo")
                    if (existeefectivo.length == 0) {
                        var dato = {
                            mediopago: "efectivo",
                            saldoinicial: saldoinicialefec,
                            debito: 0,
                            credito: 0,
                            afecta: 0,
                            total: saldoinicialefec,
                        }
                        resumen.push(dato)
                    } else {
                        for (let j = 0; j < resumen.length; j++) {
                            const element2 = resumen[j];
                            if (element2.mediopago == "efectivo") {
                                resumen[j].total = resumen[j].total
                                resumen[j].saldoinicial = saldoinicialefec
                            }
                        }
                    }
                    setmovimientosmediosdepago(resumen)

                    try {
                        let dt = JSON.parse(user)
                        var fechai = fechaInicio
                        var url = props.servidor + '/Movimientoapi/buscarbaseiva/' + fechai + '/' + cajaseleccionada + '?llave=' + dt.usuario.llaveempresa + ''
                        const api = fetch(url)
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then((dataJson) => {
                                var resumen = []

                                setmovimientoscajaiva(dataJson)


                            }).catch((error) => {
                                Swal.fire(
                                    'Opps!',
                                    'No pudieron cargar los movimientos',
                                    'error'
                                )
                                setVentas([])
                            })
                    } catch (error) {
                        console.log(error)
                    }

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setVentas([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerCajas();
        setcajaseleccionada(props.caja)
    }, [])
    const obtenerCajas = async () => {
        if (cajasbusqueda.length == 0) {
            try {


                let dt = JSON.parse(user)
                let response = await fetch(props.servidor + '/ConcajaApi/?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    setcajasbusqueda(data)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const inputProps = {
        placeholder: "Buscar producto",
        value: a_Busqueda,

    }

    const terminarVenta = () => {
        var cicaja = true
        props.permisosusuario.forEach(element => {
            if (element.tipo == "cierrecaja" && element.acceso[0] == "1") {
                cicaja = false
            }
        });
        if (!cicaja) {
            Swal.fire(
                'Opps!',
                'No tiene permisos',
                'error'
            )
            return
        }
        if (movimientosmediosdepago.length == 0) {
            Swal.fire(
                'Opps!',
                'No hay datos',
                'error'
            )
            return
        }
        let dt = JSON.parse(user)

        var url = props.servidor + '/CajaApi/cerrar/' + fechaInicio + '/' + cajaseleccionada + '/' + iva + '?llave=' + dt.usuario.llaveempresa + ''

        const api = fetch(url, {
            method: 'GET'
            // headers: {
            //     'Content-Type': 'application/json;charset=utf-8'
            // },
            // body: JSON.stringify("_movimiento")
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                // reestablecer();
                var data = dataJson;
                Swal.fire(
                    'Caja No ' + cajaseleccionada + ' cerrada correctamente',
                    '',
                    'success'
                )

            }).catch((error) => {
                Swal.fire(
                    'Opps!',
                    'No se pudo cerrar la caja',
                    'error'
                )
                console.log("No se pudo enviar la venta ", error)
            })

    }

    const abrirCaja = () => {
        var cicaja = true
        props.permisosusuario.forEach(element => {
            if (element.tipo == "cierrecaja" && element.acceso[0] == "1") {
                cicaja = false
            }
        });
        if (cicaja) {


            let dt = JSON.parse(user)

            var url = props.servidor + '/CajaApi/abrir/' + fechaInicio + '/' + cajaseleccionada + '/' + iva + '?llave=' + dt.usuario.llaveempresa + ''

            const api = fetch(url, {
                method: 'GET'
                // headers: {
                //     'Content-Type': 'application/json;charset=utf-8'
                // },
                // body: JSON.stringify("_movimiento")
            })
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    // reestablecer();
                    var data = dataJson;
                    Swal.fire(
                        'Caja No ' + cajaseleccionada + ' abierta correctamente',
                        '',
                        'success'
                    )

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No se pudo abrir la caja',
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", error)
                })
        } else {
            Swal.fire(
                'Opps!',
                'No tiene permisos',
                'error'
            )
        }

    }
    const totalsaldoinicial = () => {
        var tot = 0
        for (let i = 0; i < movimientosmediosdepago.length; i++) {
            const element = movimientosmediosdepago[i];
            tot = tot + element.saldoinicial
        }
        return tot
    }
    const totaldebito = () => {
        var tot = 0
        for (let i = 0; i < movimientosmediosdepago.length; i++) {
            const element = movimientosmediosdepago[i];
            tot = tot + element.debito
        }
        return tot
    }
    const totalcredito = () => {
        var tot = 0
        for (let i = 0; i < movimientosmediosdepago.length; i++) {
            const element = movimientosmediosdepago[i];
            tot = tot + element.credito
        }
        return tot
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < movimientosmediosdepago.length; i++) {
            const element = movimientosmediosdepago[i];
            tot = tot + element.total
        }
        return tot
    }
    const totaltotal2 = () => {
        var tot = 0
        try {
            for (let i = 0; i < ventas.length; i++) {
                const element = ventas[i];
                if (element.afecta == "debito") {
                    tot = tot + element.valor
                }
            }
        } catch (error) {

        }
        return tot
    }
    const totaltotal3 = () => {
        var tot = 0
        try {
            for (let i = 0; i < ventas.length; i++) {
                const element = ventas[i];
                if (element.afecta == "credito") {
                    tot = tot + element.valor
                }
            }
        } catch (error) {

        }
        return tot
    }
    const handleRowClicked = (row) => {
        // const isRowExpanded = expandedRows.includes(row.id);

        // setExpandedRows(
        //     isRowExpanded
        //         ? expandedRows.filter((id) => id !== row.id)
        //         : [...expandedRows, row.id]
        // );
    };

    const rowPreExpanded2 = ({ data }) => {
        return <>
            <DataTable
                progressPending={pendiente}
                columns={columns2}
                data={data.movimientos}
                noTableHead={true}
                conditionalRowStyles={conditionalRowStyles3}
            />
        </>
    }
    const rowPreExpanded3 = () => {
        return <>
            <>

            </>
        </>
    }
    const previsualizarpdf = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('p', 'pt', 'letter');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdf"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            }
        })
    }

    const conditionalRowStyles2 = [

        {
            when: row => true,
            style: {
                fontSize: '10px',

            }
        }
    ];

    const conditionalRowStyles3 = [

        {
            when: row => true,
            style: {
                fontSize: '10px',
                color: '#188BAD'

            }
        }
    ];

    const previsualizarpdfcarta = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var canttt = 0;
        try {
            var contmovv = 0
            ventas.forEach(element => {
                contmovv = contmovv + element.movimientos.length
            });
            canttt = 200 + (((ventas.length + contmovv + 10) * 300) / 100)
        } catch (error) {

        }
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdf').clientWidth
        doc.html(document.querySelector("#pruebapdf"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'cierre_caja.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }

    return (
        <Row  >
            <Col sm={9}>

                <Row className="mb-2">
                    <Col sm={12}>
                        <Card>

                            <CardBody>
                                <div id="content-wrapper table-responsive" className="d-flex flex-column">

                                    {/*Main Content*/}
                                    <div id="content">

                                        <div className="container-fluid">

                                            <Row>
                                                <Col sm={12}>
                                                    <div className="table-responsive" style={{ height: '200px' }}>
                                                        <Table striped size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Medio de Pago</th>
                                                                    <th>Saldo Inicial</th>
                                                                    <th>Debito</th>
                                                                    <th>Credito</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    (movimientosmediosdepago.length < 1) ? (
                                                                        <tr>
                                                                            <td colSpan="15">Sin Datos</td>
                                                                        </tr>
                                                                    ) :
                                                                        (
                                                                            movimientosmediosdepago.map((item, index) => (
                                                                                <tr key={item.item}>

                                                                                    <td>{item.mediopago}</td>
                                                                                    <td>{fm.from(item.saldoinicial)}</td>
                                                                                    <td>{fm.from(item.debito)}</td>
                                                                                    <td>{fm.from(item.credito)}</td>
                                                                                    <td>{fm.from(item.total)}</td>



                                                                                </tr>
                                                                            ))
                                                                        )


                                                                }
                                                                <tr key={9999}>

                                                                    <td>{"Total"}</td>
                                                                    <td>{fm.from(totalsaldoinicial())}</td>
                                                                    <td>{fm.from(totaldebito())}</td>
                                                                    <td>{fm.from(totalcredito())}</td>
                                                                    <td>{fm.from(totaltotal())}</td>



                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>

                                            </Row>
                                            {/* <Outlet /> */}

                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                                <Row className="align-items-end">
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input type="date" className="form-control form-control-sm"
                                                value={fechaInicio}
                                                onChange={(e) => {
                                                    setFechaInicio(e.target.value)
                                                    console.log(e.target.value)
                                                    console.log(fechahoy)
                                                }}></Input>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>

                                            <Input value={cajaseleccionada} onChange={(e) => { setcajaseleccionada(e.target.value) }} type="select" style={{ fontSize: '13px' }} name="cajainicio"  >
                                                return <option value={"-"}>{"-"}</option>
                                                {cajasbusqueda.map((dato) => {
                                                    if (props.caja == dato.codigo) {
                                                        return <option value={dato.codigo}>{dato.nombre}</option>

                                                    }
                                                })}

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Button color="danger" size="sm" block onClick={buscar}>
                                                <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Button color="danger" size="sm" block onClick={() => { props.setcierrecaja() }}>
                                                <i className="fa fa-search" aria-hidden="true"></i> X
                                            </Button>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>

                                    <Col sm="12">
                                        <DataTable
                                            progressPending={pendiente}
                                            columns={columns}
                                            data={ventas}
                                            pagination

                                        // customStyles={customStyles}
                                        // conditionalRowStyles={conditionalRowStyles}
                                        />


                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col sm={3}>
                <Row className="mb-2">
                    <Col sm={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                                Detalle
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col sm={12}>
                                        <InputGroup size="sm" >
                                            <InputGroupText>Saldo Total:</InputGroupText>
                                            <Input disabled value={fm.from(base)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm={12}>
                                        <InputGroup size="sm" >
                                            <InputGroupText>Saldo Inicial:</InputGroupText>
                                            <Input type="text" value={iva} onChange={(e) => {
                                                setiva(Number(e.target.value))
                                            }} />
                                        </InputGroup>
                                    </Col>
                                </Row>





                            </CardBody>
                        </Card>
                    </Col>
                    <Modal size="lg" isOpen={previsualizar}>
                        <ModalHeader>
                            Opciones
                        </ModalHeader>
                        <ModalBody id={"pruebapdf"} >
                            <Row >
                                <Col sm={3}>
                                    <Row>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            Cuadre Cajero<br />
                                            ---------------------<br />
                                            {"Cajero: " + usuario.usuario.nombre}<br />
                                            {"Caja: " + cajaseleccionada}<br />
                                            {"Fecha: " + fechahoy}<br />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Table size="sm">
                                                <thead>
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimientosmediosdepago.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimientosmediosdepago.map((item, index) => (
                                                                    <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                        <td >{item.mediopago}</td>
                                                                        <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(item.total)}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                    <tr style={{ fontSize: '10px', color: 'black' }} key={9999}>

                                                        <td>{"Total"}</td>
                                                        <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(totaltotal())}</td>



                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                            Iva Discriminado<br />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Table size="sm" style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                                <thead>
                                                    <tr>
                                                        <th>%IVA</th>
                                                        <th>BASE</th>
                                                        <th>IVA</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimientoscajaiva.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Iva</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimientoscajaiva.map((item, index) => (
                                                                    <tr style={{ fontSize: '13px', color: 'black' }} key={item.porcentaje}>

                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.porcentaje)}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.baseiva)}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.iva)}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.total)}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            Firma Cajero:__________________<br />
                                            C.C:__________________<br />
                                            Firma Supervisor<br />
                                            C.C:__________________<br />
                                            ---------------------<br />
                                            Fin Cuadre<br />

                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)
                                previsualizarpdf()
                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)
                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="lg" isOpen={previsualizar2}>
                        <ModalHeader>
                            Opciones
                        </ModalHeader>
                        <ModalBody id={"pruebapdf"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            Cuadre Cajero<br />
                                            ---------------------<br />
                                            {"Cajero: " + usuario.usuario.nombre}<br />
                                            {"Caja: " + cajaseleccionada}<br />
                                            {"Fecha Generacion: " + fechahoy}<br />
                                            {"Fecha Cierre: " + fechaInicio}<br />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Table size="sm">
                                                <thead>
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (movimientosmediosdepago.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                movimientosmediosdepago.map((item, index) => (
                                                                    <tr style={{ fontSize: '15px', color: 'black' }} key={item.item}>

                                                                        <td >{item.mediopago}</td>
                                                                        <td style={{ textAlign: 'right' }}>{fm.from(item.total)}</td>



                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                    <tr style={{ fontSize: '15px', color: 'black' }} key={9999}>

                                                        <td>{"Total"}</td>
                                                        <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(totaltotal())}</td>



                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>

                                    <Row>

                                        <Col sm="12">
                                            <Table size="sm">

                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }}>
                                                        <th style={{ width: '10%', color: 'black' }}>Concepto</th>
                                                        <th style={{ width: '20%', color: 'black' }}>Concecutivo</th>
                                                        <th style={{ width: '10%', color: 'black' }}>Tercero</th>
                                                        <th style={{ width: '10%', color: 'black' }}>Medio de Pago</th>
                                                        <th style={{ width: '10%', color: 'black' }}>Debito</th>
                                                        <th style={{ width: '10%', color: 'black' }}>Credito</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (ventas.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                ventas.filter(p => p.referencia != "").map((row, index) => {

                                                                    return <><tr style={{ fontSize: '10px', color: 'black', lineHeight: '0.8' }} key={index}>

                                                                        <td style={{ textAlign: 'left', color: 'black' }} >{row.concepto}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }} >{row.numero}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }} >{row.nomcliente}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }} >{row.formapago}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{(row.afecta == "debito") ? fm.from(Number(row.valor + "")) : 0}</td>
                                                                        <td style={{ textAlign: 'left', color: 'black' }}>{(row.afecta == "credito") ? fm.from(Number(row.valor + "")) : 0}</td>



                                                                    </tr>
                                                                        {
                                                                            row.movimientos && row.movimientos.length > 0 && (
                                                                                <tr>
                                                                                    <td colSpan="6" style={{ padding: 0 }}>
                                                                                        <Table size="sm" bordered >
                                                                                            {/* <thead>
                                                                                                <tr style={{ fontSize: '10px' , lineHeight: '0.8' }}>
                                                                                                    <th>Referencia</th>
                                                                                                    <th>Descripcion</th>
                                                                                                    <th>Precio</th>
                                                                                                </tr>
                                                                                            </thead> */}
                                                                                            <tbody>
                                                                                                {row.movimientos.map((mov, movIndex) => (
                                                                                                    <tr key={movIndex} style={{ fontSize: '10px', color: '#188BAD', lineHeight: '0.8' }}>
                                                                                                        <td>{mov.referencia}</td>
                                                                                                        <td>{mov.descrip}</td>
                                                                                                        <td>{mov.cantidad}</td>
                                                                                                        <td>{mov.precio}</td>
                                                                                                        <td>{mov.cantidad*mov.precio}</td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </>
                                                                })
                                                            )


                                                    }

                                                </tbody>
                                            </Table>

                                            {/* <DataTable
                                                progressPending={pendiente}
                                                columns={columns}
                                                data={ventas}

                                                // customStyles={customStyles}
                                                conditionalRowStyles={conditionalRowStyles2}
                                                expandableRows
                                                expandableRowsComponent={rowPreExpanded2}
                                                expandableRowExpanded={rowPreExpanded3}
                                            /> */}



                                        </Col>
                                    </Row>
                                    <Row>
                                        <Table size="sm" style={{ textAlign: 'right', color: 'black' }}>
                                            <thead>
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr key={9999}>

                                                    <td>{"Total Debito:"}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(Number(totaltotal2()))}</td>



                                                </tr>
                                                <tr key={9999}>

                                                    <td>{"Total Credito:"}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(totaltotal3())}</td>



                                                </tr>
                                                <tr key={9999}>

                                                    <td>{"Total:"}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td>{""}</td>
                                                    <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(Number(totaltotal2() - totaltotal3()))}</td>



                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            Firma Cajero:__________________<br />
                                            C.C:__________________<br />
                                            Firma Supervisor<br />
                                            C.C:__________________<br />
                                            ---------------------<br />
                                            Fin Cuadre<br />

                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar2(false)
                                previsualizarpdfcarta()
                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar2(false)
                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>
                </Row>

                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={6}>

                                <Button color="success" block onClick={() => {
                                    setprevisualizar(true)

                                }} >
                                    <i className="fas fa-money-check"></i>Prev</Button>

                            </Col>
                            <Col sm={6}>

                                <Button color="success" block onClick={() => {
                                    setprevisualizar2(true)

                                }} >
                                    <i className="fas fa-money-check"></i>Prev 2</Button>

                            </Col>
                            <Col sm={6}>
                                <Button color="success" block onClick={() => {
                                    terminarVenta()
                                }} >
                                    <i className="fas fa-money-check"></i>Cierre</Button>

                            </Col>
                            <Col sm={6}>
                                <Button color="success" block onClick={() => {
                                    abrirCaja()
                                }} >
                                    <i className="fas fa-money-check"></i>Reabrir Caja</Button>

                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </Col>
        </Row>
    )
}

export default Venta;